import React from "react"
import Listings from "container/Listings"
import root from "window-or-global"
import { enabledFeatureCheck } from "services/Utils"
import {
  InnerTab,
  NavLink,
} from "./commonStyles"
import Contracts from "../Icons/NewIcons/Contracts"

const SubmitTransactionTab = ({
  routeArray,
  isBroker,
  clearExistingData: clearListingData,
}) => (
  <InnerTab
    isActive={routeArray[1] === "transaction-coordination" || routeArray[1] === "contracts"}
  >
    <div className="icon-block-new">
      <Contracts />
    </div>
    <div className="text-block">
      <NavLink
        to={enabledFeatureCheck({ isBroker, tabName: "transaction_management_contract" }) ? "/realty/transaction-coordination" : "/realty/promos?type=contracts"}
        isActive={routeArray[1] === "transaction-coordination" || routeArray[1] === "contracts"}
        onClick={() => {
          root.scrollTo(0, 0)
          clearListingData()
          if (root.heap) {
            root.heap.track("click_sidebar_productAndServicesRAR_web")
          }
        }}
      >
        My Contracts
        {/* {CookiesStorage.load("submitContracts") && (
          <ImageSpan teamProfile>
            <img src={NewFeature} alt="newFeature.png" />
          </ImageSpan>
        )} */}
      </NavLink>
    </div>
  </InnerTab>
)

export default Listings(SubmitTransactionTab)
