import React from "react"
import styled from "styled-components"
import Clock from "images/Graphics/MortgageLandingPageAssets/Clock.png"
import Calendar from "images/Graphics/MortgageLandingPageAssets/Calendar.png"
import { ATC } from "@fonts"

const Wrap = styled.div`
  background-image: url("/images/HalfLogo.jpg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: -50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px 0px 50px 0px;

  @media(max-width: 767px) {
    height: max-content;
    padding: 40px 30px;
  }

`

const Image = styled.img`
  height: 73px;
  width: 73px;
`

const Header = styled.div`
 p {
  font-family: Butler;
  font-size: 28px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0.005em;
  text-align: center;
  margin-bottom: 0px;
 }
`
const Description = styled.div`
 width: 75%;
 max-width: 1005px;
 margin-top: 15px;

 p {
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
 }
`

const CardWrap = styled.div`
 display: flex;
 flex-direction: row;
 justify-content: center;
 gap: 40px;
 background: #fff;
 margin-top: 50px;

 @media(min-width: 1024px) {
   width: 800px;
 }

 @media(min-width: 1366px) {
   width: 100%;
 }

 @media(max-width: 767px) {
   flex-direction: column;
 }
`

const Card = styled.div`
  height: max-content;
  width: 508px;
  border-radius: 10px;
  box-shadow: 2px 4px 30px 0px #EAEAEA;
  padding-top: 69px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
`

const ImageWrap = styled.div`
 display: flex;
 justify-content: center;
`

const Title = styled.div`
  p {
    font-family: Butler;
    font-size: 22px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.015em;
    text-align: center;
  }
`

const Specification = styled.div`
  width: 342px;
  margin: 0 auto;
  p {
    font-family: ${ATC};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }
`

const Speed = () => (
  <Wrap>
    <Header>
      <p>Speed is Everything</p>
    </Header>
    <Description>
      <p>
        {`
          You got your clients to the finish line, let’s not make them wait. 
          Loandesk Mortgage prides itself on getting your buyers into their new home as efficiently as possible.
        `}
      </p>
    </Description>
    <CardWrap>
      <Card>
        <ImageWrap>
          <Image src={Clock} height={73} width={73} />
        </ImageWrap>
        <Title>
          <p>24-Hour Pre-Approvals</p>
        </Title>
        <Specification>
          <p>
            {`
              Set your clients up for success—Radius’ 24-hour 
              pre-approval process makes it easy for you to get 
              your buyers in front of the right properties fast.
            `}
          </p>
        </Specification>
      </Card>
      <Card>
        <ImageWrap>
          <Image src={Calendar} height={73} width={73} />
        </ImageWrap>
        <Title>
          <p>14-Day Clear To Close</p>
        </Title>
        <Specification>
          <p>
            {`
              Radius’ average Clear to Close time is less than 14 
              days—we’re that fast. Our expert MLOs take your 
              timeline seriously to ensure your client has the best experience possible.
            `}
          </p>
        </Specification>
      </Card>
    </CardWrap>
  </Wrap>
)

export default Speed
