import styled from "styled-components"
import {
  Checkbox,
  Button,
} from "@ui/antd"
import { PlusOutlined } from '@ant-design/icons';
import { ATC, INTER, MONTSERRAT } from "@fonts"

export const ContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  background-color: #F1F1FE;
  margin: 10px;
  border-radius: 4px;
  border: 1px solid #b8b9ee;
`

export const AddButtonContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
`

export const StyledCheckbox = styled(Checkbox)`
  ${props => props.blackbox && `
      .ant-checkbox-inner {
        border-color: #292A37;
        outline: 0.2px solid #292A37;
      }
  `}

  ${props => props.inMenu && `
    color: var(--Color-Tertiary, #1C1C1F);
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%; /* 15.052px */
  `}
`

export const AddDocument = styled(Button)`
display: flex;
width: 100%;
height: 45px;
padding: 20px;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 1px;
border-radius: 8px;
border: 1px solid var(--Color-Secondary-Secondary, #A5A8FF);
background: var(--Color-Neutral-100, #F1F1FE);
color: var(--Color-Primary-Primary, #5A5FF2);
font-family: ${MONTSERRAT};
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
text-transform: uppercase;
margin-top: 10px
`
export const CustomPlusOutlined = styled(PlusOutlined)`
  color: var(--Color-Primary-Primary, #5A5FF2);
`;

export const CheckboxText = styled.p`
  margin: 0;
  font-size: 16px;
  font-family: ${MONTSERRAT};
  color: #41446A;
`

export const CTAWrap = styled.div`
  display: flex;
`

export const CTAButton = styled.button`
  display: flex;
  gap: 5px;
  border: none;
  background: none;
  align-items: center;
  cursor: pointer;
`

export const CTAText = styled.p`
  margin: 0;
  color: #5A5FF2;
  font-size: 10px;
  font-family: ${MONTSERRAT};
`

export const AttachmentText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  p {
    color: var(--Typography-General-Primary, #303030);
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 16px; /* 133.333% */
    margin: 0;
  }
  
  img {
    width: 12px;
    height: 13px;
    position: relative;
    top: 3px;
  }
`

export const SubHeadingWrap = styled.div`
display: flex;
height: 15px;
flex-direction: column;
justify-content: center;
color: var(--Dark-Gray-Dark-Gray-2, #252C32);
font-family: ${INTER};
font-size: 12px;
font-style: normal;
font-weight: 400;
letter-spacing: -0.072px;
margin-top: 35px;
`

