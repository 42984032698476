import React from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import OnboardingContainer from "container/OnboardingSetup"
import { ToastContainer, toast } from "react-toastify"
// import Button from "@ui/Button"
import moment from "moment"
import Button from "@ui/LoaderButton"

const FormWrap = styled.div`
  margin-left: 31px;
  display: flex;
  height: 650px;
  flex-direction: column;

  &::-webkit-scrollbar { 
    display: none;
  }
`
const TopDiv = styled.div`
  display: flex;
`
const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
`
const SecondDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`

const Label = styled.div`
width: 212.71px;
height: 22px;
font-family: ${ATC};
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 22px;
color: #303030;
margin-bottom: 8px;
`
const TextInputSmall = styled.input`
  width: 212.7px;
  height: 49px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 0px;
  padding-left: 8px;
  font-weight: 200;
  font-size: 16px;
`
const TextInputBig = styled.input`
  width: 449.43px;
  height: 49px;
  font-weight: 200;
  font-size: 16px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 0px;
  padding-left: 8px;
`
const CheckboxComponent = styled.div`
  display: flex;
  margin-top: 8.5px;
  margin-bottom: 24.5px;
`
const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  font-weight: 200;
  font-size: 16px;
  border: 1px solid #C7C7C7;
  border-radius: 4px;
`
const Text = styled.h3`
  height: 16px;
  margin: 0px;
  margin-left: 8px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
`
const ContinueButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: white;
  gap: 10px;
  width: 449.43px;
  height: 48px;
  background: #3B60E4;
  border-radius: 36px;
  margin-top: 24.5px;
  margin-bottom: 16px;
  border: 0px;
  cursor: pointer;

  ${props => props.disabled === true && `
    background: #989898;
    color: white;
    cursor: not-allowed;
  `}
`
const GoBackButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 449.43px;
  margin-bottom: 16px;
  height: 48px;
  border: 1.25px solid #3B60E4;
  border-radius: 36px;
  color: #3B60E4;
  cursor: pointer;
`
const SaveForLater = styled.h3`
  display: flex;
  align-self: center;
  width: 113px;
  height: 22px;
  margin: 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #8C8C8C;
  cursor: pointer;
`

class FirstFormDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    const { response } = this.props
    this.state = {
      form: {
        step_completed: "basic_license_information",
        real_estate_license_number: response?.real_estate_license_number ? response.real_estate_license_number : null,
        license_expire_date: response?.license_expire_date ? response.license_expire_date : null,
        local_associations: response?.local_associations ? this.cleanString(response.local_associations) : null,
        mls: response?.all_mls ? this.cleanString(response.all_mls) : null,
        help_with_mls_transfer: response?.help_with_mls_transfer ? response.help_with_mls_transfer : 0,
        agent_mls_id: response?.agent_mls_id ? response.agent_mls_id : null,
      },
    }
  }

  checkAPIToCall = () => {
    const { response } = this.props
    if (response.real_estate_license_number) {
      this.updateOrientationInfoFunction()
    } else {
      this.createOrientationInfoFunction()
    }
    this.setState({
      isLoading: true,
    })
  }

  createOrientationInfoFunction = () => {
    const { createOrientationInfo, setFormStage } = this.props
    
    const { form } = this.state
    const payload = { ...form }
    createOrientationInfo({ payload, setStage: "2" })
    // setFormStage("2")
  }

  updateOrientationInfoFunction = () => {
    const { updateOrientationInfo, setFormStage } = this.props
    // setFormStage("2")
    const { form } = this.state
    const payload = { ...form }
    updateOrientationInfo({ payload, setStage: "2" })
  }

  handleClose = () => {
    const { setFormStage, setIsGoBack } = this.props
    setIsGoBack(true)
    setFormStage("0")
  }

  validateForm = () => {
    const { form } = this.state
    if (
      !form.real_estate_license_number
      || !form.license_expire_date
      || !form.local_associations
      || !form.mls
      || !form.agent_mls_id
    ) {
      return true
    }
    return false
  }

  cleanString = (str) => {
    if (str && str.length) {
      return str.map(dt => dt.trim()).join(", ")
    }
    return ""
  }

  render() {
    const {
      form,
      licenseNumberChange,
      licenseDateChange,
      localAsscociationsChange,
      mlsChange,
      agentMlsIdChange,
      isLoading,
    } = this.state
    const { response, fetchOrientationInfoResponse, createOrientationInfoResponse } = this.props
    return (
      <FormWrap>
        <TopDiv>
          <FirstDiv>
            <Label>
              Real Estate License No.
              <span style={{ color: "#F53F3F" }}>*</span>
            </Label>
            <TextInputSmall
              value={licenseNumberChange ? form.real_estate_license_number : response.real_estate_license_number}
              // required={response?.real_estate_license_number ? false : true}
              onChange={(e) => {
                this.setState({
                  form: {
                    ...form,
                    real_estate_license_number: e.target.value,
                    licenseNumberChange: true,
                  },
                  licenseNumberChange: true,
                })
              }}
            />
          </FirstDiv>
          {/* {response && this.defaultValueSet()} */}
          <SecondDiv>
            <Label>
              License Expiration Date
              <span style={{ color: "#F53F3F" }}>*</span>
            </Label>
            <TextInputSmall
              type="date"
              value={licenseDateChange ? form.license_expire_date : response.license_expire_date}
              // required={response?.license_expire_date ? false : true}
              onChange={(e) => {
                this.setState({
                  form: {
                    ...form,
                    license_expire_date: e.target.value,
                  },
                  licenseDateChange: true,
                })
              }}
              style={{ paddingLeft: "20px", paddingRight: "10px" }}
              label="License Expiration Date"
            />
          </SecondDiv>
        </TopDiv>
        <Label style={{ marginTop: "22px", marginBottom: "8px" }}>
          Local Association(s)
          <span style={{ color: "#F53F3F" }}>*</span>
        </Label>
        <TextInputBig
          value={localAsscociationsChange ? form.local_associations
            : response.local_associations
              ? this.cleanString(response.local_associations) : ""
          }
          // required={response?.local_associations ? false : true}
          onChange={(e) => {
            this.setState({
              form: {
                ...form,
                local_associations: e.target.value,
              },
              localAsscociationsChange: true,
            })
          }}
          label="Local Association(s)"
        />
        <Label style={{ marginTop: "22px", marginBottom: "8px" }}>
          MLS(s)
          <span style={{ color: "#F53F3F" }}>*</span>
        </Label>
        <TextInputBig
          placeholder="abc, efg, hij"
          value={mlsChange ? form.mls
            : response.all_mls
              ? this.cleanString(response.all_mls) : ""
          }
          // required={response?.all_mls ? false : true}
          onChange={(e) => {
            this.setState({
              form: {
                ...form,
                mls: e.target.value,
              },
              mlsChange: true,
            })
          }}
          label="MLS(s)"
        />
        <CheckboxComponent>
          <Checkbox
            defaultChecked={response?.help_with_mls_transfer === 1}
            onChange={(e) => {
              this.setState({
                form: {
                  ...form,
                  help_with_mls_transfer: e.target.checked === true ? 1 : 0,
                },
              })
            }}
            type="checkbox"
          />
          <Text>Help with MLS transfer</Text>
        </CheckboxComponent>
        <Label style={{ marginTop: "22px", marginBottom: "8px" }}>
          Agent MLS ID
          <span style={{ color: "#F53F3F" }}>*</span>
        </Label>
        <TextInputBig
          value={agentMlsIdChange ? form.agent_mls_id : response.agent_mls_id}
          // required={response?.agent_mls_id ? false : true}
          onChange={(e) => {
            this.setState({
              form: {
                ...form,
                agent_mls_id: e.target.value,
              },
              agentMlsIdChange: true,
            })
          }}
          label="Agent MLS ID"
        />
        <ContinueButton
          isShowLoader={isLoading}
          disabled={this.validateForm()}
          onClick={this.checkAPIToCall}
        >
          Continue
        </ContinueButton>
        <GoBackButton onClick={this.handleClose}>Go back</GoBackButton>
        <SaveForLater onClick={this.handleClose}>Save For Later</SaveForLater>
      </FormWrap>
    )
  }
}

export default OnboardingContainer(FirstFormDetails)
