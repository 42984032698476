import React, { useState, useEffect } from "react";
import ListingInfo from "../ListingInfo/index";
import Highlights from "../Highlights/index";
import {
  ListedBy,
  Description,
  TabsWrapper,
  Tabs,
  Header,
  Container,
  ButtonShow,
} from "./styles";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import {DownOutlined, UpOutlined} from "@ant-design/icons";

const WhatsSpecial = ({ listingPageResponse }) => {
  const [expanded, setExpanded] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  const checkWindowWidth = () => {
    setIsSmallScreen(window.innerWidth < 821);
  };

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener("resize", checkWindowWidth);
    return () => {
      window.removeEventListener("resize", checkWindowWidth);
    };
  }, []);

  return (
    <Container>
      <ListingInfo />

      {listingPageResponse?.data?.highLights && <Highlights />}
      <hr></hr>
      {(listingPageResponse?.data?.Utilities?.length > 0 ||
        listingPageResponse?.data?.PublicRemarks ||
        listingPageResponse?.data?.ListAgentFullName) && (
        <>
          <Header>About this property</Header>
          <TabsWrapper>
            {listingPageResponse?.data?.Utilities?.length > 0 &&
              listingPageResponse?.data?.Utilities.map((utility, index) => (
                <Tabs key={index}>{utility}</Tabs>
              ))}
          </TabsWrapper>

          {listingPageResponse?.data?.PublicRemarks && (
            <>
              <Description>
                {expanded
                  ? listingPageResponse?.data?.PublicRemarks
                  : `${listingPageResponse?.data?.PublicRemarks
                      .split(" ")
                      .slice(0, 20)
                      .join(" ")}${
                      // isSmallScreen ? "..." : ""
                      "..."
                    }`} 
              </Description>
              {
                listingPageResponse?.data?.PublicRemarks.split(" ")?.length >
                  20 && (
                  <ButtonShow onClick={toggleDescription}>
                    {expanded ? (<>Show Less <UpOutlined style={{marginTop: "1px", marginLeft: "5px"}} /></>) : (<>Show More <DownOutlined style={{marginTop: "1px", marginLeft: "5px"}}/></>)}
                  </ButtonShow>
                )}
            </>
          )}

          {listingPageResponse?.data?.ListAgentFullName && (
            <ListedBy>
              Listed by: {listingPageResponse?.data?.ListAgentFullName},{" "}
              {listingPageResponse?.data?.ListOfficePhone}
            </ListedBy>
          )}
          {listingPageResponse?.data?.Id && (
            <ListedBy style={{paddingBottom: "24px", marginBottom: "24px"}}>
              Source: MLSListings Inc, MLS#:{" "}
              {listingPageResponse?.data?.Id || "-"}
            </ListedBy>
          )}
        </>
      )}
      <hr></hr>
    </Container>
  );
};

export default ListingPageContainer(WhatsSpecial);
