import styled from "styled-components";
import colors from "@colors";

const StyledButton = styled.button`
    width: 368px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-radius: 32px;
    border: none;
    background-color: #5A5FF2;
    color: white;

    &:hover{
        background-color: #4043AC;
    }
`

const StyledContactButton = styled.button`
    width: 368px;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-radius: 32px;
    background-color: white;
    color: #232338;
    border: 1px solid #E0E0EB;

    &:hover{
        background-color: #EFEFFE;
  }
`

const BottomPaneForMobile = styled.div`
    display: none;
    @media (max-width: 1024px) {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid ${colors.new_tab_color};
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 96px;
        padding-bottom: 24px;
        background-color: white;
    }
`

const MobileButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 40px;
margin: 16px 16px;
width: 175px;
gap: 8px;
flex: 1 0 0;
border-radius: 32px;
`;

const WebContainer = styled.div`
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1024px) {
        display: none;
    }
`;

const OrWrap = styled.div`
    display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;
`

export { StyledButton, BottomPaneForMobile, MobileButton, WebContainer, StyledContactButton, OrWrap };