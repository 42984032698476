import React from "react"
import styled from "styled-components"
import Kris from "images/Graphics/MortgageLandingPageAssets/Kris.jpg"
import Jeero from "images/Graphics/MortgageLandingPageAssets/Jeero.jpg"
import { ATC } from "@fonts"

// import Image from "next/image"

const Wrap = styled.div`
  height: max-content;
  background: #f1f1f1;
  width: 100%;
  display: flex;
  padding: 81px 68px;
  flex-direction: column;
  gap: 60px;

  @media(max-width: 767px) {
    height: max-content;
  }
`

const Header = styled.div``

const ElevatedService = styled.div`
 h2 {
  font-family: Butler;
  font-size: 22px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0.005em;
  text-align: left;  
 }
`

const AsARadiusAgent = styled.div`
 p {
  font-family: ${ATC} ;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
 }

 @media(max-width: 767px) {
   margin-bottom: 150px;
 }
`

const Testimonial = styled.div`
 gap: 50px;
 display: flex;
 -webkit-box-pack: center;
 -webkit-justify-content: center;
 -ms-flex-pack: center;
 justify-content: center;

 @media(max-width: 767px) {
   flex-direction: column;
   gap: 150px
 }
`

const TestimonialCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  width: 41%;
  max-width: 800px;

  @media(min-width: 1366px) {
    gap: 20px;
    width: 50%;
    max-width: 500px;
    
  }
`

const ImageCard = styled.div`
  border-radius: 10px;
  background-image: url(${props => props.image});
  background-size: contain;
  background-repeat: no-repeat;

  @media(min-width: 1024px) {
    height: 265px;
    width: 100%;
  }

  @media(max-width: 767px) {
    height: 380px;
    width: 100%;
    background-size: cover;
    background-position: center;
  }
`

const TextCard = styled.div`
 width: 100%;
 display: flex;
 flex-direction: column;
 gap: 20px;

 @media(max-width: 767px) {
   height: max-content;
   width: 100%;
 }
`

const Quote = styled.div`
  min-height: 160px;
  p {
    font-family: ${ATC};
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
  }

  @media(min-width: 1366px) {
    min-height: 170px;
  }
`

const About = styled.div`
  p {
    font-family: ${ATC};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 2px;
    text-align: left;
    /* or 140% */

    letter-spacing: 2px;
    text-transform: uppercase;

    color: #306F4D;

    //styleName: Subtitle/Small;
  }
`

const Elevated = () => (
  <Wrap>
    <Header>
      <ElevatedService>
        <h2>
          Elevated Service
        </h2>
      </ElevatedService>
      <AsARadiusAgent>
        <p>
          {`
          As a Radius Agent, you receive premiere prioritization and service 
          from our partnership. Radius Loan Officers are here to make sure that 
          you and your buyers are provided the white glove service you deserve.`}
        </p>
      </AsARadiusAgent>
    </Header>
    <Testimonial>
      <TestimonialCard>
        <ImageCard image={Kris}></ImageCard>
        <TextCard>
          <Quote>
            <p>
              {`
                “In a tough market, having lending partner support 
                is the key to ensuring you’re staying top of mind 
                with your buyer base. Loandesk Mortgage allows us 
                for a quick close and gives confidence to the listing agent!”
              `}
            </p>
          </Quote>
          <About>
            <p>KRIS STEVENSON, RADIUS REALTOR</p>
          </About>
        </TextCard>
      </TestimonialCard>
      <TestimonialCard>
        <ImageCard image={Jeero}></ImageCard>
        <TextCard>
          <Quote>
            <p>
              {`
              "I refer our clients to Loandesk Mortgage because 
              I know they'll experience award-winning customer 
              service, be educated on the entire mortgage lending 
              process, and obtain a great loan with the best terms 
              in the industry. I receive great feedback from our clients about their Loandesk Mortgage experience."
              `}
            </p>
          </Quote>
          <About>
            <p>JEERO HABESHIAN, RADIUS REALTOR</p>
          </About>
        </TextCard>
      </TestimonialCard>
    </Testimonial>
  </Wrap>
)

export default Elevated
