/* eslint-disable max-len */
/* eslint-disable quotes */
import styled from "styled-components"
import colors from "@colors"
import { MONTSERRAT, INTER } from "@fonts"
import { Menu as MenuIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import { NewSelect } from "@ui/antd/Forms"

export const HeaderContainer = styled.div`
  display: flex;
  color: ${props => props.fontColor || colors.new_light};
  padding: 32px;
  flex-direction: column;
  background: ${colors.new_blueGradient};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  gap: 10px;
`

export const CloseWrap = styled.div`
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;

  p {
    font-size: 18px;
    margin: 0;
    color: ${props => props.fontColor || colors.new_light};
    font-family: ${INTER};
  }
`

export const StyledMenuIcon = styled(MenuIcon)`
  padding: 2px;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  height: auto;
  width: auto;
`

export const AgentName = styled.p`
  font-style: normal;
  font-weight: 500;
  margin-right: 14px;
  font-family: ${props => props.fontFamily || MONTSERRAT};
  font-size: ${props => props.fontSize || "28px"};
  color: ${props => props.fontColor || colors.new_light};
`

export const Subtext = styled.span`
  font-family: ${props => props.fontFamily || INTER};
  font-size: ${props => props.fontSize || "16px"};
  line-height: 1.2;
  color: ${props => props.fontColor || colors.new_light};
  ${props => props.margin && `margin: ${props.margin}`}
  ${props => props.textAlign && `text-align: ${props.textAlign}`}
`

export const Separator = styled.span`
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  font-family: ${props => props.fontFamily || INTER};
  font-size: ${props => props.fontSize || "16px"};
  color: ${props => props.fontColor || colors.new_light};
`

export const PriceContainer = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 28px;
  font-family: ${props => props.fontFamily || MONTSERRAT};
  color: ${props => props.fontColor || colors.new_light};
  text-align: right;
`

export const ShortProfileViewContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #E0E6ED;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ContactInfoRow = styled.div`
  display: flex;
  gap: 12px;
`

export const NameAndProfileContainer = styled.div`
  font-family: ${INTER};
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${colors.new_colorNeutral900};

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    margin: 0;
  }

  span.primary {
    background-color: ${colors.new_primary};
    color: ${colors.white};
    font-family: ${INTER};
    font-size: 12px;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 24px;
    height: 20px;
    box-sizing: content-box;
  }
`

export const ContactInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:not(:first-child) {
    border-left: 1px solid #EDEDED;
    padding-left: 5px;
  }

  .icon {
    border-radius: 100%;
    background-color: ${colors.new_grey50};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 28px;
    min-width: 28px;
    max-height: 28px;
    max-width: 28px;

    svg {
      transform: scale(0.75);
    }
  }

  .info {
    overflow: hidden;
    color: ${colors.new_primary}
    font-family: ${INTER};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
`
export const SectionHeading = styled.h2`
  color: ${colors.new_typographyGeneralPrimary};
  font-family: ${INTER};
  font-size: 18px;
  font-weight: 500;
  margin: 0;
`
export const TabContentContainer = styled.div`
  border-radius: 11px;
  border: 1px solid ${colors.moonGrey};
  background: ${colors.white};
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
`

export const EditCoSellerContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.new_secondary};
  background: rgba(255, 255, 255, 0.1);
  padding: 16px 12px;
  display: flex;
  flex-direction: column;
`
export const StatusColors = [
  {
    label: "New Client/Accepted",
    value:
      'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" fill="none"><g clip-path="url(%23a)"><circle cx="5" cy="5.5" r="5" fill="url(%23b)"/></g><defs><radialGradient id="b" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(61.031 -.716 4.58) scale(8.12967)"><stop stop-color="%23B8BAF9"/><stop offset=".333" stop-color="%23898CF6"/><stop offset=".536" stop-color="%235A5FF2"/><stop offset=".755" stop-color="%232A31EE"/><stop offset="1" stop-color="%231117D5"/></radialGradient><clipPath id="a"><path fill="%23fff" transform="translate(0 .5)" d="M0 0h10v10H0z"/></clipPath></defs></svg>\')',
  },
  {
    label: "Met with Client",
    value:
      'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><circle cx="5" cy="5" r="5" fill="url(%23a)"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(61.031 -.291 4.33) scale(8.12967)"><stop stop-color="%23C0EED1"/><stop offset=".333" stop-color="%23F2DFB6"/><stop offset=".536" stop-color="%23E9CB87"/><stop offset=".755" stop-color="%23E1B85B"/><stop offset="1" stop-color="%23D9A530"/></radialGradient></defs></svg>\')',
  },
  {
    label: "Pre-approved/Listing Prepped",
    value:
      'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><circle cx="5" cy="5" r="5" fill="url(%23a)"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(61.031 -.291 4.33) scale(8.12967)"><stop stop-color="%23C0EED1"/><stop offset=".333" stop-color="%2387E8AB"/><stop offset=".536" stop-color="%230DE95E"/><stop offset=".755" stop-color="%2348C376"/><stop offset="1" stop-color="%232EA85B"/></radialGradient></defs></svg>\')',
  },
  {
    label: "Showings/Tours",
    value:
      'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><circle cx="5" cy="5" r="5" fill="url(%23a)"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(61.031 -.291 4.33) scale(8.12967)"><stop stop-color="%23FFF7F0"/><stop offset=".333" stop-color="%23FFF2E6"/><stop offset=".536" stop-color="%23FFD8B2"/><stop offset=".755" stop-color="%23FFBE80"/><stop offset="1" stop-color="%23FFA54D"/></radialGradient></defs></svg>\')',
  },
  {
    label: "Sending/Receiving Offers",
    value:
      'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><circle cx="5" cy="5" r="5" fill="url(%23a)"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(61.031 -.291 4.33) scale(8.12967)"><stop stop-color="%23FCC5C5"/><stop offset=".333" stop-color="%23F43434"/><stop offset=".536" stop-color="%23E90D0D"/><stop offset=".755" stop-color="%23B80A0A"/><stop offset="1" stop-color="%23870808"/></radialGradient></defs></svg>\')',
  },
  {
    label: "In Contract",
    value:
      'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><circle cx="5" cy="5" r="5" fill="url(%23a)"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(61.031 -.291 4.33) scale(8.12967)"><stop stop-color="%23E0E0E0"/><stop offset=".333" stop-color="%23B3B3B3"/><stop offset=".536" stop-color="%23999"/><stop offset=".755" stop-color="gray"/><stop offset="1" stop-color="%23666"/></radialGradient></defs></svg>\')',
  },
  {
    label: "Closed",
    value:
      'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><circle cx="5" cy="5" r="5" fill="url(%23a)"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(61.031 -.291 4.33) scale(8.12967)"><stop stop-color="%23E6D3EE"/><stop offset=".333" stop-color="%23C092D4"/><stop offset=".536" stop-color="%23AB6DC5"/><stop offset=".755" stop-color="%239649B7"/><stop offset="1" stop-color="%23783A92"/></radialGradient></defs></svg>\')',
  },
  {
    label: "Archive",
    value:
      'url(\'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none"><circle cx="5" cy="5" r="5" fill="url(%23a)"/><defs><radialGradient id="a" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="rotate(61.031 -.291 4.33) scale(8.12967)"><stop stop-color="%23F8DDC9"/><stop offset=".333" stop-color="%23EDA978"/><stop offset=".536" stop-color="%23E68C4C"/><stop offset=".755" stop-color="%23E06F1F"/><stop offset="1" stop-color="%23B35919"/></radialGradient></defs></svg>\')',
  },
]

export const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;

  ${props => `
    background-image: ${props.bg};
    background-size: 100% 100%;
    background-position: 0px 0px;
  `}
`

export const StatusSelectContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;

  ${StatusIndicator} {
    transform: translateX(22px) !important;
  }

  ${NewSelect} {
    .ant-select-selector {
      border-radius: 4px !important;
      border: 1px solid ${colors.new_colorNeutral200} !important;
      color: ${props => props.isLight ? colors.new_colorNeutral50 : colors.new_grey600} !important;
      box-shadow: none !important;
      font-family: ${MONTSERRAT} !important;
      padding: 6px 6px 6px 28px !important;
      text-transform: uppercase !important;
      font-size: 10px !important;
      font-weight: 600 !important;
      letter-spacing: 0.5px !important;
      height: 28px !important;
    }

    &.ant-select-focused .ant-select-selector {
      color: ${props => props.isLight ? colors.new_colorNeutral50 : colors.new_grey600} !important;
      border: 1px solid ${colors.new_colorNeutral200} !important;
    }

    .ant-select-arrow {
      color: ${props => props.isLight ? colors.new_colorNeutral50 : colors.new_grey600} !important;
    }

    &.ant-select-focused .ant-select-selector .ant-select-selection-item {
      color: ${props => props.isLight ? colors.new_colorNeutral50 : colors.new_grey600} !important;
    }
  }
`

export const ShimmerWrapper = styled.div`
  padding: 20px;
`

export const ProfileDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 11px;
  border: 1px solid #D3DAE8;
  background: ${colors.white};
`

export const ProfileDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #EFEFEF;
  width: 100%;
`

export const ProfileDetailsTitle = styled.h2`
  color: ${colors.new_typographyGeneralPrimary};
  font-family: ${INTER};
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
`

export const PropertyCardsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px;
  width: 100%;
`

export const PropertyCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${colors.new_colorNeutral200};
  box-shadow: 0px 2px 6px 0px rgba(16, 24, 40, 0.06);
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 2px 10px 4px rgba(16, 24, 40, 0.06);
  }
`

export const PropertyCardDetailsContainer = styled.div`
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`

export const PropertyCardBudget = styled.h3`
  margin: 0;
  color: ${colors.new_colorNeutral900};
  font-family: ${INTER};
  font-size: 24px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.48px;
`

export const PropertyCardStatus = styled.div`
  border-radius: 24px;
  border: 1px solid ${colors.new_grey400};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  color: ${colors.new_grey400};
  text-align: center;
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-left: 8px;
`

export const PropertyCardAddress = styled.p`
  color: ${colors.new_colorNeutral500};
  font-family: ${INTER};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
`

export const PropertyCardSpecifications = styled.p`
  color: ${colors.new_colorNeutral500};
  font-family: ${INTER};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`

export const PropertyCardLastUpdated = styled.p`
  color: ${colors.new_secondary};
  font-family: ${INTER};
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  text-align: right;
  margin: 0;
`
export const PropertyCardCta = styled.button`
  color: ${colors.new_primary};
  text-align: center;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: underline;
  padding: 0;
  margin: 0;
  outline: 0;
  border: 0;
  background: none;
  margin-top: auto;
`

export const PropertyCardLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-right: 12px;
`

export const PropertyCardRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-left: 12px;
  height: 100%;
`

export const PropertyCardTabsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`

export const PropertyCardTab = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: ${colors.new_primary};
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  height: 46px;
  cursor: pointer;
  flex: 1 1 auto;
  background-color: #F9FAFB;
  transition: background-color 0.2s ease-out;

  &:not(:first-of-type) {
    border-left: 1px solid ${colors.new_colorNeutral300};
  }

  &:hover {
    background-color: ${colors.new_colorNeutral100};
  }
`

export const PropertyCardImage = styled.div`
  width: 94px;
  max-width: 94px;
  height: 94px;
  max-height: 94px;
  border-radius: 6px;
  background-color: ${colors.new_primary50};
  display: block;
  margin-right: 8px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(99,102,241,1)"><path d="M6 19H18V9.15745L12 3.7029L6 9.15745V19ZM19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20C20 20.5523 19.5523 21 19 21ZM7.5 13H9.5C9.5 14.3807 10.6193 15.5 12 15.5C13.3807 15.5 14.5 14.3807 14.5 13H16.5C16.5 15.4853 14.4853 17.5 12 17.5C9.51472 17.5 7.5 15.4853 7.5 13Z"></path></svg>');
  background-size: 32px;
  background-position: center center;
  background-repeat: no-repeat;
`
