import React, { useEffect } from "react"
import styled from "styled-components"
import Rooms from "container/Rooms/index"
import { Textarea } from "@ui/Form"
import colors from "@colors"
import {
  Header,
  FormWrap,
  InnerFormWrap,
} from "../common"
import {
  getNextPage,
} from "../commonUtils"
import Form from "./form"

const Wrap = styled.div`
  width: 955px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`

const StyledOpen = styled.div`
  @keyframes showUp{
    0% {
      height: 0px;
      opacity: 0;
    }

    50% {
      height: max-content;
    }

    100% {
      opacity: 1;
    }
  }

  animation: showUp .5s ease-in forwards;
`

const InnerWrap = styled.div`
 display: flex;
 flex-direction: column;
 width: 100%;
`

const RoomDetails = ({
  getTagsResponse,
  getTags,
  clearTags,
  toggleImageUploadModal,
  isImageUploadOpen,
  createRoomPageSwitch,
  currentImage,
  specialRoomType = "",
  createPayload,
  eventData,
  topics,
  getRoomTopicsResponse,
  toggleDeleteRoomModal,
  getRoomsMetaDataConfig,
}) => {
  const {
    isFetching: fetchingTopics,
  } = getRoomTopicsResponse || {}

  useEffect(() => {
    if (!topics || topics === null) {
      getRoomsMetaDataConfig()
    }
  }, [])

  const handleSubmit = (values) => {
    const nextPage = getNextPage(specialRoomType)
    const {
      topic_id,
    } = values || {}

    const payload = {
      ...values,
      image_url: currentImage,
    }

    if (specialRoomType === "scheduled" || specialRoomType === "property") {
      payload.topic_id = topic_id.value
    }

    createPayload(payload)

    createRoomPageSwitch({
      page: nextPage,
      type: specialRoomType,
    })
  }

  const roomTags = getTagsResponse.data || {}

  return (
    <Wrap>
      <Header>
        <p>{specialRoomType === "scheduled" ? "Schedule a Room" : "Create a Live Room"}</p>
      </Header>
      <FormWrap>
        <InnerWrap>
          <InnerFormWrap>
            {!fetchingTopics && (
              <StyledOpen>
                <Form
                  topics={topics}
                  onSubmit={handleSubmit}
                  roomTags={roomTags}
                  getTags={getTags}
                  clearTags={clearTags}
                  toggleImageUploadModal={toggleImageUploadModal}
                  isImageUploadOpen={isImageUploadOpen}
                  setPage={createRoomPageSwitch}
                  specialRoomType={specialRoomType}
                  eventData={eventData}
                  toggleDeleteRoomModal={toggleDeleteRoomModal}
                />
              </StyledOpen>
            )}
          </InnerFormWrap>
        </InnerWrap>
      </FormWrap>
    </Wrap>
  )
}

export default Rooms(RoomDetails)
