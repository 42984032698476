import React from "react"
import { withRouter } from "react-router-dom"
import ListingContainer from "container/Listings"
import ErrorModal from "dumbComponents/common/GlobalModals/ErrorModal"
import AppErrorBoundary from "dumbComponents/common/ErrorBoundary"
import Routes from "./Routes"
import {
  ListingModal,
  ViewContract,
  GoToContract,
  MLSModal,
  StatusChange,
  ViewListing,
} from "../Modals"

const Listings = ({
  isErrorModalOpen,
  toggleErrorModal,
  isViewListingContractModalOpen,
  viewContractForListing,
  isListingToContractModalOpen,
  listingToTransactionConversionConfirmationModalToggle,
  setCurrentListingTab,
  isOnMarketModalOn,
  toggleMoveToMarket,
  setMLSIdForOnMarket,
  toggleStatusChangeModal,
  isStatusChangeModalOpen,
  isViewListingModalOpen,
  viewListingModal,
  history,
}) => {
  const {
    bool: showStatusChangeModal,
    statusChangedTo,
    listingID,
  } = isStatusChangeModalOpen || {}

  return (
    <AppErrorBoundary>
      <Routes />
      {isErrorModalOpen && (
        <ErrorModal
          toClose={toggleErrorModal}
          redirectTo="/realty/listings"
        />
      )}
      {isListingToContractModalOpen && (
        <ListingModal
          toClose={() => {
            listingToTransactionConversionConfirmationModalToggle(false)
          }}
        >
          <GoToContract
            toClose={() => {
              listingToTransactionConversionConfirmationModalToggle(false)
            }}
          />
        </ListingModal>
      )}
      {isViewListingContractModalOpen && (
        <ListingModal
          height="210px"
          toClose={() => {
            viewContractForListing(false)
            setCurrentListingTab("cover_sheet")
            history.push("/realty/listings")
          }}
        >
          <ViewContract
            toClose={() => {
              viewContractForListing(false)
              setCurrentListingTab("cover_sheet")
              history.push("/realty/listings")
            }}
          />
        </ListingModal>
      )}
      {isOnMarketModalOn && (
        <ListingModal
          toClose={() => {
            toggleMoveToMarket(false)
            setMLSIdForOnMarket({
              mlsId: null,
              uniqueId: null,
            })
          }}
        >
          <MLSModal
            onClose={() => {
              toggleMoveToMarket(false)
              setMLSIdForOnMarket({
                mlsId: null,
                uniqueId: null,
              })
            }}
          />
        </ListingModal>
      )}
      {showStatusChangeModal && (
        <ListingModal
          toClose={() => {
            toggleStatusChangeModal({
              bool: false,
              statusChangedTo: null,
            })
          }}
        >
          <StatusChange
            statusChangedTo={statusChangedTo}
            id={listingID}
          />
        </ListingModal>
      )}
      {isViewListingModalOpen.bool && (
        <ListingModal
          height="230px"
          toClose={() => {
            viewListingModal({
              bool: false,
            })
          }}
        >
          <ViewListing
            toClose={() => {
              viewListingModal({
                bool: false,
              })
            }}
          />
        </ListingModal>
      )}
    </AppErrorBoundary>
  )
}

export default withRouter(ListingContainer(Listings))

