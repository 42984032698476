import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import * as ListingActions from "./actions"

const mapStateToProps = (state) => {
  const { listings } = state
  return {
    ...listings,
  }
}

const mapDispatchToProps = dispatch => (
  {
    ...bindActionCreators({
      getListings: ListingActions.getListingsAction.request,
      constructQuery: ListingActions.constructListingsQueryAction.call,
      toggleListingsFilterDrawer: ListingActions.toggleListingsFilterDrawerAction.call,
      getListingMetrics: ListingActions.getListingMetricsAction.request,
      getPaginationCount: ListingActions.getPaginationCountAction.request,
      deleteListing: ListingActions.deleteListingAction.request,
      toggleDeleteModal: ListingActions.toggleDeleteModalAction.call,
      toggleMLSModal: ListingActions.toggleMLSModalAction.call,
      toggleStatusChangeModal: ListingActions.toggleStatusChangeModalAction.call,
      toggleListingCreationModal: ListingActions.createListingForModalAction.call,
      updateCurrentListingData: ListingActions.currentDealDetailsObjectAction.call,
      initiateListing: ListingActions.initiateListingAction.request,
      getListingMappings: ListingActions.getListingMappingsAction.request,
      updatePropertyInformation: ListingActions.updatePropertyInformationAction.request,
      setCurrentListingTab: ListingActions.setCurrentListingTabAction.call,
      setCurrentContractsTab: ListingActions.setCurrentContractsTabAction.call,
      togglePropertyFormOrCardView: ListingActions.togglePropertyFormOrCardViewAction.call,
      validateListingForm: ListingActions.validateListingFormAction.request,
      getBrokerageListingsStatuses: ListingActions.getBrokerageListingsStatusesAction.request,
      updateBrokerageListingStatus: ListingActions.updateBrokerageListingStatusAction.request,
      updateListingAgentInformation: ListingActions.updateListingAgentInformationAction.request,
      updateListingAgentInformationPostMlsSelection: ListingActions
        .updateListingAgentInformationPostMlsSelectionAction.request,
      getListingAgentsDetails: ListingActions.getListingAgentsDetailsAction.request,
      getListingDataAgentsDataPostMLS: ListingActions.getListingDataAgentsDataPostMLSAction.request,
      performNecessaryActionsBeforeLoadingListing: ListingActions
        .performNecessaryActionsBeforeLoadingListingAction.request,
      updatePropertyInformationPostMLSUpdate: ListingActions.updatePropertyInformationPostMLSUpdateAction.request,
      updateSellerInformationForListing: ListingActions.updateSellerInformationForListingAction.request,
      updateListingTermsForListing: ListingActions.updateListingTermsForListingAction.request,
      validateAndSubmit: ListingActions.validateAndSubmitAction.request,
      fetchAgentInformationForCoverSheet: ListingActions.fetchAgentInformationForCoverSheetAction.request,
      handleCollapseValues: ListingActions.handleCollapseValuesAction.call,
      clearExistingData: ListingActions.clearExistingDataAction.call,
      moveToOnMarket: ListingActions.moveToOnMarketAction.request,
      toggleMoveToMarket: ListingActions.toggleMoveToMarketAction.call,
      setMLSIdForOnMarket: ListingActions.setMLSIdForOnMarketAction.call,
      listingToTransactionConversionConfirmationModalToggle: ListingActions.listingToTransactionConversionConfirmationModalToggleAction.call,
      viewContractForListing: ListingActions.viewListingContractAction.call,
      setCurrentListingsState: ListingActions.setCurrentListingsStateAction.call,
      fetchChecklistMetadata: ListingActions.fetchChecklistMetadataAction.request,
      setChecklistTemplate: ListingActions.setChecklistTemplateAction.call,
      updateChecklistTemplateMetadata: ListingActions.updateChecklistTemplateMetadataAction.request,
      fetchChecklistTemplate: ListingActions.fetchChecklistTemplateAction.request,
      fetchEnvelopeListings: ListingActions.fetchEnvelopeListingsAction.request,
      fetchMergedEnvelopes: ListingActions.fetchMergedEnvelopesAction.request,
      updateMergeEnvelopeObject: ListingActions.updateMergeEnvelopeObjectAction.call,
      toggleCreateEnvelope: ListingActions.toggleCreateEnvelopeAction.call,
      createReceiverListingViewV2: ListingActions.createReceiverListingViewV2Action.request,
      fetchListingPackageDocuments: ListingActions.fetchListingPackageDocumentsAction.request,
      updateOfferDocsListView: ListingActions.updateOfferDocsListViewAction.call,
      fetchOfferDocumentsList: ListingActions.fetchOfferDocumentsListAction.request,
      fetchListingDocumentsList: ListingActions.fetchListingDocumentsListAction.request,
      updateListingDocs: ListingActions.updateListingDocsAction.call,
      fetchLibraryUploadsList: ListingActions.fetchLibraryUploadedFilesListAction.request,
      updateUploadedDocsListObject: ListingActions.updateUploadedDocsListObjectAction.call,
      updateLibraryUploadsList: ListingActions.updateLibraryUploadsListAction.request,
      deleteLibraryUploadedFileList: ListingActions.deleteLibraryUploadedFileListAction.request,
      updateEnvelopes: ListingActions.updateEnvelopesAction.request,
      setListingId: ListingActions.setListingIdAction.call,
      updateChecklistObject: ListingActions.updateChecklistObjectAction.call,
      createEnvelopeChecklist: ListingActions.createEnvelopeChecklistAction.request,
      updateListingPackageDetails: ListingActions.updateListingPackageDetailsAction.call,
      updateEnvelopeObject: ListingActions.updateEnvelopeObjectAction.call,
      updateListingTabDisabledStatus: ListingActions.updateListingTabDisabledStatusAction.call,
      toggleDeleteFieldsConfirmationInCoverSheet: ListingActions.toggleDeleteFieldsConfirmationInCoverSheetAction.call,
      processDeletionOfFieldsInCoversheet: ListingActions.processDeletionOfFieldsInCoversheetAction.request,
      sendListingPackageMail: ListingActions.sendListingPackageMailAction.request,
      toggleListingsDocumentProcessing: ListingActions.documentsListingsProcessingAction.call,
      refreshListingsV2Documents: ListingActions.refreshListingsV2DocumentsAction.request,
      viewListingModal: ListingActions.viewListingModalAction.call,
      toggleMultipleEnvelopeShare: ListingActions.toggleMultipleEnvelopeShareAction.call,
      setViewListingSendUI: ListingActions.setViewListingSendUIAction.call,
      setEnvelopeId: ListingActions.setEnvelopeIdAction.call,
      sendOffersChecklistDocuments: ListingActions.sendOffersChecklistDocumentsAction.request,
      sendReminderListing: ListingActions.sendReminderListingAction.request,
      sendReminderOfferChecklist: ListingActions.sendReminderOfferChecklistAction.request,
      toggleAddSignedDocs: ListingActions.toggleAddSignedDocsAction.request,
      showAddDocument: ListingActions.showAddDocumentAction.call,
      setEnvelopeIdForAddDocument: ListingActions.setEnvelopeIdForAddDocumentAction.call,
      viewListingPersistence: ListingActions.viewListingPersistenceAction.request,
      setIsLoadingViewListingPage: ListingActions.setIsLoadingViewListingPageAction.call,
    }, dispatch),
  }
)

export default connect(mapStateToProps, mapDispatchToProps)
