import React from "react"
import styled from "styled-components"
import App from "container/App"
import TechAssets from "container/TechAssets"
// import { useRouter } from "next/router"
import root from "window-or-global"
import { xsMobile as mobile, tablet } from "@consts"
import PartnerImage from "images/Graphics/MortgageLandingPageAssets/PartnerImage.jpg"
import { ATC } from "@fonts"

const Wrap = styled.div`
  height: 391px;
  width: 100%;
  position: relative;
  background-image: url(${PartnerImage});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 140px 100px;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   filter: contrast(0.5) brightness(0.5);
  //   width: 100%;
  //   height: 100%;
  //   background: #8080809c;
  // }

  @media(max-width: 767px) {
    padding: 82px 20px;
    height: max-content;
  }
`

const PartnerWith = styled.div`
  margin-bottom: 10px;
  p {
    font-family: Butler;
    font-size: 42px;
    font-weight: 700;
    line-height: 49px;
    text-align: center;
    color: white;
    width: 458px;
    margin: 0 auto;

    @media(min-width: 1366px) {
    font-family: Butler;
    font-size: 42px;
    font-weight: 700;
    line-height: 60px;
    width: unset;
    }
  }
`

const CTAWrap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;

  ${tablet(`
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: 30px;
  `)}

  ${mobile(`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  `)}
`

const Button = styled.button`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: white;

  border-radius: 3px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.22664px 16.1466px;
  border: none;
  cursor: pointer;

  width: max-content;
  height: 48px;

  background: #E5AC3D;
`

const Partner = ({
  toggleMortgageForm,
  user,
}) => (
  <Wrap>
    <PartnerWith>
      <p>
        Partner with a Radius Loan Officer Today
      </p>
    </PartnerWith>
    <CTAWrap>
      <Button
        onClick={() => {
          root.open("https://loandeskmortgage.com", "_blank")
          const isPartOfBrokerageTeam = user
            && parseInt(user.brokerageTeamId, 10) > 0 || user.isBrokerageTeamMember
          const userType = user.isSubscribedToBrokerPlan
          ? isPartOfBrokerageTeam ? "client" : "client" : "community_member"
          const payload = {
            login_status: "logged_in",
            agent_id: user.id,
            agent_type: userType,
            ag_nm: `${user.firstname} ${user.lastname}`,
            ag_eml: user.email,
            event: "mortgage_meet_your_new_lo_partner_cta_clicked",
          }
          root.dataLayer.push(payload)
        }}
      >
        Meet your new LO partner
      </Button>
      <Button
        onClick={() => {
          toggleMortgageForm(true)
          const isPartOfBrokerageTeam = user
            && parseInt(user.brokerageTeamId, 10) > 0 || user.isBrokerageTeamMember
          const userType = user.isSubscribedToBrokerPlan
          ? isPartOfBrokerageTeam ? "client" : "client" : "community_member"
          const payload = {
            login_status: "logged_in",
            agent_id: user.id,
            agent_type: userType,
            ag_nm: `${user.firstname} ${user.lastname}`,
            ag_eml: user.email,
            event: "mortgage_introduce_a_client_cta_clicked",
          }
          root.dataLayer.push(payload)
        }}
      >
        Introduce a client
      </Button>
    </CTAWrap>
  </Wrap>
)

export default TechAssets(App(Partner))
