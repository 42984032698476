import React, { useEffect, PureComponent } from "react"
import { Route, Switch, withRouter } from "react-router-dom"
import App from "container/App"
import { Helmet } from "react-helmet"
import root from "window-or-global"
import { ga4DataLayerPush } from "services/Utils"
import flowContainer from "container/App/applyFlowContainer"
import styled from "styled-components"
import PageTitle from "@ui/PageTitle"
import { ATC } from "@fonts"
import Loader from "@ui/Loader"
import cookie from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
import container from "container/Referral"
import FinancingDashboardTab from "dumbComponents/FinancingDashboard/components/FinancingDashboardTab"
import Mortgage from "dumbComponents/FinancingDashboard/pages/MortgageLP"
import AcknowledgementPopup from "./AcknowledgementPopup/index"

const FinancingWrap = styled.div`
  display: flex;
  width: 100%;
`

const CenterContainer = styled.div`
  width: 100%;
`
const Body = styled.div`
  // padding: 0px 40px 50px 40px;
  margin-top: -30px;
  margin-left: -10px;
`

const PageTitleExtended = styled(PageTitle)`
  font-family: ${ATC};
  font-weight: 400;
  font-size: 34px;
  line-height: 36px;
`

const LoaderWrap = styled.div`
   height: 600px;
   width: 100%;
`

class RealtyFinancing extends PureComponent {
  state = {
    isAcknowledgementShown: true,
  }

  checkIfAcknowledgementWasShownBefore = () => {
    const isShown = cookie.load("isAcknowledgementShown")
    this.setState({ isAcknowledgementShown: isShown })
  }

  componentDidMount() {
    const { fetchState, user: userData } = this.props
    const agentId = cookie.load("agentId")
    fetchState({
      agentId,
    })
    const isShown = cookie.load("isAcknowledgementShown")
    this.setState({ isAcknowledgementShown: isShown })
    if (root.HubSpotConversations) {
      root.HubSpotConversations.widget.remove()
    }
    const noUserData = !userData || (userData && Object.keys(userData).length <= 0)

    if (!noUserData) {
      ga4DataLayerPush(userData)
    }
  }

  componentWillUnmount() {
    if (root.HubSpotConversations) {
      //root.HubSpotConversations.widget.refresh({ openToNewThread: true })
    }
  }

  render() {
    const { toShowMortgagePopup, toggleMortgagePopup, user } = this.props
    const { isAcknowledgementShown } = this.state
    return (
      <React.Fragment>
        <Helmet>
          <title>Mortgage | Radius Agent</title>
        </Helmet>
        <Body>
          {/* <PageTitleExtended>
            Mortgage
          </PageTitleExtended> */}
          <FinancingWrap>
            <CenterContainer>
              <Switch>
                <Route path="/realty/mortgage" exact component={Mortgage} />
              </Switch>
            </CenterContainer>
          </FinancingWrap>
        </Body>
        {!isAcknowledgementShown && (
          <AcknowledgementPopup
            show={toShowMortgagePopup}
            toClose={() => {
              toggleMortgagePopup(false)
              const isPartOfBrokerageTeam = user
                && parseInt(user.brokerageTeamId, 10) > 0 || user.isBrokerageTeamMember
              const userType = user.isSubscribedToBrokerPlan
              ? isPartOfBrokerageTeam ? "client" : "client" : "community_member"
              const payload = {
                login_status: "logged_in",
                agent_id: user.id,
                agent_type: userType,
                ag_nm: `${user.firstname} ${user.lastname}`,
                ag_eml: user.email,
                event: "mortgage_terms_acknowledged",
              }
              root.dataLayer.push(payload)
            }}
          />
        )}
      </React.Fragment>
    )
  }
}

export default TechAssetsContainer(container(withRouter(flowContainer(App(RealtyFinancing)))))
