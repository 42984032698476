import axios from "axios"

export const getListingsAPI = query => axios
  .get(`${API_URL}fetch-brokerage-listings?${query}`)
export const getListingMetricsAPI = query => axios
  .get(`${API_URL}brokerage-listings-metrics?${query}`)
export const getPaginationCountAPI = query => axios
  .get(`${API_URL}fetch-listing-count?${query}`)
export const deleteListingAPI = id => axios
  .delete(`${API_URL}brokerage-listing-delete/${id}`)
export const initiateListingAPI = payload => axios
  .post(`${API_URL}brokerage-listings-initiate`, payload)
export const getListingMappingsAPI = (payload) => axios
  .get(`${API_URL}brokerage-listings-mappings?state=${payload}`,payload)
export const updatePropertyInformationAPI = (payload, id) => axios
  .put(`${API_URL}listing-cover/${id}`, payload)
export const validateListingFormAPI = id => axios
  .get(`${API_URL}fetch-listing-status/${id}`)
export const getListingStatusMapAPI = () => axios
  .get(`${API_URL}brokerage-listing-statuses`)
export const updateBrokerageListingStatusAPI = payload => axios
  .post(`${API_URL}brokerage-listing-steps`, payload)
export const updateListingAgentInformationAPI = (dealId, payload) => axios
  .put(`${API_URL}listing-cover/${dealId}`, payload)
export const getListingAgentsDetailsAPI = dealId => axios
  .get(`${API_URL}listing-cover/${dealId}/agent_info`)
export const getListingPropertyInformationAPI = dealId => axios
  .get(`${API_URL}listing-cover/${dealId}/get_property_information`)
export const getSellerInformationForListingAPI = dealId => axios
  .get(`${API_URL}listing-cover/${dealId}/seller_info`)
export const updateSellerInformationForListingAPI = (dealId, payload) => axios
  .put(`${API_URL}listing-cover/${dealId}`, payload)
export const updateListingTermsForListingAPI = (dealId, payload) => axios
  .put(`${API_URL}listing-cover/${dealId}`, payload)
export const getListingTermsDetailsAPI = dealId => axios
  .get(`${API_URL}listing-cover/${dealId}/source_time`)
export const fetchAgentsInformationForListingBasedOnStateAPI = (state, agentId) => axios
  .get(`${API_URL}fetch-agent-info-coversheet?state=${state}&agentId=${agentId}`)
export const moveToOnMarketAPI = payload => axios
  .post(`${API_URL}sync-mls-with-listing`, payload)
export const fetchChecklistMetadataAPI = (query) => axios
  .get(`${API_URL}brokerage-checklist-docs/metadata?${query}`)
export const updateChecklistTemplateMetadataAPI = (payload) => axios
  .put(`${API_URL}brokerage-docs/checklist-templates`, payload)
export const updateChecklistTemplateOffersMetadataAPI = (payload) => axios
  .put(`${API_URL}brokerage-offers-checklist-docs/checklist-template`, payload)
export const fetchChecklistTemplateAPI = (listingId) => axios
  .get(`${API_URL}brokerage-docs/checklist-templates?listingId=${listingId}`)
export const fetchChecklistTemplateOffersAPI = (offerId) => axios
  .get(`${API_URL}brokerage-offers-checklist-docs/templates?offerId=${offerId}`)
export const fetchEnvelopeListingsAPI = (listingId) => axios
  .get(`${API_URL}brokerage-docs/envelopes?listingId=${listingId}`)
export const fetchEnvelopeOffersAPI = (offerId) => axios
  .get(`${API_URL}brokerage-offers-checklist-docs/envelopes?offerId=${offerId}`)
export const createReceiverListingViewV2API = payload => axios
  .post(`${API_URL}brokerage-docs/envelopes/receiver-view`, payload)
export const createReceiverOffersViewV2API = payload => axios
  .post(`${API_URL}brokerage-offers-checklist-docs/envelopes/receiver-view`,payload)
export const fetchListingPackageDocumentsAPI = payload => axios
  .post(`${API_URL}brokerage-docs/documents/info`,payload)
export const fetchOfferDocumentsAPI = state => axios
  .get(`${API_URL}brokerage-offers-docs/library-templates?state=${state}`)
export const fetchListingDocumentsAPI = state => axios
  .get(`${API_URL}brokerage-docs/library-templates?state=${state}`)
export const fetchLibraryUploadsAPI = query => axios
  .get(`${API_URL}fetch-library-uploaded-files/add_to_library?${query}`)
export const updateLibraryUploadsAPI = payload => axios
  .put(`${API_URL}update-library-uploaded-files`, payload)
export const deleteLibraryUploadedFileAPI = fileId => axios
  .delete(`${API_URL}update-library-uploaded-files/${fileId}`)
export const updateEnvelopesAPI = payload => axios
  .post(`${API_V2_URL}brokerage-docs/envelopes`, payload)
export const updateEnvelopesOfferChecklistAPI = payload => axios
  .post(`${API_V2_URL}brokerage-offers-checklist-docs/envelopes`, payload)
export const createEnvelopeChecklistAPI = payload => axios
  .post(`${API_URL}brokerage-docs/envelopes-checklist`,payload)
export const createEnvelopeChecklistOffersAPI = payload => axios
  .post(`${API_URL}brokerage-offers-checklist-docs/envelopes`,payload)
export const sendListingPackageMailAPI = payload => axios
  .post(`${API_URL}brokerage-docs/send-docs-email`,payload)
export const sendOfferChecklistAPI = payload => axios
  .post(`${API_URL}brokerage-offers-checklist-docs/send-docs-email`,payload)
export const sendOffersChecklistDocumentsAPI = payload => axios
  .put(`${API_URL}brokerage-offers-checklist-docs/envelopes`, payload)
export const sendReminderListingAPI = payload => axios
  .post(`${API_URL}brokerage-docs/envelopes/reminder`, payload)
export const sendReminderOfferChecklistAPI = payload => axios
  .post(`${API_URL}brokerage-offers-checklist-docs/envelopes/reminder`, payload)
  export const fetchOfferChecklistDocumentsAPI = payload => axios
  .post(`${API_URL}brokerage-offers-checklist-docs/documents/info`,payload)
