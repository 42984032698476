const INITIAL_DEPOSIT = {
  name: "initial_deposit",
  type: "pair_calculation",
  values: [
    {
      name: "initial_deposit_amount_percentage",
      type: "text",
      text: "Initial Deposit Percentage",
      modifiesForm: true,
      showOr: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "Initial Deposit Percentage",
    },
    {
      name: "initial_deposit_amount",
      type: "text",
      text: "Initial Deposit Amount",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "Initial Deposit Amount",
      required: true,
    },
  ],
}
const PURCHASE_PRICE = {
  name: "price",
  type: "text",
  placeholder: "Purchase Price",
  modifiesForm: true,
  formatterType: "money",
  text: "Purchase Price",
  required: true,
}

const LOAN_AMOUNT = {
  name: "loan",
  type: "pair_calculation",
  values: [
    {
      name: "loan_amount_percentage",
      type: "text",
      text: "Loan Amount Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "Loan Amount Percentage",
    },
    {
      name: "loan_amount",
      type: "text",
      text: "Loan Amount",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "Loan Amount",
    },
  ],
}

const INCREASED_DEPOSIT = {
  name: "increased_deposit",
  type: "pair_calculation",
  values: [
    {
      name: "increased_deposit_amount_percentage",
      type: "text",
      text: "Increased Deposit Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "Increased Deposit Percentage",
    },
    {
      name: "increased_deposit_amount",
      type: "text",
      text: "Increased Deposit Amount",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "Increased Deposit Amount",
      // required: true,
    },
  ],
}

const ADDITIONAL_AMOUNT = {
  name: "additional_amount",
  type: "pair_calculation",
  values: [
    {
      name: "additional_financial_amount_percentage",
      type: "text",
      text: "Additional Financial Amount Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "Additional Financial Amount Percentage",
    },
    {
      name: "additional_financial_amount",
      type: "text",
      text: "Additional Financial Amount",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "Additional Financial Amount",
    },
  ],
}

const EARNEST_AMOUNT = {
  name: "earnest_money_deposit",
  type: "text",
  text: "Earnest Money Deposit",
  formatterType: "money",
  placeholder: "Earnest Money Deposit",
}

const BALANCE_DOWNPAYMENT = {
  name: "balance_of_down_payment",
  type: "text",
  text: "Balance of Down Payment",
  formatterType: "money",
  required: true,
  placeholder: "Down Payment Amount",
}

const OTHER = {
  name: "other", //ask abhinav
  type: "text",
  text: "Other",
  formatterType: "money",
  modifiesForm: true,
  placeholder: "Other",
}

// const ADDITIONAL_DEPOSIT = {
//   name: "additional_deposit",
//   type: "text",
//   text: "Additional Deposit",
//   formatterType: "money",
//   placeholder: "Additional Deposit",
// }

const OPTION_FEE = {
  name: "option_fee",
  type: "text",
  text: "Option Fee",
  formatterType: "money",
  placeholder: "Option Fee",
  required: true,
}

const SELLERS_CONTRIBUTION_AT_CLOSING = {
  name: "sellers_contribution_at_closing",
  type: "text",
  text: "Closing Costs Seller's Contribution at Closing",
  formatterType: "money",
  placeholder: "Closing Costs",
}

const ADDITIONAL_OPTION_MONEY = {
  name: "additional_option_money",
  type: "text",
  text: "Additional Option Money",
  formatterType: "money",
  placeholder: "Additional Option Money",
}

const REMAINDER_OF_DOWN_PAYMENT = {
  name: "remainder_of_down_payment",
  type: "text",
  text: "Remainder Of Down Payment",
  formatterType: "money",
  modifiesForm: true,
  placeholder: "Remainder Of Down Payment",
}

const REMAINDER_OF_PURCHASE_PRICE = {
  name: "balance_of_purchase_price",
  type: "text",
  text: "Remainder Of Purchase Price",
  formatterType: "money",
  placeholder: "Remainder Of Purchase Price",
}

const BUYERS_CLOSING_COSTS_AND_PREPAID_ITEMS = {
  name: "buyers_closing_costs_and_prepaid_items",
  type: "text",
  text: "Buyer's Closing Costs And Prepain Items",
  formatterType: "money",
  placeholder: "Buyer's Closing Costs And Prepain Items",
}

const FINANCE_AMOUNT = {
  name: "loan",
  type: "pair_calculation",
  values: [
    {
      name: "loan_amount_percentage",
      type: "text",
      text: "Financing Amount Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "Financing Amount Percentage",
    },
    {
      name: "loan_amount",
      type: "text",
      text: "Financing Amount",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "Financing Amount",
    },
  ],
}

const ADDITIONAL_DEPOSIT = {
  name: "loan",
  type: "pair_calculation",
  values: [
    {
      name: "additional_deposit_percentage",
      type: "text",
      text: "Additional Deposit Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "Additional Deposit Percentage",
    },
    {
      name: "additional_deposit",
      type: "text",
      text: "Additional Deposit",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "Additional Deposit",
    },
  ],
}

const ADDITIONAL_EARNEST_MONEY = {
  name: "loan",
  type: "pair_calculation",
  values: [
    {
      name: "additional_earnest_money_percentage",
      type: "text",
      text: "Additional Earnest Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "Additional Earnest Percentage",
    },
    {
      name: "additional_earnest_money",
      type: "text",
      text: "Additional Earnest Money",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "Additional Earnest Money",
    },
  ],
}

const BALANCE_OF_DOWN_PAYMENT = {
  name: "balance_of_down_payment",
  type: "text",
  text: "Cash Portion of Sales Price",
  formatterType: "money",
  modifiesForm: true,
  placeholder: "Cash Portion of Sales Price",
}

const EARNEST_MONEY = {
  name: "loan",
  type: "pair_calculation",
  values: [
    {
      name: "earnest_money_percentage",
      type: "text",
      text: "Earnest Money Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "Earnest Money Percentage",
      required: true,
    },
    {
      name: "earnest_money",
      type: "text",
      text: "Earnest Money",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "Earnest Money",
      required: true,
      requiredMessage: "Required",
    },
  ],
}

const FIRST_MORTGAGE_LOAN_AMOUNT = {
  name: "loan",
  type: "pair_calculation",
  values: [
    {
      name: "loan_amount_percentage",
      type: "text",
      text: "First Mortgage Loan Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "First Mortgage Loan Percentage",
    },
    {
      name: "loan_amount",
      type: "text",
      text: "First Mortgage Loan Amount",

      modifiesForm: true,
      formatterType: "money",
      placeholder: "First Mortgage Loan Amount",
    },
  ],
}

const SECOND_MORTGAGE_LOAN_AMOUNT = {
  name: "loan",
  type: "pair_calculation",
  values: [
    {
      name: "additional_financial_amount_percentage",
      type: "text",
      text: "Second Mortgage Loan Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "Second Mortgage Loan Percentage",
    },
    {
      name: "additional_financial_amount",
      type: "text",
      text: "Second Mortgage Loan Amount",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "Second Mortgage Loan Amount",
    },
  ],
}

const SALES_PRICE = {
  name: "price",
  type: "text",
  placeholder: "Sales Price",
  modifiesForm: true,
  formatterType: "money",
  text: "Sales Price",
  required: true,
}

const BALANCE_TO_CLOSE = {
  name: "balance_to_close",
  type: "text",
  text: "Balance To Close",
  formatterType: "money",
  placeholder: "Balance To Close",
}

const NEW_LOAN_AMOUNT = {
  name: "loan",
  type: "pair_calculation",
  values: [
    {
      name: "new_loan_amount_percentage",
      type: "text",
      text: "New Loan Percentage",
      showOr: true,
      modifiesForm: true,
      formatterType: "percentage",
      disabled: true,
      placeholder: "New Loan Percentage",
    },
    {
      name: "new_loan_amount",
      type: "text",
      text: "New Loan",
      modifiesForm: true,
      formatterType: "money",
      placeholder: "New Loan",
    },
  ],
}

const ASSUMPTION_BALANCE = {
  name: "assumption_balance",
  type: "text",
  text: "Assumption Balance",
  modifiesForm: true,
  formatterType: "money",
  placeholder: "Assumption Balance",
}

const PRIVATE_FINANCING = {
  name: "private_financing",
  type: "text",
  text: "Private Financing",
  formatterType: "money",
  modifiesForm: true,
  placeholder: "Private Financing",
}

const SELLER_FINANCING = {
  name: "seller_financing",
  type: "text",
  text: "Seller Financing",
  formatterType: "money",
  modifiesForm: true,
  placeholder: "Seller Financing",
}

const OTHER_FEES_1 = {
  name: "other_fees_1",
  type: "text",
  text: "Other 1",
  formatterType: "money",
  modifiesForm: true,
  placeholder: "Other 1",
}

const OTHER_FEES_2 = {
  name: "other_fees_2",
  type: "text",
  text: "Other 2",
  formatterType: "money",
  modifiesForm: true,
  placeholder: "Other 2",
}

const CASH_AT_CLOSING = {
  name: "cash_at_closing",
  type: "text",
  text: "Cash at Closing",
  formatterType: "money",
  placeholder: "Cash at Closing",
}

export const getInputFieldsBasedOnState = (state) => {
  switch (state) {
    case "california":
      // return [PURCHASE_PRICE, LOAN_AMOUNT, INITIAL_DEPOSIT, INCREASED_DEPOSIT, ADDITIONAL_AMOUNT, BALANCE_DOWNPAYMENT, ADDITIONAL_EARNEST_MONEY, ADDITIONAL_DEPOSIT, EARNEST_AMOUNT]
      return [PURCHASE_PRICE, INITIAL_DEPOSIT, INCREASED_DEPOSIT, LOAN_AMOUNT, ADDITIONAL_AMOUNT, BALANCE_DOWNPAYMENT]
    case "texas":
      return [SALES_PRICE, FINANCE_AMOUNT, EARNEST_MONEY, ADDITIONAL_EARNEST_MONEY, BALANCE_OF_DOWN_PAYMENT, OPTION_FEE]
    case "florida":
      return [PURCHASE_PRICE, INITIAL_DEPOSIT, FINANCE_AMOUNT, ADDITIONAL_DEPOSIT, OTHER, BALANCE_TO_CLOSE]
    case "washington":
      return [PURCHASE_PRICE, EARNEST_MONEY, LOAN_AMOUNT, BALANCE_DOWNPAYMENT]
    case "oregon":
      return [PURCHASE_PRICE, EARNEST_MONEY, REMAINDER_OF_DOWN_PAYMENT, REMAINDER_OF_PURCHASE_PRICE]
    case "colorado":
      return [PURCHASE_PRICE, EARNEST_MONEY, NEW_LOAN_AMOUNT, ASSUMPTION_BALANCE, PRIVATE_FINANCING, SELLER_FINANCING, OTHER_FEES_1, OTHER_FEES_2, CASH_AT_CLOSING]
    case "georgia":
      return [PURCHASE_PRICE, SELLERS_CONTRIBUTION_AT_CLOSING, EARNEST_MONEY, FIRST_MORTGAGE_LOAN_AMOUNT, SECOND_MORTGAGE_LOAN_AMOUNT, ADDITIONAL_OPTION_MONEY]

    default:
      return [PURCHASE_PRICE, LOAN_AMOUNT, INITIAL_DEPOSIT, INCREASED_DEPOSIT, ADDITIONAL_AMOUNT, BALANCE_DOWNPAYMENT, EARNEST_AMOUNT, ADDITIONAL_DEPOSIT]
  }
}
