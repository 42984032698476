import React, { useEffect, useState } from "react"
import ListingsContainer from "container/Listings"
import TechAssetsContainer from "container/TechAssets"
import TCContainer from "container/Realty/TransactionFormContainer"
import { checkIfOnboardingBannerIsVisible } from "@ui/Sidebar/index"
import {
  ConfirmDelete,
  ListingModal,
  MLSModal,
  StatusChange,
  CreateListing,
  GoToContract,
  ViewContract,
} from "./Components/Modals"
import {
  Header,
  Stats,
  Filters,
  ListingTable,
  FiltersDrawer,
} from "./Components"
import {
  ListingWrap,
} from "./commonStyles"

const ListingsV2 = ({
  query,
  constructQuery,
  isListingDrawerOpen,
  toggleListingsFilterDrawer,
  toggleDeleteModal,
  isDeleteModalOpen,
  toggleMLSModal,
  isMLSModalOpen,
  isStatusChangeModalOpen,
  toggleStatusChangeModal,
  toggleListingCreationModal,
  isListingCreationModalOpen,
  updateCurrentListingData,
  getBrokerageListingsStatuses,
  getListingMappings,
  isOnMarketModalOn,
  toggleMoveToMarket,
  setMLSIdForOnMarket,
  isListingToContractModalOpen,
  listingToTransactionConversionConfirmationModalToggle,
  isViewListingContractModalOpen,
  viewContractForListing,
}) => {
  const {
    bool: deleteModalOpenStatus,
    id: deletingListingID,
  } = isDeleteModalOpen || {}

  const [isOnboardingBannerVisible, setIsOnboardingBannerVisible] = useState(false)
  const [clearFiltersOnDemand, setClearFiltersOnDemand] = useState()

  useEffect(() => {
    constructQuery({
      query,
    })
    getBrokerageListingsStatuses()
    getListingMappings()

    checkIfOnboardingBannerIsVisible((isVisible) => {
      setIsOnboardingBannerVisible(isVisible)
    })
  }, [])

  const {
    bool: showStatusChangeModal,
    statusChangedTo,
    listingID,
  } = isStatusChangeModalOpen || {}

  return (
    <ListingWrap isOnboardingBannerVisible={isOnboardingBannerVisible}>
      <Header />
      <Stats />
      <Filters
        clearFiltersOnDemand={clearFiltersOnDemand}
        setClearFiltersOnDemand={setClearFiltersOnDemand}
      />
      <ListingTable />
      <FiltersDrawer
        open={isListingDrawerOpen}
        handleClose={() => toggleListingsFilterDrawer(false)}
        title="FILTERS"
        clearFiltersOnDemand={clearFiltersOnDemand}
        setClearFiltersOnDemand={setClearFiltersOnDemand}
      />
      {deleteModalOpenStatus && (
        <ListingModal
          noMinHeight
          toClose={() => {
            toggleDeleteModal({
              bool: false,
              id: "",
            })
          }}
        >
          <ConfirmDelete
            id={deletingListingID}
          />
        </ListingModal>
      )}
      {isMLSModalOpen && (
        <ListingModal
          toClose={() => {
            toggleMLSModal(false)
            setMLSIdForOnMarket({
              mlsId: null,
              uniqueId: null,
            })
          }}
        >
          <MLSModal
            onClose={() => {
              toggleMLSModal(false)
              setMLSIdForOnMarket({
                mlsId: null,
                uniqueId: null,
              })
            }}
          />
        </ListingModal>
      )}
      {showStatusChangeModal && (
        <ListingModal
          toClose={() => {
            toggleStatusChangeModal({
              bool: false,
              statusChangedTo: null,
            })
          }}
        >
          <StatusChange
            statusChangedTo={statusChangedTo}
            id={listingID}
          />
        </ListingModal>
      )}
      {isListingCreationModalOpen && (
        <ListingModal
          toClose={() => {
            toggleListingCreationModal(false)
            const resetData = {
              dealId: null,
              editingAgentId: null,
              transactionId: null,
              listingStatus: null,
            }
            updateCurrentListingData(resetData)
          }}
        >
          <CreateListing
            toClose={() => {
              const resetData = {
                dealId: null,
                editingAgentId: null,
                transactionId: null,
                listingStatus: null,
              }
              toggleListingCreationModal(false)
              updateCurrentListingData(resetData)
            }}
          />
        </ListingModal>
      )}
      {isOnMarketModalOn && (
        <ListingModal
          toClose={() => {
            toggleMoveToMarket(false)
            setMLSIdForOnMarket(null)
          }}
        >
          <MLSModal
            toClose={() => {
              toggleMoveToMarket(false)
              setMLSIdForOnMarket(null)
            }}
          />
        </ListingModal>
      )}
      {isViewListingContractModalOpen && (
        <ListingModal
          height="210px"
          toClose={() => {
            viewContractForListing(false)
          }}
        >
          <ViewContract
            listingView
            toClose={() => {
              viewContractForListing(false)
            }}
          />
        </ListingModal>
      )}
    </ListingWrap>
  )
}

export default TechAssetsContainer(TCContainer(ListingsContainer(ListingsV2)))
