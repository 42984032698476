import React from "react"
import {
  Form, Space, NewSelect,
} from "@ui/antd"
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const { Item } = Form
const { Option } = NewSelect
import styled from "styled-components"

const CLIENT_SOURCE = [
  {
    label: "Radius Marketplace",
    value: "radius_marketplace",
  },
  {
    label: "Zillow (Radius Provided)",
    value: "zillow_radius_provided",
  },
  {
    label: "Zillow (Agent's Personal Account)",
    value: "zillow_agent_personal_account",
  },
  {
    label: "Zillow Flex",
    value: "zillow_flex",
  },
  {
    label: "OpCity/Realtor.com",
    value: "opcity_realtor_com",
  },
  {
    label: "Team/Mentor Lead",
    value: "team_mentor_lead",
  },
  {
    label: "Self",
    value: "self",
  },
  {
    label: "Sphere of Influence/Personal",
    value: "sphere_of_influence",
  },
  {
    label: "Referrals (Agent/Lender)",
    value: "referrals_agent_Lender",
  },
  {
    label: "Other",
    value: "other",
  },
]


const CommonForm = ({
  formRef,
  getClientFiltersResponse,
}) => {
  const { data: clientFilterStatus } = getClientFiltersResponse || {}
  const { clientTransactionStatuses } = clientFilterStatus || []
  return (
    <Space style={{ display: "block", marginBottom: 8, gap: 40, width: "100%" }} align="baseline">
      <Item name="status" rules={[{ required: true }]}
      label={<LabelComponent text="Lead Status*" />}>
        <NewSelect placeholder="Lead Status*">
          {Object.keys(clientTransactionStatuses).map((key, index) => (
            <Option value={clientTransactionStatuses[key]} key={key}>
              {clientFilterStatus[key]}
            </Option>
          ))}
        </NewSelect>
      </Item>
      <Item name="client_source" rules={[{ required: true }]}
      label={<LabelComponent text="Client Source*" />}>
        <NewSelect placeholder="Client Source*" options={CLIENT_SOURCE} />
      </Item>
    </Space>
  )
}

export default CommonForm
