import React, { useEffect, useState } from "react"
import styled from "styled-components"
import App from "container/App"
import { LeftOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import CRMV2Container from "container/CRM"
import { withRouter } from "react-router-dom"
import moment from "moment"
import { commaSeperatedNumberUS } from "services/formUtils"
import TechAssetsContainer from "container/TechAssets"
import ReferralContainer from "container/Referral"
import Box from "@ui/Box"
import DropdownMenu from "@ui/NewTooltip"
import ArchiveIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/archiveCRM.svg"
import colors from "@colors"
import binSymbol from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Delete.svg"
import { ATC, MONTSERRAT, INTER } from "@fonts"
import DrawerShimmer from "./ShimmerForDrawer"
import { NewSelect, Tabs, NewButton, Modal, Spin, } from "@ui/antd"
import { Li } from "@ui/DragDropColumn/common"
import Loader from "@ui/Loader"
import { USCurrencyFormat, moneyFormatter } from "shared/currencyUtils"
import {
  HeaderWrap,
  AgentInfoWrap,
  AgentInfo,
  AgentWrap,
  ListSubtextHeader,
  TabsWrap,
  LeadInfo,
  StatusButton,
  StatusWrap,
  SelectWrap,
  ARCHIVE,
  IN_CONTRACT,
  CLOSED,
  SELLER,
  BUYER,
  SHOWING_TOURS,
  SENDING_RECEIVING_OFFERS,
  CONTRACT,
  LISTING,
  OFFER,
  CLOSED_STATUS
} from "../common"
import {
  UpdateSearchCriteriaForm,
  NotesTab,
  Financing,
  Reminders,
  Timeline,
  FinanceTabV2,
  Offers,
  Uploads,
} from "./Tabs"
import LeadDetails from "./Tabs/LeadDetails"
import {
  HeaderContainer,
  CloseWrap,
  StyledMenuIcon,
  AgentName,
  Subtext,
  Separator,
  PriceContainer,
  TabContentContainer,
  StatusColors,
  StatusIndicator,
  StatusSelectContainer,
  ShimmerWrapper,
} from "./styles"
import LeftArrow from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/LeftArrow"
import { formatAdressWithoutStateAndCountry } from "../utils"

const { TabPane } = Tabs

const Wrap = styled.div`
  height: 100%;
  background: ${colors.new_lightBackground};
`

const ListingButton = styled.div`
  margin: 20px;
  // width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`

const RightWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  align-items: flex-end;
`

const Container = styled.div`
  display: flex;
  align-items: center;
`

const PrimaryTag = styled.div`
  display: flex;
  align-items: center;
`

const Tag = styled.p`
  font-family: ${ATC};
  background: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  padding: 6px;
  line-height: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #3B60E4;
  box-shadow: 0px 1px 3px rgb(180, 180, 180);
  border-radius: 4px;
`

const CloseContainer = styled.div`
  position: relative;
`

const SearchCriteriaDetailsInfo = ({
  handleClose,
  updateBuyerRequirement,
  getBrokerageTransactionStatusResponse,
  setModalOpenConfirm,
  setModalOpenView,
  history,
  updateClientTransaction,
  currentTab,
  updateBuyerReqResponse,
  getCrmClientResponse,
  currentFilters,
  getCtIdResponse,
  setCurrentDrawerPage,
  toggleGoToContract,
  toggleArchiveLeadModal,
  setClientID,
  deleteClientTransaction,
  setIsViewListing,
  setIsViewOffer,
  setIsAddListing,
  setIsAddOffer,
  setIsCreateWarning,
  setIsClosed,
  setIsEndContract,
  setIsViewOfferToContract,
  setIsViewListingToContract,
  setStatusValue,
  setIsViewContract,
  setCannotConvertContract,
  toggleShowInContract,
  toggleUserOverview,
  user,
  setDealToView,
}) => {
  const {
    data,
    isFetching: fetchingData,
  } = getCtIdResponse || {}
  const {
    searchCriteria,
    type,
    client_id,
    id,
    agent_id,
    status,
    req_id,
    coClients,
    radius_transaction_type,
  } = data || {}

  const [currentStatusForIndicator, setCurrentStatusForIndicator] = useState(status)

  const {
    isFetching,
    data: brokerageTransactionStatus,
  } = getBrokerageTransactionStatusResponse || {}
  const {
    transactionType,
    id: brokerageId,
    status: transactionStatus,
    initiateTransaction,
  } = brokerageTransactionStatus || {}

  const {
    min_budget, max_budget, min_size, max_size, area_unit,
    types, bathroom, bedroom, updated_at, sale_type, locations,
    sale_price,
  } = searchCriteria || {}
  const getColor = currentStatus => StatusColors.find(cl => cl.label === currentStatus)?.value

  const handleChange = (value) => {
    if (value === "Archive") {
      toggleArchiveLeadModal(true)
    } else {
      const payload = {
        status: value,
        client_id,
        type,
      }
      updateClientTransaction({
        transactionId: id,
        payload,
        currentFilters,
      })
    }
  }

  const {
    data: updatedData,
    isFetching: isUpdating,
  } = updateBuyerReqResponse || {}

  const {
    locations: updatedLocations,
    min_budget: updatedMinBudget,
    max_budget: updatedMaxBudget,
    min_size: updatedMinSize,
    max_size: updatedMaxSize,
    area_unit: updatedAreaUnit,
    types: updatedTypes,
    bathroom: updatedBathroom,
    bedroom: updatedBedroom,
    updated_at: updatedTime,
    sale_price: updatedPrice,
  } = updatedData || {}

  const currentLocation = updatedLocations || locations
  const currentMinBud = updatedMinBudget || min_budget
  const currentMaxBud = updatedMaxBudget || max_budget
  const currentMinSize = updatedMinSize || min_size
  const currentMaxSize = updatedMaxSize || max_size
  const currentUnit = updatedAreaUnit || area_unit
  const currentType = updatedTypes || types
  const currentBathRoom = updatedBathroom || bathroom
  const currentBedRoom = updatedBedroom || bedroom
  const currentUpdate = updatedTime || updated_at
  const currentSalePrice = updatedPrice || sale_price

  const finalLocation = currentLocation && currentLocation[currentLocation.length - 1].c_locality_name !== null
    && currentLocation[currentLocation.length - 1].c_locality_name.includes("USA")
      ? currentLocation && currentLocation[currentLocation.length - 1].c_locality_name
      : `${currentLocation && currentLocation[currentLocation.length - 1].c_locality_name},
    ${currentLocation && currentLocation[currentLocation.length - 1].city !== null
    ? `${currentLocation && currentLocation[currentLocation.length - 1].city}, ` : ""}
    ${currentLocation && currentLocation[currentLocation.length - 1].state}`

  const {
    isFetching: fetchingUpdatedValues,
    data: clientData,
  } = getCrmClientResponse || {}

  const { name } = clientData || {}

  if (!fetchingData && req_id === null) {
    setCurrentDrawerPage("drawer-header")
  }

  const confirmDeleteLead = () => {
    Modal.confirm({
      title: "Deleting Lead!",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${finalLocation}?`,
      okText: "Yes",
      cancelText: "Not Now",
      onOk() {
        history.push("/crm")
        deleteClientTransaction({
          currentFilters,
          transactionId: id,
          soft_delete: 1,
        })
        toggleUserOverview({ isUserOverviewDrawerOpen: false })
        setClientID({
          id: "",
          deal_id: "",
        })
      },
    })
  }

  return (
    <>
      {fetchingData ? (
        <ShimmerWrapper>
          <DrawerShimmer height="200px" />
          <DrawerShimmer height="50px" />
          <DrawerShimmer height="50px" width="60%" />
          <DrawerShimmer height="50px" width="60%" />
          <DrawerShimmer height="50px" width="60%" />
          <DrawerShimmer height="50px" width="60%" />
          <DrawerShimmer height="300px" width="100%" />
          <DrawerShimmer height="150px" width="100%" />
        </ShimmerWrapper>
      ) : (
        <Wrap>
          <HeaderContainer>
            <CloseContainer>
              <CloseWrap>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: "8px", cursor: "pointer"}} onClick={() => handleClose()}>
                  <LeftArrow color={colors.new_light} />
                  <p>Back to Profile</p>
                </div>

                {(status !== IN_CONTRACT || status !== CLOSED) && transactionType !== CONTRACT && (
                  <Box position="absolute" t="5" r="15">
                    <DropdownMenu
                      position="bottom"
                      component={<StyledMenuIcon color={colors.new_light} />}
                      top={20}
                      padding={10}
                      left={-190}
                      width={200}
                      borderColor="#FFF"
                      eventType="hover"
                      borderRadius={4}
                      showShadow
                      arrowPosition="right"
                    >
                      <React.Fragment>
                        <Li
                          onClick={(e) => {
                            e.stopPropagation()
                            toggleArchiveLeadModal(true)
                            setClientID({
                              id: client_id,
                              deal_id: id,
                            })
                          }}
                        >
                          <span>Archive</span>
                          <img src={ArchiveIcon} alt="Refer out icon" style={{ width: "20px", height: "20px" }} />
                        </Li>
                        <Li
                          onClick={(e) => {
                            e.stopPropagation()
                            confirmDeleteLead()
                          }}
                        >
                          <span>Delete</span>
                          <img src={binSymbol} alt="bin" />
                        </Li>
                      </React.Fragment>
                    </DropdownMenu>
                  </Box>
                )}
              </CloseWrap>
            </CloseContainer>
            <AgentInfoWrap style={{ alignItems: "baseline" }}>
              <AgentInfo>
                <AgentWrap>
                  {fetchingUpdatedValues || fetchingData ? (
                    <p>Loading</p>
                  ) : (
                    <>
                      <Container>
                        <AgentName width="300px" height="max-content" fontSize="28px">
                          {name}
                        </AgentName>
                        <PrimaryTag>
                          <Tag>{sale_type === "rent" ? "RENTER" : "BUYER"}</Tag>
                        </PrimaryTag>
                      </Container>
                      {currentLocation &&
                      currentLocation[currentLocation.length - 1].c_locality_name !== null &&
                      currentLocation[currentLocation.length - 1].c_locality_name.includes(
                        "USA"
                      ) ? (
                        <AgentName fontSize="18px" fontFamily={INTER}>
                          {
                            currentLocation &&
                            formatAdressWithoutStateAndCountry(
                              currentLocation[currentLocation.length - 1]
                            )
                          }
                        </AgentName>
                      ) : (
                        <>
                          <AgentName fontSize="18px" fontFamily={INTER}>
                            {currentLocation &&
                            currentLocation[currentLocation.length - 1].city !== null
                              ? `${currentLocation &&
                                  currentLocation[currentLocation.length - 1].city}, `
                              : ""}
                          </AgentName>
                          <AgentName fontSize="18px" fontFamily={INTER}>
                            {currentLocation && currentLocation[currentLocation.length - 1].state}
                          </AgentName>
                        </>
                      )}
                    </>
                  )}
                  <div>
                    {currentBedRoom > 0 && (
                      <>
                        <Subtext>{currentBedRoom} Beds</Subtext>
                      </>
                    )}
                    {currentBathRoom > 0 && (
                      <>
                        <Separator> | </Separator>
                        <Subtext>{currentBathRoom} Baths</Subtext>
                      </>
                    )}
                    {currentMinSize > 0 && (
                      <>
                        <Separator> | </Separator>
                        <Subtext>{commaSeperatedNumberUS(currentMinSize)}</Subtext>{" "}
                      </>
                    )}
                    {currentMinSize === 0 && currentMaxSize > 0 && <Separator> | </Separator>}
                    {currentMinSize > 0 && currentMaxSize > 0 && <>{" - "}</>}
                    {currentMaxSize > 0 && (
                      <Subtext>{commaSeperatedNumberUS(currentMaxSize)} </Subtext>
                    )}
                    {(currentMinSize > 0 || currentMaxSize > 0) && currentUnit && (
                      <Subtext>{currentUnit}</Subtext>
                    )}
                  </div>
                </AgentWrap>
              </AgentInfo>
              <RightWrap>
                <PriceContainer>
                  {/* {`${USCurrencyFormat(min_budget)}`} -  {`${USCurrencyFormat(max_budget)}`} */}
                  {currentMinBud > 0 ? `$${moneyFormatter(currentMinBud, 2)}` : ""}
                  {currentMinBud > 0 && currentMaxBud > 0 ? " - " : ""}
                  {currentMaxBud > 0 ? `$${moneyFormatter(currentMaxBud, 2)}` : ""}
                </PriceContainer>
                <StatusWrap>
                  <StatusSelectContainer isLight>
                    <StatusIndicator bg={getColor(currentStatusForIndicator || status)} />
                    <NewSelect
                      // value={listingData.displayDealStatus}
                      defaultValue={status}
                      onChange={(value) => {
                        setStatusValue(value)
                        setCurrentStatusForIndicator(value)

                        if (value === ARCHIVE) {
                          handleChange(value)
                        } else if (initiateTransaction) {
                          if (SELLER.includes(type)) {
                            if (value === IN_CONTRACT || value === CLOSED) {
                              setIsEndContract(true)
                              toggleGoToContract({ isShowGoToContract: true })
                              return
                            }
                            setIsAddListing(true)
                            handleChange(value)
                          } else {
                            if (value === CLOSED) {
                              setStatusValue(IN_CONTRACT)
                              setIsClosed(true)
                              return
                            }
                            if (value === IN_CONTRACT) {
                              setIsEndContract(true)
                              toggleGoToContract({ isShowGoToContract: true })
                              return
                            }
                            setIsAddOffer(true)
                            setIsCreateWarning(true)
                            handleChange(value)
                          }
                        } else if (!initiateTransaction && transactionType === CONTRACT && ((value !== IN_CONTRACT && value !== CLOSED) || transactionStatus === CLOSED_STATUS)) {
                          // if (BUYER.includes(type) && transactionType === CONTRACT && transactionStatus === "submitted") {
                          //   setCannotConvertContract(true)
                          //   return
                          // }
                          if (SELLER.includes(type)) {
                            setIsViewListingToContract(true)
                          } else {
                            setIsViewOfferToContract(true)
                          }
                        } else if (!initiateTransaction && value === IN_CONTRACT) {
                          setIsEndContract(true)
                          toggleGoToContract({ isShowGoToContract: true })
                        } else if (transactionType === CONTRACT && value === CLOSED) {
                          toggleShowInContract(true)
                          handleChange(value)
                        } else if (!initiateTransaction && value === CLOSED) {
                          setStatusValue(IN_CONTRACT)
                          setIsClosed(true)
                        } else {
                          handleChange(value)
                        }
                      }}
                      // onChange={this.handleChange}
                      bordered={false}
                      // disabled={listingData.displayDealStatus === "Incomplete"}
                      options={[
                        {
                          value: "New Client/Accepted",
                          label: "New Client/Accepted",
                        },
                        {
                          value: "Met with Client",
                          label: "Met with Client",
                        },
                        {
                          value: "Pre-approved/Listing Prepped",
                          label: "Pre-approved/Listing Prepped",
                        },
                        {
                          value: "Showings/Tours",
                          label: "Showings/Tours",
                        },
                        {
                          value: "Sending/Receiving Offers",
                          label: "Sending/Receiving Offers",
                        },
                        {
                          value: "In Contract",
                          label: "In Contract",
                        },
                        {
                          value: "Closed",
                          label: "Closed",
                        },
                        {
                          value: "Archive",
                          label: "Archive",
                        },
                      ]}
                      placement="bottomRight"
                      dropdownMatchSelectWidth={false}
                    />
                  </StatusSelectContainer>
                  <Subtext fontSize="14px" fontFamily={INTER} margin="8px 0 0 0" textAlign="right">
                    Last updated at - {moment(currentUpdate * 1000).format("MM/DD/YYYY")}
                    <br />
                    {radius_transaction_type === null && status === CLOSED
                      ? "Pending compliance approval"
                      : ""}
                  </Subtext>
                </StatusWrap>
              </RightWrap>
            </AgentInfoWrap>
          </HeaderContainer>
          <TabsWrap>
            <LeadInfo defaultActiveKey={currentTab}>
              <TabPane tab="OVERVIEW" key="OVERVIEW">
                {isFetching || isUpdating === true ? (
                  <Spin />
                ) : (
                  <>
                    <>
                      {(SELLER.includes(type)) && currentFilters && currentFilters.view_type === "view_mine" && (status !== IN_CONTRACT && status !== CLOSED) ? (
                        <ListingButton>
                          {initiateTransaction === true ? (
                            <NewButton
                              type="primary"
                              // onClick={createTransaction}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setIsAddListing(true)
                                setIsCreateWarning(true)
                                setModalOpenConfirm(true)
                                setClientID({
                                  deal_id: id,
                                })
                              }}
                            >
                              Add Listing
                            </NewButton>
                          ) : (
                            <>
                              {brokerageId && transactionType === LISTING && (
                                <NewButton
                                  type="primary"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setIsViewListing(true)
                                    setModalOpenView(true)
                                    setClientID({
                                      deal_id: id,
                                    })
                                  }}
                                >
                                  View Listing
                                </NewButton>
                              )}
                            </>
                          )}
                        </ListingButton>
                      ) : null}
                    </>
                    <>
                      {(BUYER.includes(type)) && currentFilters && currentFilters.view_type === "view_mine"
                        && ((status === SHOWING_TOURS || status === SENDING_RECEIVING_OFFERS)) ? (
                        <ListingButton>
                          {initiateTransaction ? (
                            <NewButton
                              type="primary"
                              // onClick={createTransaction}
                                onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setIsAddOffer(true)
                                setIsCreateWarning(true)
                                setModalOpenConfirm(true)
                                setClientID({
                                    deal_id: id,
                                })
                              }}
                            >
                              Add Offer
                            </NewButton>
                          ) : (
                            ""
                          )}
                        </ListingButton>
                      ) : null}
                    </>
                    <>
                      {currentFilters && currentFilters.view_type === "view_mine" ? (
                        <ListingButton>
                          {brokerageId && transactionType === CONTRACT && (
                            <NewButton
                              type="primary"
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setIsViewContract(true)
                                setModalOpenView(true)
                                setClientID({
                                  deal_id: id,
                                })
                              }}
                            >
                              View Contract
                            </NewButton>
                          )}
                        </ListingButton>
                      ) : null}
                    </>
                  </>
                )}
                {/* <BuyerLeadOverview
                  is_pre_approved={is_pre_approved}
                  is_working_with_agent={is_working_with_agent}
                  transactionId={id}
                  client_id={client_id}
                  type={type}
                  timeline={timeline}
                /> */}

                <TabContentContainer>
                  <LeadDetails isBuyer coClients={coClients} />

                  {fetchingData ? (
                    <p>AISJDOASJDJ</p>
                  ) : (
                    <UpdateSearchCriteriaForm
                      initialValues={searchCriteria}
                      id={id}
                      client_id={client_id}
                      updateBuyerRequirement={updateBuyerRequirement}
                      isBuyer
                    />
                  )}
                </TabContentContainer>
              </TabPane>
              <TabPane tab="OFFERS" key="OFFERS">
                <Offers
                  data={data}
                />
              </TabPane>
              <TabPane tab="UPLOADS" key="UPLOADS">
                <Uploads
                  data={data}
                />
              </TabPane>
              <TabPane tab="NOTES" key="NOTES">
                <NotesTab />
              </TabPane>
              {(finalLocation.includes("CA") || finalLocation.includes("California")) && (
                <TabPane tab="FINANCING" key="FINANCING">
                  <FinanceTabV2 />
                </TabPane>
              )}
              <TabPane tab="REMINDERS" key="REMINDERS">
                <Reminders />
              </TabPane>
              <TabPane tab="TIMELINE" key="TIMELINE">
                <Timeline />
              </TabPane>
            </LeadInfo>
          </TabsWrap>
        </Wrap>
      )}
    </>
  )
}

export default withRouter(ReferralContainer(TechAssetsContainer(CRMV2Container(App(SearchCriteriaDetailsInfo)))))
