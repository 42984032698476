import React, { Component } from "react"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import cookie from "services/CookieStorage"
import axios from "axios"
import root from "window-or-global"
import { store, persistor } from "store/config"
import PubnubForFeed from "dumbComponents/common/PubNubForFeed"
import Router from "./Router"
import "react-toastify/dist/ReactToastify.css"
import "services/axiosConfig"
import "library/icons/icomoon.css"
import "library/slick/slick.css"
import "library/slick/slick-theme.css"
import "react-day-picker/lib/style.css"
import "rc-time-picker/assets/index.css"
import "react-table/react-table.css"
import "react-image-crop/dist/ReactCrop.css"

class App extends Component {
  async componentDidMount() {
    //GOOGLE OPTIMIZE - start
    if (!cookie.load("G_HOME_PAGE_VARIANT")) {
      if (root.dataLayer) {
        await root.dataLayer.push({ event: "optimize.activate" })
      }
      this.intervalId = setInterval(() => {
        if (root.google_optimize !== undefined) {
          cookie.save("G_HOME_PAGE_VARIANT", root.google_optimize.get("yJSVhbOBQWWbe1LwDM8kIQ"))
          clearInterval(this.intervalId)
        }
      }, 100)
    }
    //GOOGLE OPTIMIZE - end

    const axiosCopy = axios.create()
    axiosCopy.interceptors.request.use((config) => {
      config.headers.common = {}
      return config
    })

    // Disable scroll
    if ("scrollRestoration" in root.history) {
      // Back off, browser, I got this...
      root.history.scrollRestoration = "manual"
    }
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <PubnubForFeed />
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
