import React from "react"
import styledComponents from "styled-components"
import Modal from "@ui/Modal"
import colors from "@colors"
import { ATC, HIGHLIGHT_FONT } from "@fonts"

const HeaderWrap = styledComponents.div`
  h1 {
    font-family: ${HIGHLIGHT_FONT};
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0.005em;
    text-align: left;
  }
`

const DescriptionWrap = styledComponents.div`
  p {
    font-family: ${ATC};
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

    margin-top: ${props => props.marginTop || "0px"};
  }
`

const ScrollWrap = styledComponents.div`
  height: 242px;
  overflow: scroll;
  width: 100%;

  p {
    font-family: ${ATC};
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
`

const BreakLine = styledComponents.div`
  height: 2px;
  width: 100%;
  background: #efefef;
  margin-top: 40px;
`

const SubHeaderWrap = styledComponents.div`
  h4 {
    font-family: ${HIGHLIGHT_FONT};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 20px;
  }
`
const ContentWrap = styledComponents.div``

const TableWrap = styledComponents.div`
  margin-top: 27px;
`

const Table = styledComponents.div`
  height: max-content;
  width: 757px;
  border-radius: 12px;
`

const Row = styledComponents.div`
  width: 100%;
  min-height: 54px;
  height: max-content;
  display: flex;
  justify-content: flex-start;
  gap: 10%;
  padding: 0px 0px 0px 16px;

  ${props => props.isHeader && `
    background: #F1F3F8;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    align-items: center;
  `}

  ${props => props.isRow && `
    background: #F9F9F7;
  `}
`

const RowText = styledComponents.p`
  font-family: ${ATC};
  font-style: normal;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0px;

  ${props => props.isHeader && `
    color: #303030;
    font-weight: 400;
    font-size: 18px;
  `}

  ${props => props.isRow && `
    color: #303030;
    font-weight: 200;
    font-size: 16px;
  `}
`

const CTAWrap = styledComponents.div`
  margin-top: 30px;
  button {
    font-family: ${ATC};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: white;

    cursor: pointer;
    height: 48px;
    width: 100%;
    padding: 16px;
    border: none;
    background: ${colors.pulpyOrange};
  }
`

const AcknowledgementPopup = ({
  show,
  toClose,
}) => {
  return (
    <Modal
      show={show}
      toClose={toClose}
      width="856px"
      responsive
      responsiveWidth="375px"
    >
      <HeaderWrap>
        <h1>Welcome to LoanDesk!</h1>
      </HeaderWrap>
      <DescriptionWrap>
        <p>We just want to share with you the relationship between Agentdesks, Inc and LoanDesk.</p>
      </DescriptionWrap>
      <BreakLine />
      <SubHeaderWrap>
        <h4>Affiliated Business Arrangement Disclosure</h4>
      </SubHeaderWrap>
      <ScrollWrap>
        <ContentWrap>
          <p>
            {`
            This is to give you notice that LoanDesk has a business relationship with Agentdesks, Inc. 
            Agentdesks, Inc is LoanDesk via a fictitious business name.  ​Because of this relationship, 
            any referrals of you by any of our Radius Agents may provide either organization and their 
            respective employees with a financial or other benefits.
            `}
          </p>
          <p>
            {`
            There are frequently other settlement service providers available with similar services. 
            You are free to shop around to determine that you are receiving the best services and 
            the best rate for these services.
            `}
          </p>
          <p>
            {`
            Set forth below is the estimated charge or range of charges for each of the services listed. 
            You are NOT required to use any of these service providers as a condition of the sale of the 
            subject property or to obtain access to any settlement service. These services may not be 
            available in all markets.
            `}
          </p>
        </ContentWrap>
        <TableWrap>
          <Table>
            <Row isHeader>
              <RowText>Service Provider</RowText>
              <RowText>Service Provided</RowText>
              <RowText>Charge or Range of Charges</RowText>
            </Row>
            <Row isRow>
              <RowText
                isRow
                style={{
                  margin: "15px 0px 10px 0px",
                }}
              >
                LoanDesk
              </RowText>
              <RowText
                isRow
                style={{
                  position: "relative",
                  right: "31px",
                  margin: "15px 0px 10px 0px",
                }}
              >
                Loan Origination
              </RowText>
              <RowText
                isRow
                style={{
                  position: "relative",
                  right: "30px",
                  margin: "15px 0px 10px 0px",
                }}
              >
                0% to 1% of the loan amount
              </RowText>
            </Row>
            <Row isRow>
              <RowText
                isRow
                style={{
                  margin: "15px 0px 10px 0px",
                }}
              >
                Agentdesks, Inc
              </RowText>
              <RowText
                isRow
                style={{
                  position: "relative",
                  left: "7px",
                  width: "154px",
                  margin: "15px 0px 10px 0px",
                }}
              >
                Real Estate Listing Services
              </RowText>
              <RowText
                isRow
                style={{
                  width: "259px",
                  position: "relative",
                  right: "21px",
                  margin: "15px 0px 10px 0px",
                }}
              >
                {`
                0% to 6% of the sale price of the listed property, 
                paid by the seller from escrow funds upon close of transaction. 
                Typically half of the listing commission will be paid to the buyer’s agent.
                `}
              </RowText>
            </Row>
          </Table>
        </TableWrap>
        <DescriptionWrap
          marginTop="15px"
        >
          <p>
            {`
              I have read this disclosure form and understand that the affiliation above may refer me 
              to purchase the above-described service(s) from one another and that any such 
              referrals may provide the referring company, its affiliates, and/or their 
              employees with a financial or other benefit.
            `}
          </p>
        </DescriptionWrap>
      </ScrollWrap>
      <CTAWrap>
        <button
          type="button"
          onClick={toClose}
        >
          I Acknowledge
        </button>
      </CTAWrap>
    </Modal>
  )
}

export default AcknowledgementPopup
