import React, { useState } from "react"
import ListingsContainer from "container/Listings"
import cookie from "services/CookieStorage"
import createToast from "dumbComponents/common/Toast/customToast"
import { useWindowSize } from "services/Utils"
import {
  InputSearch,
  TeamFilterAndSearch,
  Filter,
} from "dumbComponents/common/InputFields"
import FieldShimmer from "../FieldShimmer/index"
import ListingStatus from "../ListingStatus/index"
import {
  Wrap,
  FilterWrap,
  ClearFilterButton,
} from "../commonStyles"

const ListingFilters = ({
  getTeamDetailsResponse,
  toggleListingsFilterDrawer,
  query,
  getListingsResponse,
  constructQuery,
  clearFiltersOnDemand,
  setClearFiltersOnDemand,
}) => {
  const { width: screenWidth } = useWindowSize()
  const { isFetching: fetchingTeams } = getTeamDetailsResponse || {}
  const { isFetching: fetchingListingsRows } = getListingsResponse || {}
  const teamExists = cookie.load("brokerageTeamId") || ""
  const [searchValue, setSearchValue] = useState("")

  const handleStatusChange = (value) => {
    const curr = {
      ...query,
      dealType: value,
    }
    constructQuery({
      query: curr,
    })
  }

  const getStatusValue = () => {
    const {
      dealType,
    } = query || {}

    if (!dealType || dealType === null) return null

    if (dealType) {
      return dealType
    }

    return null
  }

  const triggerQuery = (value) => {
    const curr = {
      ...query,
      searchTextInListing: value || "",
    }
    setSearchValue(value || "")
    constructQuery({
      query: curr,
    })
  }

  const handleTeamFilterChange = (selectedValue,teamMember=null) => {
    if(teamMember){
      localStorage.setItem('listing_type_dropdown', teamMember);
    } else {
      localStorage.setItem('listing_type_dropdown', selectedValue);
    }
    const map = {
      "Team Listings": "view_all_team_members",
      "My Listings": "view_mine",
      "All Listings": null,
    }

    const curr = {
      ...query,
    }

    // eslint-disable-next-line no-prototype-builtins
    if (map.hasOwnProperty(selectedValue)) {
      curr.viewType = map[selectedValue]
    } else {
      curr.viewType = "view_specific_team_members"
      curr.teamMemberIds = [parseInt(selectedValue, 10)]
    }

    constructQuery({
      query: curr,
    })
  }

  const clearFilters = () => {
    setClearFiltersOnDemand(!clearFiltersOnDemand)

    const curr = {
      ...query,
    }

    Object.keys(curr).forEach((item) => {
      curr[item] = null
    })

    curr.skip = 0
    curr.limit = 4

    setSearchValue("")

    constructQuery({
      query: curr,
    })
  }

  return (
    <Wrap
      style={{
        padding: "20px 49px 20px 24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <FilterWrap>
        {fetchingListingsRows ? (
          <FieldShimmer />
        ) : (
          <ListingStatus
            handleStatusChange={handleStatusChange}
            currentStatus={getStatusValue()}
          />
        )}

        {fetchingListingsRows ? (
          <FieldShimmer />
        ) : (
          <InputSearch
            placeholder="Search by address or seller name"
            onSearch={e => triggerQuery(e)}
            style={screenWidth <= 1440 ? { borderRadius: "4px" } : { width: 372, borderRadius: "4px" }}
            noWidth
            defaultValue={searchValue}
          />
        )}
        {teamExists && teamExists !== "null" && teamExists !== undefined && (
          <>
            {(fetchingTeams || fetchingListingsRows) ? (
              <FieldShimmer />
            ) : (
              <TeamFilterAndSearch
                initOptions={["My Listings", "Team Listings", "All Listings"]}
                onMainSelectChange={handleTeamFilterChange}
              />
            )}
          </>
        )}
        {/**CTA For Filters*/}
      </FilterWrap>
      <FilterWrap>
        <Filter
          onClick={() => toggleListingsFilterDrawer(true)}
        />
        <ClearFilterButton
          onClick={clearFilters}
        >
          <p>Clear Filters</p>
        </ClearFilterButton>
      </FilterWrap>
    </Wrap>
  )
}

export default ListingsContainer(ListingFilters)
