import styled from "styled-components";
import colors from "@colors";
import { INTER, MONTSERRAT } from "@fonts";
import { Modal } from "antd";

const SubmittedContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  color: #232338;
  @media (max-width: 1024px) {
    padding: 16px;
    padding-bottom: 40px;
  }
`;

const WebContent = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;
const ModalHeader = styled.div`
  color: #232338;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-left: 24px;
  line-height: 27px;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
`;

const DateTimeDesc = styled.div`
  margin: 24px 0;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
`;

const MessageText = styled.textarea`
  width: 100%;
  height: 65px;
  border-radius: 8px;
  border: 1px solid #c1c1d7;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 300;
  color: #303030;
`;

const PreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
`;

const DateCardWrapper = styled.div`
  display: block;
  margin-right: 20px;
  margin-left: 16px;
  margin-bottom: 16px;
`;

const Address = styled.div`
  font-size: 16px;
  font-weight: 300;
`;

const Number = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #373758;
  margin: 0;
  margin-right: 4px;
`;

const Pipe = styled.p`
  color: #e0e0eb;
  margin: 0px 8px;
`;

const HouseDataWrap = styled.div`
  display: flex;
  margin-top: 8px;
  font-size: 16px;
  align-items: center;
  letter-spacing: 0.5px;
`;

const RequestModalWrapper = styled.div`
  height: 500px;
`;

export {
  SubmittedContentWrap,
  WebContent,
  ModalHeader,
  DateTimeDesc,
  MessageText,
  PreviewWrapper,
  DateCardWrapper,
  Address,
  Number,
  Pipe,
  HouseDataWrap,
  RequestModalWrapper
};
