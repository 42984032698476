import React, { useState, useEffect } from "react"
import styled from "styled-components"
import colors from "@colors"
import root, { alert } from "window-or-global"
import query from "query-string"
import { checkForUserObject } from "services/Utils"
import { get } from "lodash"
import { ATC } from "@fonts"
import TeamFilterComponent from "dumbComponents/Listings/components/TeamFilterComponent"
import Loader from "@ui/Loader"
import TCOfferBanner from "dumbComponents/TransactionCoordinationV2/Navbar/TCOfferBanner"
import moment from "moment"
import { DownOutlined } from "@ant-design/icons"
import ZeroState from "dumbComponents/common/ZeroState"
import { SwitchToggleV2 } from "@ui/Form"
import Container from "container/Realty/TransactionFormContainer"
import { withRouter } from "react-router"
import PlusIcon from "images/icons/WhiteIconPlus.png"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import ListingLogo from "images/Graphics/TransactionCoordinationAssets/ListingLogo.svg"
import SubmittedLogo from "images/Graphics/TransactionCoordinationAssets/SubmittedLogo.svg"
import TechAssetsContainer from "container/TechAssets"
import Listings from "container/Listings"
import Listing from "./Listing"
import AddTransactionPopup from "dumbComponents/Listings/components/AddTransactionPopup"
// import Filter from "images/Graphics/TransactionCoordinationAssets/Filter.png"
import { Button, Select, Input, Dropdown, Space, Empty, DatePicker } from "@ui/antd"

const { Search } = Input

const { RangePicker } = DatePicker

const Wrap = styled.div`
  margin-top: 32px;
  .ant-table-cell {
    font-family: ${ATC};
    color: #303030;
    font-size: 16px;
    font-weight: 300;
  }
  .ant-select-selector {
    width: 200px;
    border: 1px solid rgb(199, 199, 199) !important;
    border-radius: 4px;
  }
  .ant-picker {
    border: 1px solid rgb(199, 199, 199) !important;
    border-radius: 4px;
  }
`

const DropDownUI = styled(Dropdown)`
  .ant-dropdown-menu {
    width: 250px;
  }
  .ant-dropdown-menu-item {
    font-size: 16px;
    font-family: ${ATC};
    padding: 10px 12px;
  }
`

const SubmitNewFile = styled.button`
  height: 36px;
  width: 188px;
  border-radius: 100px;
  background: ${colors.marineBLue};
  color: ${colors.white};
  cursor: pointer;
  border: none;
  padding-left: 12px;
  padding-right: 12px;

  span img {
    height: 15px;
    position: relative;
    left: 11px;
    top: 3px;
  }
`
const FilterContainer = styled.div`
  height: 30px;
  width: 100px;
  display: flex;
  justify-content: space-between;
`
const FilterText = styled.h2`
  font-size: 20px;
  color: #8C8C8C;
  margin: 0;
  padding-top: 3px;
`
const FilterImage = styled.img`
  height: 24px;
  width: 24px;
`

const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${props => props.showMargin && "margin: 92px 0 27px;"}
  h1 {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: ${colors.black};
    margin: 10px 6px 0;
  }
`

const ListingWrap = styled.div`
  margin-top: 24px;
`

const InnerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ListingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.mt && `
    margin-top: ${props.mt}
  `}
  ${props => props.fd && `
    flex-direction: ${props.fd}
  `}
`
const DropDownHeader = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 22px;
  margin-left: 5px;
  h2 {

  }
  span {
    margin-left: 5px;
  }
`

const FilterSpace = styled.div`
  display: flex;

`
const FilterInnerSpace = styled.div`
  padding: 10px 0 0 20px;
`

const SearchContainer = styled.div``

const CTAWrap = styled.div``

const fireActionsOnLoad = (
  fetchFiles,
) => {
  const user = checkForUserObject("user")
  const agentId = get(user, "agent_id") || get(user, "id")
  useEffect(() => {
    fetchFiles({
      skip: 0,
      limit: 100,
      agentId,
      contractOnly: 1,
    })
  }, [])
}

const FileMenu = ({
  history,
  fetchTCFiles,
  fetchTransactionCoordinationDocumentsResponse,
  getTeamDetailsResponse,
  setLoaderOnAgentInfo,
  setDealToView,
  resetContractsForm,
  resetListingsForm,
  googleAuthToken,
  setEditingDealTeamMemberId,
  resetForm,
  toggleAddTransactionPopup,
  saveDocumentsForUploadToContracts,
  resetAllTCForms,
  // getTCBanner,
  clearTCData,
  setOfferIdToView,
  setListingId,
  ...props
}) => {
  console.log("props", props)
  fireActionsOnLoad(fetchTCFiles)

  const [showIncomplete, toggleComplete] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState("My Contracts")
  const [selectedMenuValue, setSelectedMenuValue] = useState("")
  const [searchText, setSearchText] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [dateFilter, setDateFilter] = useState([])
  const [teamMemberId, setTeamMemberId] = useState(null)
  const {
    data: teamDetails,
    isFetching: fetchingTeamDetails,
  } = getTeamDetailsResponse || {}
  const {
    data: deals,
    isFetching: fetchingTCDeals,
  } = fetchTransactionCoordinationDocumentsResponse || {}

  const user = checkForUserObject("user")
  const agentId = get(user, "agent_id") || get(user, "id")

  // useEffect(() => {
  //   if (agentId) {
  //     getTCBanner(agentId)
  //   }
  // }, [])

  const { search } = root.location
  const parsedQuery = query.parse(search)
  const filter = parsedQuery && parsedQuery.filter

  let fetchedDeals = deals
  //let legacyDeals = null

  const dealsExist = deals && deals.length > 0

  // if (filter && dealsExist) {
  //   fetchedDeals = deals.filter(deal => deal.file_type === "new_listing")
  // } else if (!filter && dealsExist) {
  //   fetchedDeals = deals.filter(deal => deal.file_type !== "new_listing")
  // }

  // if (!filter && showIncomplete && fetchedDeals && dealsExist) {
  //   fetchedDeals = fetchedDeals.filter(deal => deal.step === "incomplete")
  // } else if (!filter && fetchedDeals && dealsExist) {
  //   fetchedDeals = fetchedDeals.filter(deal => deal.completed_steps && deal.step !== "incomplete")
  // }

  const getFiltersOptions = () => {
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const user = checkForUserObject("user")
    const agentId = get(user, "agent_id") || get(user, "id")
    const teamNames = (teamDetails && teamDetails.team_members) ? teamDetails.team_members.filter(td => td.agent_id !== agentId).map(tm => ({
      key: `${tm.agent_id}::${tm.firstname}'s Contracts`,
      label: `${tm.firstname}'s Contracts`,
    })) : []

    return [
      {
        key: "::My Contracts",
        label: "My Contracts",
      },
      {
        key: "all::Team Contracts",
        label: "Team Contracts",
      },
      ...teamNames,
    ]
    // { value: "view_specific_team_member", label: `${td.firstname || ""}
  }

  const handleMenuClick = (e, multiselect) => {
    let event = e
    let allTeamMemberIds = []
    if (multiselect) {
      event = e[0]
      allTeamMemberIds = e.map(dt => parseInt(dt.key.split("::")[0], 10))
    }
    const payload = {}
    const user = checkForUserObject("user")
    const agentId = get(user, "agent_id") || get(user, "id")
    const keys = event?.key.split("::")
    const teamMemberId = (multiselect && event) ? allTeamMemberIds : null

    setSelectedMenu(keys ? keys[1] : "My Contracts")
    setSelectedMenuValue(keys ? keys[0] : "")
    setTeamMemberId(teamMemberId)
    if (teamMemberId?.length) {
      payload.memberAgentId = teamMemberId
      payload.filterBy = "member"
    }
    if (keys[0] === "all") {
      payload.filterBy = "all"
    }
    fetchTCFiles({
      skip: 0,
      limit: 100,
      agentId,
      contractOnly: 1,
      ...payload,
      apiMethod: "post",
      transactionType: "Contract",
    })
  }

  const getFilteredListings = () => {
    if (fetchedDeals) {
      // const startDate = dateFilter.length === 2 ? moment(dateFilter[0]).valueOf() : null
      // const endDate = dateFilter.length === 2 ? moment(dateFilter[1]).valueOf() : null
      const startDate = dateFilter.length === 2 && dateFilter[0] ? moment(dateFilter[0]).format("YYYY-MM-DD") : null;
      const endDate = dateFilter.length === 2 && dateFilter[1] ? moment(dateFilter[1]).format("YYYY-MM-DD") : null;
      console.log("dates", startDate, endDate)
      // moment
      const filterBySearchdeals = fetchedDeals.filter(dt => dt.listing_address?.toLowerCase()?.includes(searchText?.toLowerCase()) || dt.city?.toLowerCase()?.includes(searchText?.toLowerCase()) || dt.state?.toLowerCase()?.includes(searchText?.toLowerCase()) || dt.clients?.some(client => client.name?.toLowerCase().includes(searchText?.toLowerCase())) || !searchText)
      const filterByStatusDeals = filterBySearchdeals.filter(dt => dt.deal_status === statusFilter || !statusFilter)
      if (startDate && endDate) {
        return filterByStatusDeals.filter(dt => dt.deal_created_at && (moment(dt.deal_created_at).format("YYYY-MM-DD") >= startDate && moment(dt.deal_created_at).format("YYYY-MM-DD") <= endDate))
      }
      return filterByStatusDeals
    }
    return null
  }

  const addNewContract = () => {
    setLoaderOnAgentInfo(true)
    setOfferIdToView(null)
    setDealToView(null)
    setListingId(null)
    resetContractsForm()
    resetListingsForm()
    // setEditingDealTeamMemberId(null)
    // if (googleAuthToken?.data?.access_token) {
    //   history.push("/realty/transaction-coordination/contract/form")
    //   // history.push("/realty/transaction-coordination/contract/basic-info")
    // } else {
    //   history.push("/realty/transaction-coordination/contract/integrations")
    // }

    //GAPI ISSUE
    history.push("/realty/transaction-coordination/contract/form")
  }

  const addNewContractIntiate = () => {
    if (teamDetails?.id) {
      toggleAddTransactionPopup(true)
    } else {
      setEditingDealTeamMemberId(null)
      addNewContract()
    }
  }


  return (
    <Wrap>
      {/* <TCOfferBanner onClick={null} agentId={agentId} /> */}
      <AddTransactionPopup
        subtextForLead="Please choose the agent for this contract"
        subtextForTC="Add Contract on behalf of your team. Please select from dropdown"
        header="Add Contract"
        callback={addNewContract}
      />
      <CTAWrap>
        <InnerWrap>
          <SubmitNewFile
            className="add_new_contract"
            onClick={() => {
              setLoaderOnAgentInfo(true)
              setListingId(null)
              setOfferIdToView(null)
              setDealToView(null)
              saveDocumentsForUploadToContracts([])
              resetAllTCForms()
              resetContractsForm()
              resetListingsForm()
              clearTCData()
              // addNewContract()
              resetForm()
              setEditingDealTeamMemberId(null)
              addNewContractIntiate()
            }}
          >
            Add New Contract
            <span>
              <img src={PlusIcon} alt="test" />
            </span>
          </SubmitNewFile>
          <SearchContainer>
            <FilterInnerSpace>
              <Search
                placeholder="Search by address or client name"
                onSearch={(value) => { setSearchText(value) }}
                style={{ width: 300, border: "1px solid #C7C7C7", borderRadius: "4px" }}
              />
            </FilterInnerSpace>
            {/*
               Component: Input
               Function: take input and return based on matching client name
             */}
          </SearchContainer>
          {/* {!filter && (
            <SwitchContainer>
              <h1>Submitted/Verified Deals</h1>
              <SwitchToggleV2
                isSelected={showIncomplete}
                bg={colors.marineBLue}
                circleColor="#E9F3FB"
                onClick={() => {
                  toggleComplete(!showIncomplete)
                }}
              />
              <h1>Incomplete Deals</h1>
            </SwitchContainer>
          )} */}
          {/* <FilterContainer onClick={() => }>
            <FilterText>FILTER</FilterText>
            <FilterImage src={Filter} alt="filter" />
          </FilterContainer> */}
        </InnerWrap>
        <ListingHeader mt="10px" fd={(teamDetails && teamDetails.id) ? "row" : "row-reverse"}>
          {teamDetails && teamDetails.id && (
            <TeamFilterComponent
              handleMenuClick={handleMenuClick}
              firstValueOption={{ key: "::My Contracts", label: "My Contracts" }}
              secondValueOption={{ key: "all::Team Contracts", label: "Team Contracts" }}
              firstValueLabel="My Contracts"
              secondValueLabel="My Team"
              defaultInitialName="My Contracts"
              multiselect
            />
            // <DropDownUI
            //   overlayStyle={{
            //     fontSize: 40,
            //     width: "250px",
            //   }}
            //   menu={{
            //     items: getFiltersOptions(),
            //     onClick: handleMenuClick,
            //   }}
            // >
            //   <DropDownHeader>
            //     <h2>{selectedMenu}</h2>
            //     <span><DownOutlined /></span>
            //   </DropDownHeader>
            // </DropDownUI>
          )}
          <FilterSpace>
            <RangePicker
              style={{
                width: 400,
              }}
              onChange={(dates, values) => setDateFilter(values)}
              placeholder={["Contract Date From", "Contract Date To"]}
            />
            <Select
              style={{
                width: 200,
                marginLeft: "10px",
                border: "1px solid black !important",
                fontFamily: `${ATC}`,
                fontWeight: "100",
              }}
              placeholder="Select Status"
              onChange={(value) => { setStatusFilter(value) }}
              allowClear
              options={[
                {
                  value: "incomplete",
                  label: "Incomplete Contract",
                },
                {
                  value: "submitted",
                  label: "Contract Submitted",
                },
                {
                  value: "audit_verified",
                  label: "Audit Verified",
                },
                {
                  value: "demand_pending",
                  label: "Demand Pending",
                },
                {
                  value: "demand_completed",
                  label: "Demand Completed",
                },
                {
                  value: "closed",
                  label: "Closed",
                },
                {
                  value: "gross_commission_received",
                  label: "Gross Commission Received",
                },
                {
                  value: "agent_commission_initiated",
                  label: "Agent Commission Initiated",
                },
                {
                  value: "payment_received",
                  label: "Commission Finalized",
                },
                {
                  value: "contract_canceled",
                  label: "Contract Canceled ",
                },
                {
                  value: "archived",
                  label: "Archived",
                },
              ]}
            />
          </FilterSpace>
        </ListingHeader>
        <ListingWrap>
          <>
            {fetchingTCDeals ? (
              <Loader top={50} />
            ) : (
              <>
                {getFilteredListings() && getFilteredListings().length <= 0 ? (
                  <ZeroState
                    height="474px"
                    width="100%"
                    backgroundColor="#F9F9F7"
                    text="No Contract Found"
                    logo={filter ? ListingLogo : SubmittedLogo}
                    onClickHandler={() => { history.push("/realty/transaction-coordination/contract/basic-info") }}
                    displayStyle="flex"
                    alignItems="center"
                    justifyContent="center"
                    buttonCTAText=""
                    innerBoxDisplayStyle="flex"
                    innerBoxFlexDirection="column"
                    innerBoxContentJustification="center"
                    innerBoxItemAlign="center"
                  />
                  // <Empty />
                ) : (
                  <Listing
                    fetchingTCDeals={fetchingTCDeals}
                    deals={getFilteredListings()}
                    filter={filter}
                    showIncomplete={showIncomplete}
                  />
                )}
              </>
            )}
          </>
        </ListingWrap>
      </CTAWrap>
    </Wrap>
  )
}

export default Listings(TechAssetsContainer(TeamProfileContainer(Container(withRouter(FileMenu)))))
