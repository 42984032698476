/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react"
import Listings from "container/Listings"
import ListingsV2 from "container/ListingsV2"
import OffersV2 from "container/OffersV2"
import root from "window-or-global"
import TechAssets from "container/TechAssets"
import ContractDetails from "dumbComponents/common/ContractDetailsForm"
import { ContractCard } from "dumbComponents/common/ListingsCommon"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import Loader from "@ui/Loader"
import ComplianceSection from "../Compliance"
import { LISTINGS } from "dumbComponents/OffersV3/consts"

import {
  ListingsWrap,
  MenuWrap,
  ListingDetailsDiv,
} from "./commonStyles"
import {
  Tabs,
  HeaderSectionCommon,
} from "./components"
import ListingCoverSheet from "../ListingCoversheet"

const ListingTabs = ({
  currentListingTab = "cover_sheet",
  setCurrentListingTab,
  listingData,
  fetchEnvelopeListingsResponse,
  toggleDocumentTab,
  history,
  listingTabs,
  getContractByIdResponse,
  handleCollapseValues,
  location,
  match,
  viewListingPersistence,
  viewListingModal,
  isLoadingViewListingPage,
  setIsLoadingViewListingPage,
  getBrokerageListingsStatuses,
  processBeforeViewingContract,
}) => {
  const { search } = root.location
  const parsedQuery = queryString.parse(search)

  const fromNotification = localStorage.getItem("from_notifs")

  const {
    isFetching : fetchingEnvelopes,
    data: envelopeData
  } = fetchEnvelopeListingsResponse || []

  useEffect(() => {
    const hasMergedEnvelopes = envelopeData?.some(envelope => 
      envelope.merged_envelopes && envelope.merged_envelopes.length > 0
    );

    if(listingData?.listingStatus === "pending" && hasMergedEnvelopes){
      toggleDocumentTab("offer_package")
      setCurrentListingTab("compliance")
    }
  }, [envelopeData])

  useEffect(() => {

    if (listingData && (!listingData.editingAgentId || listingData.editingAgentId === null) && match?.params?.id) {
      setIsLoadingViewListingPage(true)
      getBrokerageListingsStatuses()
      viewListingPersistence({
        id: match.params.id,
        history,
        callback: (newListingData) => {
          handleCollapseValues(["property_information"])
          if(fromNotification === "true"){
            setCurrentListingTab("compliance")
          }
          else if (newListingData?.listingStatus === "pending") {
            setCurrentListingTab("contract_details")
          } else {
            setCurrentListingTab("cover_sheet")
          }

          viewListingModal({
            bool: false,
          })
        },
      })
    }

    if (listingData?.listingStatus === "pending") {
      setCurrentListingTab("contract_details")
    } else {
      setCurrentListingTab("cover_sheet")
    }
  }, [])

  const {
    data: contractData,
  } = getContractByIdResponse || {}

  let data = {
    ...listingData,
  }

  if (contractData && contractData.length > 0) {
    data = {
      ...data,
      ...contractData[0],
    }
  }

  const getTransactionId = () => {
    if (!parsedQuery || !parsedQuery.transaction_id) return ""
    return parsedQuery.transaction_id
  }

  const getCurrentDealId = () => {
    const splitUrl = location?.pathname?.split("/") || ""
    const extractedId = splitUrl && splitUrl[splitUrl.length - 1]
    if (isNaN(extractedId)) {
      return ""
    }
    return extractedId
  }

  const viewContract = () => {
    if (listingData?.dealId?.length || match?.params?.id?.length) {
      processBeforeViewingContract({
        dealId: listingData?.dealId || match.params.id,
        listingView: false,
        history,
      })
      setCurrentListingTab("contract_details")
    }
  }

  return (
    <>
      <ListingsWrap>
        <MenuWrap>
          {
            isLoadingViewListingPage ? (
              <>
                <Loader />
              </>
            )
              : (
                <>
                  <HeaderSectionCommon />
                  {currentListingTab === "contract_details" && listingData.contractId && (
                    <ListingDetailsDiv>
                      <ContractCard
                        details={data}
                        handleEdit={() => {
                          handleCollapseValues(["property_information"])
                          setCurrentListingTab("cover_sheet")
                        }}
                        mlsId={listingData ? listingData.propertyData ? listingData.propertyData.mls_id : "" : ""}
                        handleView={() => {
                          const {
                            propertyData: {
                              mls_id,
                              street,
                            },
                          } = listingData || {}

                          const moddedForUrl = street && street.split(" ").join("-") || "listing-radius"
                          if (!mls_id) return
                          root.open(`/property/${mls_id}/${moddedForUrl}`, "_blank")
                        }}
                      />
                    </ListingDetailsDiv>
                  )}
                  <Tabs
                    steps={listingTabs}
                    toggleTab={currentTab => setCurrentListingTab(currentTab)}
                    currentActiveTab={currentListingTab}
                    viewContract={viewContract}
                  />
                  {currentListingTab === "cover_sheet" && (
                    <ListingCoverSheet />
                  )}

                  {currentListingTab === "contract_details" && (
                    <ContractDetails />
                  )}

                  {currentListingTab === "compliance" && (
                    <ComplianceSection transactionId={getTransactionId()} />
                  )}
                </>
              )}
        </MenuWrap>
      </ListingsWrap>
    </>
  )
}

export default OffersV2(ListingsV2(TechAssets(withRouter(Listings(ListingTabs)))))
