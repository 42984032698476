import React, { useRef, useState, useEffect } from "react"
import { NewSelect, Select } from "@ui/antd"
import Listings from "container/Listings"
import StatusShimmer from "../StatusShimmer"
import {
  StatusIndicator,
  StatusColors,
  StatusSelectContainer,
  CustomSelect,
} from "./styles"
import {
  getStatusOptions,
} from "../../../commonUtils"

const NewListingStatusDropdown = ({
  currentStatus,
  handleStatusChange,
  getBrokerageListingsStatusesResponse,
  defaultValue,
  disabledStatuses,
}) => {
  const getColor = () => statuses && statuses.filter(item => item.value === currentStatus)[0].colorHex || "transparent"

  const [listingStatus, setListingStatus] = useState([])
  const {
    data: statuses,
    isFetching: fetchingStatus,
  } = getBrokerageListingsStatusesResponse || {}


  useEffect(() => {
    if(statuses){
      const listStatus = [...statuses]
      listStatus.forEach(item => {
        if (item.value === "incomplete") {
          item.disabled = true;
        }
      }) 
      setListingStatus([...listStatus])
    }
  }, [statuses])

  return (
    <>
      {fetchingStatus ? (
        <StatusShimmer />
      ) : (
        <StatusSelectContainer>
          <StatusIndicator bg={getColor()} />
          <CustomSelect
            value={currentStatus}
            defaultValue={defaultValue}
            onChange={handleStatusChange}
            bordered={false}
            options={listingStatus ? getStatusOptions(listingStatus) : []}
            placement="bottomRight"
            dropdownMatchSelectWidth={false}
            disabled={disabledStatuses && disabledStatuses.includes(currentStatus)}
          />
        </StatusSelectContainer>
      )}
    </>
  )
}

export default Listings(NewListingStatusDropdown)
