import React from "react"
import styled from "styled-components"
import Grace from "images/Graphics/MortgageLandingPageAssets/Grace_1.jpg"
import { ATC } from "@fonts"

const Wrap = styled.div`
  width: 100%;
  height: max-content;
  background: #F1F1F1;
`

const InnerWrap = styled.div`
  display: flex;
  flex-direction: ${props => props.fd};
  padding: ${props => props.padding};
  gap: 15px;
  justify-content: center;


  @media(min-width: 1366px) {
    padding-top: 100px;
  }

  @media(max-width: 767px) {
    flex-direction: column;
    padding: 27px;
  }
`

const PhotoWrap = styled.div`
  height: 470px;
  width: 541px;
  border-radius: 10px;
  background-image: url(${CLOUDFRONT}/RaulRamirez.jpg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media(min-width: 1024px) {
    width: 380px;
  }

  @media(min-width: 1366px) {
    width: 400px;
  }

  @media(max-width: 767px) {
    width: 100%;
    max-width: 380px;
    margin: 0 auto;
  }
`

const TextWrap = styled.div`
  width: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-conent: center;

  @media(max-width: 767px) {
    width: 100%;
  }
`

const AgentLoveWorking = styled.div`
   p {
    font-family: Butler;
    font-size: 22px;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.015em;
    text-align: center;
    margin-top: 0px;

    @media(min-width: 1366px) {
      font-size: 30px;
    }
   }
`

const Quote = styled.div`
   p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 400;
    font-size: 80px;
    line-height: 25px;
    color: #306F4D;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 30px;
   }
`

const Testimonial = styled.div`
  width: 90%;
  margin: 0 auto;
  p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    /* or 135% */

    text-align: center;

    /* Typography/General/Primary */

    color: #303030;
    margin-top: 0px;
  }
`

const Name = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 30px;
  color: #306F4D;
  
   p {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    /* or 133% */
    
    letter-spacing: 2px;
    text-transform: uppercase;
   }
`

const Partner = styled.div`
   display: flex;
   justify-content: center;
   align-content: center;
   p {
    font-family: ${ATC};
    font-size: 18px;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    margin-bottom: 0px;

    @media(max-width: 767px) {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 5px;
    }
   }
`

const LowRates = styled.div`
   display: flex;
   justify-content: center;
   align-content: center;
  p {
    font-family: Butler;
    font-size: 34px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0.005em;
    text-align: center;
    margin: 0px;

    @media(max-width: 767px) {
      line-height: 50px;
      font-size: 36px;
    }
  }
`

const FirstBlockTestimonial = () => (
  <Wrap>
    <InnerWrap
      padding="0px 100px 0px 100px"
      fd="row"
    >
      <PhotoWrap />
      <TextWrap>
        <AgentLoveWorking>
          <p>Agent&apos;s love working with Radius</p>
        </AgentLoveWorking>
        <Quote>
          <p>“</p>
        </Quote>
        <Testimonial>
          <p>
            {`
            I want my agents to know that we work with all kinds of buyers, 
            that we have versatility of programs, and that when they send me a customer, 
            I'm going to send one right back.
            `}
          </p>
        </Testimonial>
        <Name>
          RAUL RAMIREZ  •  LOAN ORIGINATOR
        </Name>
      </TextWrap>
    </InnerWrap>
    <InnerWrap
      padding="0px 50px 50px 50px"
      fd="column"
    >
      <Partner>
        <p>Partner with an in-house lender at Radius:</p>
      </Partner>
      <LowRates>
        <p>Low Rates. Fast Closes. Elevated Service.</p>
      </LowRates>
    </InnerWrap>
  </Wrap>
)

export default FirstBlockTestimonial
