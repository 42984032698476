import styled from "styled-components";
import colors from "@colors";
import { INTER, MONTSERRAT } from "@fonts";

const ListingPrice = styled.div`
  color: ${colors.new_grey600};
  font-size: 32px;
  font-weight: normal;
  margin-bottom: 8px;
  width: 150px;
`;

const Header = styled.div`
  color: ${colors.new_grey600};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: ${MONTSERRAT};
`;
const ListingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 100%;
`;
const StyledImage = styled.img`
  width: 16px;
  height: 16px;
`;
const MortgageWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.new_primary};
  background: ${colors.new_colorNeutral100};
  padding: 12px;
  width: 173px;
  height: 93px;
  box-shadow: 0px 44px 12px 0px rgba(90, 95, 242, 0),
    0px 28px 11px 0px rgba(90, 95, 242, 0.01),
    0px 16px 9px 0px rgba(90, 95, 242, 0.05),
    0px 7px 7px 0px rgba(90, 95, 242, 0.09),
    0px 2px 4px 0px rgba(90, 95, 242, 0.1);
`;
const Address = styled.div`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
  line-height: 1.5;
`;
const Information = styled.div`
  display: flex;
  margin-right: 20px;
  @media (max-width: 1024px) {
    margin: 0px 5px;
    maxwidth: 400px;
    margin-left: -10px;
  }
`;

const InformationInnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  @media (max-width: 1024px) {
    flex-direction: row;
    align-items: center;
    margin: 0px 10px;
  }
`;
const Entity = styled.div`
  color: ${colors.new_text_purple_600};
  font-family: ${INTER};
  font-size: 20px;
  line-height: 1.5;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;
const Icon = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    width: 16px;
    height: 16px;
    color: ${colors.iconDarkPurple};
    margin-right: 2px;
  }
`;
const Number = styled.div`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  margin-right: 4px;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const EstimateHeading = styled.div`
  color: ${colors.new_grey600};
  font-family: ${MONTSERRAT};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`;
const MortgageValue = styled.div`
  color: ${colors.new_grey600};
  font-family: ${MONTSERRAT};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
`;
const GetQualified = styled.div`
  color: ${colors.new_primary};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
`;
const InfoWrapper = styled.div`
  display: flex;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export {
  Icon,
  InfoWrapper,
  StyledImage,
  GetQualified,
  MortgageValue,
  EstimateHeading,
  Entity,
  Number,
  Information,
  Address,
  MortgageWrapper,
  ListingPrice,
  Header,
  ListingWrapper,
  InformationInnerWrap,
};
