import styled from "styled-components"
import colors from "@colors"
import { MONTSERRAT, INTER } from "@fonts"
import { Menu as MenuIcon } from "dumbComponents/CRMV2/CRMDashboard/Components/Icons"
import { NewSelect } from "@ui/antd/Forms"
import { Select } from "antd"

export const StatusColors = [
  {
    label: "New Client/Accepted",
    value:
      "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"11\" fill=\"none\"><g clip-path=\"url(%23a)\"><circle cx=\"5\" cy=\"5.5\" r=\"5\" fill=\"url(%23b)\"/></g><defs><radialGradient id=\"b\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"rotate(61.031 -.716 4.58) scale(8.12967)\"><stop stop-color=\"%23B8BAF9\"/><stop offset=\".333\" stop-color=\"%23898CF6\"/><stop offset=\".536\" stop-color=\"%235A5FF2\"/><stop offset=\".755\" stop-color=\"%232A31EE\"/><stop offset=\"1\" stop-color=\"%231117D5\"/></radialGradient><clipPath id=\"a\"><path fill=\"%23fff\" transform=\"translate(0 .5)\" d=\"M0 0h10v10H0z\"/></clipPath></defs></svg>')",
  },
  {
    label: "Met with Client",
    value:
      "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"10\" fill=\"none\"><circle cx=\"5\" cy=\"5\" r=\"5\" fill=\"url(%23a)\"/><defs><radialGradient id=\"a\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"rotate(61.031 -.291 4.33) scale(8.12967)\"><stop stop-color=\"%23C0EED1\"/><stop offset=\".333\" stop-color=\"%23F2DFB6\"/><stop offset=\".536\" stop-color=\"%23E9CB87\"/><stop offset=\".755\" stop-color=\"%23E1B85B\"/><stop offset=\"1\" stop-color=\"%23D9A530\"/></radialGradient></defs></svg>')",
  },
  {
    label: "Pre-approved/Listing Prepped",
    value:
      "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"10\" fill=\"none\"><circle cx=\"5\" cy=\"5\" r=\"5\" fill=\"url(%23a)\"/><defs><radialGradient id=\"a\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"rotate(61.031 -.291 4.33) scale(8.12967)\"><stop stop-color=\"%23C0EED1\"/><stop offset=\".333\" stop-color=\"%2387E8AB\"/><stop offset=\".536\" stop-color=\"%230DE95E\"/><stop offset=\".755\" stop-color=\"%2348C376\"/><stop offset=\"1\" stop-color=\"%232EA85B\"/></radialGradient></defs></svg>')",
  },
  {
    label: "Showings/Tours",
    value:
      "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"10\" fill=\"none\"><circle cx=\"5\" cy=\"5\" r=\"5\" fill=\"url(%23a)\"/><defs><radialGradient id=\"a\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"rotate(61.031 -.291 4.33) scale(8.12967)\"><stop stop-color=\"%23FFF7F0\"/><stop offset=\".333\" stop-color=\"%23FFF2E6\"/><stop offset=\".536\" stop-color=\"%23FFD8B2\"/><stop offset=\".755\" stop-color=\"%23FFBE80\"/><stop offset=\"1\" stop-color=\"%23FFA54D\"/></radialGradient></defs></svg>')",
  },
  {
    label: "Sending/Receiving Offers",
    value:
      "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"10\" fill=\"none\"><circle cx=\"5\" cy=\"5\" r=\"5\" fill=\"url(%23a)\"/><defs><radialGradient id=\"a\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"rotate(61.031 -.291 4.33) scale(8.12967)\"><stop stop-color=\"%23FCC5C5\"/><stop offset=\".333\" stop-color=\"%23F43434\"/><stop offset=\".536\" stop-color=\"%23E90D0D\"/><stop offset=\".755\" stop-color=\"%23B80A0A\"/><stop offset=\"1\" stop-color=\"%23870808\"/></radialGradient></defs></svg>')",
  },
  {
    label: "In Contract",
    value:
      "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"10\" fill=\"none\"><circle cx=\"5\" cy=\"5\" r=\"5\" fill=\"url(%23a)\"/><defs><radialGradient id=\"a\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"rotate(61.031 -.291 4.33) scale(8.12967)\"><stop stop-color=\"%23E0E0E0\"/><stop offset=\".333\" stop-color=\"%23B3B3B3\"/><stop offset=\".536\" stop-color=\"%23999\"/><stop offset=\".755\" stop-color=\"gray\"/><stop offset=\"1\" stop-color=\"%23666\"/></radialGradient></defs></svg>')",
  },
  {
    label: "Closed",
    value:
      "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"10\" fill=\"none\"><circle cx=\"5\" cy=\"5\" r=\"5\" fill=\"url(%23a)\"/><defs><radialGradient id=\"a\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"rotate(61.031 -.291 4.33) scale(8.12967)\"><stop stop-color=\"%23E6D3EE\"/><stop offset=\".333\" stop-color=\"%23C092D4\"/><stop offset=\".536\" stop-color=\"%23AB6DC5\"/><stop offset=\".755\" stop-color=\"%239649B7\"/><stop offset=\"1\" stop-color=\"%23783A92\"/></radialGradient></defs></svg>')",
  },
  {
    label: "Archive",
    value:
      "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"10\" fill=\"none\"><circle cx=\"5\" cy=\"5\" r=\"5\" fill=\"url(%23a)\"/><defs><radialGradient id=\"a\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"rotate(61.031 -.291 4.33) scale(8.12967)\"><stop stop-color=\"%23F8DDC9\"/><stop offset=\".333\" stop-color=\"%23EDA978\"/><stop offset=\".536\" stop-color=\"%23E68C4C\"/><stop offset=\".755\" stop-color=\"%23E06F1F\"/><stop offset=\"1\" stop-color=\"%23B35919\"/></radialGradient></defs></svg>')",
  },
]

export const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  position: relative;
  top: 0.5px;
  z-index: 10;

  ${props => `
    background-color: ${props.bg};
  `}
`

export const CustomSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid ${colors.new_borderGrey} !important;
    color: ${colors.new_grey400} !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
    font-family: Montserrat, 'sans-serif' !important;
    font-weight: 600 !important;
    padding: 10px 14px !important;
  }

  &.ant-select-focused .ant-select-selector {
    border: 1px solid ${colors.new_primary} !important;
  }

  .ant-select-arrow {
    color: ${colors.new_grey400};
  }

  &.ant-select-focused .ant-select-selector .ant-select-selection-item {
    border-color: none;
  }

  &.ant-select-multiple .ant-select-selector {
    height: 100% !important;
  }

  &.ant-select-multiple .ant-select-selection-item {
    height: 42px;
    align-items: center;
  }
`

export const StatusSelectContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;

  ${StatusIndicator} {
    transform: translateX(22px);
  }

  ${CustomSelect} {
    .ant-select-selector {
      border-radius: 4px !important;
      border: 1px solid ${colors.new_colorNeutral200} !important;
      color: ${colors.new_typographyGeneralPrimary} !important;
      box-shadow: none !important;
      font-family: ${MONTSERRAT} !important;
      padding: 6px 6px 6px 28px !important;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.5px;
      height: 37px !important;
      width: 161px;
      font-size: 11.5px;
    }

    &.ant-select-focused .ant-select-selector {
      border: 1px solid ${colors.new_typographyGeneralPrimary} !important;
    }

    .ant-select-arrow {
      color: ${colors.new_typographyGeneralPrimary};
      font-family: ${MONTSERRAT}, 'sans-serif' !important;
      font-weight: 600 !important;
    }

    &.ant-select-focused .ant-select-selector .ant-select-selection-item {
      color: ${colors.new_typographyGeneralPrimary};
    }
    &.ant-select-focused{
      border-color: none;
    }
  }
`
