import React from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import get from "lodash/get"
import Button from "@ui/LoaderButton"
import OnboardingContainer from "container/OnboardingSetup"
import uploadSVG from "dumbComponents/OnboardingOrientation/Images/upload.svg"
// import UploadIcon from "../../Images/Upload.png"
import Loader from "@ui/Loader"

const FormWrap = styled.div`
  margin-left: 31px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar { 
    display: none;
  }
`
const FirstDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
width: 212.71px;
height: 22px;
font-family: ${ATC};
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 22px;
color: #303030;
margin-bottom: 8px;
`
const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 449.43px;
  height: 69px;
  box-shadow: 0px 1px 11px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 0px;
`
const UploadButton = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 150px;
  font-weight: 200;
  font-size: 16px;
  height: 36px;
  border: 1px solid #3B60E4;
  border-radius: 100px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #3B60E4;

  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`
const UploadButtonWrap = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  ${props => props.disabled && `
    pointer-events: none;
  `}
`
const UploadUI = styled(Button)`
  border: 1px solid #3B60E4;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 28px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: #3B60E4;
  display: flex;
  img {
    margin: 0 10px 0;
  }
  cursor: pointer;
  width: 148px;
  padding: 8px 15px;
  ${props => props.isDisabled && `
    pointer-events: none;
    background: #e7ebed;
    border: snow;
  `}
`

const ShowFileName = styled.div`
  margin-top: 5px;
  font-size: 12px;
`

const ContinueButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: white;
  gap: 10px;
  width: 449.43px;
  height: 48px;
  background: #3B60E4;
  border-radius: 36px;
  margin-top: 24.5px;
  margin-bottom: 16px;
  border: 0px;
  cursor: pointer;

  ${props => props.disabled === true && `
    background: #989898;
    color: white;
  `}
`
const GoBackButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 10px;
  width: 449.43px;
  margin-bottom: 16px;
  height: 48px;
  border: 1.25px solid #3B60E4;
  border-radius: 36px;
  color: #3B60E4;
  cursor: pointer;
`
const SaveForLater = styled.h3`
  display: flex;
  align-self: center;
  width: 113px;
  height: 22px;
  margin: 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #8C8C8C;
  cursor: pointer;
`

class SecondFormDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    const { response } = this.props
    this.state = {
      currentlyUpload: null,
      statVars: {
        file1Name: [],
        file2Name: null,
        file3Name: null,
      },
      form: {
        step_completed: "upload_files",
        license_url: response.license_url ? response.license_url : null,
        w9_form_url: response.w9_form_url ? response.w9_form_url : null,
        s_corp_w9_form_url: response.s_corp_w9_form_url ? response.s_corp_w9_form_url : null,
      },
    }
  }

  componentDidMount() {
    this.validateForm()
  }

  updateOrientationInfoFunction = () => {
    const {
      updateOrientationInfo, setFormStage, uploadDocsToBucketResponse, response,
    } = this.props
    const { form } = this.state
    this.setState({
      isLoading: true,
    })
    const payload = {
      license_url: response.license_url ? response.license_url : null,
      w9_form_url: response.w9_form_url ? response.w9_form_url : null,
      s_corp_w9_form_url: response.s_corp_w9_form_url ? response.s_corp_w9_form_url : null,
      step_completed: "upload_files",
    }
    // setFormStage("3")
    payload.license_url = response.license_url ? response.license_url : uploadDocsToBucketResponse?.data?.licenseUrl?.licenseUrl
    payload.w9_form_url = response.w9_form_url ? response.w9_form_url : uploadDocsToBucketResponse?.data?.w9Url?.w9Url || null
    payload.s_corp_w9_form_url = response.s_corp_w9_form_url ? response.s_corp_w9_form_url : (uploadDocsToBucketResponse.data?.sCorpW9Url?.sCorpW9Url ? uploadDocsToBucketResponse.data?.sCorpW9Url?.sCorpW9Url : null)
    this.setState({
      form: {
        license_url: uploadDocsToBucketResponse.data?.licenseUrl?.licenseUrl || response.license_url || null,
        w9_form_url: uploadDocsToBucketResponse.data?.w9Url?.w9Url || response.w9_form_url || null,
        s_corp_w9_form_url: uploadDocsToBucketResponse.data?.sCorpW9Url?.sCorpW9Url || response.s_corp_w9_form_url || null,
      },
    })
    updateOrientationInfo({ payload, setStage: "3" })
  }

  validateForm = () => {
    const { uploadDocsToBucketResponse, response } = this.props
    const licenseUrl = get(uploadDocsToBucketResponse, "data[licenseUrl]")
    const w9Url = get(uploadDocsToBucketResponse, "data[w9Url]")
    //(w9Url || response.w9_form_url) && (licenseUrl || response.license_url)
    const check = licenseUrl || response.license_url
    if (check) {
      return false
      // eslint-disable-next-line no-else-return
    } else {
      return true
    }
  }

  goBackFunction = () => {
    const { setFormStage, setIsGoBack } = this.props
    setIsGoBack(true)
    setFormStage("1")
  }

  saveForLater = () => {
    const { setFormStage, setIsGoBack } = this.props
    setIsGoBack(true)
    setFormStage("0")
  }

  cleanUrl = (str) => {
    const newstr = str.split("/").pop()
    return newstr
  }

  setCurrentlyUploading = (value) => {
    setTimeout(() => {
      this.setState({
        currentlyUpload: value,
      })
    }, 1000)
  }

  render() {
    const {
      statVars, form, licenseUrlChange, currentlyUpload, isLoading,
    } = this.state
    const {
      uploadDocsToBucket, uploadDocsToBucketResponse, response, setFileUploading, fileUploading,
    } = this.props
    return (
      <FormWrap>
        <FirstDiv>
          <Label>
            Real Estate License
            <span style={{ color: "#F53F3F" }}>*</span>
          </Label>
          <FileUpload>
            <div style={{ display: "flex" }}>
              <UploadButtonWrap disabled={uploadDocsToBucketResponse.isFetching === true}>
                <UploadUI
                  loaderOption={{
                    color: "#3B60E4",
                    margin: "auto",
                  }}
                  isDisabled={uploadDocsToBucketResponse.isFetching === true}
                  isShowLoader={uploadDocsToBucketResponse.isFetching === true && fileUploading === "licenseUrl"}
                >
                  Upload file
                  <img src={uploadSVG} alt="upload image" />
                </UploadUI>
                <UploadButton
                  type="file"
                  onChange={(e) => {
                    this.setState({
                      form: {
                        license_url: e.target.files[0].name,
                      },
                    })
                    setFileUploading("licenseUrl")
                    uploadDocsToBucket({
                      file: e.target.files[0],
                      objName: "licenseUrl",
                      fileName: e.target.files[0].name,
                    })
                  }}
                />
              </UploadButtonWrap>
            </div>
            <ShowFileName>
              {uploadDocsToBucketResponse.data?.licenseUrl?.licenseUrlFileName || (response?.license_url && this.cleanUrl(response?.license_url)) || null}
            </ShowFileName>
          </FileUpload>
        </FirstDiv>
        <Label>
          W9 Form
          {/* <span style={{ color: "#F53F3F" }}>*</span> */}
        </Label>
        <FileUpload>
          <div style={{ display: "flex" }}>
            <UploadButtonWrap disabled={uploadDocsToBucketResponse.isFetching === true}>
              <UploadUI
                loaderOption={{
                  color: "#3B60E4",
                  margin: "auto",
                }}
                isDisabled={uploadDocsToBucketResponse.isFetching === true}
                isShowLoader={uploadDocsToBucketResponse.isFetching === true && fileUploading === "w9Url"}
              >
                Upload file
                <img src={uploadSVG} alt="upload image" />
              </UploadUI>
              <UploadButton
                type="file"
                onChange={(e) => {
                  this.setState({
                    form: {
                      w9_form_url: e.target.files[0].name,
                    },
                  })
                  setFileUploading("w9Url")
                  uploadDocsToBucket({
                    file: e.target.files[0],
                    objName: "w9Url",
                    fileName: e.target.files[0].name,
                  })
                }}
              />
            </UploadButtonWrap>
          </div>
          <ShowFileName>
            {uploadDocsToBucketResponse.data?.w9Url?.w9UrlFileName || (response?.w9_form_url && this.cleanUrl(response?.w9_form_url)) || null}
          </ShowFileName>
        </FileUpload>
        <Label>
          S Corp W9 Form
        </Label>
        <FileUpload>
          <div style={{ display: "flex" }}>
            <UploadButtonWrap disabled={uploadDocsToBucketResponse.isFetching === true}>
              <UploadUI
                loaderOption={{
                  color: "#3B60E4",
                  margin: "auto",
                }}
                isDisabled={uploadDocsToBucketResponse.isFetching === true}
                isShowLoader={uploadDocsToBucketResponse.isFetching === true && fileUploading === "sCorpW9Url"}
              >
                Upload file
                <img src={uploadSVG} alt="upload image" />
              </UploadUI>
              <UploadButton
                type="file"
                onChange={(e) => {
                  this.setState({
                    form: {
                      s_corp_w9_form_url: e.target.files[0].name,
                    },
                  })
                  setFileUploading("sCorpW9Url")
                  uploadDocsToBucket({
                    file: e.target.files[0],
                    objName: "sCorpW9Url",
                    fileName: e.target.files[0].name,
                  })
                }}
              />
            </UploadButtonWrap>
            {uploadDocsToBucketResponse.isFetching === true && fileUploading === "sCorpW9Url"
              ? (
                <Loader size={30} top={0} left={0} />) : null}
          </div>
          <ShowFileName>
            {uploadDocsToBucketResponse.data?.sCorpW9Url?.sCorpW9UrlFileName || (response?.s_corp_w9_form_url && this.cleanUrl(response?.s_corp_w9_form_url)) || null}
          </ShowFileName>
        </FileUpload>
        <ContinueButton
          isShowLoader={isLoading}
          disabled={this.validateForm()}
          onClick={this.updateOrientationInfoFunction}
        >
          Continue
        </ContinueButton>
        <GoBackButton onClick={this.goBackFunction}>Go back</GoBackButton>
        <SaveForLater onClick={this.saveForLater}>Save For Later</SaveForLater>
      </FormWrap>
    )
  }
}

export default OnboardingContainer(SecondFormDetails)
