import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
  fetchUserInfoAction,
} from "container/App/actions"
import {
  setRTFContentAction,
  uploadImagesToS3Action,
  submitTeamDetailsAction,
  getTeamDetailsAction,
  setCurrentImageToBeCroppedAction,
  setRemovedImagesArrayAction,
  fileSizeErrorToggleAction,
  getTeamDetailsViaNameAction,
  toggleDeleteModeAction,
  setCurrentImageTypeToBeDeletedAction,
  toggleDeleteConfirmationAction,
  toggleColorProfileModalAction,
  setImageBlocksAction,
  getOtherTeamDetailsAction,
  toggleTeamProfileEditModeAction,
  getTCAgentAssignedAction,
  toggleCreateTCInvitation,
  getTeamTCs,
  inviteTeamTCs,
} from "./actions"

const mapStateToProps = (state, ownProps) => {
  const { realty, teamProfileReducer, app } = state
  const { teamDetails: teamDetailsServer } = (ownProps && ownProps.staticContext) || {}
  const {
    brokerageTeamId,
  } = app
  return {
    ...realty,
    ...teamProfileReducer,
    ...teamDetailsServer,
    brokerageTeamId,
  }
}

const mapDispatchToProps = dispatch => (
  {

    ...bindActionCreators({
      setCurrentRTFValue: setRTFContentAction.call,
      uploadToS3: uploadImagesToS3Action.request,
      submitTeamDetails: submitTeamDetailsAction.request,
      getTeamDetails: getTeamDetailsAction.request,
      fetchUserInfo: fetchUserInfoAction.request,
      setCurrentImageToBeCropped: setCurrentImageToBeCroppedAction.call,
      setRemovedImage: setRemovedImagesArrayAction.call,
      fileSizeErrorToggle: fileSizeErrorToggleAction.call,
      getTeamDetailsViaName: getTeamDetailsViaNameAction.request,
      toggleDeleteModal: toggleDeleteConfirmationAction.call,
      deleteModeToggle: toggleDeleteModeAction.call,
      setCurrentImageTypeToBeDeleted: setCurrentImageTypeToBeDeletedAction.call,
      toggleColorProfileModal: toggleColorProfileModalAction.call,
      setImageBlocks: setImageBlocksAction.call,
      getOtherTeamDetails: getOtherTeamDetailsAction.request,
      toggleEditMode: toggleTeamProfileEditModeAction.call,
      getTCAGentAssigned: getTCAgentAssignedAction.request,
      toggleCreateTCInvitation: toggleCreateTCInvitation.call,
      getTeamTCs: getTeamTCs.request,
      inviteTeamTCs: inviteTeamTCs.request,
    }, dispatch),
  })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)
