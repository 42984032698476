import React, { useEffect } from "react"
import {
  OFFER_DOCS,
  LISTING_DOCS,
  UPLOADS,
} from "dumbComponents/ListingsV2/const"
import ListingsV2 from "container/ListingsV2"
import OffersV2 from "container/OffersV2"
import Listings from "container/Listings"
//import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import OldOffers from "container/TechAssets"
import { EyeIcon, TrashIcon } from "dumbComponents/ListingsV2/icons"
import DocumentShimmer from "dumbComponents/ListingsV2/Components/DocumentShimmer"
import Tabs from "dumbComponents/ListingsV2/Components/Tabs"
import {
  Wrap,
  DocumentTypeWrap,
} from "./style"
import OfferDoc from "./OfferDoc"
import UploadDoc from "./UploadDocs"
import createToast from "dumbComponents/common/Toast/customToast"
import { OFFERS, LISTINGS, PURCHASE } from "dumbComponents/OffersV3/consts"

const addDocumentsUI = ({
  options: LibraryOptions,
  toggleLibraryTab,
  currentLibraryTab,
  listingDocsList,
  offersDocList,
  fetchLibraryUploads,
  fetchLibraryUploadedFilesResponse,
  docList,
  getOfferDocumentsResponse,
  getOfferDocuments,
  goBack,
  fetchOfferDocuments,
  stateForOffer,
  fetchOfferDocumentsResponse,
  updateRecommendedListOfDocs,
  fetchListingDocumentsResponse,
  fetchListingDocuments,
  getTemplatePreview,
  deleteLibraryUploadedFile,
  updateRecommendedListOfDocsResponse,
  uploadedDocs,
  //shrungi
  deleteLibraryUploadedFileList,
  fetchOfferDocumentsList,
  offersDocListView,
  listingDocsListView,
  fetchListingDocumentsList,
  fetchOfferDocumentsListResponse,
  fetchListingDocumentsListResponse,
  fetchLibraryUploadsList,
  fetchLibraryUploadedFilesListResponse,
  uploadedDocsList,
  updateEnvelopes,
  updateEnvelopesResponse,
  listingIdToView,
  currentState,
  currentBrokerageType,
  dealToView,
  offerIdToView,
  isAddChecklistDocument,
  envelopeIdForAddDocument,
}) => {

  const {
    isFetching: fetchingOffersDocs,
  } = getOfferDocumentsResponse || {}

  const {
    isFetching: updatingRecommendedDocs,
  } = updateEnvelopesResponse || {}

  const {
    isFetching: fetchingLibraryUploads,
  } = fetchLibraryUploadedFilesListResponse || {}

  const {
    isFetching: offerDocumentsFetching,
  } = fetchOfferDocumentsListResponse || {}

  const {
    isFetching: fetchingListingDocs,
  } = fetchListingDocumentsListResponse || {}

  useEffect(() => {
    if (currentLibraryTab === UPLOADS) {
      fetchLibraryUploadsList({
        payload: {
          transaction_type: currentBrokerageType === OFFERS ? "offer" : "listing",
          transaction_id: currentBrokerageType === OFFERS ? (offerIdToView || dealToView) : listingIdToView,
        },
      })
    }

    if (currentLibraryTab === OFFER_DOCS) {
      fetchOfferDocumentsList({
        //state: stateForOffer, //shrungi
        state: currentState
      })
    }

    if (currentLibraryTab === LISTING_DOCS) {
      fetchListingDocumentsList({
        //state: stateForOffer, shrungi
        state:currentState
      })
    }
  }, [currentLibraryTab])

  const handleSubmit = () => {
    let payload
    let id
    if(currentBrokerageType == OFFERS){
      payload = {
        offerId:offerIdToView || dealToView,
        templateIds: [],
      }
      id = offerIdToView || dealToView
    } else if (currentBrokerageType == LISTINGS){
      payload = {
        listingId:listingIdToView,
        templateIds: [],
      }
      id = listingIdToView
    }
    let templatesToBeUploaded = []

    if (currentLibraryTab === UPLOADS) {
      const { sharedWithMe, teamDocs, transactionDocs } = uploadedDocsList || {}
      const selectedSharedDoc = sharedWithMe.filter(item => item.isSelected).map(item => item.id)
      const selectedIndividualDoc = teamDocs.filter(item => item.isSelected).map(item => item.id)
      const selectedTransactionDocs = transactionDocs.filter(item => item.isSelected).map(item => item.id)
      templatesToBeUploaded = [...selectedSharedDoc, ...selectedIndividualDoc, ...selectedTransactionDocs]
      if(isAddChecklistDocument && templatesToBeUploaded.length != 1){
        createToast("You can only select one document at a time","error")
        return;
      }
      const idPayload = {
        ids: [...templatesToBeUploaded],
        type: "library_uploads",
      }
      payload.templateIds = [idPayload]
    }
    if (currentLibraryTab === LISTING_DOCS) {
      const selectedItems = listingDocsListView.filter(item => item.isSelected);
      if(isAddChecklistDocument && selectedItems.length != 1){
        createToast("You can only select one document at a time","error")
        return;
      }
      const groupedByType = selectedItems.reduce((acc, item) => {
        if (!acc[item.template_type]) {
            acc[item.template_type] = [];
        }
        acc[item.template_type].push(item.id);
        return acc;
    }, {});

    const templateIds = Object.entries(groupedByType).map(([type, ids]) => ({
      ids,
      type
    }));
    payload.templateIds=templateIds
    }
    if (currentLibraryTab === OFFER_DOCS) {
      const selectedItems = offersDocListView.filter(item => item.isSelected);
      if(isAddChecklistDocument && selectedItems.length != 1){
        createToast("You can only select one document at a time","error")
        return;
      }
      const groupedByType = selectedItems.reduce((acc, item) => {
        if (!acc[item.template_type]) {
            acc[item.template_type] = [];
        }
        acc[item.template_type].push(item.id);
        return acc;
    }, {});

    const templateIds = Object.entries(groupedByType).map(([type, ids]) => ({
      ids,
      type
    }));
    payload.templateIds=templateIds
  }
  if(isAddChecklistDocument){
    payload.envelopeId= envelopeIdForAddDocument
  }
  updateEnvelopes({
    type: currentBrokerageType,
    payload,
    id,
    callBack: () => {
      goBack()
    },
  })
  }

  const OFFERS_MAIN_LIST = [
    {
      label: "VIEW",
      image: <EyeIcon />,
      onClick: (id) => {
        //needs a loader
        const payload = {
          templateId: id,
        }
        //NEED TOAST
        getTemplatePreview({
          payload,
          dealType: "offer",
        })
      },
      showDivide: false,
    },
  ]

  const LISTING_MAIN_LIST = [
    {
      label: "VIEW",
      image: <EyeIcon />,
      onClick: (id) => {
        const payload = {
          templateId: id,
        }
        //NEED TOAST
        getTemplatePreview({
          payload,
          dealType: "listing",
        })
      },
      showDivide: false,
    },
  ]
  
  const UPLOAD_MAIN_LIST = [
    {
      label: "DELETE",
      image: <TrashIcon />,
      onClick: (id) => {
        //NEED TOAST shrungi
        createToast("Deleting document...")
        deleteLibraryUploadedFileList({
          id,
          transaction_type: currentBrokerageType === OFFERS ? "offer" : "listing",
          transaction_id: currentBrokerageType === OFFERS ? (offerIdToView || dealToView) : listingIdToView,
        })
      },
      showDivide: false,
    },
  ]

  return (
    <Wrap>
      <Tabs
        steps={LibraryOptions}
        toggleTab={currentTab => toggleLibraryTab(currentTab)}
        currentTab={currentLibraryTab}
        tabWidth="100%"
      />
      <DocumentTypeWrap>
        {currentLibraryTab === OFFER_DOCS && (
          <>
            {offerDocumentsFetching ? (
              <DocumentShimmer />
            ) : (
              <OfferDoc
                docs={offersDocListView}
                handleSubmit={handleSubmit}
                mainList={OFFERS_MAIN_LIST}
                updatingRecommendedDocs={updatingRecommendedDocs}
              />
            )}
          </>
        )}
        {currentLibraryTab === LISTING_DOCS && (
          <>
            {fetchingListingDocs ? (
              <DocumentShimmer />
            ) : (
              <OfferDoc
                docs={listingDocsListView}
                handleSubmit={handleSubmit}
                mainList={LISTING_MAIN_LIST}
                updatingRecommendedDocs={updatingRecommendedDocs}
              />
            )}
          </>
        )}
        {currentLibraryTab === UPLOADS && (
          <>
            {fetchingLibraryUploads ? (
              <DocumentShimmer />
            ) : (
              <UploadDoc
                handleSubmit={handleSubmit}
                goBack={goBack}
                mainList={UPLOAD_MAIN_LIST}
                updatingRecommendedDocs={updatingRecommendedDocs}
                transactionType={currentBrokerageType === OFFERS ? "offer" : "listing"}
                transactionId={currentBrokerageType === OFFERS ? (offerIdToView || dealToView) : listingIdToView}
              />
            )}
          </>
        )}
      </DocumentTypeWrap>
    </Wrap>
  )
}

export default Listings(OldOffers(ListingsV2(OffersV2(addDocumentsUI))))
