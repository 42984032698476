import { createDeltaReducer, handleDeltaReducer } from "shared/reduxUtils"
import * as Actions from "./actions"

const initState = {
  showPopupDetails: {
    showMortgagePopup: false,
  },
  toggleScreenPopupStep: null,
  isShow: null,
  fileUploading: null,
  businessCard: {},
  yardSign: {},
  preferrerdTitle: "",
  bothDone: null,
  dealToView: null,
  loaderOnAgentInfo: true,
  contractForAgent: "",
  // loaderOnAgentInfo: false,
  escrowIdToEdit: null,
  updatingBuyer: false,
  agentGoogleContacts: [],
  showViewListingHistory: false,
  showComfirmToGoToContract: false,
  showDocumentSelectionConfirm: false,
  showToggleDocumentSentPopup: false,
  showConfirmToExecute: false,
  showUploadListingImages: false,
  showUploadListingDocs: false,
  showUploadCoverLetter: false,
  previewListingImages: false,
  currentVendorToEdit: {
    id: null,
    data: null,
  },
  totalDealCount: 0,
  isAllCashOffer: false,
  offerStatusChangeNote: "",
  isDownloadingDocs: false,
  toShowMortgagePopup: true,
  showVoidAndRestart: false,
  showVoidAndRestartOffer: false,
  showVoidAndDelete: false,
  showTroubleshoot: false,
  showToggleUploadLoader: false,
  offerInfoDetails: {},
  toggleLoader: false,
  showTroubleshootOffer: false,
  showVoidAndDeleteOffer: false,
  offerDocumentDownloadStatus: "pending",
  editingDealTeamMemberId: null,
  showAlertNavigation: false,
  showAddTransactionPopup: false,
  currentEnvelopeDetails: {},
  showComposeEmail: false,
  unselect: null,
  showSuccessPage: false,
  showOfferSentPopup: false,
  agentIdSet: null,
  showMortgageForm: false,
  showNetworkAgentPopup: false,
  modalPage: 0,
  offerIdToView: null,
  isContractSuccessVisible: false,
  isShowUpdate: false,
  contentForUpdate: null,
  isOfferToContractRedirectionModalVisible: {
    bool: false,
    url: "",
  },
  isStatusChangeModalOpen: {
    bool: false,
    id: "",
    status: "",
    transactionId: "",
    agentId: "",
  },
  showAgentType: null,
  listingAddress:null,
  currentListingContractID: null,
  ...createDeltaReducer("googleAuthToken"),
  ...createDeltaReducer("rarProductAndServices"),
  ...createDeltaReducer("rarTechRequest"),
  ...createDeltaReducer("slackProductNotification"),
  ...createDeltaReducer("rarAssets"),
  ...createDeltaReducer("rarCreativeRequest"),
  ...createDeltaReducer("designReferenceImage"),
  ...createDeltaReducer("rarFinancingClientList"),
  ...createDeltaReducer("rarFinancingSetting"),
  ...createDeltaReducer("saveFinancingClientList"),
  ...createDeltaReducer("isFinanceModalOpen"),
  ...createDeltaReducer("isSuccessBarOpen"),
  ...createDeltaReducer("agentState"),
  ...createDeltaReducer("todaysPriceResponse"),
  ...createDeltaReducer("popupDetails"),
  ...createDeltaReducer("brandingAssetsResponse"),
  ...createDeltaReducer("brandingInfoResponse"),
  ...createDeltaReducer("uploadDocsToBucketResponse"),
  ...createDeltaReducer("getListingCoverSheetResponse"),
  ...createDeltaReducer("createListingCoverSheetResponse"),
  ...createDeltaReducer("updateListingCoverSheetResponse"),
  ...createDeltaReducer("getAllListingsResponse"),
  ...createDeltaReducer("getListingStatusMapResponse"),
  ...createDeltaReducer("updateDealStatusResponse"),
  ...createDeltaReducer("getContractByIdResponse"),
  ...createDeltaReducer("createContractResponse"),
  ...createDeltaReducer("updateContractResponse"),
  ...createDeltaReducer("getListingHistoryResponse"),
  ...createDeltaReducer("createOfferActionResponse"),
  ...createDeltaReducer("updateOfferActionResponse"),
  ...createDeltaReducer("getAllOffersActionResponse"),
  ...createDeltaReducer("getCurrentDealDetailsResponse"),
  ...createDeltaReducer("updateOfferDealStatusResponse"),
  ...createDeltaReducer("updateVendorActionResponse"),
  ...createDeltaReducer("updateLenderActionResponse"),
  ...createDeltaReducer("getListingDocumentsResponse"),
  ...createDeltaReducer("getOfferDocumentsResponse"),
  ...createDeltaReducer("sendListingDocumentsResponse"),
  ...createDeltaReducer("createSendeViewResponse"),
  ...createDeltaReducer("createReceiverViewResponse"),
  ...createDeltaReducer("createReceiverViewOfferResponse"),
  ...createDeltaReducer("createEditViewResponse"),
  ...createDeltaReducer("createEditViewOfferResponse"),
  ...createDeltaReducer("downloadEnvelopeDocResponse"),
  ...createDeltaReducer("getListingStepsResponse"),
  ...createDeltaReducer("refreshListingDocumentsResponse"),
  ...createDeltaReducer("refreshOfferDocumentsResponse"),
  ...createDeltaReducer("updateDocusignEnvelopeStatusResponse"),
  ...createDeltaReducer("fetchDealTimelineResponse"),
  ...createDeltaReducer("fetchDealTCDetailsResponse"),
  ...createDeltaReducer("imageUploadForListingResponse"),
  ...createDeltaReducer("createAllListingImagesResponse"),
  ...createDeltaReducer("sendForVoidAndRestartResponse"),
  ...createDeltaReducer("sendForVoidAndRestartOfferResponse"),
  ...createDeltaReducer("sendForVoidAndDeleteResponse"),
  ...createDeltaReducer("sendTroubleShootMessageResponse"),
  ...createDeltaReducer("uploadListingDocToDocusignResponse"),
  ...createDeltaReducer("regenerateListingDocumentsResponse"),
  ...createDeltaReducer("getOfferDocumentsResponse"),
  ...createDeltaReducer("sendOfferDocumentsResponse"),
  ...createDeltaReducer("createSendeViewOfferResponse"),
  ...createDeltaReducer("createReceiverViewOfferResponse"),
  ...createDeltaReducer("downloadEnvelopeDocOfferResponse"),
  ...createDeltaReducer("getOfferStepsResponse"),
  ...createDeltaReducer("updateOfferDocusignEnvelopeStatusResponse"),
  ...createDeltaReducer("sendForVoidAndRestartOfferResponse"),
  ...createDeltaReducer("sendTroubleShootMessageOfferResponse"),
  ...createDeltaReducer("uploadOfferDocToDocusignResponse"),
  ...createDeltaReducer("uploadCoverLetterDocToDocusignResponse"),
  ...createDeltaReducer("sendForVoidAndDeleteOfferResponse"),
  ...createDeltaReducer("regenerateOfferDocumentsResponse"),
  ...createDeltaReducer("uploadCoverLetterResponse"),
  ...createDeltaReducer("fetchCoverLetterDocsResponse"),
  ...createDeltaReducer("submitAndSendAllOffersFileResponse"),
  ...createDeltaReducer("sendAllOffersDocsResponse"),
  ...createDeltaReducer("sendDocusignDocsResponse"),
  ...createDeltaReducer("requestToCreateZipResponse"),
  ...createDeltaReducer("putCoverLetterDocsResponse"),
  ...createDeltaReducer(Actions.toggleMortgagePopupAction.RESPONSE),
  ...createDeltaReducer("generateListingEnvelopeFromTemplateResponse"),
  ...createDeltaReducer("generateOfferEnvelopeFromTemplateResponse"),
  ...createDeltaReducer("fetchListingDocusignTemplatesResponse"),
  ...createDeltaReducer("fetchOfferocusignTemplatesResponse"),
  ...createDeltaReducer("getTemplatePreviewResponse"),
  ...createDeltaReducer("voidAndDuplicateCompletedOfferResponse"),
  ...createDeltaReducer("voidAndDuplicateCompletedListingResponse"),
  ...createDeltaReducer("deleteCompletedListingResponse"),
  ...createDeltaReducer("deleteCompletedOfferResponse"),
  ...createDeltaReducer("deleteCoverLetterResponse"),
  ...createDeltaReducer("showCoverLetterResponse"),
  ...createDeltaReducer("sendMailToListingAgentsResponse"),
  ...createDeltaReducer("saveListingStepsResponse"),
  ...createDeltaReducer(Actions.sendMortgagePropertiesAction.RESPONSE),
  ...createDeltaReducer(Actions.sendClientDetailsAction.RESPONSE),
  ...createDeltaReducer("saveListingStepsResponse"),
  ...createDeltaReducer("createContractV2Response"),
  ...createDeltaReducer("updateContractV2Response"),
  ...createDeltaReducer("getOfferContractDetailsResponse"),
  ...createDeltaReducer("getContractFieldValidationResponse"),
  ...createDeltaReducer("createOfferConvertedContractResponse"),
  ...createDeltaReducer("createTransactionConvertedContractResponse"),
  ...createDeltaReducer("processBeforeViewingContractResponse"),
  ...createDeltaReducer("createListingConvertedToContractResponse"),
  ...createDeltaReducer("createSendeViewOfferChecklistResponse"),
  ...createDeltaReducer("createEditViewOfferChecklistResponse"),
  ...createDeltaReducer("downloadEnvelopeDocOfferChecklistResponse"),
  ...createDeltaReducer("sendForVoidAndRestartOfferChecklistResponse"),
  ...createDeltaReducer("sendForVoidAndDeleteOfferChecklistResponse"),
  ...createDeltaReducer("createReceiverViewOfferChecklistResponse"),
}

export default function rarTech(state = initState, action) {
  switch (action.type) {
    case Actions.getRarProductAndServices.REQUEST:
    case Actions.getRarProductAndServices.SUCCESS:
    case Actions.getRarProductAndServices.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getRarProductAndServices, "rarProductAndServices"),
      }
    }

    case Actions.saveRarTechRequest.REQUEST:
    case Actions.saveRarTechRequest.SUCCESS:
    case Actions.saveRarTechRequest.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.saveRarTechRequest, "rarTechRequest"),
      }
    }

    case Actions.sendSlackNotificationForProduct.REQUEST:
    case Actions.sendSlackNotificationForProduct.SUCCESS:
    case Actions.sendSlackNotificationForProduct.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendSlackNotificationForProduct, "slackProductNotification"),
      }
    }

    case Actions.getRarAssets.REQUEST:
    case Actions.getRarAssets.SUCCESS:
    case Actions.getRarAssets.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getRarAssets, "rarAssets"),
      }
    }

    case Actions.rarSaveCreativeRequest.REQUEST:
    case Actions.rarSaveCreativeRequest.SUCCESS:
    case Actions.rarSaveCreativeRequest.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.rarSaveCreativeRequest, "rarCreativeRequest"),
      }
    }

    case Actions.saveDesignReferenceImage.REQUEST:
    case Actions.saveDesignReferenceImage.SUCCESS:
    case Actions.saveDesignReferenceImage.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.saveDesignReferenceImage, "designReferenceImage"),
      }
    }

    case Actions.removeDesignReferenceImage.REQUEST:
    case Actions.removeDesignReferenceImage.SUCCESS:
    case Actions.removeDesignReferenceImage.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.removeDesignReferenceImage, "designReferenceImage"),
      }
    }

    case Actions.getFinancingClientList.REQUEST:
    case Actions.getFinancingClientList.SUCCESS:
    case Actions.getFinancingClientList.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getFinancingClientList, "rarFinancingClientList"),
      }
    }

    case Actions.getFinancingSetting.REQUEST:
    case Actions.getFinancingSetting.SUCCESS:
    case Actions.getFinancingSetting.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getFinancingSetting, "rarFinancingSetting"),
      }
    }

    case Actions.rarSaveFinancingClientList.REQUEST:
    case Actions.rarSaveFinancingClientList.SUCCESS:
    case Actions.rarSaveFinancingClientList.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.rarSaveFinancingClientList, "saveFinancingClientList"),
      }
    }

    case Actions.toggleFinanceModal.REQUEST:
    case Actions.toggleFinanceModal.SUCCESS:
    case Actions.toggleFinanceModal.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.toggleFinanceModal, "isFinanceModalOpen"),
      }
    }

    case Actions.showSuccessBar.REQUEST:
    case Actions.showSuccessBar.SUCCESS:
    case Actions.showSuccessBar.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.showSuccessBar, "isSuccessBarOpen"),
      }
    }

    case Actions.fetchState.REQUEST:
    case Actions.fetchState.SUCCESS:
    case Actions.fetchState.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchState, "agentState"),
      }
    }

    case Actions.fetchTodaysPriceAction.REQUEST:
    case Actions.fetchTodaysPriceAction.SUCCESS:
    case Actions.fetchTodaysPriceAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchTodaysPriceAction, "todaysPriceResponse"),
      }
    }

    case Actions.getPopupDetailsAction.REQUEST:
    case Actions.getPopupDetailsAction.SUCCESS:
    case Actions.getPopupDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getPopupDetailsAction, "popupDetails"),
      }
    }

    case Actions.fetchBrandingAssetsAction.REQUEST:
    case Actions.fetchBrandingAssetsAction.SUCCESS:
    case Actions.fetchBrandingAssetsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchBrandingAssetsAction, "brandingAssetsResponse"),
      }
    }

    case Actions.fetchBrandingInfoAction.REQUEST:
    case Actions.fetchBrandingInfoAction.SUCCESS:
    case Actions.fetchBrandingInfoAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchBrandingInfoAction, "brandingInfoResponse"),
      }
    }

    case Actions.uploadDocsToBucketAction.REQUEST:
    case Actions.uploadDocsToBucketAction.FAILURE:
    case Actions.uploadDocsToBucketAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.uploadDocsToBucketAction,
          "uploadDocsToBucketResponse"
        ),
      }
    }

    case Actions.createOfferAction.REQUEST:
    case Actions.createOfferAction.FAILURE:
    case Actions.createOfferAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.uploadDocsToBucketAction,
          "createOfferActionResponse"
        ),
      }
    }

    case Actions.fetchDealTimelineAction.REQUEST:
    case Actions.fetchDealTimelineAction.FAILURE:
    case Actions.fetchDealTimelineAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchDealTimelineAction,
          "fetchDealTimelineResponse"
        ),
      }
    }

    case Actions.fetchDealTCDetailsAction.REQUEST:
    case Actions.fetchDealTCDetailsAction.FAILURE:
    case Actions.fetchDealTCDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.fetchDealTCDetailsAction,
          "fetchDealTCDetailsResponse"
        ),
      }
    }

    case Actions.getAllOffersAction.REQUEST:
    case Actions.getAllOffersAction.FAILURE:
    case Actions.getAllOffersAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getAllOffersAction,
          "getAllOffersActionResponse"
        ),
      }
    }

    case Actions.getCurrentDealDetailsAction.REQUEST:
    case Actions.getCurrentDealDetailsAction.FAILURE:
    case Actions.getCurrentDealDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getCurrentDealDetailsAction,
          "getCurrentDealDetailsResponse"
        ),
      }
    }

    case Actions.updateOfferDealStatusAction.REQUEST:
    case Actions.updateOfferDealStatusAction.FAILURE:
    case Actions.updateOfferDealStatusAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateOfferDealStatusAction,
          "updateOfferDealStatusResponse"
        ),
      }
    }

    case Actions.updateOfferAction.REQUEST:
    case Actions.updateOfferAction.FAILURE:
    case Actions.updateOfferAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateOfferAction,
          "updateOfferActionResponse"
        ),
      }
    }

    case Actions.updateVendorAction.REQUEST:
    case Actions.updateVendorAction.FAILURE:
    case Actions.updateVendorAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateVendorAction,
          "updateVendorActionResponse",
        ),
      }
    }

    case Actions.updateLenderAction.REQUEST:
    case Actions.updateLenderAction.FAILURE:
    case Actions.updateLenderAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateLenderAction,
          "updateLenderActionResponse",
        ),
      }
    }

    case Actions.createContractV2Action.REQUEST:
    case Actions.createContractV2Action.FAILURE:
    case Actions.createContractV2Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.createContractV2Action,
          "createContractV2AResponse",
        ),
      }
    }

    case Actions.createOfferConvertedContractAction.REQUEST:
    case Actions.createOfferConvertedContractAction.FAILURE:
    case Actions.createOfferConvertedContractAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.createOfferConvertedContractAction,
          "createOfferConvertedContractResponse",
        ),
      }
    }

    case Actions.updateContractV2Action.REQUEST:
    case Actions.updateContractV2Action.FAILURE:
    case Actions.updateContractV2Action.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.updateContractV2Action,
          "updateContractV2Response",
        ),
      }
    }

    //getOfferContractDetailsAction
    case Actions.getOfferContractDetailsAction.REQUEST:
    case Actions.getOfferContractDetailsAction.FAILURE:
    case Actions.getOfferContractDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getOfferContractDetailsAction,
          "getOfferContractDetailsResponse",
        ),
      }
    }

    case Actions.getContractFieldValidationAction.REQUEST:
    case Actions.getContractFieldValidationAction.FAILURE:
    case Actions.getContractFieldValidationAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getContractFieldValidationAction,
          "getContractFieldValidationResponse",
        ),
      }
    }

    case Actions.processBeforeViewingContractAction.REQUEST:
    case Actions.processBeforeViewingContractAction.FAILED:
    case Actions.processBeforeViewingContractAction.SUCCESS: {
      return {
        ...state,
        ...createDeltaReducer("createListingConvertedToContractResponse"),
        ...handleDeltaReducer(
          state,
          action,
          Actions.processBeforeViewingContractAction,
          "processBeforeViewingContractResponse",
        ),
      }
    }

    case Actions.createListingConvertedToContractAction.REQUEST:
    case Actions.createListingConvertedToContractAction.FAILED:
    case Actions.createListingConvertedToContractAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.createListingConvertedToContractAction,
          "createListingConvertedToContractResponse",
        ),
      }
    }

    case Actions.toggleScreenPopup.type: {
      return {
        ...state,
        toggleScreenPopupStep: action.data,
      }
    }

    case Actions.setPreferrerdTitle.type: {
      return {
        ...state,
        preferrerdTitle: action.data,
      }
    }

    case Actions.setUnselect.type: {
      return {
        ...state,
        unselect: action.data,
      }
    }

    case Actions.setFileUploading.type: {
      return {
        ...state,
        fileUploading: action.data,
      }
    }

    case Actions.setEditingDealTeamMemberId.type: {
      return {
        ...state,
        editingDealTeamMemberId: action.data,
      }
    }

    case Actions.setBusinessCard.type: {
      return {
        ...state,
        businessCard: action.data,
      }
    }

    case Actions.setYardSign.type: {
      return {
        ...state,
        yardSign: action.data,
      }
    }

    case Actions.setBothDone.type: {
      return {
        ...state,
        bothDone: action.data,
      }
    }

    case Actions.setIsShow.type: {
      return {
        ...state,
        isShow: action.data,
      }
    }

    case Actions.showPopupAction.type: {
      const { showMortgagePopup } = action.data

      return {
        ...state,
        showPopupDetails: {
          showMortgagePopup,
        },
      }
    }

    case Actions.setOfferInfoDetailsAction.type: {
      return {
        ...state,
        offerInfoDetails: action.data,
      }
    }

    case Actions.setLoaderAction.type: {
      return {
        ...state,
        toggleLoader: action.data,
      }
    }

    case Actions.resetGoogleContacts.type: {
      return {
        ...state,
        googleAuthToken: { data: null },
        agentGoogleContacts: [],
      }
    }

    case Actions.getListingCoverSheet.REQUEST:
    case Actions.getListingCoverSheet.SUCCESS:
    case Actions.getListingCoverSheet.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getListingCoverSheet, "getListingCoverSheetResponse"),
      }
    }

    case Actions.createListingCoverSheet.REQUEST:
    case Actions.createListingCoverSheet.SUCCESS:
    case Actions.createListingCoverSheet.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createListingCoverSheet, "createListingCoverSheetResponse"),
      }
    }

    case Actions.updateListingCoverSheet.REQUEST:
    case Actions.updateListingCoverSheet.SUCCESS:
    case Actions.updateListingCoverSheet.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateListingCoverSheet, "updateListingCoverSheetResponse"),
      }
    }

    case Actions.getAllListings.REQUEST:
    case Actions.getAllListings.SUCCESS:
    case Actions.getAllListings.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getAllListings, "getAllListingsResponse"),
      }
    }

    case Actions.getListingStatusMap.REQUEST:
    case Actions.getListingStatusMap.SUCCESS:
    case Actions.getListingStatusMap.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getListingStatusMap, "getListingStatusMapResponse"),
      }
    }

    case Actions.updateDealStatus.REQUEST:
    case Actions.updateDealStatus.SUCCESS:
    case Actions.updateDealStatus.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateDealStatus, "updateDealStatusResponse"),
      }
    }

    case Actions.getContractById.REQUEST:
    case Actions.getContractById.SUCCESS:
    case Actions.getContractById.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getContractById, "getContractByIdResponse"),
      }
    }

    case Actions.createContract.REQUEST:
    case Actions.createContract.SUCCESS:
    case Actions.createContract.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createContract, "createContractResponse"),
      }
    }

    case Actions.updateContract.REQUEST:
    case Actions.updateContract.SUCCESS:
    case Actions.updateContract.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateContract, "updateContractResponse"),
      }
    }

    case Actions.getListingHistory.REQUEST:
    case Actions.getListingHistory.SUCCESS:
    case Actions.getListingHistory.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getListingHistory, "getListingHistoryResponse"),
      }
    }

    case Actions.getGoogleAuthToken.REQUEST:
    case Actions.getGoogleAuthToken.SUCCESS:
    case Actions.getGoogleAuthToken.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getGoogleAuthToken, "googleAuthToken"),
      }
    }

    case Actions.setDealToView.type: {
      return {
        ...state,
        dealToView: action.data,
      }
    }

    case Actions.setAgentId.type: {
      return {
        ...state,
        agentIdSet: action.data,
      }
    }

    case Actions.setLoaderOnAgentInfo.type: {
      return {
        ...state,
        loaderOnAgentInfo: action.data,
      }
    }

    case Actions.setEscrowIdToEdit.type: {
      return {
        ...state,
        escrowIdToEdit: action.data,
      }
    }

    case Actions.setAgentGoogleContacts.type: {
      let list = action.data
      if (action.data && action.data.length) {
        list = action.data.filter(ct => ct.names)
      }
      return {
        ...state,
        agentGoogleContacts: list,
      }
    }

    case Actions.resetListingsForm.REQUEST:
    case Actions.resetListingsForm.SUCCESS:
    case Actions.resetListingsForm.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.resetListingsForm, "getAllListingsResponse"),
      }
    }

    case Actions.resetContractsForm.REQUEST:
    case Actions.resetContractsForm.SUCCESS:
    case Actions.resetContractsForm.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.resetContractsForm, "getContractByIdResponse"),
      }
    }

    case Actions.getAgentDetailsAction.REQUEST:
    case Actions.getAgentDetailsAction.SUCCESS:
    case Actions.getAgentDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.getAgentDetailsAction,
          Actions.getAgentDetailsAction.RESPONSE,
        ),
      }
    }

    case Actions.toggleViewListingHistory.type: {
      return {
        ...state,
        showViewListingHistory: action.data,
      }
    }

    case Actions.toggleMortgagePopupAction.type: {
      return {
        ...state,
        toShowMortgagePopup: action.data,
      }
    }

    case Actions.toggleComfirmToGoToContract.type: {
      return {
        ...state,
        showComfirmToGoToContract: action.data,
      }
    }

    case Actions.toggleDocumentSelectionConfirm.type: {
      return {
        ...state,
        showDocumentSelectionConfirm: action.data,
      }
    }

    case Actions.toggleDocumentSentPopup.type: {
      return {
        ...state,
        showToggleDocumentSentPopup: action.data,
      }
    }

    case Actions.toggleConfirmToExecute.type: {
      return {
        ...state,
        showConfirmToExecute: action.data,
      }
    }

    case Actions.setCurrentVendorEditDetailsAction.type: {
      const {
        id,
        data,
      } = action.data

      return {
        ...state,
        currentVendorToEdit: {
          id,
          data,
        },
      }
    }

    case Actions.resetLastDealAction.type: {
      return {
        ...state,
        offerStatusChangeNote: "",
        getCurrentDealDetailsResponse: {
          data: null,
          isFetching: false,
          error: false,
        },
      }
    }

    case Actions.updateStatusNoteForChangeAction.type: {
      return {
        ...state,
        offerStatusChangeNote: action.data,
      }
    }

    case Actions.toggleAllCashOfferAction.type: {
      return {
        ...state,
        isAllCashOffer: action.data,
      }
    }

    case Actions.getListingDocuments.REQUEST:
    case Actions.getListingDocuments.SUCCESS:
    case Actions.getListingDocuments.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getListingDocuments, "getListingDocumentsResponse"),
      }
    }

    case Actions.sendListingDocuments.REQUEST:
    case Actions.sendListingDocuments.SUCCESS:
    case Actions.sendListingDocuments.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendListingDocuments, "sendListingDocumentsResponse"),
      }
    }

    case Actions.createReceiverView.REQUEST:
    case Actions.createReceiverView.SUCCESS:
    case Actions.createReceiverView.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createReceiverView, "createReceiverViewResponse"),
      }
    }

    case Actions.createEditView.REQUEST:
    case Actions.createEditView.SUCCESS:
    case Actions.createEditView.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createEditView, "createEditViewResponse"),
      }
    }

    case Actions.downloadEnvelopeDoc.REQUEST:
    case Actions.downloadEnvelopeDoc.SUCCESS:
    case Actions.downloadEnvelopeDoc.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.downloadEnvelopeDoc, "downloadEnvelopeDocResponse"),
      }
    }

    case Actions.getListingSteps.REQUEST:
    case Actions.getListingSteps.SUCCESS:
    case Actions.getListingSteps.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getListingSteps, "getListingStepsResponse"),
      }
    }

    case Actions.refreshListingDocuments.REQUEST:
    case Actions.refreshListingDocuments.SUCCESS:
    case Actions.refreshListingDocuments.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.refreshListingDocuments, "refreshListingDocumentsResponse"),
      }
    }

    case Actions.updateDocusignEnvelopeStatus.REQUEST:
    case Actions.updateDocusignEnvelopeStatus.SUCCESS:
    case Actions.updateDocusignEnvelopeStatus.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateDocusignEnvelopeStatus, "updateDocusignEnvelopeStatusResponse"),
      }
    }

    case Actions.imageUploadForListing.REQUEST:
    case Actions.imageUploadForListing.SUCCESS:
    case Actions.imageUploadForListing.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.imageUploadForListing, "imageUploadForListingResponse"),
      }
    }

    case Actions.createAllListingImages.REQUEST:
    case Actions.createAllListingImages.SUCCESS:
    case Actions.createAllListingImages.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createAllListingImages, "createAllListingImagesResponse"),
      }
    }

    case Actions.fetchCoverLetterDocsAction.REQUEST:
    case Actions.fetchCoverLetterDocsAction.SUCCESS:
    case Actions.fetchCoverLetterDocsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchCoverLetterDocsAction, "fetchCoverLetterDocsResponse"),
      }
    }

    case Actions.downloadingDocs.type: {
      return {
        ...state,
        isDownloadingDocs: action.data,
      }
    }

    case Actions.toggleInterestFormAction.type: {
      return {
        ...state,
        isContactFormOpen: action.data,
      }
    }

    case Actions.toggleUploadListingImages.type: {
      return {
        ...state,
        showUploadListingImages: action.data,
      }
    }

    case Actions.toggleUploadListingDocs.type: {
      return {
        ...state,
        showUploadListingDocs: action.data,
      }
    }

    case Actions.toggleUploadCoverLetter.type: {
      return {
        ...state,
        showUploadCoverLetter: action.data,
      }
    }

    case Actions.togglePreviewListingImages.type: {
      return {
        ...state,
        previewListingImages: action.data,
      }
    }

    case Actions.toogleVoidAndRestart.type: {
      return {
        ...state,
        showVoidAndRestart: action.data,
      }
    }

    case Actions.toogleVoidAndDelete.type: {
      return {
        ...state,
        showVoidAndDelete: action.data,
      }
    }

    case Actions.toggleUploadLoader.type: {
      return {
        ...state,
        showToggleUploadLoader: action.data,
      }
    }

    case Actions.toogleTroubleshoot.type: {
      return {
        ...state,
        showTroubleshoot: action.data,
      }
    }

    case Actions.sendForVoidAndRestart.REQUEST:
    case Actions.sendForVoidAndRestart.SUCCESS:
    case Actions.sendForVoidAndRestart.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendForVoidAndRestart, "sendForVoidAndRestartResponse"),
      }
    }

    case Actions.sendForVoidAndDelete.REQUEST:
    case Actions.sendForVoidAndDelete.SUCCESS:
    case Actions.sendForVoidAndDelete.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendForVoidAndDelete, "sendForVoidAndDeleteResponse"),
      }
    }

    case Actions.sendTroubleShootMessage.REQUEST:
    case Actions.sendTroubleShootMessage.SUCCESS:
    case Actions.sendTroubleShootMessage.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendTroubleShootMessage, "sendTroubleShootMessageResponse"),
      }
    }

    case Actions.uploadListingDocToDocusign.REQUEST:
    case Actions.uploadListingDocToDocusign.SUCCESS:
    case Actions.uploadListingDocToDocusign.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.uploadListingDocToDocusign, "uploadListingDocToDocusignResponse"),
      }
    }

    case Actions.createEditViewOffer.REQUEST:
    case Actions.createEditViewOffer.SUCCESS:
    case Actions.createEditViewOffer.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createEditViewOffer, "createEditViewOfferResponse"),
      }
    }

    case Actions.regenerateListingDocuments.REQUEST:
    case Actions.regenerateListingDocuments.SUCCESS:
    case Actions.regenerateListingDocuments.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.regenerateListingDocuments, "regenerateListingDocumentsResponse"),
      }
    }

    case Actions.getOfferDocuments.REQUEST:
    case Actions.getOfferDocuments.SUCCESS:
    case Actions.getOfferDocuments.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getOfferDocuments, "getOfferDocumentsResponse"),
      }
    }

    case Actions.sendOfferDocuments.REQUEST:
    case Actions.sendOfferDocuments.SUCCESS:
    case Actions.sendOfferDocuments.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendOfferDocuments, "sendOfferDocumentsResponse"),
      }
    }

    case Actions.createSendeViewOffer.REQUEST:
    case Actions.createSendeViewOffer.SUCCESS:
    case Actions.createSendeViewOffer.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createSendeViewOffer, "createSendeViewOfferResponse"),
      }
    }

    case Actions.createReceiverViewOffer.REQUEST:
    case Actions.createReceiverViewOffer.SUCCESS:
    case Actions.createReceiverViewOffer.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createReceiverViewOffer, "createReceiverViewOfferResponse"),
      }
    }

    case Actions.downloadEnvelopeDocOffer.REQUEST:
    case Actions.downloadEnvelopeDocOffer.SUCCESS:
    case Actions.downloadEnvelopeDocOffer.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.downloadEnvelopeDocOffer, "downloadEnvelopeDocOfferResponse"),
      }
    }

    case Actions.getOfferSteps.REQUEST:
    case Actions.getOfferSteps.SUCCESS:
    case Actions.getOfferSteps.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getOfferSteps, "getOfferStepsResponse"),
      }
    }

    case Actions.updateOfferDocusignEnvelopeStatus.REQUEST:
    case Actions.updateOfferDocusignEnvelopeStatus.SUCCESS:
    case Actions.updateOfferDocusignEnvelopeStatus.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.updateOfferDocusignEnvelopeStatus, "updateOfferDocusignEnvelopeStatusResponse"),
      }
    }

    case Actions.sendForVoidAndRestartOffer.REQUEST:
    case Actions.sendForVoidAndRestartOffer.SUCCESS:
    case Actions.sendForVoidAndRestartOffer.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendForVoidAndRestartOffer, "sendForVoidAndRestartOfferResponse"),
      }
    }

    case Actions.sendTroubleShootMessageOffer.REQUEST:
    case Actions.sendTroubleShootMessageOffer.SUCCESS:
    case Actions.sendTroubleShootMessageOffer.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendTroubleShootMessageOffe, "sendTroubleShootMessageOffeResponse"),
      }
    }

    case Actions.uploadOfferDocToDocusign.REQUEST:
    case Actions.uploadOfferDocToDocusign.SUCCESS:
    case Actions.uploadOfferDocToDocusign.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.uploadOfferDocToDocusign, "uploadOfferDocToDocusignResponse"),
      }
    }

    case Actions.uploadCoverLetterDocToDocusign.REQUEST:
    case Actions.uploadCoverLetterDocToDocusign.SUCCESS:
    case Actions.uploadCoverLetterDocToDocusign.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.uploadCoverLetterDocToDocusign, "uploadCoverLetterDocToDocusignResponse"),
      }
    }

    case Actions.sendForVoidAndDeleteOffer.REQUEST:
    case Actions.sendForVoidAndDeleteOffer.SUCCESS:
    case Actions.sendForVoidAndDeleteOffer.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendForVoidAndDeleteOffer, "sendForVoidAndDeleteOfferResponse"),
      }
    }

    case Actions.regenerateOfferDocuments.REQUEST:
    case Actions.regenerateOfferDocuments.SUCCESS:
    case Actions.regenerateOfferDocuments.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.regenerateOfferDocuments, "regenerateOfferDocumentsResponse"),
      }
    }

    case Actions.toogleVoidAndRestartOffer.type: {
      return {
        ...state,
        showVoidAndRestartOffer: action.data,
      }
    }

    case Actions.setOfferDocumentDownloadStatus.type: {
      return {
        ...state,
        offerDocumentDownloadStatus: action.data,
      }
    }

    case Actions.toogleTroubleshootOffer.type: {
      return {
        ...state,
        showTroubleshootOffer: action.data,
      }
    }
    case Actions.toogleVoidAndDeleteOffer.type: {
      return {
        ...state,
        showVoidAndDeleteOffer: action.data,
      }
    }

    case Actions.submitAndSendAllOffersFile.REQUEST:
    case Actions.submitAndSendAllOffersFile.SUCCESS:
    case Actions.submitAndSendAllOffersFile.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.submitAndSendAllOffersFile, "submitAndSendAllOffersFileResponse"),
      }
    }

    case Actions.sendAllOffersDocsAction.REQUEST:
    case Actions.sendAllOffersDocsAction.SUCCESS:
    case Actions.sendAllOffersDocsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendAllOffersDocsAction, "sendAllOffersDocsResponse"),
      }
    }

    case Actions.sendDocusignDocsAction.REQUEST:
    case Actions.sendDocusignDocsAction.SUCCESS:
    case Actions.sendDocusignDocsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendDocusignDocsAction, "sendDocusignDocsResponse"),
      }
    }

    case Actions.uploadCoverLetterAction.REQUEST:
    case Actions.uploadCoverLetterAction.FAILURE:
    case Actions.uploadCoverLetterAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.uploadCoverLetterAction,
          "uploadCoverLetterResponse"
        ),
      }
    }

    case Actions.setUploadedFilesNullAction.type: {
      return {
        ...state,
        uploadCoverLetterResponse: [],
      }
    }

    case Actions.refreshOfferDocuments.REQUEST:
    case Actions.refreshOfferDocuments.SUCCESS:
    case Actions.refreshOfferDocuments.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.refreshOfferDocuments, "refreshOfferDocumentsResponse"),
      }
    }

    case Actions.requestToCreateZip.REQUEST:
    case Actions.requestToCreateZip.SUCCESS:
    case Actions.requestToCreateZip.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.requestToCreateZip, "requestToCreateZipResponse"),
      }
    }
    case Actions.putCoverLetterDocsAction.REQUEST:
    case Actions.putCoverLetterDocsAction.SUCCESS:
    case Actions.putCoverLetterDocsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.putCoverLetterDocsAction, "putCoverLetterDocsResponse"),
      }
    }

    case Actions.toggleAlertNavigation.type: {
      return {
        ...state,
        showAlertNavigation: action.data,
      }
    }

    case Actions.toggleAddTransactionPopup.type: {
      return {
        ...state,
        showAddTransactionPopup: action.data,
      }
    }
    
    case Actions.resetTransactionState.type: {
      return {
        ...state,
        getOfferStepsResponse: {
          data: null,
          isFetching: false,
          error: false,
        },
      }
    }

    case Actions.setComposeEmail.type: {
      return {
        ...state,
        showComposeEmail: action.data,
      }
    }

    case Actions.setSuccessPage.type: {
      return {
        ...state,
        showSuccessPage: action.data,
      }
    }

    case Actions.setOfferSentPopup.type: {
      return {
        ...state,
        showOfferSentPopup: action.data,
      }
    }

    case Actions.generateListingEnvelopeFromTemplate.REQUEST:
    case Actions.generateListingEnvelopeFromTemplate.SUCCESS:
    case Actions.generateListingEnvelopeFromTemplate.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.generateListingEnvelopeFromTemplate, "generateListingEnvelopeFromTemplateResponse"),
      }
    }

    case Actions.generateOfferEnvelopeFromTemplate.REQUEST:
    case Actions.generateOfferEnvelopeFromTemplate.SUCCESS:
    case Actions.generateOfferEnvelopeFromTemplate.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.generateOfferEnvelopeFromTemplate, "generateOfferEnvelopeFromTemplateResponse"),
      }
    }

    case Actions.fetchListingDocusignTemplates.REQUEST:
    case Actions.fetchListingDocusignTemplates.SUCCESS:
    case Actions.fetchListingDocusignTemplates.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchListingDocusignTemplates, "fetchListingDocusignTemplatesResponse"),
      }
    }

    case Actions.fetchOfferocusignTemplates.REQUEST:
    case Actions.fetchOfferocusignTemplates.SUCCESS:
    case Actions.fetchOfferocusignTemplates.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.fetchOfferocusignTemplates, "fetchOfferocusignTemplatesResponse"),
      }
    }

    case Actions.getTemplatePreview.REQUEST:
    case Actions.getTemplatePreview.SUCCESS:
    case Actions.getTemplatePreview.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.getTemplatePreview, "getTemplatePreviewResponse"),
      }
    }

    case Actions.voidAndDuplicateCompletedOfferAction.REQUEST:
    case Actions.voidAndDuplicateCompletedOfferAction.SUCCESS:
    case Actions.voidAndDuplicateCompletedOfferAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.voidAndDuplicateCompletedOfferAction, "voidAndDuplicateCompletedOfferResponse"),
      }
    }

    case Actions.voidAndDuplicateCompletedListingAction.REQUEST:
    case Actions.voidAndDuplicateCompletedListingAction.SUCCESS:
    case Actions.voidAndDuplicateCompletedListingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.voidAndDuplicateCompletedListingAction, "voidAndDuplicateCompletedListingResponse"),
      }
    }

    case Actions.deleteCompletedListingAction.REQUEST:
    case Actions.deleteCompletedListingAction.SUCCESS:
    case Actions.deleteCompletedListingAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteCompletedListingAction, "deleteCompletedListingResponse"),
      }
    }

    case Actions.deleteCompletedOfferAction.REQUEST:
    case Actions.deleteCompletedOfferAction.SUCCESS:
    case Actions.deleteCompletedOfferAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteCompletedOfferAction, "deleteCompletedOfferResponse"),
      }
    }

    case Actions.showCoverLetterResponseAction.REQUEST:
    case Actions.showCoverLetterResponseAction.SUCCESS:
    case Actions.showCoverLetterResponseAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.showCoverLetterResponseAction, "showCoverLetterResponse"),
      }
    }

    case Actions.sendMailToListingAgents.REQUEST:
    case Actions.sendMailToListingAgents.SUCCESS:
    case Actions.sendMailToListingAgents.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendMailToListingAgents, "sendMailToListingAgentsResponse"),
      }
    }

    case Actions.setCurrentEnvelopeDetailsAction.type: {
      return {
        ...state,
        currentEnvelopeDetails: action.data,
      }
    }

    case Actions.deleteCoverLetter.REQUEST:
    case Actions.deleteCoverLetter.SUCCESS:
    case Actions.deleteCoverLetter.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.deleteCoverLetter, "deleteCoverLetterResponse"),
      }
    }
    case Actions.saveListingStepsAction.REQUEST:
    case Actions.saveListingStepsAction.FAILURE:
    case Actions.saveListingStepsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.saveListingStepsAction,
          "saveListingStepsResponse"
        ),
      }
    }
    case Actions.toggleMortgageForm.type: {
      return {
        ...state,
        showMortgageForm: action.data,
      }
    }

    case Actions.sendClientDetailsAction.REQUEST:
    case Actions.sendClientDetailsAction.SUCCESS:
    case Actions.sendClientDetailsAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendClientDetailsAction, Actions.sendClientDetailsAction.RESPONSE),
      }
    }

    case Actions.sendMortgagePropertiesAction.REQUEST:
    case Actions.sendMortgagePropertiesAction.SUCCESS:
    case Actions.sendMortgagePropertiesAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendMortgagePropertiesAction, Actions.sendMortgagePropertiesAction.RESPONSE),
      }
    }

    case Actions.saveListingStepsAction.REQUEST:
    case Actions.saveListingStepsAction.FAILURE:
    case Actions.saveListingStepsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.saveListingStepsAction,
          "saveListingStepsResponse"
        ),
      }
    }

    case Actions.createTransactionConvertedContractAction.REQUEST:
    case Actions.createTransactionConvertedContractAction.FAILURE:
    case Actions.createTransactionConvertedContractAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          Actions.createTransactionConvertedContractAction,
          "createTransactionConvertedContractResponse"
        ),
      }
    }


    case Actions.toggleNetworkAgentPopupAction.type: {
      return {
        ...state,
        showNetworkAgentPopup: action.data,
      }
    }

    case Actions.toggleModalPageAction.type: {
      return {
        ...state,
        modalPage: action.data,
      }
    }

    case Actions.showSuccessModalAction.type: {
      return {
        ...state,
        isContractSuccessVisible: action.data,
      }
    }

    case Actions.showUpdatingBuyerInformationLoader.type: {
      return {
        ...state,
        updatingBuyer: action.data,
      }
    }

    case Actions.dealCountUpdateAction.type: {
      const {
        forAgent,
        dealCount,
      } = action.data || {}
      return {
        ...state,
        totalDealCount: dealCount,
        contractForAgent: forAgent,
      }
    }

    case Actions.toggleOfferToContractConversionAction.type: {
      const {
        bool,
        url,
      } = action.data || {}
      return {
        ...state,
        ...createDeltaReducer("getContractByIdResponse"),
        isOfferToContractRedirectionModalVisible: {
          bool,
          url,
        },
      }
    }
    //toggleStatusChangeModalAction

    case Actions.toggleStatusChangeModalAction.type: {
      const {
        bool,
        id = "",
        status = "",
        transactionId = null,
        agentId = null,
      } = action.data || {}
      return {
        ...state,
        isStatusChangeModalOpen: {
          bool,
          id,
          status,
          transactionId,
          agentId,
        },
      }
    }
    
    case Actions.setCurrentContractIDAction.type: {
      return {
        ...state,
        currentListingContractID: action.data,
      }
    }

    case Actions.setOfferIdToViewAction.type: {
      return {
        ...state,
        offerIdToView: action.data,
      }
    }
    
    case Actions.toggleShowUpdateAction.type: {
      return {
        ...state,
        isShowUpdate: action.data,
      }
    }

    case Actions.setContentForUpdateAction.type: {
      return {
        ...state,
        contentForUpdate: action.data,
      }
    }

    case Actions.createSendeViewOfferChecklistAction.REQUEST:
    case Actions.createSendeViewOfferChecklistAction.SUCCESS:
    case Actions.createSendeViewOfferChecklistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createSendeViewOfferChecklistAction, "createSendeViewOfferChecklistResponse"),
      }
    }

    case Actions.createEditViewOfferChecklistAction.REQUEST:
    case Actions.createEditViewOfferChecklistAction.SUCCESS:
    case Actions.createEditViewOfferChecklistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createEditViewOfferChecklistAction, "createEditViewOfferChecklistResponse"),
      }
    }

    case Actions.downloadEnvelopeDocOfferChecklistAction.REQUEST:
    case Actions.downloadEnvelopeDocOfferChecklistAction.SUCCESS:
    case Actions.downloadEnvelopeDocOfferChecklistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.downloadEnvelopeDocOfferChecklistAction, "downloadEnvelopeDocOfferChecklistResponse"),
      }
    }

    case Actions.sendForVoidAndRestartOfferChecklistAction.REQUEST:
    case Actions.sendForVoidAndRestartOfferChecklistAction.SUCCESS:
    case Actions.sendForVoidAndRestartOfferChecklistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendForVoidAndRestartOfferChecklistAction, "sendForVoidAndRestartOfferChecklistResponse"),
      }
    }

    case Actions.sendForVoidAndDeleteOfferChecklistAction.REQUEST:
    case Actions.sendForVoidAndDeleteOfferChecklistAction.SUCCESS:
    case Actions.sendForVoidAndDeleteOfferChecklistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.sendForVoidAndDeleteOfferChecklistAction, "sendForVoidAndDeleteOfferChecklistResponse"),
      }
    }

    case Actions.createReceiverViewOfferChecklistAction.REQUEST:
    case Actions.createReceiverViewOfferChecklistAction.SUCCESS:
    case Actions.createReceiverViewOfferChecklistAction.FAILURE: {
      return {
        ...state,
        ...handleDeltaReducer(state, action, Actions.createReceiverViewOfferChecklistAction, "createReceiverViewOfferChecklistResponse"),
      }
    }


    case Actions.defineAgentTypeAction.type: {
      return {
        ...state,
        showAgentType: action.data
      }
    }

    case Actions.setListingAddressAction.type: {
      return {
        ...state,
        listingAddress: action.data
      }
    }

    default: {
      return state
    }
  }
}
