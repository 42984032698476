import React, { useState } from "react"
import styled from "styled-components"
import CRMV2Container from "container/CRM"
import CircularImage from "dumbComponents/common/CircularImage"
import Box from "@ui/Box"
import DropdownMenu from "@ui/NewTooltip"
import {
  Li,
} from "@ui/DragDropColumn/common"
import ArchiveIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/archiveCRM.svg"
import moreMenuSVG from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/Menu.svg"
import { normalizePhone, email } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import AddIcon from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/AddIcon.svg"
import {
  Phone as PhoneIcon,
  Mail as MailIcon,
} from "dumbComponents/CRMV2/CRMDashboard/Components/Icons/"
import colors from "@colors"
import { ATC, MONTSERRAT, INTER } from "@fonts"
import {
  Select,
  Tabs,
  Row,
  Col,
} from "@ui/antd"
import { get } from "lodash"
import { TimeZone } from "services/Utils"
import Loader from "@ui/Loader"
import BuyerLeadOverview from "./BuyerLeadOverView"
import { LeadInfo, Title, Icon } from "../common"
import { SearchCriteriaInfo, PropertyInfo } from "./Tabs"

const CLIENT_SOURCE = [
  {
    label: "Radius Marketplace",
    value: "radius_marketplace",
  },
  {
    label: "Zillow (Radius Provided)",
    value: "zillow_radius_provided",
  },
  {
    label: "Zillow (Agent's Personal Account)",
    value: "zillow_agent_personal_account",
  },
  {
    label: "Zillow Flex",
    value: "zillow_flex",
  },
  {
    label: "OpCity/Realtor.com",
    value: "opcity_realtor_com",
  },
  {
    label: "Team/Mentor Lead",
    value: "team_mentor_lead",
  },
  {
    label: "Self",
    value: "self",
  },
  {
    label: "Sphere of Influence/Personal",
    value: "sphere_of_influence",
  },
  {
    label: "Referrals (Agent/Lender)",
    value: "referrals_agent_Lender",
  },
  {
    label: "Other",
    value: "other",
  },
]

const { TabPane } = Tabs

const Wrap = styled.div`
  margin: 32px;
`

const HeaderWrap = styled.div`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #E0E6ED;
  display: flex;
  padding: 10px 18px;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 32px;
`

const AgentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const AgentNameAndTypeWrap = styled.div`
  display: flex;
  align-items: center;
`

const AgentName = styled.p`
  color: ${colors.new_colorNeutral900};
  font-family: ${MONTSERRAT};
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin: 0;
`

const AgentSource = styled.p`
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`

const ContactInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .icon {
    border-radius: 100%;
    background-color: ${colors.new_grey50};
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 28px;
    min-width: 28px;
    max-height: 28px;
    max-width: 28px;

    svg {
      transform: scale(0.75);
    }
  }

  .info {
    overflow: hidden;
    color: ${colors.new_primary}
    font-family: ${INTER};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
`

const EditClientInfo = styled.p`
  color: ${colors.new_primary};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  margin: 0;
  text-align: right;
`

const AgentType = styled.p`
  background-color: ${colors.new_primary};
  color: ${colors.white};
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px;
  border-radius: 24px;
  height: 20px;
  margin: 0;
  margin-left: 8px;
  text-transform: capitalize;
`

const RightContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
`

const AgentLastUpdated = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`

const AgentLastUpdatedLabel = styled.div`
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const AgentLastUpdatedDate = styled.div`
  background: ${colors.new_borderPurple};
  border: 1px solid ${colors.new_secondary};
  border-radius: 4px;
  display: flex;
  padding: 2px 9px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${colors.new_grey400};
  font-family: ${INTER};
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
`

const ReqWrap = styled.div`
  width: 626px;
  border: 1px solid #D3DAE8;
  border-radius: 11px;
  padding: 23px;
`

const TitleDiv = styled.div`
  display: flex;
  flex-direction:
  margin-top: 17px;
  height: 22px;
  justify-content: space-between;
  align-items: space-between;
`
const AddProperty = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  background: none;
`

const DrawerHeader = ({
  agentInfo,
  getCrmClientResponse,
  setCurrentDrawerPage,
  setClientID,
  toggleAddNewLeadDrawer,
  toggleUserOverview,
  setCurrentClientType,
  getCtIdResponse,
}) => {
  const handleChange = (e) => {
    console.log(e)
  }

  const { data, isFetching } = getCrmClientResponse || {}
  const {
    clientTransactions,
    name,
    phones,
    emails,
    created_at,
  } = data || {}
  const { data: clientTransactionData } = getCtIdResponse || {}
  const { type, client_source } = clientTransactionData || {}
  const { SELLING: sellerTransactions, BUYING: buyerTransactions } = clientTransactions || {}
  const phone = !isFetching && phones && get(phones[0], "phone") || ""
  const email = !isFetching && emails && get(emails[0], "email") || ""

  const dualType = sellerTransactions && sellerTransactions.length > 0 && buyerTransactions && buyerTransactions.length > 0

  const systemTime = TimeZone(created_at, false)
  const { localeDateString } = systemTime

  const getClientSource = () => {
    const foundSource = CLIENT_SOURCE.find(source => source.value === client_source)
    if (foundSource) {
      return foundSource.label
    }
    return null
  }

  return (
    <Wrap>
      {isFetching ? <Loader /> : (
        <>
          <HeaderWrap>
            {/* <CircularImage
              size={92}
              name={name}
              charColor={colors.marineBLue}
              fontSize="40px"
            /> */}
            <Row>
              <Col span={18}>
                <AgentInfo>
                  <AgentNameAndTypeWrap>
                    <AgentName>
                      {name}
                    </AgentName>
                    <AgentType>
                      {dualType ? "DUAL" : type}
                    </AgentType>
                  </AgentNameAndTypeWrap>
                  <AgentSource>
                    {`Source: ${client_source === null ? "" : getClientSource()}`}
                  </AgentSource>

                  {phone && (
                    <ContactInfoContainer>
                      <div className="icon">
                        <PhoneIcon />
                      </div>
                      <p className="info">{phone && normalizePhone(phone)}</p>
                    </ContactInfoContainer>
                  )}

                  <ContactInfoContainer>
                    <div className="icon">
                      <MailIcon />
                    </div>
                    <p className="info">{email}</p>
                  </ContactInfoContainer>
                </AgentInfo>
              </Col>
              <Col span={6}>
                <RightContainerWrap>
                  <EditClientInfo
                    onClick={() => { setCurrentDrawerPage("edit-profile") }}
                  >
                    Edit Client Info
                  </EditClientInfo>

                  <AgentLastUpdated>
                    <AgentLastUpdatedLabel>
                      Added on
                    </AgentLastUpdatedLabel>
                    <AgentLastUpdatedDate>
                      {localeDateString}
                    </AgentLastUpdatedDate>
                  </AgentLastUpdated>
                </RightContainerWrap>
              </Col>
            </Row>
          </HeaderWrap>

          <LeadInfo defaultActiveKey="1">
            {sellerTransactions && (
              <>
                <TabPane tab="SELLING" key="2">
                  <PropertyInfo />
                </TabPane>
              </>
            )}
            {buyerTransactions && (
              <>
                <TabPane tab="BUYING" key="4">
                  <SearchCriteriaInfo />
                </TabPane>
              </>
            )}
            {!sellerTransactions && !buyerTransactions && (type && (type.toLowerCase() === "buyer" || type.toLowerCase() === "Buyer")) && (
              <ReqWrap>
                <TitleDiv>
                  <Title fontSize={18}>Add Search</Title>
                  <AddProperty
                    onClick={() => {
                      setCurrentDrawerPage("add-same-lead")
                      toggleAddNewLeadDrawer(true)
                      toggleUserOverview({ showUserOverviewDrawer: false, agentInfo })
                      setCurrentClientType("buyer")
                      setClientID({
                        id: data.client_id,
                        deal_id: data.id,
                      })
                    }}
                  >
                    <Title fontSize={15}>
                      Add Criteria
                    </Title>
                    <Icon src={AddIcon} />
                  </AddProperty>
                </TitleDiv>
              </ReqWrap>
            )}
            {!sellerTransactions && !buyerTransactions && (type && (type.toLowerCase() === "seller" || type.toLowerCase() === "Seller")) && (
              <ReqWrap>
                <TitleDiv>
                  <Title fontSize={18}>Add Listing</Title>
                  <AddProperty
                    onClick={() => {
                      setCurrentDrawerPage("add-same-lead")
                      toggleAddNewLeadDrawer(true)
                      toggleUserOverview({ showUserOverviewDrawer: false, agentInfo })
                      setCurrentClientType("seller")
                      setClientID({
                        id: data.client_id,
                        deal_id: data.id,
                      })
                    }}
                  >
                    <Title fontSize={15}>
                      Add Listing
                    </Title>
                    <Icon src={AddIcon} />
                  </AddProperty>
                </TitleDiv>
              </ReqWrap>
            )}
          </LeadInfo>
        </>
      )}
    </Wrap>
  )
}

export default CRMV2Container(DrawerHeader)
