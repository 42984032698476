import React from "react";
import {
  ListPoints,
  ListBullet,
  ListWrapper,
  Heading,
  HighlightsWrapper,
  HighlightsCard,
} from "./styles.js";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import YearBuilt from "../Icons/yearBuilt.js";
import SquareArea from "../Icons/squareArea.js";
import PricePerUnit from "../Icons/pricePerUnit.js";

const Highlights = ({ listingPageResponse }) => {
  return (
    <HighlightsWrapper>
        {listingPageResponse?.data?.highLights?.yearBuilt && (
          <HighlightsCard>
            <ListWrapper>
              <ListPoints>
                <YearBuilt style={{marginRight: "8px"}}/> Built in{" "}
                {listingPageResponse?.data?.highLights?.yearBuilt}
              </ListPoints>
            </ListWrapper>
          </HighlightsCard>
        )}
        {listingPageResponse?.data?.highLights?.buildingAreaTotal && (
          <HighlightsCard>
            <ListWrapper>
              <ListPoints>
                <SquareArea style={{marginRight: "8px"}}/>{" "}
                {listingPageResponse?.data?.highLights?.buildingAreaTotal} sqft
                loft
              </ListPoints>
            </ListWrapper>
          </HighlightsCard>
        )}
        {listingPageResponse?.data?.highLights?.pricePerUnit && (
          <HighlightsCard>
            <ListWrapper>
              <ListPoints>
                <PricePerUnit style={{marginRight: "8px"}} />{" "}
                {listingPageResponse?.data?.highLights?.pricePerUnit}/sqft
              </ListPoints>
            </ListWrapper>
          </HighlightsCard>
        )}
    </HighlightsWrapper>
  );
};

export default ListingPageContainer(Highlights);
