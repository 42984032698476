/* eslint-disable linebreak-style */
import React, { useEffect } from "react"
import Loadable from "react-loadable"
import styled from "styled-components"
import root from "window-or-global"
import { get } from "lodash"
import { Route, Switch, Redirect, useLocation } from "react-router-dom"
import AsyncComponent from "hoc/asyncComponent"
import Loader from "@ui/Loader"
import OnboardingRoute from "hoc/OnboardingRoute"
import AgreementRoute from "hoc/AgreementRoute"
import RedirectionRoute from "hoc/RedirectionRoute"
import HeaderRoute from "hoc/HeaderRoute"
import MailRoute from "hoc/MailRoute"
import SidebarRoute from "hoc/SidebarRoute"
import PublicRoute from "hoc/PublicRoute"
import Layout from "dumbComponents/common/Layout"
// import HomePage from "dumbComponents/HomePage/HomeV7"
import CommunityLanding from "dumbComponents/HomePage/HomeV6"
import Profile from "ssr/Profile"
import LandingPageNew from "dumbComponents/NewLandingPage"
import NewHomePage from "dumbComponents/HomePageV2"
import OnboardingOrientation from "dumbComponents/OnboardingOrientation"
import TeamProfileSSR from "ssr/TeamProfilePage"
import queryString from "query-string"
import cookie from "services/CookieStorage"
// import RealtyV2 from "dumbComponents/RealtyV2"
import ListingsCoverSheet from "dumbComponents/ListingsV2/Components/ListingRouter"
import RadiusTeamsPage from "dumbComponents/RealtyV3/RadiusTeamsPage"
import WebinarRegistration from "dumbComponents/common/WebinarRegistration"
import ApplyModal from "dumbComponents/common/NewApplyForm/InterestForm"
import SuccessModal from "dumbComponents/common/NewApplyForm/SuccessModal"
import HubspotModal from "dumbComponents/common/NewApplyForm/HubspotModal"
import LoginModal from "dumbComponents/common/NewApplyForm/LoginModal"
import { WhoWeServe } from "dumbComponents/Marketing"
import TransactionCoordinationV2 from "dumbComponents/TransactionCoordinationV2"
import RadiusTeamJobsPreview from "dumbComponents/RealtyV3/RARRecruitment/pages/PreviewPage"
import NewTeamsPage from "dumbComponents/NewTeamsPages"
import NewAgentLP from "dumbComponents/NewAgentLP"
import ScheduledRoomsPublicPageV2 from "dumbComponents/EventRooms/ScheduledRoomsPublicPageV3"
import GetAppMobile from "dumbComponents/EventRooms/GetAppMobile"
import NonAssist from "dumbComponents/NonAssist"
import RoomsLP from "dumbComponents/VideoRooms/components/LandingPage"
import ShowcasePreRecorded from "dumbComponents/VideoRooms/components/LandingPage/Showcase"
import CRMV2 from "dumbComponents/CRMV2"
import ListingsV2 from "dumbComponents/ListingsV2"
import OtherMenuModal from "./dumbComponents/common/Navbar/OtherMenuModal"
import ArchivedLeads from "./dumbComponents/CRMV2/CRMDashboard/Components/ArchivedLeads/index"
import StripePOC from "./dumbComponents/StripePOC"
import StripeUIPOC from "./dumbComponents/StripePOC/InjectedCardForm"
import CookiesStorage from "services/CookieStorage"
import LegalDisclosures from "./dumbComponents/LegalDisclosures"
import ICAOnboarding from "./dumbComponents/ICAOnboarding"
import SignupV2 from "./dumbComponents/Auth/SignupV2"
import { ConditionalRenderingPage } from "./dumbComponents/Auth/SignupV2/Pages/index"
import { TeamMemberInvite } from "./dumbComponents/ICAOnboarding/Components/index"
import DeleteAccount from "./dumbComponents/DeleteAccount"
import OffersV2 from "./dumbComponents/OffersV2/index"
import OffersV3 from "./dumbComponents/OffersV3/index"
import { authCheck } from "./services/auth"
import HomePageV2 from "./dumbComponents/HomePageV2/index"
import RedirectionPage from "./dumbComponents/RedirectionPage/index"
import ListingPageLayout from "./dumbComponents/Listing Page/index"
import Mortgage from "./dumbComponents/Mortgage/index"
// import RadiusTeamJobs from "dumbComponents/RealtyV3/RARRecruitment/pages/JobsLandingPage"
// import JobsLandingPageIndividual from "dumbComponents/RealtyV3/RARRecruitment/pages/JobsLandingPageIndividual"
// import JobsLandingPageMobile from "dumbComponents/RealtyV3/RARRecruitment/pages/JobsLandingPageMobile"

const search = get(root, "location.search", {})
const parsedQuery = queryString.parse(search)

if (parsedQuery && parsedQuery.secure_id) {
  cookie.save("secureId", parsedQuery.secure_id)
  cookie.save("user", { secureId: parsedQuery.secure_id })
}

const LoaderWrap = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const RadiusDefaultLoader = () => (
  <LoaderWrap>
    <Loader top="0" bottom="0" />
  </LoaderWrap>
)

// Pages
//import RequirementCollection from "dumbComponents/ClientCollection/RequirementCollection"
const RequirementCollection = Loadable({
  loader: () => import("dumbComponents/ClientCollection/RequirementCollection"),
  loading: RadiusDefaultLoader,
})

const RedirectEngine = Loadable({
  loader: () => import("dumbComponents/RedirectEngine"),
  loading: RadiusDefaultLoader,
})

const RadiusTeamJobs = Loadable({
  loader: () => import("dumbComponents/RealtyV3/RARRecruitment/pages/JobsLandingPage"),
  loading: RadiusDefaultLoader,
})

const JobsLandingPageIndividual = Loadable({
  loader: () => import("dumbComponents/RealtyV3/RARRecruitment/pages/JobsLandingPageIndividual"),
  loading: RadiusDefaultLoader,
})

const JobsLandingPageMobile = Loadable({
  loader: () => import("dumbComponents/RealtyV3/RARRecruitment/pages/JobsLandingPageMobile"),
  loading: RadiusDefaultLoader,
})

const IconRuntime = Loadable({
  loader: () => import("dumbComponents/IconRuntime"),
  loading: RadiusDefaultLoader,
})

const BeginSubmitReferral = Loadable({
  loader: () => import("dumbComponents/Referral/SubmitReferral/ReferralService"),
  loading: RadiusDefaultLoader,
})

const MarketplaceWelcomePage = Loadable({
  loader: () => import("dumbComponents/Marketplace/WelcomePage"),
  loading: RadiusDefaultLoader,
})

//import PropertyCollection from "dumbComponents/ClientCollection/PropertyCollection"
const PropertyCollection = Loadable({
  loader: () => import("dumbComponents/ClientCollection/PropertyCollection"),
  loading: RadiusDefaultLoader,
})

//import UnsubscribeEmail from "dumbComponents/UnsubscribeEmail"
const UnsubscribeEmail = Loadable({
  loader: () => import("dumbComponents/UnsubscribeEmail"),
  loading: RadiusDefaultLoader,
})

//import SuccessPage from "dumbComponents/ClientCollection/SuccessPage"
const SuccessPage = Loadable({
  loader: () => import("dumbComponents/ClientCollection/SuccessPage"),
  loading: RadiusDefaultLoader,
})
//import ISAAgreement from "dumbComponents/Referral/ISAAgreement"
const ISAAgreement = Loadable({
  loader: () => import("dumbComponents/Referral/ISAAgreement"),
  loading: RadiusDefaultLoader,
  delay: 300,
  timeout: 5000,
})
//import CardDetails from "dumbComponents/UnsubscribeEmail"
const CardDetails = Loadable({
  loader: () => import("dumbComponents/Referral/ISAAgreement/PaymentSettings/CardDetails"),
  loading: RadiusDefaultLoader,
})

//import MarketplaceCallPage from "dumbComponents/Marketplace/CallingPage"
const MarketplaceCallPage = Loadable({
  loader: () => import("dumbComponents/Marketplace/CallingPage"),
  loading: RadiusDefaultLoader,
})
//import Careerpage from "dumbComponents/Career"
const Careerpage = Loadable({
  loader: () => import("dumbComponents/Career"),
  loading: RadiusDefaultLoader,
})
//import actionCoordinationPage from "dumbComponents/TransactionCoordination"
const TransactionCoordinationPage = Loadable({
  loader: () => import("dumbComponents/TransactionCoordination"),
  loading: RadiusDefaultLoader,
})
//import ReferralLP from "dumbComponents/ReferralLP"
const ReferralLP = Loadable({
  loader: () => import("dumbComponents/ReferralLP"),
  loading: RadiusDefaultLoader,
})
//import ReferPageRedirect from "dumbComponents/Referral/InviteRedirect"
const ReferPageRedirect = Loadable({
  loader: () => import("dumbComponents/Referral/InviteRedirect"),
  loading: RadiusDefaultLoader,
})
//import ReferralLoader from "dumbComponents/Referral/ReferralLoader"
const ReferralLoader = Loadable({
  loader: () => import("dumbComponents/Referral/ReferralLoader"),
  loading: RadiusDefaultLoader,
})

const SelectACity = Loadable({
  loader: () => import("dumbComponents/RealtyV2/SelectCity"),
  loading: RadiusDefaultLoader,
})

const RealtyScheduleACall = Loadable({
  loader: () => import("dumbComponents/RealtyV2/ScheduleACall"),
  loading: RadiusDefaultLoader,
})

const RedirectPage = Loadable({
  loader: () => import("dumbComponents/Redirect"),
  loading: RadiusDefaultLoader,
})

/*const FeaturePages = Loadable({
  loader: () => import("dumbComponents/RealtyV2/FeaturePages"),
  loading: RadiusDefaultLoader,
  delay: 300,
  timeout: 5000,
})*/

//import RadiusTeam from "dumbComponents/BrokersLP/RadiusTeam"

const RealtyV3 = Loadable({
  loader: () => import("dumbComponents/RealtyV3"),
  loading: RadiusDefaultLoader,
  delay: 300,
  timeout: 5000,
})

const AboutUsV2 = Loadable({
  loader: () => import("dumbComponents/AboutUsV2"),
  loading: RadiusDefaultLoader,
  delay: 300,
  timeout: 5000,
})

//ThankYou
const ThankYouPage = Loadable({
  loader: () => import("dumbComponents/RealtyV3/Common/ModalForm/ThankYou"),
  loading: RadiusDefaultLoader,
  delay: 300,
  timeout: 5000,
})

const RadiusEdgePage = Loadable({
  loader: () => import("dumbComponents/RealtyV3/RadiusEdgePage"),
  loading: RadiusDefaultLoader,
  delay: 300,
  timeout: 5000,
})

const RadiusAgentsPage = Loadable({
  loader: () => import("dumbComponents/RealtyV3/RadiusAgentsPage"),
  loading: RadiusDefaultLoader,
  delay: 300,
  timeout: 5000,
})

const RadiusOurAgentsPage = Loadable({
  loader: () => import("dumbComponents/LandingPages/OurAgents"),
  loading: RadiusDefaultLoader,
  delay: 300,
  timeout: 5000,
})

const ContactDashboard = Loadable({
  loader: () => import("dumbComponents/RealtyV3/ContactDashboard"),
  loading: RadiusDefaultLoader,
})

const OldTechAndAssets = Loadable({
  loader: () => import("dumbComponents/RARDashboard"),
  loading: RadiusDefaultLoader,
})

const TransactionCoordinationFormPage = Loadable({
  loader: () => import("dumbComponents/RealtyV3/Dashboard/Transaction/GoogleForm"),
  loading: RadiusDefaultLoader,
})

// const TransactionCoordinationV2 = Loadable({
//   loader: () => import("dumbComponents/TransactionCoordinationV2"),
//   loading: RadiusDefaultLoader,
// })

//import PropertyStatusEmailer from "dumbComponents/PropertyStatusEmailer"
const PropertyStatusEmailer = Loadable({
  loader: () => import("dumbComponents/PropertyStatusEmailer"),
  loading: RadiusDefaultLoader,
})

// //import OnboardingDetails from "dumbComponents/OnboardingSetup"
// const SetupPopup = Loadable({
//   loader: () => import("dumbComponents/OnboardingSetup"),
//   loading: RadiusDefaultLoader,
// })

// //import OnboardingOrientation from "dumbComponents/OnboardingOrientation"
// const OrientationPopup = Loadable({
//   loader: () => import("dumbComponents/OnboardingOrientation"),
//   loading: RadiusDefaultLoader,
// })

//import HomePage from "dumbComponents/HomePage"
//import Notifications from "dumbComponents/Notifications"
const Notifications = Loadable({
  loader: () => import("dumbComponents/Notifications"),
  loading: RadiusDefaultLoader,
})

//import ErrorPage from "ssr/ErrorPage"
const ErrorPage = Loadable({
  loader: () => import("ssr/ErrorPage"),
  loading: RadiusDefaultLoader,
})
//import ShareFeed from "ssr/ShareFeed"
const ShareFeed = Loadable({
  loader: () => import("ssr/ShareFeed"),
  loading: RadiusDefaultLoader,
})
//import LoginPage from "dumbComponents/Auth/Login"
const LoginPage = Loadable({
  loader: () => import("dumbComponents/Auth/Login"),
  loading: RadiusDefaultLoader,
})
//import SignUpPage from "dumbComponents/Auth/SignUp"
const SignUpPage = Loadable({
  loader: () => import("dumbComponents/Auth/SignUp"),
  loading: RadiusDefaultLoader,
})

const ReferralInvitePage = Loadable({
  loader: () => import("dumbComponents/RecruitEarnLanding/index"),
  loading: RadiusDefaultLoader,
})

//import ForgotPage from "dumbComponents/Auth/Forgot"
const ForgotPage = Loadable({
  loader: () => import("dumbComponents/Auth/Forgot"),
  loading: RadiusDefaultLoader,
})
//import ClaimProfile from "dumbComponents/Auth/Claim"
const ClaimProfile = Loadable({
  loader: () => import("dumbComponents/Auth/Claim"),
  loading: RadiusDefaultLoader,
})
//import AssociationsPage from "dumbComponents/Auth/Associations"
const AssociationsPage = Loadable({
  loader: () => import("dumbComponents/Auth/Associations"),
  loading: RadiusDefaultLoader,
})
//import SetPassword from "dumbComponents/Auth/SetPassword"
const SetPassword = Loadable({
  loader: () => import("dumbComponents/Auth/SetPassword"),
  loading: RadiusDefaultLoader,
})
//import CitiesServered from "dumbComponents/Auth/Onboarding"
const CitiesServered = Loadable({
  loader: () => import("dumbComponents/Auth/Onboarding"),
  loading: RadiusDefaultLoader,
})
//import GeneralTermsOfUse from "dumbComponents/Legal/TermsOfUser"
const GeneralTermsOfUse = Loadable({
  loader: () => import("dumbComponents/Legal/TermsOfUseV2"),
  loading: RadiusDefaultLoader,
})

const ConsumerNotice = Loadable({
  loader: () => import("dumbComponents/Legal/ConsumerNotice"),
  loading: RadiusDefaultLoader,
})

const InformationAboutBrokerageServices = Loadable({
  loader: () => import("dumbComponents/Legal/InformationAboutBrokerageServices"),
  loading: RadiusDefaultLoader,
})

const AffliatedBusinessArrangementDisclosure = Loadable({
  loader: () => import("dumbComponents/Legal/AffliatedBusinessArrangementDisclosure"),
  loading: RadiusDefaultLoader,
})


const RealtyTermsOfUse = Loadable({
  loader: () => import("dumbComponents/Legal/RealtyTermsOfUse"),
  loading: RadiusDefaultLoader,
})

const CashbackTermsOfUse = Loadable({
  loader: () => import("dumbComponents/Legal/CashbackTermsOfUse"),
  loading: RadiusDefaultLoader,
})

const DisclosuresAndLicensing = Loadable({
  loader: () => import("dumbComponents/Legal/Disclosures&Licensing"),
  loading: RadiusDefaultLoader,
})
//import ISATermsAndConditions from "dumbComponents/Legal/ISATermsAndConditions"
const ISATermsAndConditions = Loadable({
  loader: () => import("dumbComponents/Legal/ISATermsAndConditions"),
  loading: RadiusDefaultLoader,
})
//import PrivacyPolicy from "dumbComponents/Legal/PrivacyPolicy"
const PrivacyPolicy = Loadable({
  loader: () => import("dumbComponents/Legal/PrivacyPolicy"),
  loading: RadiusDefaultLoader,
})
//import InviteRedirect from "dumbComponents/Referral/InviteRedirect"
const InviteRedirect = Loadable({
  loader: () => import("dumbComponents/Referral/InviteRedirect"),
  loading: RadiusDefaultLoader,
})
//import UnsubscribeEmail from "dumbComponents/RecruitAndEarn"
// const RecruitAndEarn = Loadable({
//   loader: () => import("dumbComponents/RealtyV3/RecruitAndEarn"),
//   loading: RadiusDefaultLoader,
// })

const RealtyResources = Loadable({
  loader: () => import("dumbComponents/RealtyV3/RARResources"),
  loading: RadiusDefaultLoader,
})

const RealtyRecruitment = Loadable({
  loader: () => import("dumbComponents/RealtyV3/RARRecruitment"),
  loading: RadiusDefaultLoader,
})

const RecruitAndEarnProgress = Loadable({
  loader: () => import("dumbComponents/RealtyV3/RecruitAndEarn/Progress"),
  loading: RadiusDefaultLoader,
})

const RecruitAndEarnV2 = Loadable({
  loader: () => import("dumbComponents/RealtyV3/RecruitAndEarnV2"),
  loading: RadiusDefaultLoader,
})

const Cashback = Loadable({
  loader: () => import("dumbComponents/RealtyV3/Cashback"),
  loading: RadiusDefaultLoader,
})

const Referrals = Loadable({
  loader: () => import("dumbComponents/Referral/index.js"),
  loading: RadiusDefaultLoader,
})

const SubmitReferral = Loadable({
  loader: () => import("dumbComponents/Referral/SubmitReferral/index.js"),
  loading: RadiusDefaultLoader,
})

const Search = Loadable({
  loader: () => import("dumbComponents/Search/index.js"),
  loading: RadiusDefaultLoader,
})

const Network = Loadable({
  loader: () => import("dumbComponents/Network/index.js"),
  loading: RadiusDefaultLoader,
})

const AllActivities = Loadable({
  loader: () => import("dumbComponents/Network/AllNetworkActivity"),
  loading: RadiusDefaultLoader,
})

const AllPeopleYouMayKnow = Loadable({
  loader: () => import("dumbComponents/Network/FollowerListFullScreen"),
  loading: RadiusDefaultLoader,
})

const AllInfluencersToFollow = Loadable({
  loader: () => import("dumbComponents/Network/InfluencersListFullScreen"),
  loading: RadiusDefaultLoader,
})

const ClientProfile = Loadable({
  loader: () => import("dumbComponents/ClientProfile/index.js"),
  loading: RadiusDefaultLoader,
})

const AssistPage = Loadable({
  loader: () => import("dumbComponents/Referral/Prospects/index.js"),
  loading: RadiusDefaultLoader,
})

const OutboundClientProfile = Loadable({
  loader: () => import("dumbComponents/Client/index.js"),
  loading: RadiusDefaultLoader,
})

const AcademyPage = Loadable({
  loader: () => import("dumbComponents/Academy/index.js"),
  loading: RadiusDefaultLoader,
  delay: 300,
  timeout: 5000,
})

const TeamAcceptancePage = Loadable({
  loader: () => import("dumbComponents/common/TeamAcceptancePage.js"),
  loading: RadiusDefaultLoader,
})

// Settings Page
const SettingsAsync = () => import("dumbComponents/Settings/index.js").then(module => module.default)
const Settings = AsyncComponent(SettingsAsync, <RadiusDefaultLoader />)

// UnsignedAsync page
const UnsignedAsync = () => import("dumbComponents/Referral/Unsigned.js").then(module => module.default)
const UnsignedReferral = AsyncComponent(UnsignedAsync, <RadiusDefaultLoader />)

//Checkout page
const CheckoutAsync = () => import("dumbComponents/Referral/Checkout/index.js").then(module => module.default)
const CheckoutPage = AsyncComponent(CheckoutAsync, <RadiusDefaultLoader />)

//Incomplete Profile Page
const IncompleteProfilePageAsync = () => import("dumbComponents/common/IncompleteProfile/index.js").then(module => module.default)
const IncompleteProfilePage = AsyncComponent(IncompleteProfilePageAsync, <RadiusDefaultLoader />)

const ChatFloaterPlugin = Loadable({
  loader: () => import("dumbComponents/Plugins/ChatFloater"),
  loading: RadiusDefaultLoader,
})

const PropertyDetails = Loadable({
  loader: () => import("dumbComponents/PropertyDetails/index.js"),
  loading: RadiusDefaultLoader,
})

const Leaderboard = Loadable({
  loader: () => import("dumbComponents/Leaderboard"),
  loading: RadiusDefaultLoader,
})

const Promos = Loadable({
  loader: () => import("dumbComponents/Promos"),
  loading: RadiusDefaultLoader,
})

const Mentoring = Loadable({
  loader: () => import("dumbComponents/Mentoring"),
  loading: RadiusDefaultLoader,
})

const TechAssets = Loadable({
  loader: () => import("dumbComponents/TechAssets"),
  loading: RadiusDefaultLoader,
})

const TeamProfile = Loadable({
  loader: () => import("dumbComponents/TeamProfile"),
  loading: RadiusDefaultLoader,
})
const showNewHomePage = cookie.load("G_HOME_PAGE_VARIANT") === 1 || cookie.load("G_HOME_PAGE_VARIANT") === "1"

const JobsUI = Loadable({
  loader: () => import("dumbComponents/Jobs"),
  loading: RadiusDefaultLoader,
})

const RealtyFinancing2 = Loadable({
  loader: () => import("dumbComponents/FinancingDashboard"),
  loading: RadiusDefaultLoader,
})

const Listings = Loadable({
  loader: () => import("dumbComponents/Listings"),
  loading: RadiusDefaultLoader,
})

const Offers = Loadable({
  loader: () => import("dumbComponents/Offers"),
  loading: RadiusDefaultLoader,
})

// const Mortgage = Loadable({
//   loader: () => import("dumbComponents/Mortgage"),
//   loading: RadiusDefaultLoader,
// })

const PreSale = Loadable({
  loader: () => import("dumbComponents/PreSale"),
  loading: RadiusDefaultLoader,
})

const FinancingCommunity = Loadable({
  loader: () => import("dumbComponents/FinancingCommunity"),
  loading: RadiusDefaultLoader,
})

const Wellness = Loadable({
  loader: () => import("dumbComponents/Wellness"),
  loading: RadiusDefaultLoader,
})

const ListingVideos = Loadable({
  loader: () => import("dumbComponents/Rolla"),
  loading: RadiusDefaultLoader,
})

const MarketingAssets = Loadable({
  loader: () => import("dumbComponents/TechAssets/AssetsRoute"),
  loading: RadiusDefaultLoader,
})

const EventRoom = Loadable({
  loader: () => import("dumbComponents/EventRooms/PublicPageV2"),
  loading: RadiusDefaultLoader,
})

const ScheduledRoomsPublicPage = Loadable({
  loader: () => import("dumbComponents/EventRooms/ScheduledRoomsPublicPage"),
  loading: RadiusDefaultLoader,
})

const VideoRooms = Loadable({
  loader: () => import("dumbComponents/VideoRooms"),
  loading: RadiusDefaultLoader,
})

const OnboardVideoRoom = Loadable({
  loader: () => import("dumbComponents/VideoRooms/Onboard"),
  loading: RadiusDefaultLoader,
})

const DocusignReturnPage = Loadable({
  loader: () => import("dumbComponents/Listings/pages/Docusign/DocusignReturnPage"),
  loading: RadiusDefaultLoader,
})

const ListingPageSSR = Loadable({
  loader: () => import("dumbComponents/Listing Page"),
  loading: RadiusDefaultLoader,
})

// const listingPageLocation = useLocation();
//   const params = new URLSearchParams(listingPageLocation.search);
//   const page = params.get('page');

const Router = (props) => {
  const {
    location,
    vwoData,
    sideMenuToggle,
  } = props

  /*TO BE CONTINUED*/
  // CookiesStorage.save("vwo_visitor_id", uniqueVisitorId)
  // const existingVisitorID = CookiesStorage.load("vwo_visitor_id")

  const {
    variation,
  } = vwoData || {}

// if (window.location.href.includes("page=mobile")) {
//     return (
//       <Switch>
//         <Route path="/property/:MLS_ID/:Address" component={ListingPageSSR} location={location}/>
//       </Switch>
//     );
//   }

//   if (window.location.href.includes("page=web")) {
//     return (
//       <Switch>
//         <HeaderRoute path="/property/:MLS_ID/:Address" component={ListingPageSSR} location={location}/>
//       </Switch>
//     );
//   }

  // we are checking the route for docusign callback
  // as this page closes once we receive the event there is
  // no need to call the AppMount saga where unnecesary API calls
  // were taking place, hence we made a skeleton route
  if (root.location.href.includes("/realty/listings/documents-callback")) {
    localStorage.setItem('isDocusignOpen', 2)
    return (
      <Switch>
        <Route path="/realty/listings/documents-callback" exact component={DocusignReturnPage} requireAuth location={location}/>
      </Switch>
    )
  }

  return (
    <Layout>
      <HubspotModal />
      <SuccessModal />
      <ApplyModal />
      <LoginModal />
      <OtherMenuModal />
      <WebinarRegistration />
      <Switch>
        {process.env.NODE_ENV === "development" && <Route path="/icons" component={IconRuntime} />}

        <HeaderRoute path="/teamprofile" component={TeamProfileSSR} location={location} headerVersion="v2" />
        <HeaderRoute path="/profile" component={Profile} location={location} headerVersion="v2" />
        <HeaderRoute path="/profile/agent" component={Profile} location={location} />
        <SidebarRoute path="/notifications" component={Notifications} location={location} />
        {/* Error Routes */}
        <HeaderRoute path="/error/:type" component={ErrorPage} location={location} />
        <Route path="/referral-loader" component={ReferralLoader} />

        {/* Referral Routes */}
        <SidebarRoute path="/referrals" component={Referrals} location={location} />

        {/* Full Page Route */}
        <Route path="/submit/referral/begin" component={BeginSubmitReferral} exact />
        <SidebarRoute path="/marketplace/welcome" component={MarketplaceWelcomePage} exact />

        <Route path="/submit/referral" component={SubmitReferral} location={location} />
        <Route path="/invite/:code" component={InviteRedirect} />
        <MailRoute path="/marketing/for-teams" component={NewTeamsPage} exact />
        <MailRoute path="/marketing/for-agents" component={NewAgentLP} exact />
        {/* <Route path="/new-landing-page" component={NewHomePage} /> */}
        <Redirect
          from="/marketplace"
          to={`/referrals/marketplace${get(root, "location.search", "")}`}
          exact
        />

        {/* Event Room */}
        <HeaderRoute path="/rooms/onboard/:roomId/:name" component={OnboardVideoRoom} />
        <Route path="/rooms/:roomId/live/:name" component={VideoRooms} location={location} requireAuth />
        <Route path="/rooms/:roomId" component={EventRoom} location={location} />
        {/* Scheduled Room */}
        <HeaderRoute path="/scheduledrooms/:roomId/:name" component={ScheduledRoomsPublicPageV2} location={location} />
        <HeaderRoute path="/gettheapp" component={GetAppMobile} location={location} />

        {/* Property Details */}
        <HeaderRoute path="/property-details/:propertyId/:slug" component={PropertyDetails} />

        {/* Academy Page */}
        <HeaderRoute path="/academy" component={AcademyPage} headerVersion="v2" />

        {/*Property Status Emailer*/}
        <HeaderRoute path="/property-status-emailer" component={PropertyStatusEmailer} />

        {/*New Onboarding Details Page*/}
        {/* <HeaderRoute path="/onboarding-setup" component={SetupPopup} /> */}

        {/*New Onboarding Orientation Page*/}
        {/* <HeaderRoute path="/onboarding-orientation" component={OrientationPopup} /> */}

        <SidebarRoute path="/crm" component={CRMV2} location={location} headerVersion="v2" />
        <SidebarRoute path="/archivedleads" component={ArchivedLeads} location={location} headerVersion="v2" />

        {/* ====== ROUTES SERVED VIA NGINX PROXY */}
        {/* Assist Page */}
        <SidebarRoute path="/assist" component={AssistPage} location={location} headerVersion="v2" />
        {/* Career page NOTE: Hosted on https://radiusagent.con/careers*/}
        <HeaderRoute path="/careers" component={Careerpage} headerVersion="v2" />
        {/* Moncord Hosted on https://radiusagent.com/transaction-coordination */}
        <HeaderRoute path="/transaction-coordination" component={TransactionCoordinationPage} headerVersion="v2" />
        {/* ReferralLP Hosted on https://radiusagent.com/referrals */}
        <HeaderRoute path="/partner-referrals" component={ReferralLP} headerVersion="v2" />
        {/* BrokersLP Hosted on https://radiusagent.com/realty */}
        {/* <SidebarRoute path="/realty/dashboard" component={RARDashbaord} exact /> */}
        <SidebarRoute path="/realty/dashboard" component={Leaderboard} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/support" component={ContactDashboard} exact requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/team-profile" component={TeamProfile} />
        <SidebarRoute path="/realty/jobs" component={JobsUI} requireAuth />
        <SidebarRoute path="/wellness" component={Wellness} requireAuth />
        <SidebarRoute path="/listing-videos" component={ListingVideos} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/mentoring" component={Mentoring} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/financing" component={RealtyFinancing2} requireAuth />
        <SidebarRoute path="/realty/listings" component={ListingsV2} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/listings" component={ListingsCoverSheet} requireAuth />
        <SidebarRoute path="/realty/offers" component={Offers} requireAuth />
        <SidebarRoute exact layoutMaxWidth="92%" path="/realty/offers-v2" component={OffersV2} requireAuth />
        <SidebarRoute exact layoutMaxWidth="92%" path="/realty/offers-v3" component={OffersV3} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/offers-v3/:id" component={OffersV3} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/offers-v2/:id" component={OffersV2} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/mortgage" component={Mortgage} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/presale" component={PreSale} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/products-and-services" component={TechAssets} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/marketing-assets" component={MarketingAssets} requireAuth />
        {/* <SidebarRoute path="/realty/tech-and-assets" component={OldTechAndAssets} exact /> */}
        <SidebarRoute path="/realty/transaction-coordination" component={TransactionCoordinationV2} requireAuth />
        <SidebarRoute path="/realty/dashboard/transaction-coordination" component={TransactionCoordinationFormPage} exact requireAuth />
        <SidebarRoute path="/realty/promos" component={Promos} exact requireAuth />
        {/* <HeaderRoute path="/realty/agents" component={RadiusOurAgentsPage} exact headerVersion="v2" /> */}
         <Redirect
          from="/realty/agents"
          to={`/`}
          exact
        />
        {/* <HeaderRoute path="/realty/radius-financing" component={RadiusEdgePage} exact headerVersion="v2" /> */}
          <Redirect
          from="/realty/radius-financing"
          to={`/`}
          exact
        />
        {/* <HeaderRoute path="/realty/for-agents" component={RadiusAgentsPage} exact headerVersion="v2" /> */}
          <Redirect
          from="/realty/for-agents"
          to={`/meet-the-team`}
          exact
        />
        {/* <HeaderRoute path="/realty/elite" component={RadiusRoyalePage} exact headerVersion="v2" /> */}
        <HeaderRoute path="/rooms" component={RoomsLP} exact headerVersion="v2" />
        <Route path="/stripe/addCardDetails/authenticate" component={StripePOC} headerVersion="v2" />
        <Route path="/office/ica-onboarding" component={ICAOnboarding} headerVersion="v2" />
        <Route path="/signup" component={SignupV2} headerVersion="v2" />
                
        <Route path="/office/authenticate" component={ConditionalRenderingPage} headerVersion="v2" />
        <HeaderRoute path="/watch/rooms/:id/:name" component={ShowcasePreRecorded} exact headerVersion="v2" />

        {/* Leaderboard */}
        {/* <SidebarRoute layoutMaxWidth="92%" path="/realty/leaderboard" component={Leaderboard} requireAuth /> */}
        <SidebarRoute path="/realty/resources" component={RealtyResources} requireAuth />
        <SidebarRoute layoutMaxWidth="92%" path="/realty/recruitment" component={RealtyRecruitment} requireAuth />
        <SidebarRoute path="/mortage" component={FinancingCommunity} requireAuth />

        {/* <HeaderRoute path="/realty/choose-your-city" component={SelectACity} exact /> */}
          <Redirect
          from="/realty/choose-your-city"
          to={`/`}
          exact
        />
        {/* <HeaderRoute path="/realty/schedule-a-call" component={RealtyScheduleACall} exact /> */}
         <Redirect
          from="/realty/schedule-a-call"
          to={`/`}
          exact
        />
        {/*<HeaderRoute path="/realty/features" component={FeaturePages} exact />*/}
        {/* <HeaderRoute path="/realty" component={RealtyV2} /> */}
        {/* <HeaderRoute path="/realty" component={RealtyV3} exact headerVersion="v2" /> */}
        {/* About Us */}
        {/* <HeaderRoute path="/about" component={AboutUsV2} exact headerVersion="v2" /> */}
        {/* <HeaderRoute path="/realty/pricing" component={RadiusPricingPage} exact headerVersion="v2" /> */}
        <HeaderRoute path="/realty/teams" component={RadiusTeamsPage} exact headerVersion="v2" />
        {/* <HeaderRoute path="/new-landing-page" component={LandingPageNew} exact headerVersion="v2" /> */}
        <HeaderRoute path="/realty/team-jobs/preview" component={RadiusTeamJobsPreview} exact headerVersion="v2" />
        <HeaderRoute path="/teams/jobs/" component={RadiusTeamJobs} location={location} exact headerVersion="v2" />
        <HeaderRoute path="/teams/jobs/:jobSlug" component={JobsLandingPageIndividual} exact location={location} headerVersion="v2" />
        <HeaderRoute path="/teams/jobs/mobile/:jobSlug" component={JobsLandingPageMobile} exact location={location} headerVersion="v2" />
        {/* <HeaderRoute path="/realty/thankyou" component={ThankYouPage} exact /> */}
        <Redirect
          from="/realty/thankyou"
          to={`/`}
          exact
        />
        {/* ++++++++++++++ */}

        {/* Settings Routes */}
        <HeaderRoute path="/settings" component={Settings} />

        {/* Realty Recruit And Earn Routes */}
        {/* <SidebarRoute path="/realty/recruit-and-earn" component={RecruitAndEarn} requireAuth />
      <SidebarRoute path="/realty/recruit-and-earn/progress" component={RecruitAndEarnProgress} requireAuth />
      <SidebarRoute path="/realty/recruit-and-earn/stats" component={RecruitAndEarnStats} requireAuth /> */}

        <SidebarRoute path="/realty/recruit-and-earn" component={RecruitAndEarnV2} requireAuth />

        <SidebarRoute path="/realty/cashback" component={Cashback} requireAuth />

        {/* Refer and Earn Routes V2 */}
        <SidebarRoute path="/realty/activity" component={RecruitAndEarnV2} requireAuth />

        {/*IncompleteProfile*/}
        <HeaderRoute path="/incomplete-profile" component={IncompleteProfilePage} requireAuth />

        {/* Outbound Client Profile */}
        <HeaderRoute path="/outbound/:clientId" component={OutboundClientProfile} exact requireAuth />

        {/* StandAlone Routes */}
        <HeaderRoute path="/team-acceptance" component={TeamAcceptancePage} exact requireAuth />
        <HeaderRoute path="/search" component={Search} />
        {/* <SidebarRoute path="/unsigned" component={UnsignedReferral} /> */}
        <HeaderRoute path="/email/unsubscribe/:hashId" component={UnsubscribeEmail} location={location} />
        <Route path="/requirement/:id/agent/:agentId" component={RequirementCollection} />
        <Route path="/property/:id/agent/:agentId" component={PropertyCollection} />
        <Route path="/:type/success" component={SuccessPage} />
        <HeaderRoute path="/card-details" component={CardDetails} />
        <Route path="/redirect" component={RedirectionRoute} />

        <HeaderRoute path="/delete-account" component={DeleteAccount} />

        {/* Marketplace Routes */}
        <Route path="/marketplace/active-call" component={MarketplaceCallPage} exact />
        <Redirect from="/marketplace/list" to="/referrals/marketplace" />
        <Redirect from="/referrals/marketplace/list" to="/referrals/marketplace" />
        <Redirect
          from="/marketplace/open-lead"
          to={`/referrals/marketplace${get(root, "location.search", "")}&isOpenLeadModal=true`}
        />

        {/* Client Page (Inbound) */}
        <HeaderRoute path="/client/:clientId" component={ClientProfile} location={location} />

        {/* <HeaderRoute path="/radius-assist" component={ISAAgreement} headerVersion="v2" /> */}
        <HeaderRoute path="/checkout" component={CheckoutPage} headerVersion="v2" />

        {/* <AgreementRoute path="/wcr/isa-program" component={ISAAgreement} redirect="/radius-assist?womensCouncil=1" /> */}

        {/* Community Routes */}
        <SidebarRoute path="/:id/feed" component={ShareFeed} location={location} />
        <SidebarRoute path="/community/all-network-activity" exact component={AllActivities} requireAuth/>
        <SidebarRoute path="/community/people-you-may-know" exact component={AllPeopleYouMayKnow} requireAuth />
        <SidebarRoute path="/community/people-to-follow" exact component={AllInfluencersToFollow} requireAuth />
        <SidebarRoute path="/community" component={Network} requireAuth />

        {/* Login */}
        <PublicRoute path="/old-login" component={LoginPage} location={location} />
        {/* SignUp */}
        {/* <PublicRoute path="/signup" component={SignUpPage} location={location} isOnlyPublic /> */}
        <PublicRoute path="/forgot" component={ForgotPage} location={location} isOnlyPublic />
        <PublicRoute exact path="/set-password" component={SetPassword} location={location} isOnlyPublic />
        <PublicRoute path="/set-password/:email" component={SetPassword} location={location} isOnlyPublic />

        <Route path="/page/redirect" component={RedirectEngine} location={location} />
        <PublicRoute path="/claim" component={ClaimProfile} isOnlyPublic />
        {/* <OnboardingRoute path="/onboarding" component={CitiesServered} requireAuth /> */}
        {/* Associations */}
        <PublicRoute path="/association/:association" component={AssociationsPage} isOnlyPublic />
        {/* Legal Pages */}
        <HeaderRoute path="/general-terms-of-use" component={GeneralTermsOfUse} headerVersion="v2" />
        <HeaderRoute path="/terms-and-conditions" component={GeneralTermsOfUse} headerVersion="v2" />
        <HeaderRoute path="/consumer-notice" component={ConsumerNotice} headerVersion="v2" />
        <HeaderRoute path="/information-about-brokerage-services" component={InformationAboutBrokerageServices} headerVersion="v2" />
        <HeaderRoute path="/affliated-business-arrangement-disclosure" component={AffliatedBusinessArrangementDisclosure} headerVersion="v2" />
        <HeaderRoute path="/ambassador-program-terms-and-conditions" component={RealtyTermsOfUse} headerVersion="v2" />
        <HeaderRoute path="/cashback-terms-and-conditions" component={CashbackTermsOfUse} headerVersion="v2" />
        <HeaderRoute path="/disclosures-and-licensing" component={DisclosuresAndLicensing} headerVersion="v2" />
        {/* <HeaderRoute path="/terms-and-conditions" component={ISATermsAndConditions} /> */}
        <HeaderRoute path="/privacy-policy" component={PrivacyPolicy} />
        {/* Home Page */}
        <HeaderRoute path="/agreement/redirect" component={RedirectPage} />
        <Redirect from="/broker" to="/realty" />
        {/* Plug-in for Blog */}
        <HeaderRoute path="/userredirect" component={RedirectionPage} location={location} headerVersion="v2"/>
        <Route path="/plugin/chat-floater" component={ChatFloaterPlugin} />
        <Redirect from="/network" to="/" />
        {/* <HeaderRoute path="/network" component={CommunityLanding} location={location} isOnlyPublic headerVersion="v2" /> */}
        <HeaderRoute path="/referral-invite" component={ReferralInvitePage} location={location} headerVersion="v2" />
        <HeaderRoute path="/legal" vwoVariation={variation} component={LegalDisclosures} location={location} isOnlyPublic headerVersion="v2" />
        <HeaderRoute path="/" vwoVariation={variation} component={NewHomePage} location={location} isOnlyPublic headerVersion="v2" />

        {/* <HeaderRoute path="/property/:MLS_ID/:Address" component={ListingPageLayout} /> */}
        {/* <HeaderRoute path="/" component={LandingPageNew} location={location} isOnlyPublic headerVersion="v2" /> */}
        {/* Default Fallback */}
        {/* <Redirect to="/community" /> */}
      </Switch>
    </Layout>
  )
}

export default Router
