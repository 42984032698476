import styled from "styled-components";
import colors from "@colors";
import { INTER } from "@fonts";

const HighlightsWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  width: 70%;
  justify-content: space-between;
  @media (max-width: 1024px) {
          margin-top: 12px;
        width: 105%;
        overflow: scroll;
        margin-right: 100px;
  }
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
`;

const HighlightsCard = styled.div`
  border: 1px solid ${colors.new_border_outline};
  width: 200px;
  border-radius: 12px;
  margin-right:12px;
  @media (max-width: 1024px) {
    width: 160px;
    margin-top: 12px;
  }
`;

const Heading = styled.div`
  color: ${colors.new_grey600};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
`;

const ListWrapper = styled.div`
  display: flex;
`;

const ListBullet = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: ${colors.new_colorNeutral200};
  margin-right: 8px;
`;

const ListPoints = styled.div`
  color: ${colors.new_grey600};
  font-family: ${INTER};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 16px;
    padding: 12px 0px;
    letter-spacing: 0.5px;
    width: 145px;
    display: flex;
    align-items: center;
`;

export {
  ListPoints,
  ListBullet,
  ListWrapper,
  Heading,
  HighlightsWrapper,
  HighlightsCard,
};
