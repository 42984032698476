import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import { get } from "lodash"
import { Link } from "react-router-dom"
import query from "query-string"
import md5 from "md5"
import container from "container/Auth"
import containerApp from "container/App"
import createToast from "@ui/Toast"
import firebase from "firebase/app"
import { initiatePendo } from "services/Utils"
import Button from "@ui/Button"
import Loader from "@ui/Loader"
import cookie from "services/CookieStorage"
import { handleDataLayerPush } from "services/dataLayerUtil"
import WithRegisterContainer from "dumbComponents/common/LoginSignUpHelpers/withRegisterContainer"
import SocialSignIn from "./SocialSignIn"
import EWebinarComponent from "dumbComponents/common/EWebinarScript"
import Input from "./UI/Input"
import FontAwesome from "./UI/FontAwesome"
import CookiesStorage from "services/CookieStorage"
import {
  defaultLoginForm,
  ShowHidePassword,
  formValidator as FormValidator,
  GetUserPlatform,
  getOnboardingNavigateRoute,
} from "./authUtils"
import colors from "@colors"

const META_DESCRIPTION = `Login to your Radius Agent account with your email and
 password. Engage with other agents, exchange referrals and access the Radius
 Assist ISA service.
`

const Wrapper = styled.div`
  padding-top: 0;
  padding-bottom: 25px;
`

const LoaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px auto;

  > span {
    margin-top: 20px;
    color: ${colors.contentText};
  }
`

const StyledFA = styled(FontAwesome)`
  float: right;
  position: absolute;
  top: 14px;
  right: 15px;
  font-size: 18px;
  color: #aaa;
  cursor: pointer;
`

export const CTAButton = styled(Button)`
  font-family: DM Sans;
  font-size: 16px;
  padding: 8px 12px;
  background: transparent;
  color: #11adf3;
  width: 300px;
`

const FormRelative = styled.div`
  position: relative;
`

const ForgotPassword = styled.div`
  display: inline-block;
  font-size: 12px;
  line-height: 1.31;
  padding: 5px;
  color: #354052;
  width: fit-content;
  text-decoration: underline;
  cursot: pointer;

  &:hover {
    color: #11adf3;
    text-decoration: underline;
  }
`

const LoginButtonWrapper = styled.div`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 20px;
`

const SignupLinkWrapper = styled.div`
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.black};
  margin-top: 15px;
`

const ForgotPasswordWrapper = styled.div`
  height: max-content;
  width: 100%;
  text-align: right;
  bottom: 12px;
  margin-right: 13px;

  a {
    color: ${colors.black};
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
  }
`

const SignupLink = styled.a`
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.black};
  text-decoration: underline;
  padding-left: 5px;
`

class LoginPage extends React.Component {
  state = {
    loginForm: null,
    formIsValid: false,
    isSubmitted: false,
  }

  componentDidMount() {
    // const {  } = this.props
    const { search } = root.location
    this.initLoginForm(search)
    cookie.checkForCookieUserObject()
    this.checkForAppleLoginStatus()
    const parsedQuery = query.parse(search)
    if (parsedQuery?.invite_type === "team_member_invite") {
      cookie.save("team_member_invite", {
        brokerage_team_id: parsedQuery.team_id,
        agent_id: parsedQuery.agent_id,
      })
    }
  }

  checkForAppleLoginStatus = () => {
    const { search } = root.location
    // const { history } = this.props
    const parsedQuery = query.parse(search)
    if (parsedQuery.login_status === "success" && parsedQuery.code) {
      this.setState({
        loginForm: null,
      })
      try {
        localStorage.setItem("socialLogin", JSON.stringify({
          code: parsedQuery.code,
          state: parsedQuery.state,
          type: "apple_login",
        }))
        this.handleAppleLogin({
          code: parsedQuery.code,
          state: parsedQuery.state,
          type: "apple_login",
        })
      } catch (error) {
        createToast("Oop! Something went wrong. Please try again", "Error")
      }
    }
  }

  handleAppleLogin = (data) => {
    const { appleSignup, history } = this.props
    appleSignup({
      payload: {
        code: data.code,
      },
      callback: (response) => {
        cookie.save("secureId", response.secure_id)
        cookie.saveUserToCookies(response.user)
        if (response.newUser) {
          cookie.save("newUser", response.newUser)
          history.push("/signup/social")
        } else if (
          !response.user.phone
          || !response.user.email
          || !response.user.firstname
          || !response.user.lastname
        ) {
          history.push("/signup/social")
        } else {
          setTimeout(() => {
            root.location.href = `${APP_URL}/community`
          }, 500)
        }
      },
    })
  }

  initLoginForm = (pathname) => {
    const initState = defaultLoginForm(pathname)
    this.setState({
      loginForm: initState,
    })
  }

  gotoLastView = (user) => {
    const isSubscribedToAnyISAPlan = get(user, "user.isSubscribedToAnyISAPlan")
    const isBroker = get(user, "user.isSubscribedToBrokerPlan")
    const teamRole = get(user, "user.teamRole")
    const academyLink = CookiesStorage.load("academy-link")
    const tcInformation = cookie.load("tcInformation")
    if (academyLink) {
      root.location.href = `/academy/course-details/${academyLink}`
    } else if (tcInformation) {
      root.location.href = "/realty/listings"
    } else {
      root.location.href = isBroker ? `${APP_URL}/realty/dashboard` : `${APP_URL}/community`
    }
  }

  inputChangedHandler = (event, inputIdentifier, data) => {
    let formObject = { ...this.state.loginForm }
    let loginForm = FormValidator(formObject, event, inputIdentifier, data)
    this.setState({
      loginForm: loginForm.formObject,
      formIsValid: loginForm.formIsValid,
    })
  }

  showHidePassword = (event, innerObject) => {
    this.setState({ loginForm: ShowHidePassword(event, innerObject, this.state.loginForm) })
  }

  handleSuccess = async (result) => {
    const { response } = result.data
    const {
      user: userData,
    } = response || {}

    
    const { history } = this.props
    const { parsedQuery } = this.state
    const { search } = root.location
    const { user } = result.data.response
    //get user id
    const agentId = user?.id || null
    //log google analytics
    firebase.analytics().setUserId(agentId)
    const emailId = user.email
    const isPartOfBrokerageTeam = user && parseInt(user.brokerageTeamId, 10) > 0 || user.isBrokerageTeamMember
    const userType = user.isSubscribedToBrokerPlan
      ? isPartOfBrokerageTeam ? "brokerage_team" : "brokerage_agent" : "network"

    //team lead
    // team member
    //   individual brokerage user

    if (root.dataLayer) {
      const payload = {
        login_method: "login",
      }
      handleDataLayerPush(userData, "login_success", "login_method", payload)
      // await root.dataLayer.push({
      //   event: "user_data",
      //   email: emailId,
      //   user_type: userType,
      // })
    }
    const userObject = {
      id: userData.id,
      email: userData.email,
      name: `${userData.firstname} ${userData.lastname}`,
      userType,
    }


    initiatePendo(userObject)

    root.gtag("set", "user_properties", {
      email: emailId,
      user_type: userType,
      event_callback: () => { console.log("User data set in GA") },
    })

    cookie.save("secureId", result.data.response.secure_id)
    if (result.data.response.newUser) {
      cookie.save("newUser", result.data.response.newUser)
    }

    // Saving Cookie user Object
    cookie.saveUserToCookies(user)
    if (cookie.load("redirectUrl")) {
      const redirectUrl = cookie.load("redirectUrl")
      cookie.remove("redirectUrl")
      window.location.href = `${APP_URL}${redirectUrl}`
      return
    }

    const isUserOnboarded = get(result, "data.response.user.isUserOnboarded")
    const isRealEstateAgent = get(result, "data.response.user.account_type") === "real_estate_agent"
    const onboardingQuizInfo = get(result, "data.response.user.onboardingQuizInfo")
    const citiesServed = get(result, "data.response.user.citiesServed")
    if (!isUserOnboarded && onboardingQuizInfo && onboardingQuizInfo.showOnboardingQuiz && isRealEstateAgent) {
      const onboardingRoute = getOnboardingNavigateRoute(onboardingQuizInfo.showStepKey)
      if (onboardingRoute) {
        history.push(onboardingRoute)
        return
      }
    }
    if (!(citiesServed && citiesServed.length) && isRealEstateAgent) {
      history.push("/onboarding/tell-about-yourself")
      return
    }
    if (GetUserPlatform() === "desktop") {
      if (search.indexOf("?") !== -1) {
        const results = search.split("?")
        const query_key = results[1].split("=")
        switch (query_key[0]) {
          case "id":
            window.location.href = NETWORK_URL + "sign-agreement/" + query_key[1]
            break
          case "viewEnvelopeId":
            window.location.href = NETWORK_URL + "view-agreement/" + query_key[1]
            break
          case "otherAgentId":
            window.location.href = NETWORK_URL + "referral/" + query_key[1]
            break
          default:
            this.gotoLastView(result.data.response)
            break
        }
      } else {
        this.gotoLastView(result.data.response)
      }
    } else {
      history.push("/page/redirect?tag=landing_page&params={}")
    }
  }

  handleLogin = (event) => {
    event.preventDefault()
    this.setState({ isSubmitted: true })
    const { loginUser } = this.props
    const {
      formIsValid,
      loginForm,
    } = this.state
    if (formIsValid) {
      loginUser({
        payload: {
          email: loginForm.email.value,
          password: md5(loginForm.password.value),
        },
        callback: this.handleSuccess,
      })
    }
  }

  render() {
    const { loginForm } = this.state
    const { vwoActiveVariant } = this.props
    const formElementsArray = []
    // const {  } = this.props
    for (let key in this.state.loginForm) {
      if (this.state.loginForm[key].fieldName.indexOf("Password") > -1) {
        formElementsArray.push({
          id: key,
          config: this.state.loginForm[key],
          eyeIcon: (
            <StyledFA
              onClick={(event) => this.showHidePassword(event, this.state.loginForm[key])}
              className={
                this.state.loginForm[key].elementConfig.type === "password" ? "fas fa-eye" : "fas fa-eye-slash"
              }
            />
          ),
        })
      } else {
        formElementsArray.push({
          id: key,
          config: this.state.loginForm[key],
        })
      }
    }

    const variantClass = vwoActiveVariant === "Blue" ? "blue_variant_log_in_form" : "white_variant_log_in_form"
    return (
      <>
        <WithRegisterContainer
          title=""
          subTitle="Welcome back!"
          metaTitle="Radius Agent Login | Access Your Account | Radius Agent"
          metaDescription={META_DESCRIPTION}
        >
          {loginForm ? (
            <Wrapper>
              <SocialSignIn login />
              <form className={variantClass} onSubmit={this.handleLogin}>
                {formElementsArray.map(formElement => (
                  <FormRelative key={formElement.id}>
                    <Input
                      key={formElement.id}
                      label={formElement.config.label}
                      elementType={formElement.config.elementType}
                      elementConfig={formElement.config.elementConfig}
                      value={formElement.config.value}
                      errorType={formElement.config.errorType}
                      fieldName={formElement.config.fieldName}
                      invalid={!formElement.config.valid}
                      shouldValidate={formElement.config.validation}
                      touched={formElement.config.touched}
                      submitted={this.state.isSubmitted}
                      changed={(event, data) => this.inputChangedHandler(event, formElement.id, data)}
                    />
                    {formElement.eyeIcon}
                  </FormRelative>
                ))}
                <LoginButtonWrapper>
                  <CTAButton className="login-button-hp">Login</CTAButton>
                </LoginButtonWrapper>

                <ForgotPasswordWrapper
                  onClick={() => {
                    handleDataLayerPush(null, "login_page_interactions", "forgot_password")
                  }}
                >
                  <Link to="/forgot/enteremail">
                    Forgot password?
                  </Link>
                </ForgotPasswordWrapper>

                <SignupLinkWrapper
                  onClick={() => {
                    handleDataLayerPush(null, "login_page_interactions", "signup")
                  }}
                >
                  <p>
                    {"Don't have an account?"}
                  </p>
                  <Link to="/signup">
                    <SignupLink>Sign Up</SignupLink>
                  </Link>
                </SignupLinkWrapper>
              </form>
            </Wrapper>
          ) : (
            <LoaderWrap>
              <Loader top={0} bottom={0} />
              <span>Loading...</span>
            </LoaderWrap>
          )}
        </WithRegisterContainer>
        <EWebinarComponent />
      </>
    )
  }
}

export default containerApp(container(LoginPage))
