import { handleDeltaReducer, createDeltaReducer } from "shared/reduxUtils"
import * as RealtyActions from "./actions"

const initState = {
  currentState: "",
  teamSize: 2,
  isAgentType: true,
  isFreshChatPopUphidden: true,
  currentRARPlanType: "Professional",
  currentRARPlanSelected: "",
  isRARModalOpen: false,
  isResourceFilterOpen: false,
  filterMap: [],
  currentResourcePaginationLimit: 10,
  currentResourcePaginationSkip: 0,
  isInitialSearch: false,
  currentResourceQuery: "",
  isSuccessModalToggled: false,
  ...createDeltaReducer("newLetterResponse"),
  ...createDeltaReducer("scheduleACallResponse"),
  ...createDeltaReducer("brokerageFeatureViewedResponse"),
  ...createDeltaReducer("rarDashboardUpcomingEvents"),
  ...createDeltaReducer("rarGetQuotesResponse"),
  ...createDeltaReducer("getSupportAgentsResponse"),
  ...createDeltaReducer("getBrokerageSupportResponse"),
  ...createDeltaReducer("initiateSupportClickResponse"),
  ...createDeltaReducer("initiateUpgradeClickResponse"),
  ...createDeltaReducer("initiateTCClickResponse"),
  ...createDeltaReducer("getCurrentPlanDetailsResponse"),
  ...createDeltaReducer("getResourceFiltersResponse"),
  ...createDeltaReducer("fetchResourceDocumentsResponse"),
  ...createDeltaReducer("triggerSlackNotificationResponse"),
  ...createDeltaReducer("rarResourcesRequestResponse"),
  ...createDeltaReducer("eventCalenderResponse"),
  ...createDeltaReducer("getAllInviteeDetailsResponse"),
  ...createDeltaReducer("getCashbackProgramResponse"),
}

export default function navbarReducer(state = initState, action) {
  switch (action.type) {
    case RealtyActions.submitNewsLetterAction.REQUEST:
    case RealtyActions.submitNewsLetterAction.FAILED:
    case RealtyActions.submitNewsLetterAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.submitNewsLetterAction,
          "newLetterResponse"
        ),
      }
    }

    case RealtyActions.getRoomCalendarEventsAction.REQUEST:
    case RealtyActions.getRoomCalendarEventsAction.FAILED:
    case RealtyActions.getRoomCalendarEventsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getRoomCalendarEventsAction,
          "eventCalenderResponse"
        ),
      }
    }

    case RealtyActions.brokerageFeatureViewedAction.REQUEST:
    case RealtyActions.brokerageFeatureViewedAction.FAILED:
    case RealtyActions.brokerageFeatureViewedAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.brokerageFeatureViewedAction,
          "brokerageFeatureViewedResponse"
        ),
      }
    }

    case RealtyActions.getCommunityUpcomingEventsForRARDboardAction.REQUEST:
    case RealtyActions.getCommunityUpcomingEventsForRARDboardAction.FAILED:
    case RealtyActions.getCommunityUpcomingEventsForRARDboardAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getCommunityUpcomingEventsForRARDboardAction,
          "rarDashboardUpcomingEvents"
        ),
      }
    }

    case RealtyActions.submitScheduleACallAction.REQUEST:
    case RealtyActions.submitScheduleACallAction.FAILED:
    case RealtyActions.submitScheduleACallAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.submitScheduleACallAction,
          "scheduleACallResponse"
        ),
      }
    }

    case RealtyActions.getQuotesAction.REQUEST:
    case RealtyActions.getQuotesAction.FAILED:
    case RealtyActions.getQuotesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getQuotesAction,
          "rarGetQuotesResponse"
        ),
      }
    }

    case RealtyActions.getAgentSupportListAction.REQUEST:
    case RealtyActions.getAgentSupportListAction.FAILED:
    case RealtyActions.getAgentSupportListAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getAgentSupportListAction,
          "getSupportAgentsResponse"
        ),
      }
    }

    case RealtyActions.getBrokerageSupportAction.REQUEST:
    case RealtyActions.getBrokerageSupportAction.FAILED:
    case RealtyActions.getBrokerageSupportAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getBrokerageSupportAction,
          "getBrokerageSupportResponse"
        ),
      }
    }

    case RealtyActions.initiateSupportClickAction.REQUEST:
    case RealtyActions.initiateSupportClickAction.FAILED:
    case RealtyActions.initiateSupportClickAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.initiateSupportClickAction,
          "initiateSupportClickResponse"
        ),
      }
    }

    case RealtyActions.initiateUpgradeClickAction.REQUEST:
    case RealtyActions.initiateUpgradeClickAction.FAILED:
    case RealtyActions.initiateUpgradeClickAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.initiateUpgradeClickAction,
          "initiateUpgradeClickResponse"
        ),
      }
    }

    case RealtyActions.initiateTransactionCoordinationClickAction.REQUEST:
    case RealtyActions.initiateTransactionCoordinationClickAction.FAILED:
    case RealtyActions.initiateTransactionCoordinationClickAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.initiateTransactionCoordinationClickAction,
          "initiateTCClickResponse"
        ),
      }
    }

    case RealtyActions.getCurrentPlanDetailsAction.REQUEST:
    case RealtyActions.getCurrentPlanDetailsAction.FAILED:
    case RealtyActions.getCurrentPlanDetailsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getCurrentPlanDetailsAction,
          "getCurrentPlanDetailsResponse"
        ),
      }
    }

    case RealtyActions.fetchResourceFiltersAction.REQUEST:
    case RealtyActions.fetchResourceFiltersAction.FAILED:
    case RealtyActions.fetchResourceFiltersAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.fetchResourceFiltersAction,
          "getResourceFiltersResponse"
        ),
      }
    }

    case RealtyActions.fetchResourceDocumentsAction.REQUEST:
    case RealtyActions.fetchResourceDocumentsAction.FAILED:
    case RealtyActions.fetchResourceDocumentsAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.fetchResourceDocumentsAction,
          "fetchResourceDocumentsResponse"
        ),
      }
    }

    case RealtyActions.triggerSlackNotificationAction.REQUEST:
    case RealtyActions.triggerSlackNotificationAction.FAILED:
    case RealtyActions.triggerSlackNotificationAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.triggerSlackNotificationAction,
          "triggerSlackNotificationResponse"
        ),
      }
    }

    case RealtyActions.setActiveNavbarSectionAction.type: {
      return {
        ...state,
        activeTab: action.data,
      }
    }

    // case RealtyActions.toggleVideoModalAction.type: {
    //   return {
    //     ...state,
    //     modalVideoURL: action.data,
    //   }
    // }

    case RealtyActions.toggleHubSpotModalAction.type: {
      return {
        ...state,
        modalHubSpot: action.data,
      }
    }

    case RealtyActions.setCurrentLocationAction.type: {
      const {
        currentState,
        currentCountry,
        currentCity,
        addressTextBoxValue,
      } = action.data
      return {
        ...state,
        currentState,
        currentCountry,
        currentCity,
        addressTextBoxValue,
      }
    }

    case RealtyActions.togglePlanTypeAction.type: {
      const {
        isAgentType,
      } = action.data
      return {
        ...state,
        isAgentType,
      }
    }

    case RealtyActions.showReferralManagerV2Action.type: {
      return {
        ...state,
        isFreshChatPopUphidden: action.data,
      }
    }

    case RealtyActions.storeRARPlanSelectedAction.type: {
      return {
        ...state,
        currentRARPlanSelected: action.data,
      }
    }

    case RealtyActions.storeTeamSizeAction.type: {
      return {
        ...state,
        teamSize: action.data,
      }
    }

    case RealtyActions.toggleFilterComponentAction.type: {
      return {
        ...state,
        isResourceFilterOpen: action.data,
      }
    }

    case RealtyActions.rarFormModalToggleAction.type: {
      return {
        ...state,
        isRARModalOpen: action.data,
      }
    }

    case RealtyActions.createFilterMapAction.type: {
      return {
        ...state,
        filterMap: action.data,
      }
    }

    case RealtyActions.updateFilterMapAction.type: {
      return {
        ...state,
        filterMap: action.data,
      }
    }

    case RealtyActions.setResourcesPaginationLimitAction.type: {
      return {
        ...state,
        currentResourcePaginationLimit: action.data,
      }
    }

    case RealtyActions.setResourcesPaginationSkipAction.type: {
      return {
        ...state,
        currentResourcePaginationSkip: action.data,
      }
    }

    case RealtyActions.toggleInitialSearchAction.type: {
      return {
        ...state,
        isInitialSearch: action.data,
      }
    }

    case RealtyActions.setCurrentFetchResourceSearchQueryAction.type: {
      return {
        ...state,
        currentResourceQuery: action.data,
      }
    }

    case RealtyActions.getRarResourcesAction.REQUEST:
    case RealtyActions.getRarResourcesAction.FAILED:
    case RealtyActions.getRarResourcesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getRarResourcesAction,
          "rarResourcesRequestResponse"
        ),
      }
    }

    case RealtyActions.getAllInviteesAction.REQUEST:
    case RealtyActions.getAllInviteesAction.FAILED:
    case RealtyActions.getAllInviteesAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getAllInviteesAction,
          "getAllInviteeDetailsResponse"
        ),
      }
    }

    case RealtyActions.toggleSuccessModalAction.type: {
      return {
        ...state,
        isSuccessModalToggled: action.data,
      }
    }

    case RealtyActions.getCashbackProgramAction.REQUEST:
    case RealtyActions.getCashbackProgramAction.FAILED:
    case RealtyActions.getCashbackProgramAction.SUCCESS: {
      return {
        ...state,
        ...handleDeltaReducer(
          state,
          action,
          RealtyActions.getCashbackProgramAction,
          "getCashbackProgramResponse"
        ),
      }
    }

    default:
      return state
  }
}
