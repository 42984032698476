import { isInboundReferrals, isCRS } from "services/Utils"

export const columnConfigList = [
  {
    display_name: "New Client/Accepted",
    type: "Accepted Leads",
    value: "New Client/Accepted",
    textColor: "#4e676a",
    backgroundColor: "#DBF494",
    progressPercentage: 25,
    checklist: true,
    className: "new_leads",
  },
  {
    display_name: "Met with Client",
    type: "Met with Client Leads",
    value: "Met with Client",
    textColor: "#00ce7d",
    backgroundColor: "#FFED9F",
    progressPercentage: 50,
    checklist: false,
    className: "met_with_client",
  },
  {
    display_name: "Pre-approved/Listing Prep",
    type: "Pre-approved/Listing Prepped",
    value: "Pre-approved/Listing Prepped",
    textColor: "#00ce7d",
    backgroundColor: "rgb(158 206 179)",
    progressPercentage: 50,
    checklist: false,
    className: "pre_approved_listing_prep",
  },
  {
    display_name: "Showings/Tours",
    type: "Showings/Tours Leads",
    value: "Showings/Tours",
    textColor: "#066752",
    backgroundColor: "#F6D7FF",
    progressPercentage: 75,
    checklist: true,
    className: "showing_tour",
  },
  {
    display_name: "Sending/Receiving Offers",
    type: "Sending/Receiving Offers Leads",
    value: "Sending/Receiving Offers",
    textColor: "#066752",
    backgroundColor: "#C3EEFF",
    progressPercentage: 100,
    checklist: false,
    className: "send_rec_offers",
  },
  {
    display_name: "In Contract",
    type: "In Contract Leads",
    value: "In Contract",
    textColor: "#918c77",
    backgroundColor: "#FFCEE1",
    progressPercentage: 0,
    checklist: false,
    className: "in_contract",
  },
  {
    display_name: "Closed",
    type: "Closed Leads",
    value: "Closed",
    textColor: "#918c77",
    backgroundColor: "#D9CAFF",
    progressPercentage: 0,
    checklist: false,
    className: "closed_lead",
  },
]

export const columnConfig = () => {
  if (isInboundReferrals() && isCRS()) {
    const PENDING = [{
      display_name: "PENDING",
      type: "Inbound Referral Status",
      value: "Pending",
      textColor: "#4e676a",
      backgroundColor: "#b2ebf2",
      progressPercentage: 0,
      checklist: true,
      className: "pending",
    }]
    return [
      ...PENDING,
      ...columnConfigList,
    ]
  }
  return [
    ...columnConfigList,
  ]
}

export default columnConfig
