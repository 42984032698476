import React, { useState } from "react"
import styled from "styled-components"
import { ATC } from "@fonts"
import OnboardingContainer from "container/OnboardingSetup"
import WelcomeToRadiusAgent from "../Images/WelcomeToRadiusAgent.jpg"
import CloseIcon from "../Images/CrossIcon.png"
import Button from "@ui/LoaderButton"

const Wrap = styled.div`
  position: fixed;
  width: 614px;
  height: max-content;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background: #F9F9F7;
  border: 1px solid #E5E5E5;
  left: calc(50% - 614px/2);
  top: calc(50% - 410px/2 + 0.42px);
  overflow: hidden;
`
const ImageDiv = styled.div`
  width: 614px;
  height: 172px;
  position: relative;
`

const TextDiv = styled.div`
  width: 100%;
  padding: 24px;
`
const Title = styled.div`
  padding: 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 28px;
  // line-height: 32px;
  color: #303030;
`
const Subtitle = styled.h3`
  margin-top: 9px;
  padding: 0px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  // line-height: 17px;
  color: #303030;
`
const CrossIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 11px;
  z-index: 99;
  cursor: pointer;
`
const GetStartedButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  color: white;
  width: 564px;
  margin-top: 27px;
  background: #3B60E4;
  border-radius: 36px;
  border: 0px;
  cursor: pointer;
`
const SaveForLater = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #8C8C8C;
  cursor: pointer;
`

const GettingStarted = ({ formStage, response, setFormStage, setIsGoBack, createOrientationInfo }) => {

  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setIsGoBack(true)
    setFormStage("0")
  }

  const continueToForm = ({ setFormStage}) => {
    const payload = { step_completed: "get_started" }
    setIsLoading(true)
    createOrientationInfo({ payload, setStage: "1", isGettingStarted: true })
    // setFormStage("1")
  }

  return (
    <Wrap>
      <ImageDiv>
        <CrossIcon onClick={handleClose}>
          <img src={CloseIcon} alt="Close Icon" />
        </CrossIcon>
        <img src={WelcomeToRadiusAgent} alt="Welcome Image" />
      </ImageDiv>
      <TextDiv>
        <Title>Welcome to Radius Agent!</Title>
        <Subtitle>Let’s get started on providing some information to help us process your commissions for your upcoming transactions.</Subtitle>
        <GetStartedButton
          isShowLoader={isLoading}
          onClick={continueToForm}>Get Started</GetStartedButton>
        <SaveForLater onClick={handleClose}>Remind me Later</SaveForLater>
      </TextDiv>
    </Wrap>
  )
}

export default OnboardingContainer(GettingStarted)
