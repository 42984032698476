import STATES from "shared/US_STATES.json"

export const REPRESENTING = [{
  label: "Buyer",
  value: "buyer",
}, {
  label: "Seller",
  value: "seller",
}, {
  label: "Landlord",
  value: "landlord",
}, {
  label: "Tenant",
  value: "tenant",
}, {
  label: "Dual",
  value: "dual",
}, {
  label: "Referral",
  value: "referral",
}]

const SELLER_OPTIONS = [{
  label: "Listing with accepted offer",
  value: "listing_with_accepted_offer",
}, {
  label: "Dual",
  value: "dual",
}, {
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}]

const BUYER_OPTIONS = [{
  label: "Pending Contract",
  value: "pending_contract",
}, {
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}, {
  label: "Dual",
  value: "dual",
}]

const LANDLORD_OPTIONS = [{
  label: "Lease Listing",
  value: "lease_listing",
}, {
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}]

const TENANT_OPTIONS = [{
  label: "Pending Lease",
  value: "pending_lease",
}, {
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}]

const DUAL_OPTIONS = [{
  label: "Dual",
  value: "dual",
}]

const OTHER_OPTIONS = [{
  label: "External Referral Agreement",
  value: "external_referral_agreement",
}]

export const CLIENT_FILE_MAP = {
  seller: SELLER_OPTIONS,
  buyer: BUYER_OPTIONS,
  landlord: LANDLORD_OPTIONS,
  tenant: TENANT_OPTIONS,
  dual: DUAL_OPTIONS,
  other: OTHER_OPTIONS,
  referral: OTHER_OPTIONS,
}

export const CLIENT_SOURCE = [{
  label: "Radius Marketplace",
  value: "radius_marketplace",
}, {
  label: "Zillow (Agent's Personal Account)",
  value: "zillow_agent_personal_account",
}, {
  label: "Zillow (Radius Provided)",
  value: "zillow_radius_provided",
}, {
  label: "Zillow Flex",
  value: "zillow_flex",
}, {
  label: "OpCity/Realtor.com",
  value: "opcity_realtor_com",
}, {
  label: "Team/Mentor Lead",
  value: "team_mentor_lead",
}, {
  label: "Self",
  value: "self",
}, {
  label: "Referrals (Agent/Lender)",
  value: "referrals_agent_Lender",
},
{ label: "Sphere of Influence/Personal", 
  value: "sphere_of_influence" }, 
{
  label: "Others",
  value: "other",
}]

export const RENT_INTERVAL_TYPE = [{
  label: "Month",
  value: "month",
}, {
  label: "Annual",
  value: "annual",
}, {
  label: "Other",
  value: "other",
}]

export const COMMISSION_TYPE = [{
  label: "Percentage",
  value: "percentage",
}, {
  label: "Fixed Fee",
  value: "fixed_fee",
}, {
  label: "Both",
  value: "both",
}]

export const TRANSACTION_TYPE = [{
  label: "Standard Residential",
  value: "residential_1_4",
}, {
  label: "Residential Income Property",
  value: "residential_inc",
}, {
  label: "Vacant Land",
  value: "vacant_land",
}, {
  label: "Manufactured/Mobile Home",
  value: "manufactured_mo",
}, {
  label: "New Construction",
  value: "new_construction",
}, {
  label: "Lease",
  value: "lease",
}, {
  label: "Short Sales",
  value: "short_sales",
}, {
  label: "Relocation",
  value: "relocation",
}, {
  label: "Referral Agreement",
  value: "referral_agreem",
}, {
  label: "Condominium",
  value: "condominium",
}, {
  label: "Fame & Ranch",
  value: "farm_and_ranch",
}, {
  label: "Commercial",
  value: "commercial",
}, {
  label: "Other",
  value: "other",
}]

export const STATE_OPTIONS = Object.keys(STATES).map(key => ({ label: STATES[key], value: STATES[key] }))
