import React, { useState, useEffect } from 'react'
import colors from '@colors'
import CRMV2Container from 'container/CRM'
import {
  Form,
  Select,
  Space,
  NewGoogleAutocompleteSingle,
  NewInputNumber,
  NewRadioGroup,
  NewRadioButton,
  NewSelect,
  NewButton
} from '@ui/antd'
import { get } from 'lodash'
import { Add } from 'dumbComponents/CRMV2/CRMDashboard/Components/Icons'
import { MinusCircleOutlined } from '@ant-design/icons'
import { LeadSourceWrap, SearchCriteriaHeaderWrap, StyledLabel } from '../../common'
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common"

const { Item, List } = Form
const { Option } = Select

const PropertyRequirement = ({
  formRef,
  setCityFormValue,
  cityFormValue,
  uploadImage,
  uploadToS3Response,
  setFileList,
  fileList,
  getPropertyTypeResponse,
  getPropertyTypes
}) => {
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }
  useEffect(() => {
    getPropertyTypes()
  }, [])

  const { data: propertyTypes } = getPropertyTypeResponse || {}
  const { types } = propertyTypes || {}

  const { isFetching: isUploadingFile, data: uploadData } = uploadToS3Response || {}

  // const onPreview = () => {
  //   if (isUploadingFile !== true) {
  //     if (uploadData) {
  //       const object1 = Object.values(uploadData)
  //       object1.map((x) => {
  //         root.open(Object.values(x)[0])
  //       })
  //     }
  //     if (allListingImages) {
  //       allListingImages.map((x) => {
  //         root.open(x.url)
  //       })
  //     }
  //   }
  // }

  const [count, setCount] = useState(0)
  const uploadImages = async (file) => {
    setCount(count + 1)
    await uploadImage({
      file,
      objName: `image${count}`,
      fileName: file.name,
    })
  }

  const [leadSource, setLeadSource] = useState("")
  const onLeadSourceSelect = (value) => {
    setLeadSource(value)
  }
  const [transactionType, setTransactionType] = useState("")
  const onTransactionTypeChange = (value) => {
    setTransactionType(value)
    formRef.current.setFieldsValue({ transactionType: value })
  }

  const [metric, setMetric] = useState("")
  const onMetricChange = (value) => {
    setMetric(value)
    formRef.current.setFieldsValue({ metric: value })
  }

  const setStreet = (location, key) => {
    const locationDetails = {
      city: get(location, "locality.long_name")|| "" ,
      state: get(location, "administrative_area_level_1.long_name") || "",
      stateShortName: get(location, "administrative_area_level_1.short_name") || "",
      country: get(location, "country.long_name") || "",
      latitude: get(location, "lat")|| "",
      longitude: get(location, "lng")|| "",
      // address: get(location, "address"),
      streetName: get(location, "street_number.short_name", ""),
      route: get(location, "route.long_name", ""),
      zipcode: get(location, "postal_code.long_name","")
    }
    console.log("locationDetails",locationDetails)
    locationDetails.address = `${locationDetails.streetName} ${locationDetails.route}`

    const currentFieldValues = formRef.current.getFieldsValue()
    let neighborhood = null
    if (location.neighborhood) {
      neighborhood = location.neighborhood.long_name
    } else if (location.route) {
      neighborhood = location.route.long_name
    } else if (location.postal_code) {
      neighborhood = location.postal_code.long_name
    } else if (location.sublocality_level_1) {
      neighborhood = location.sublocality_level_1.long_name
    }
    if (locationDetails.address) {
      currentFieldValues.property_details[key].listing_address = `${locationDetails.address}, ${locationDetails.city}`
    } else if (neighborhood) {
      currentFieldValues.property_details[key].listing_address = `${neighborhood}, ${locationDetails.city}, ${locationDetails.state}`
    } else {
      currentFieldValues.property_details[key].listing_address = `${locationDetails.city}, ${locationDetails.state}`
    }

    formRef.current.setFieldsValue({ ...currentFieldValues })
    const temp = [...cityFormValue]
    temp[key] = locationDetails
    setCityFormValue(temp)
  }
  return (
    <LeadSourceWrap width="100%">
      <List name="property_details">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <>
                <Space key={key} direction="vertical" size={16}>
                  <SearchCriteriaHeaderWrap>
                    <h2>Property Listing</h2>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </SearchCriteriaHeaderWrap>
                  {/* <UploadImage
                    action={uploadImages}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    // onPreview={onPreview}
                    disabled={isUploadingFile === true}
                  >
                    {fileList.length < 20 && "+ Upload"}
                  </UploadImage> */}
                  <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '8px' }}>
                    <StyledLabel>Transaction Type*</StyledLabel>
                    <Item
                      name={[name, 'sale_type']}
                      rules={[{ required: true }]}
                      style={{ marginBottom: 0 }}
                    >
                      <NewRadioGroup
                        onChange={onTransactionTypeChange}
                        value={transactionType}
                        size="small"
                      >
                        <NewRadioButton value="resale">Sale</NewRadioButton>
                        <NewRadioButton value="rent">Rent</NewRadioButton>
                      </NewRadioGroup>
                    </Item>
                  </div>
                  <Space key={key} align="baseline">
                    <Item
                      {...restField}
                      name={[name, 'no_of_bed_room']}
                      rules={[{ required: false, message: 'Missing', type: 'number' }]}
                      style={{ marginBottom: 0 }}
                      label={<LabelComponent text="Beds" />}
                    >
                      <NewInputNumber min={1} placeholder="Beds" />
                    </Item>
                    <Item
                      {...restField}
                      name={[name, 'no_of_bath_room']}
                      rules={[{ required: false, message: 'Missing', type: 'number' }]}
                      style={{ marginBottom: 0 }}
                      label={<LabelComponent text="Baths" />}
                    >
                      <NewInputNumber min={1} placeholder="Baths" />
                    </Item>
                  </Space>
                  <Item
                    {...restField}
                    name={[name, 'listing_address']}
                    rules={[{ required: true, message: 'Missing', }]}
                    style={{ marginBottom: 0 }}
                    label={<LabelComponent text="Search Address*" />}
                  >
                    <NewGoogleAutocompleteSingle
                      id="cities"
                      types={['address']}
                      name="cities"
                      // formValue={cityFormValue}
                      onChange={e => {}}
                      location={loc => {
                        setStreet(loc, key)
                      }}
                      placeholder="Search Address*"
                      restrict={{
                        lat: 37.09024,
                        lng: -95.712891,
                        south_west: {
                          lat: 25.82,
                            lng: -124.39,
                        },
                        north_east: {
                          lat: 49.38,
                            lng: -66.94,
                        },
                          address: "United States",
                        country: {
                            long_name: "United States",
                            short_name: "US",
                            types: [
                              "country",
                              "political",
                            ],
                          },
                      }}
                    />
                  </Item>
                  <Item
                    {...restField}
                    name={[name, 'resale_price']}
                    rules={[{ required: true, message: 'Only numbers allowed' }]}
                    style={{ marginBottom: 0 }}
                     label={<LabelComponent text="Estimated Price*" />}
                  >
                    <NewInputNumber
                      formatter={value =>
                        value ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                      }
                      controls={false}
                      placeholder="Estimated Price*"
                    />
                  </Item>
                  <Item
                    {...restField}
                    name={[name, 'covered_area']}
                    rules={[{ required: false, message: 'Only numbers allowed' }]}
                    style={{ marginBottom: 0 }}
                     label={<LabelComponent text="Property Size" />}
                  >
                    <NewInputNumber
                      formatter={value =>
                        value ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
                      }
                      controls={false}
                      placeholder="Property Size"
                    />
                  </Item>
                  <Item
                    name={[name, 'area_coverage']}
                    rules={[{ required: false }]}
                    style={{ marginBottom: 0 }}
                  >
                    <NewRadioGroup onChange={onMetricChange} value={metric} size="small">
                      <NewRadioButton value="Sq.ft">Sq. ft</NewRadioButton>
                      <NewRadioButton value="Sq.mt">Sq.mt</NewRadioButton>
                      <NewRadioButton value="Acres">Acres</NewRadioButton>
                    </NewRadioGroup>
                  </Item>
                  <Item
                    name={[name, 'property_type']}
                    rules={[{ required: false }]}
                    style={{ marginBottom: '16px' }}
                    label={<LabelComponent text="Property Type" />}
                  >
                    <NewSelect placeholder="Property Type...">
                      {types &&
                        types.map(type => (
                          <Option value={type.value} key={type.value}>
                            {type.value}
                          </Option>
                        ))}
                    </NewSelect>
                  </Item>
                  {/* <Item name={[name, "status"]} label={<LabelComponent text="Listing Status" />} rules={[{ required: true }]}>
                      <Select onChange={(value) => { onLeadSourceSelect(value) }} placeholder="Select...">
                        <Option value="seller">Seller</Option>
                        <Option value="buyer">Buyer</Option>
                      </Select>
                    </Item> */}
                </Space>
              </>
            ))}

            <NewButton
              type="primary"
              onClick={() => {
                add()
              }}
              icon={<Add color={colors.new_buttonText} />}
              iconPosition="start"
            >
              Add Listing
            </NewButton>
          </>
        )}
      </List>
    </LeadSourceWrap>
  )
}

export default CRMV2Container(PropertyRequirement)
