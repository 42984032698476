import React from "react";
import { Form } from "@ui/antd";
import { LabelComponent } from "dumbComponents/CRMV2/CRMDashboard/Components/common";
import { Wrap, SelectFieldWrap } from "./styles";

const SelectField = ({
  placeholder,
  options,
  style,
  arrowIcon,
  isFormField,
  required,
  defaultValue,
  name,
  onChange,
  text,
}) => {
  // Define validation rules based on `required` prop
  const rules = required
    ? [{ required: true, message: "Required field" }]
    : [];

  const getSelectField = () => {
    if (isFormField) {
      return (
        <Form.Item
          name={name}
          rules={rules}
           label={<LabelComponent text={`${text}`} required={required} />}
          initialValue={defaultValue}
        >
          <SelectFieldWrap
            placeholder={placeholder || "Select"}
            options={options}
            style={style}
            suffixIcon={arrowIcon}
            isFormField
            name={name}
            onChange={onChange}
          />
        </Form.Item>
      );
    } else {
      return (
        <Wrap>
          <SelectFieldWrap
            placeholder={placeholder}
            options={options}
            style={style}
            suffixIcon={arrowIcon}
            defaultValue={defaultValue}
            onChange={onChange}
          />
        </Wrap>
      );
    }
  };

  return <>{getSelectField()}</>;
};

export default SelectField;
