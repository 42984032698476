/* eslint-disable max-len */
import React, { useState } from "react"
import styled from "styled-components"
import LoaderButton from "@ui/LoaderButton"
import { withRouter } from "react-router-dom"
import {
  TeamMemberSelect,
} from "dumbComponents/common/InputFields"
import Listings from "container/Listings/index"
import {
  Header,
  Content,
  ContentContainer,
  ParaWrap,
} from "./styles"

const Wrap = styled.div`
  height: 300px;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 0 20px;
`

const MLSModal = ({
  updateCurrentListingData,
  history,
  togglePropertyFormOrCardView,
  toggleListingCreationModal,
  toClose,
}) => {

  const handleMemberSelectChange = (id) => {
    updateCurrentListingData({
      dealId: null,
      editingAgentId: id,
      transactionId: null,
      listingStatus: "incomplete",
      propertyData: null,
      agents: null,
      sellers: null,
      listingTerms: null,
      propertyDataErrorCount: null,
      agentsDataErrorCount: null,
      sellersDataErrorCount: null,
      listingTermsErrorCount: null,
    })
  }

  return (
    <Wrap>
      <>
        <ParaWrap>
          <Header>
            <h1>
              Create Listing for
            </h1>
          </Header>
          <ContentContainer>
            <Content>
              Please choose an agent for the listing
            </Content>
          </ContentContainer>
        </ParaWrap>
        <ParaWrap
          padding="0px 20px 40px 20px"
        >
          <TeamMemberSelect
            onChange={handleMemberSelectChange}
          />
        </ParaWrap>
        <ButtonWrap>
          <LoaderButton
            bsStyle="marineBLueOutlined"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={toClose}
          >
            Cancel
          </LoaderButton>
          <LoaderButton
            bsStyle="marineBLueFill"
            padding="16px"
            margin="0 5px"
            width="50%"
            borderRadius="50px"
            loaderOption={{
              color: "white",
            }}
            onClick={() => {
              togglePropertyFormOrCardView(false)
              toggleListingCreationModal(false)
              history.push("/listings/form")
            }}
          >
            Create Listing
          </LoaderButton>
        </ButtonWrap>
      </>
    </Wrap>
  )
}

export default withRouter(Listings(MLSModal))
