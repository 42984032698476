/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react"
import { get } from "lodash"
import root from "window-or-global"
import styled from "styled-components"
import md5 from "md5"
import Button from "@ui/Button"
import axios from "axios"
import colors from "@colors"
import cookie from "services/CookieStorage"
import createToast from "@ui/Toast"
import queryString from "query-string"
import { withRouter, Link } from "react-router-dom"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import { preTabletWidth } from "shared/consts"
import {
  formValidator as FormValidator,
  ShowHidePassword,
} from "dumbComponents/Auth/authUtils"
import FontAwesome from "dumbComponents/Auth/UI/FontAwesome"
import Input from "dumbComponents/Auth/UI/Input"
import PhoneNumber from "dumbComponents/Auth/UI/PhoneNumber"
import { ALPHA_NUM_SPL_8LEN } from "services/formUtils"
import ConfirmSignInModal from "./ConfirmSignInModal"
import { ATC } from "@fonts"

const SignUpBox = styled.div`
  text-align: center;
  margin-bottom: 25px;
  font-family: ${ATC};
  margin-bottom: 25px;
  font-family: ${ATC};
  display: flex;
  justify-content: center;
  gap: 10px;

  ${Button}{
    background: ${colors.marineBLue};
    color: ${colors.white};
    cursor: pointer;
    box-shadow: none;
    border: none;
    width: 180px;
    padding: 15px;
    height: 50px;
  }
`

const ErrorText = styled.small`
  font-family: 'DM Sans', sans-serif;
  display: flex;
  margin-top: 3px;
  line-height: 1.75;
  text-align: left;
  color: #f99fa2;
  font-size: 12px;
  padding-bottom: 15px;
`

const FormElement = styled.form`
  @media(max-width: 1099px) {
    padding: 57px;
    margin-top: -30px;
  }

  ${preTabletWidth`
    padding: 30px;
  `}
`

const FormRelative = styled.div`
  position: relative;
`

const StyledFA = styled(FontAwesome)`
  float: right;
  position: absolute;
  top: 14px;
  right: 15px;
  font-size: 18px;
  color: #aaa;
  cursor: pointer;
`

const AgreementWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const AgreementText = styled.p`
  font-family: ${ATC};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${colors.marineBLue};
  }
`

const PhoneWrapper = styled.div`
  margin-bottom: 40px;
  padding: 0 3px;
`

const RedirectToCommunity = styled(Link)`
  text-decoration: none;
`

const BackButton = styled(Button)`
  background: ${colors.marineBLue};
  border: none;
  align-items: baseline;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 150px! important;
  display: flex;
  justify-content: center;
  i {
    display: inline-block;
    position: relative;
    transform: rotate(180deg);
  }
`

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

class SignUpForm extends Component {
  constructor(props) {
    super(props)
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const emailId = parsedQuery.email_id || ""

    this.state = {
      roomId: get(this.props, "match.params.roomId"),
      roomName: get(this.props, "match.params.name"),
      signupForm: {
        firstname: {
          elementName: "name",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "John",
          },
          value: "",
          fieldName: "First name",
          label: "Your name",
          validation: {
            required: true,
            isAlphaNumeric: true,
          },
          valid: false,
          touched: true,
        },
        email: {
          elementName: "email",
          elementType: "input",
          elementConfig: {
            type: "email",
            placeholder: "you@domain.com",
          },
          value: emailId || "",
          fieldName: "Email",
          label: "Email",
          validation: {
            required: true,
            isEmail: true,
          },
          valid: false,
          touched: true,
        },
        password: {
          elementName: "password",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "•••••••",
          },
          value: "",
          fieldName: "Password",
          label: "Password",
          validation: {
            required: true,
            // isPasswordValid: false
          },
          valid: false,
          touched: true,
        },
      },
      pageLoaded: false,
      formIsValid: false,
      isSubmitted: false,
      isSaving: false,
      showPasswordError: false,
    }
  }

  componentDidMount() {
    this.setState({
      pageLoaded: true
    })
  }

  inputChangedHandler = (event, inputIdentifier, data,) => {
    const {
      signupForm,
    } = this.state

    const formObject = { ...signupForm }
    const signupFormObject = FormValidator(formObject, event, inputIdentifier, data)
    this.setState({
      signupForm: signupFormObject.formObject,
      formIsValid: signupFormObject.formIsValid,
    })
  }

  showHidePassword = (event, innerObject) => {
    const {
      signupForm,
    } = this.state

    this.setState({ signupForm: ShowHidePassword(event, innerObject, signupForm) })
  }

  createAccount = (event) => {
    if (root.heap) {
      root.heap.track("Click_Sign_Up_CTA_Video_Room")
    }
    event.preventDefault()
    const {
      signupForm,
      formIsValid,
      countryCode,
      phone,
      roomId,
      roomName,
    } = this.state

    const {
      toggleConfirmSignInModal,
    } = this.props

    const {
      firstname,
      email,
      password,
    } = signupForm

    this.setState({ isSubmitted: true })

    const splitName = firstname.value.split(" ")
    const firstName = get(splitName, "[0]", "Radius")
    const lastName = get(splitName, "[1]", "")

    const data = {
      username: {
        firstname: firstName,
        lastname: lastName,
      },
      email: email.value,
      password: md5(password.value),
      signup_source: "event_rooms",
      phone,
    }

    const isMobile = root.innerWidth <= 767

    if (formIsValid) {
      if (phone.length >= 10) {
        if (ALPHA_NUM_SPL_8LEN.test(password.value)) {
          this.setState({ isSaving: true })
          axios.post(`${API_V2_URL}user/sign-up`, data).then(
            (result) => {
              const {
                data: {
                  response,
                },
              } = result || {}

              const {
                user: userDetail,
              } = response || {}

              const user = userDetail

              // Inspectlet and rootscope profile image portion pending
              cookie.save("secureId", result.data.response.secure_id)
              if (result.data.response.newUser) {
                cookie.save("newUser", result.data.response.newUser)
              }
              // Saving Cookie user Object
              cookie.saveUserToCookies(user)

              // identify the user
              if (window.heap) {
                window.heap.identify(get(user, "id"))
              }


              axios
                .post(
                  `${API_V2_URL}appDownloadSms`,
                  { phone: countryCode + phone.length > 10 ? phone.substr(1, phone.length) : phone },
                  { headers: { "secure-id": result.data.response.secure_id } },
                )
                .then(
                  () => {
                    this.setState({ isSaving: false })
                    //redirect
                    root.location.href = `/scheduledrooms/${roomId}/${roomName}?redirectToRSVP=true`
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            },
            (error) => {
              const {
                data: datum,
              } = error || {}

              if (datum.error.message === "Email already exists") {
                toggleConfirmSignInModal({
                  showConfirmationModal: true,
                  email: email.value,
                })
              } else {
                createToast(`${datum.error.message}`, "error")
              }
              this.setState({ isSaving: false })
            },
          )
        } else {
          this.setState({ showPasswordError: true })
        }
      } else {
        this.setState({ showErrorState: true })
      }
    }
  }

  onPhoneNumberChange = (event) => {
    const {
      target: {
        rawValue,
        rawValue: {
          length,
        },
      },
    } = event
    const errorLength = length <= 10
    this.setState({
      phone: rawValue,
      showErrorState: errorLength,
    })
  }

  render() {
    const formElementsArray = []
    const {
      isSaving,
      isSubmitted,
      showPasswordError,
      pageLoaded,
      showErrorState,
      roomId,
      roomName,
    } = this.state
    const {
      isConfirmSignInModalOpen,
      toggleConfirmSignInModal,
    } = this.props
    const { showConfirmationModal, email } = isConfirmSignInModalOpen
    let passErrorState = ""
    let errorState = ""

    for (const key in this.state.signupForm) {
      if (this.state.signupForm[key].fieldName.indexOf("Password") > -1) {
        formElementsArray.push({
          id: key,
          config: this.state.signupForm[key],
          eyeIcon: (
            <StyledFA
              onClick={event => this.showHidePassword(event, this.state.signupForm[key])}
              className={
                this.state.signupForm[key].elementConfig.type === "password" ? "fas fa-eye" : "fas fa-eye-slash"
              }
            />
          ),
        })
      } else {
        formElementsArray.push({
          id: key,
          config: this.state.signupForm[key],
        })
      }
    }

    if (isSubmitted && showErrorState) {
      errorState = <ErrorText>Phone number is required</ErrorText>
    }

    if (isSubmitted && showPasswordError) {
      passErrorState = `
      Password must be alpha-numeric, must have a special character and must be at-least 8 characters long.`
    }
    return (
      <div>
        <FormElement onSubmit={this.createAccount}>
          {formElementsArray.map(formElement => (
            <FormRelative key={formElement.id}>
              <Input
                id={formElement.elementID || ""}
                key={formElement.id}
                label={formElement.config.label}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                errorType={formElement.config.errorType}
                fieldName={formElement.config.fieldName}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                submitted={isSubmitted}
                required={formElement.config.validation.required}
                changed={(event, data) => this.inputChangedHandler(
                  event,
                  formElement.id,
                  data,
                  formElement.elementType,
                )}
                options={formElement.config.options}
                passErrorState={passErrorState}
              />
              {formElement.eyeIcon}
            </FormRelative>
          ))}
          {pageLoaded && (
            <PhoneWrapper>
              <PhoneNumber
                placeholder="xxx xxx xxxx"
                onChange={this.onPhoneNumberChange}
                label="Phone Number"
                value={this.state.phone}
                required
              />
              {errorState}
            </PhoneWrapper>
          )}
          <SignUpBox>
            {/* <RedirectToCommunity to="/login">
              <BackButton>
                <span>Login</span>
              </BackButton>
            </RedirectToCommunity> */}
            <Button
              width="100%"
              type="submit"
              bsStyle="primary"
              disabled={isSaving}
              boxShadow
            >
              {isSaving ? (
                <ThreeDotLoader color="#fff" />
              ) : "Sign up & RSVP"}
            </Button>
          </SignUpBox>
        </FormElement>
        <AgreementWrap>
          <AgreementText negativeMt>
            Already have an account?
            {" "}
            <a
              onClick={() => {
                if (root.heap) {
                  root.heap.track("Click_Log_In_CTA")
                }
                cookie.save("redirectUrl", `/scheduledrooms/${roomId}/${roomName}?redirectToRSVP=true`)
              }}
              href="/signup"
            >
              Log In
            </a>
          </AgreementText>
          <AgreementText>
            By clicking Sign up, I agree to the
            {" "}
            <span>
              <a
                onClick={() => {
                  if (root.heap) {
                    root.heap.track("Click_Terms_And_Policy_CTA")
                  }
                }}
                href="/terms-and-conditions"
              >
                Terms of Service and Privacy Policy
              </a>
            </span>
            .
          </AgreementText>
        </AgreementWrap>
        {showConfirmationModal && (
          <ConfirmSignInModal
            toClose={() => toggleConfirmSignInModal(false)}
            show={showConfirmationModal}
            email={email}
            redirectUrl={`/scheduledrooms/${roomId}/${roomName}?redirectToRSVP=true`}
          />
        )}
      </div>
    )
  }
}

export default withRouter(SignUpForm)
