import React, { useEffect, useState } from "react"
import styled from "styled-components"
import colors from "@colors"
import moment from "moment"
import icons from "@icons"
import root from "window-or-global"
import cookie from "services/CookieStorage"
import { toast as createToast } from "react-toastify"
import TechAssetsContainer from "container/TechAssets"
import ListingsContainer from "container/Listings"
import { Link, withRouter } from "react-router-dom"
import { USCurrencyFormat } from "shared/currencyUtils"
import { format } from "date-fns"
import CircularImage from "dumbComponents/common/CircularImage"
import OffersV2 from "container/OffersV2"
import Button from "@ui/Button"
import {
  unixToDate,
} from "services/Utils"
import { get } from "lodash"
import { Table } from "@ui/antd"
import { ATC } from "@fonts"
import {
  BASIC_FORM_ROUTE,
  DETAILS_FORM_ROUTE,
  COORDINATION_FORM_ROUTE,
  ESCROW_FORM_ROUTE,
  SIGN_POST_FORM_ROUTE,
  UPLOAD_FILE_FORM_ROUTE,
} from "../../Routes"

const toastConfig = {
  position: "bottom-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "video-room-notifications",
}

const Wrap = styled.div`
  height: max-content;
  width: 100%;
  margin-bottom: 50px;
  // border-top-right-radius: 16px;
  // border-top-left-radius: 16px;
  // border: 1px solid #D3DAE8;
  // overflow: hidden;
  cursor: pointer;
`

const HeaderWrap = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  background: ${colors.lightGrayishBlue};
`

const DivBox = styled.div`
  flex-grow: 1;
  max-width: ${props => props.width || "25%"};
  padding: 16px;

  ${props => props.pd24 && `
    padding: 16px 16px 16px 24px;
  `}

  ${props => props.Left5Pd && `
    padding: 16px 16px 16px 11px;
  `}

  ${props => props.Left1Pd && `
    padding: 16px 16px 16px 1px;
  `}

 @media(min-width: 1440px) {
   max-width: 25%;
 }
`

const TableValue = styled.p`
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: ${props => props.textAlign || "left"};
  color: ${colors.black};
  max-width: 200px;

  ${props => props.forcedCenter && `
    position: relative;
    left: -18px;
  `}

  ${props => props.name && `
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: 0px;
   text-align: left;
   color: ${colors.black};
   position: relative;

     i {
      position: relative;
      left: 10px;
      top: 2px;
     }
  `}

  ${props => props.date && `
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: 0px;
   text-align: left;
   color: ${colors.black};
   position: relative;
   top: 14px;
  `}

  ${props => props.email && `
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #8C8C8C;
  `}

  ${props => props.phone && `
   font-size: 16px;
   font-style: normal;
   font-weight: 400;
   line-height: 20px;
   letter-spacing: 0px;
   text-align: left;
   color: ${colors.black};
  `}

  ${props => props.paddingLeft24px && `
     padding-left: 24px;
   `}

   ${props => props.customLeftPadding && `
      padding-left: ${props.customLeftPadding};
    `}
`
const ResultsWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: start;
  border-bottom: 1px solid ${colors.silentWhite};

  ${props => props.isLast && `
    border-bottom: none;
  `}

  &:hover {
   background: whitesmoke;
  }
`

const Result = styled.div`
  flex-grow: ${props => props.flexG || "1"};
  max-width: ${props => props.width || "25%"};
  padding: 16px;

  ${props => props.name && `
    padding: 16px 16px 16px 0px;
  `}

  ${props => props.flex && `
    display: flex;
    flex-direction: column;
  `}

  ${props => props.customLeftPadding && `
    padding: 16px 16px 16px ${props.customLeftPadding || "0px"};
  `}
`

const PaddingWrap = styled.div`

`

const Tag = styled.div`
  height: 16px;
  width: max-content;
  border-radius: 4px;
  padding: 4px;
  background: ${colors.moonGrey};
  color: ${colors.white};
  font-family: DM Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 8px;
  letter-spacing: 1px;
  text-align: center;
  position: relative;
  top: 14px;

  ${props => props.completed && `
    background: #60CA4E;
  `}

  ${props => props.verified && `
    background: #105c04;
  `}

`
const TimelineText = styled.h4`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-left: 28px;
  text-decoration-line: underline;
  color: #3B60E4;
  cursor: pointer;
`

const ClientText = styled.h4`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-left: 28px;
  ${props => props.clickable && `
    cursor: pointer;
    color: #3B60E4;
  `}
`

const DetailFlexWrap = styled.div`
 display: flex;
 flex-direction: column;
`

const TableRowValue = styled.div`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  p {
    margin: 2px 0;
  }
  a {
    color: ${colors.marineBLue};
    font-size: 14px;
    padding-top: 15px;
  }
  ${props => props.color && `
    color: ${props.color};
  `}
`

const DetailsWithImage = styled.div`
  border-top: 1px solid #ebebeb;
  padding: 10px 0 0 0;
  margin: 15px 0 0 0;
  h3 {
    font-family: ${ATC};
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
`

const StatusTag = styled.span`
  background: #58C896;
  padding: 4px 6px;
  white-space: nowrap;
  border-radius: 10px;
  font-size: 8px;
  color: white;
  letter-spacing: 1px;
`

const DATE_FORMAT = "MM/DD/YYYY"

const ClientWrap = styled.span`
  padding: 4px 6px;
  width: 43px;
  height: 16px;
  background: #F1F3F8;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 8px;
  font-family: ${ATC};
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 5px;
`

const Listing = ({
  history,
  deals,
  setDealToView,
  getGoogleAuthToken,
  setEditingDealTeamMemberId,
  setListingId,
  clearExistingData,
  setCurrentListingTab,
  updateCurrentListingData,
  updateListingTabDisabledStatus,
  performNecessaryActionsBeforeLoadingListing,
  toggleActiveTab,
  setOfferIdToView,
  setStateForOffer,
  setCurrentListingsState,
  setListingAddress,
}) => {
  useEffect(() => {
    getGoogleAuthToken()
  }, [])
  // if (dealsExist) {
  //   legacyDeals = fetchedDeals.filter(deal => deal.file_type === null
  //    || deal.file_type === undefined)
  //   fetchedDeals = legacyDeals ? [...fetchedDeals, ...legacyDeals] : fetchedDeals
  // }

  const [timelineRow, setTimelineRow] = useState(false)

  const isCompleted = stageArray => stageArray && stageArray.includes("submitted")
  const getIsEdit = deal => deal && !(deal.step === "commission_verified" || deal.step === "closed" || deal.step === "payment_received")

  const getTransactionId = (record) => {
    const primaryClient = record?.clients?.find(dt => dt.is_primary === "Y" || dt.is_primary === 1)
    return primaryClient?.transaction_id
  }

  const getClientId = (record) => {
    const primaryClient = record?.clients?.find(dt => dt.is_primary === "Y" || dt.is_primary === 1)
    return primaryClient?.client_id
  }

  const getClientType = (client_type) => {
    if (["landlord", "seller", "dual", "Rental owner"].includes(client_type)) {
      return "seller"
    }
    if (client_type === "other") {
      return "other"
    }
    return "buyer"
  }

  const getStatusLabel = (stageArray, step) => {
    if (step === "audit_verified") {
      return "AUDIT VERIFIED"
    }
    if (step === "incomplete") {
      return "INCOMPLETE"
    }
    if (step === "closed") {
      return "CLOSED"
    }
    if (step === "active_listing") {
      return "ACTIVE LISTING"
    }
    if (step === "listing_verified") {
      return "LISTING VERIFIED"
    }
    if (step === "submitted") {
      return "CONTRACT SUBMITTED"
    }
    if (step === "demand_pending") {
      return "DEMAND PENDING"
    }
    if (step === "demand_completed") {
      return "DEMAND COMPLETED"
    }
    if (step === "payment_received") {
      return "COMMISSION FINALIZED"
    }
    if (step === "contract_expired") {
      return "CONTRACT EXPIRED"
    }
    if (step === "contract_canceled") {
      return "CONTRACT CANCELED"
    }
    if (step === "contract_withdrawn") {
      return "CONTRACT WITHDRAWN"
    }
    if (step === "commission_verified") {
      return "COMMISSION VERIFIED"
    }

    if (step === "gross_commission_received") {
      return "GROSS COMMISSION RECIEVED"
    }

    if (step === "agent_commission_initiated") {
      return "AGENT COMMISSION INITIATED"
    }

    if (stageArray.includes("audit_verified")) {
      return "AUDIT VERIFIED"
    }
    if (stageArray.includes("commission_verified")) {
      return "COMMISSION VERIFIED"
    }

    if (stageArray.includes("demand_pending")) {
      return "DEMAND PENDING"
    }
    if (stageArray.includes("demand_completed")) {
      return "DEMAND COMPLETED"
    }
    if (stageArray.includes("payment_received")) {
      return "COMMISSION FINALIZED"
    }

    if (stageArray.includes("submitted")) {
      return "CONTRACT SUBMITTED"
    }

    if (stageArray.includes("gross_commission_received")) {
      return "GROSS COMMISSION RECIEVED"
    }

    if (stageArray.includes === "agent_commission_initiated") {
      return "AGENT COMMISSION INITIATED"
    }

    return "INCOMPLETE"
  }

  const COLUMNS = [
    {
      title: "Address",
      width: 150,
      dataIndex: "listing_address",
      key: "listing_address",
      // fixed: "left",
      render: (_, record) => {
        return (
          <div>
            <TableRowValue>
              <p>
                {record.listing_address || "Incomplete Property Information"}
                {" "}
                <ClientWrap>{record.client_type}</ClientWrap>
              </p>
              <p>{`${record.state || ""} ${record.zipcode || ""}`}</p>
              <p><StatusTag>{getStatusLabel(record && record.completed_steps || [], record.step)}</StatusTag></p>
              {record.commission_demand_url && (
                <p>
                  <a
                    href={record.commission_demand_url}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    target="_blank"
                  >
                    View Commission Demand
                  </a>
                </p>
              )}
              <p>
                <DetailsWithImage
                  id="created_by_agent"
                  onClick={() => {
                    root.open(record.profile_url)
                  }}
                >
                  <CircularImage
                    id="created_by_agent"
                    name={`${record.agent_firstname || ""} ${record.agent_lastname || ""}`}
                    size={45}
                    charSize={28}
                    image={record.agent_image || ""}
                  />
                </DetailsWithImage>
              </p>
            </TableRowValue>
          </div>
        )
      },
    },
    {
      title: "Agent Commission",
      width: 100,
      dataIndex: "agent_commission",
      key: "agent_commission",
      render: (_, record) => (
        <TableRowValue color="#3DC789">
          {USCurrencyFormat(record.agent_commission || 0, 1, false, true)}
        </TableRowValue>
      ),
    },
    {
      title: "Gross Commission",
      width: 100,
      dataIndex: "gross_commission",
      key: "gross_commission",
      render: (_, record) => (
        <TableRowValue color="#EAA678">
          {USCurrencyFormat(record.gross_commission || 0, 1, false, true)}
        </TableRowValue>
      ),
    },
    {
      title: "Purchase Price",
      width: 100,
      dataIndex: "list_price",
      key: "list_price",
      render: (_, record) => (
        <TableRowValue color="#8192D9">
          {USCurrencyFormat(record.list_price || record.price || 0, 1, false, true)}
        </TableRowValue>
      ),
    },
    // {
    //   title: "Client Name",
    //   width: 100,
    //   dataIndex: "client_name",
    //   key: "client_name",
    //   render: (_, record) => {
    //     return (
    //       <TableRowValue>
    //         {record.client_name || " - "}
    //       </TableRowValue>
    //     )
    //   },
    // },
    // {
    //   title: "Agent Created",
    //   width: 100,
    //   dataIndex: "agent_firstname",
    //   key: "agent_firstname",
    //   render: (_, record) => {
    //     return (
    //       <TableRowValue>
    //         {`${record.agent_firstname} ${record.agent_lastname}`}
    //       </TableRowValue>
    //     )
    //   },
    // },
    {
      title: "Contract Date",
      width: 100,
      dataIndex: "row_created_at",
      key: "row_created_at",
      render: (_, record) => (
        <TableRowValue>
          {moment(record.deal_created_at).format(DATE_FORMAT)}
        </TableRowValue>
      ),
    },
    {
      title: "Closing Date",
      width: 100,
      dataIndex: "closed_on",
      key: "closed_on",
      render: (_, record) => (
        <TableRowValue>
          {record.closed_on ? format(record.closed_on * 1000, DATE_FORMAT) : " - "}
        </TableRowValue>
      ),
    },
    {
      title: "Clients",
      width: 100,
      dataIndex: "clients",
      key: "clients",
      render: (_, record) => (
        <TableRowValue>
          <ClientText
            id="created_by_agent"
            clickable={(!!getTransactionId(record)) && (getClientType(record.client_type) !== "other")}
            onClick={() => {
              if (getTransactionId(record) && (getClientType(record.client_type) !== "other")) {
                root.open(`${WEB_URL}crm?transactionId=${getTransactionId(record)}&client_id=${getClientId(record)}&type=${getClientType(record.client_type)}`)
              }
            }}
          >
            {`${record?.clients?.length ? record?.clients?.map(ct => ct.name).join(", ") : "N/A"}`}
          </ClientText>
        </TableRowValue>
      ),
    },
    // {
    //   title: "Timeline",
    //   width: 100,
    //   dataIndex: "timeline",
    //   key: "timeline",
    //   render: (_, record) => (
    //     <>
    //       {record.show_timeline === 1 ? (
    //         <TimelineText
    //           id="timeline"
    //           onClick={() => {
    //             history.push(`/realty/transaction-coordination/contract/${record.id}/timeline-checklist`)
    //           }}
    //         >
    //           View Timeline
    //         </TimelineText>
    //       ) : " - "}
    //     </>
    //   ),
    // },
    // {
    //   title: "Created By",
    //   width: 100,
    //   dataIndex: "agent_firstname",
    //   key: "agent_firstname",
    //   fixed: "right",
    //   render: (_, record) => (
    //     <TableRowValue id="created_by_agent">
    //       <DetailsWithImage
    //         id="created_by_agent"
    //         onClick={() => {
    //           root.open(record.profile_url)
    //         }}
    //       >
    //         <CircularImage
    //           id="created_by_agent"
    //           name={`${record.agent_firstname || ""} ${record.agent_lastname || ""}`}
    //           size={45}
    //           charSize={28}
    //           image={record.agent_image || ""}
    //           style={{ margin: "auto" }}
    //         />
    //         <h3 id="created_by_agent">
    //           {`${record.agent_firstname || ""} ${record.agent_lastname || ""}`}
    //         </h3>
    //       </DetailsWithImage>
    //     </TableRowValue>
    //   ),
    // },
  ]

  const rowClick = (record) => {
    console.log("row record",record)
    setOfferIdToView(null)
    const contractState = record.state
    if(record.listing_id && !record.is_auto_generated){
      clearExistingData()
      setListingId(record.listing_id)
      setListingAddress(record.listing_address)
      setCurrentListingsState(contractState.toLowerCase())
      record.active_contract_id = record.id
      // updateCurrentListingData({
      //       editingAgentId: record.agent_id,
      // })
      performNecessaryActionsBeforeLoadingListing({
        dealId: record.listing_id,
        record,
        history,
        suppressNotifications: true,
      })
      //setCurrentListingTab("contract_details")
      history.push(`/listings/form/${record.listing_id}`)
    } else if (record.offer_id && !record.is_auto_generated) {
      const dealId = record.offer_id
      setDealToView(dealId)
      setStateForOffer(contractState.toLowerCase())
      setListingAddress(record.listing_address)
      setEditingDealTeamMemberId(parseInt(record.primary_agent_id, 10))
      toggleActiveTab("contract_details")
      const {
        clients,
        deal_status,
        deal_id,
      } = record || {}
      if (clients && clients.length > 0) {
        const id = clients[0]?.transaction_id
        const url = `/realty/offers-v3/${dealId}?transaction_id=${id}&agent_id=${record.primary_agent_id}`
        const sendToContractPhase = deal_status === "submitted"
        history.push(`${url}${sendToContractPhase ? "&contract_phase=submitted" : ""}`)
      } else {
        history.push(`/realty/offers-v3/${dealId}`)
        // root.location.href = `${RADIUS_URL}/realty/offers-v3/${dealId}`
      }
    } else{
      setDealToView(record.id)
      setListingAddress(record.listing_address)
      const user = cookie.load("user") || {}
    const agentId = user.agent_id || user.id
    setEditingDealTeamMemberId(parseInt(record.agent_id, 10))
    if (record.step === "submitted") {
      const transactionId = record.clients && record.clients[0].transaction_id
      // const fileCreatedFromOffer = record.file_creation_flow === "offer"
      let url
      if(record.offer_id){
        setOfferIdToView(record.offer_id)
        setStateForOffer(contractState.toLowerCase())
        url = `/realty/transaction-coordination/contract/form/${record.id}?offerId=${record.offer_id}`
      } else {
        setListingId(record.listing_id)
        setCurrentListingsState(contractState.toLowerCase())
        url = `/realty/transaction-coordination/contract/form/${record.id}?listingId=${record.listing_id}`
      }
      history.push(url)
    } else {
      history.push("/realty/transaction-coordination/contract/form")
    }
    }
  }

  return (
    <Wrap>
      {deals && deals.length && (
        <Table
          style={{
            border: "1px solid #E5E5E5",
          }}
          columns={COLUMNS}
          dataSource={deals}
          scroll={{
            // x: 1500,
          }}
          onRow={(record, rowIndex) => ({
            onClick: (event) => {
              if (!["timeline", "created_by_agent"].includes(event.target.id)) {
                rowClick(record)
              }
            }, // click row
          })}
        />
      )}
      {/* <HeaderWrap>
        <DivBox pd24 width="236px">
          <TableValue>CLIENT DETAILS</TableValue>
        </DivBox>
        <DivBox Left5Pd>
          <TableValue>ADDRESS</TableValue>
        </DivBox>
        <DivBox>
          <TableValue>STATUS</TableValue>
        </DivBox>
        <DivBox Left1Pd>
          <TableValue>CREATED ON</TableValue>
        </DivBox>
      </HeaderWrap> */}
      {/* <PaddingWrap>
        {deals && deals.map(deal => (
          <ResultsWrap
            onClick={(e) => {
              if (e.target.tagName === "A") {
                return
              }
              const EscrowFormRoute = ["dual", "pending_contract", "external_referral_agreement"]
              const getNextStage = (step) => {
                switch (step) {
                  case "basic_info":
                    return DETAILS_FORM_ROUTE
                  case "transaction_details":
                    return COORDINATION_FORM_ROUTE
                  case "transaction_coordination":
                    if (EscrowFormRoute.includes(deal.file_type)) {
                      return ESCROW_FORM_ROUTE
                    }
                    if (deal.file_type === "new_listing") {
                      return SIGN_POST_FORM_ROUTE
                    }
                    return UPLOAD_FILE_FORM_ROUTE
                  case "sign_post_installation":
                  case "escrow_pre_escrow":
                    return UPLOAD_FILE_FORM_ROUTE
                  default:
                    return BASIC_FORM_ROUTE
                }
              }
              const lastStep = deal && deal.completed_steps && deal.completed_steps.length || 0
              const isEdit = getIsEdit(deal)
              if (isCompleted(get(deal, "completed_steps"))) {
                history.push(`${BASIC_FORM_ROUTE}?id=${deal.id}&isEdit=${isEdit}`)
              } else if (lastStep > 0) {
                history.push(`${getNextStage(deal.completed_steps[lastStep - 1])}?id=${deal.id}&isEdit=${isEdit}`)
              } else {
                history.push(`${BASIC_FORM_ROUTE}?id=${deal.id}&isEdit=${isEdit}`)
              }
            }}
          >
            {console.log("==deals===", getStatusLabel(deal && deal.completed_steps || [], deal.step))}
            <Result name flexG="3">
              <DetailFlexWrap>
                <TableValue name paddingLeft24px>
                  {get(deal, "clients[0].name", "")}
                  {getStatusLabel(deal && deal.completed_steps || []) !== "VERIFIED" && (
                    <i className={icons.genericEdit}></i>
                  )}
                </TableValue>
                <TableValue email paddingLeft24px>{get(deal, "clients[0].email", "")}</TableValue>
                <TableValue phone paddingLeft24px>
                  {`${get(deal, "clients[0].phone", "")}`}
                </TableValue>
                {(deal.clients !== "undefined"
                  && deal.clients !== null
                  && deal.clients !== undefined
                  && deal.clients.length >= 2) && (
                    <TableValue paddingLeft24px>
                      {`+${deal.clients.length - 1} client${deal.clients.length > 2 ? "s" : ""}`}
                    </TableValue>
                  )}
                {deal.commission_demand_url && (
                  <TableValue paddingLeft24px>
                    <a
                      href={deal.commission_demand_url}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      target="_blank"
                    >
                      View Commission Demand
                    </a>
                  </TableValue>
                )}
              </DetailFlexWrap>
            </Result>
            <Result name flexG="1">
              <DetailFlexWrap>
                <TableValue name customLeftPadding="8px">
                  {`${get(deal, "listing_address", "") !== null ? `${get(deal, "listing_address")},` : ""}
                  ${get(deal, "city", "") !== null ? `${get(deal, "city")},` : ""}
                  ${get(deal, "state", "") !== null ? `${get(deal, "state")}` : ""}`}
                </TableValue>
              </DetailFlexWrap>
            </Result>
            <Result flex flexG="1">
              <Tag
                verified={getStatusLabel(deal && deal.completed_steps || []) === "AUDIT VERIFIED" || getStatusLabel(deal && deal.completed_steps || []) === "COMMISSION VERIFIED" || getStatusLabel(deal && deal.completed_steps || []) === "VERIFIED"}
                completed={getStatusLabel(deal && deal.completed_steps || [], deal.step) === "CONTRACT SUBMITTED"}
              >
                {getStatusLabel(deal && deal.completed_steps || [], deal.step)}
              </Tag>
            </Result>
            <Result flexG="1" customLeftPadding="0px">
              {deal && (
                <TableValue date>
                  {format(get(deal, "deal_created_at"), DATE_FORMAT)}
                </TableValue>
              )}
            </Result>
          </ResultsWrap>
        ))}
      </PaddingWrap> */}
    </Wrap>
  )
}

export default withRouter(OffersV2(ListingsContainer(TechAssetsContainer(Listing))))
