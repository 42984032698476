import React from "react"
import CookiesStorage from "services/CookieStorage"
import root from "window-or-global"
import {
  TeamDetailsContainer, TeamImage, TeamNameLabel, TeamName,
} from "./commonStyles"

const TeamDetails = ({
  routeArray,
  checkIfTeamUpdatedByLead,
  teamDetails,
  toggleEditMode,
  thisAgentHasATeam,
  isBroker,
}) => (
  <>
    {teamDetails?.name?.length ? (
      <TeamDetailsContainer
        isActive={routeArray[1] === "team-profile" || routeArray[1] === "team_development"}
        to={
          isBroker && thisAgentHasATeam
            ? checkIfTeamUpdatedByLead(teamDetails) || CookiesStorage.load("teamProfileWelcomeDone")
              ? "/realty/team-profile/edit"
              : "/realty/team-profile"
            : "/realty/promos?type=team_development"
        }
        onClick={() => {
          root.scrollTo(0, 0)
          if (isBroker && thisAgentHasATeam) {
            if (root.heap) {
              root.heap.track("click_sidebar_teamProfileRAR_web")
            }

            toggleEditMode(true)
          }
        }}
      >
        {teamDetails?.icon_url?.length && (
          <TeamImage src={teamDetails.icon_url} alt={`${teamDetails.name}'s team icon`} />
        )}
        <div>
          <TeamNameLabel>Team name</TeamNameLabel>
          <TeamName>{teamDetails.name}</TeamName>
        </div>
      </TeamDetailsContainer>
    ) : (
      <></>
    )}
  </>
)

export default TeamDetails
