import React from "react"
import styled from "styled-components"
import App from "container/App"
import TechAssets from "container/TechAssets"
import { useRouter } from "next/router"
import root from "window-or-global"
import { xsMobile as mobile, tablet } from "@consts"
import Conversation from "images/Graphics/MortgageLandingPageAssets/Conversation.png"

const Wrap = styled.div`
  width: 100%;
  height: 215px;
  padding: 48px 72px 48px 72px;
  background: #306F4D;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  ${tablet(`
     height: 360px;
     padding: 48px 44px 48px 44px;
     justify-content: center;
  `)}


  ${mobile(`
     height: 360px;
     padding: 48px 72px 48px 44px;
  `)}

`

const Title = styled.h1`
  margin-top: 0;
  font-family: 'Butler';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 39px;
  /* identical to box height, or 117% */

  /* Typography/General/Light */

  color: #F9F9F7;

  @media(min-width: 1366px) {
    font-size: 28px;
    line-height: 49px;
  }
`

const CTAWrap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;

  ${tablet(`
     flex-direction: column;
  `)}

  ${mobile(`
     flex-direction: column;
  `)}
`

const Button = styled.button`
  font-family: ATC;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: white;

  border-radius: 3px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.22664px 16.1466px;
  border: none;
  cursor: pointer;

  width: max-content;
  height: 48px;

  background: #E5AC3D;
`

const InnerWrap = styled.div`
  ${props => props.hideOnMobile && `
    @media(max-width: 767px) {
      display: none;
    }
  `}
`

const ImageWrap = styled.img`

`

const ActionContainer = ({
  toggleMortgageForm,
  user,
}) => (
  <Wrap>
    <InnerWrap>
      <Title>
        Partner with a Radius Loan Officer today!
      </Title>
      <CTAWrap>
        <Button
          onClick={() => {
            root.open("https://loandeskmortgage.com", "_blank")
            const isPartOfBrokerageTeam = user
              && parseInt(user.brokerageTeamId, 10) > 0 || user.isBrokerageTeamMember
            const userType = user.isSubscribedToBrokerPlan
            ? isPartOfBrokerageTeam ? "client" : "client" : "community_member"
            const payload = {
              login_status: "logged_in",
              agent_id: user.id,
              agent_type: userType,
              ag_nm: `${user.firstname} ${user.lastname}`,
              ag_eml: user.email,
              event: "mortgage_meet_your_new_lo_partner_cta_clicked",
            }
            root.dataLayer.push(payload)
          }}
        >
          Meet your new LO partner
        </Button>
        <Button
          onClick={() => {
            // router.push("/enquiry?type=check")
            toggleMortgageForm(true)
            const isPartOfBrokerageTeam = user
              && parseInt(user.brokerageTeamId, 10) > 0 || user.isBrokerageTeamMember
            const userType = user.isSubscribedToBrokerPlan
            ? isPartOfBrokerageTeam ? "client" : "client" : "community_member"
            const payload = {
              login_status: "logged_in",
              agent_id: user.id,
              agent_type: userType,
              ag_nm: `${user.firstname} ${user.lastname}`,
              ag_eml: user.email,
              event: "mortgage_introduce_a_client_cta_clicked",
            }
            root.dataLayer.push(payload)
          }}
        >
          Introduce a client
        </Button>
      </CTAWrap>
    </InnerWrap>
    <InnerWrap hideOnMobile>
      <ImageWrap width={400} height={259} src={Conversation} alt="conversation" />
    </InnerWrap>
  </Wrap>
)

export default TechAssets(App(ActionContainer))
