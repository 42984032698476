/* eslint-disable no-unused-expressions */
import React, { useState } from "react"
import {
  NewSelect, Row, Col,
} from "@ui/antd"
import { formatAmount, commaSeperatedNumberUS } from "services/formUtils"
import moment from "moment"
import TechAssetsContainer from "container/TechAssets"
import ReferralContainer from "container/Referral"
import { withRouter } from "react-router-dom"
import CRMV2Container from "container/CRM"
import {
  ARCHIVE, IN_CONTRACT, CLOSED, SELLER, BUYER, SHOWING_TOURS, SENDING_RECEIVING_OFFERS, CONTRACT, OFFER, CLOSED_STATUS,
} from "../../../common"
import {
  PropertyCardContainer,
  PropertyCardDetailsContainer,
  PropertyCardAddress,
  PropertyCardBudget,
  PropertyCardSpecifications,
  StatusIndicator,
  StatusSelectContainer,
  StatusColors,
  PropertyCardLastUpdated,
  PropertyCardCta,
  PropertyCardLeftContainer,
  PropertyCardRightContainer,
  PropertyCardTabsContainer,
  PropertyCardTab,
} from "../../styles"

const SearchCriteriaInfoList = ({
  setCurrentDrawerPage,
  data,
  setCurrentTransactionDetails,
  updateClientTransaction,
  setCurrentTab,
  currentFilters,
  setModalOpenConfirm,
  setModalOpenView,
  getClientTransactionsById,
  toggleGoToContract,
  toggleArchiveLeadModal,
  getBrokerageTransactionStatus,
  setIsViewListing,
  setIsViewOffer,
  setIsAddListing,
  setIsAddOffer,
  setIsCreateWarning,
  setIsClosed,
  setIsEndContract,
  setIsViewOfferToContract,
  setIsViewListingToContract,
  setIsViewContract,
  setCannotConvertContract,
  setStatusValue,
  setClientID,
  toggleShowInContract,
}) => {
  const {
    requirement, client_id, id, type, status, brokerage_transaction_status,
    radius_transaction_type,
  } = data
  const {
    min_budget, max_budget, min_size, max_size, area_unit, req_id,
    types, bathroom, bedroom, updated_at, sale_type, locations,
  } = requirement || {}

  const {
    id: dealIdToSend, initiateTransaction, transactionType: transactionTypeToSend, status: statusToSend,
  } = brokerage_transaction_status

  const [currentStatusForIndicator, setCurrentStatusForIndicator] = useState(status)

  const getColor = currentStatus => StatusColors.find(cl => cl.label === currentStatus)?.value

  const handleChange = (value) => {
    if (value === "Archive") {
      toggleArchiveLeadModal(true)
    } else {
      const payload = {
        status: value,
        client_id,
        type,
      }
      updateClientTransaction({
        transactionId: id,
        payload,
        currentFilters,
      })
    }
  }

  return (
    <PropertyCardContainer
      onClick={() => {
        setCurrentTransactionDetails(data)
        getClientTransactionsById({ transactionId: id })
        setCurrentDrawerPage("search-criteria")
        getBrokerageTransactionStatus({ transactionId: id })
      }}
    >
      <PropertyCardDetailsContainer>
        <Row>
          <Col span={14}>
            <PropertyCardLeftContainer>
              {locations && locations.length > 0 && (
                <PropertyCardAddress>
                  {
                    locations[locations.length - 1].c_locality_name !== null
                    && locations[locations.length - 1].c_locality_name.includes("USA")
                      ? `${locations[locations.length - 1].c_locality_name} `
                      : `${locations[locations.length - 1].city}, 
                                  ${locations[locations.length - 1].state}`
                  }
                </PropertyCardAddress>
              )}

              <PropertyCardBudget>
                {min_budget && min_budget > 0 ? formatAmount(min_budget) : ""}
                {min_budget && max_budget > 0 ? " - " : ""}
                {max_budget && max_budget > 0 ? formatAmount(max_budget) : ""}
              </PropertyCardBudget>

              <PropertyCardSpecifications>
                {bedroom > 0 && (
                  `${bedroom} Beds`
                )}
                {bathroom > 0 && (
                  `, ${bathroom} Baths`
                )}
                {min_size > 0 && (
                  `, ${commaSeperatedNumberUS(min_size)}`
                )}
                {/* when both sizes are present */}
                {min_size > 0 && max_size > 0 && (
                  "-"
                )}
                {/* when min size is 0 */}
                {min_size === 0 && max_size !== 0 && (
                  ","
                )}
                {max_size > 0 && area_unit && (
                  `${commaSeperatedNumberUS(max_size)}`
                )}
                {(max_size > 0 || min_size > 0) && area_unit && (
                  ` ${area_unit}`
                )}
              </PropertyCardSpecifications>
            </PropertyCardLeftContainer>
          </Col>
          <Col span={10}>
            <PropertyCardRightContainer>
              <StatusSelectContainer>
                <StatusIndicator bg={getColor(currentStatusForIndicator || status)} />
                <NewSelect
                  defaultValue={status}
                  onChange={(value) => {
                    setStatusValue(value)
                    setCurrentStatusForIndicator(value)

                    if (value === ARCHIVE) {
                      handleChange(value)
                    } else if (initiateTransaction) {
                      if (SELLER.includes(type)) {
                        if (value === IN_CONTRACT || value === CLOSED) {
                          setIsEndContract(true)
                          toggleGoToContract({ isShowGoToContract: true })
                          return
                        }
                        setIsAddListing(true)
                        handleChange(value)
                      } else {
                        if (value === CLOSED) {
                          setStatusValue(IN_CONTRACT)
                          setIsClosed(true)
                          return
                        }
                        if (value === IN_CONTRACT) {
                          setIsEndContract(true)
                          toggleGoToContract({ isShowGoToContract: true })
                          return
                        }
                        setIsAddOffer(true)
                        setIsCreateWarning(true)
                        handleChange(value)
                      }
                    } else if (!initiateTransaction && ((value !== IN_CONTRACT && value !== CLOSED) || statusToSend === CLOSED_STATUS) && transactionTypeToSend === CONTRACT) {
                      // if (BUYER.includes(type) && transactionTypeToSend === CONTRACT && statusToSend === "submitted") {
                      //   setCannotConvertContract(true)
                      //   return
                      // }
                      if (SELLER.includes(type)) {
                        setIsViewListingToContract(true)
                      } else {
                        setIsViewOfferToContract(true)
                      }
                    } else if (transactionTypeToSend === CONTRACT && value === CLOSED) {
                      toggleShowInContract(true)
                      handleChange(value)
                    } else if (!initiateTransaction && value === IN_CONTRACT) {
                      setIsEndContract(true)
                      toggleGoToContract({ isShowGoToContract: true })
                    } else if (!initiateTransaction && value === CLOSED) {
                      setStatusValue(IN_CONTRACT)
                      setIsClosed(true)
                    } else {
                      handleChange(value)
                    }
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  bordered={false}
                  options={[
                    {
                      value: "New Client/Accepted",
                      label: "New Client/Accepted",
                    },
                    {
                      value: "Met with Client",
                      label: "Met with Client",
                    },
                    {
                      value: "Pre-approved/Listing Prepped",
                      label: "Pre-approved/Listing Prepped",
                    },
                    {
                      value: "Showings/Tours",
                      label: "Showings/Tours",
                    },
                    {
                      value: "Sending/Receiving Offers",
                      label: "Sending/Receiving Offers",
                    },
                    {
                      value: "In Contract",
                      label: "In Contract",
                    },
                    {
                      value: "Closed",
                      label: "Closed",
                    },
                    {
                      value: "Archive",
                      label: "Archive",
                    },
                  ]}
                  placement="bottomRight"
                  dropdownMatchSelectWidth={false}
                />
              </StatusSelectContainer>
              <PropertyCardLastUpdated>
                Last Updated At:
                {" "}
                {moment(updated_at * 1000).format("MM/DD/YYYY")}
              </PropertyCardLastUpdated>
              <>
                {(SELLER.includes(type)) && currentFilters && currentFilters.view_type === "view_mine" && (status !== IN_CONTRACT && status !== CLOSED) ? (
                  <>
                    {initiateTransaction ? (
                      <PropertyCardCta
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setIsAddListing(true)
                          setModalOpenConfirm(true)
                          setClientID({
                            deal_id: id,
                          })
                        }}
                      >
                        Add Listing
                      </PropertyCardCta>
                    ) : (
                      <>
                        {dealIdToSend && (
                          <PropertyCardCta
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setIsViewListing(true)
                              setModalOpenView(true)
                              setClientID({
                                deal_id: id,
                              })
                            }}
                          >
                            View Listing
                          </PropertyCardCta>
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </>
              <>
                {(BUYER.includes(type)) && currentFilters && currentFilters.view_type === "view_mine"
                  && ((status === SHOWING_TOURS || status === SENDING_RECEIVING_OFFERS)) ? (
                    <>
                      {initiateTransaction === true ? (
                        <PropertyCardCta
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setIsAddOffer(true)
                            setIsCreateWarning(true)
                            setModalOpenConfirm(true)
                            setClientID({
                              deal_id: id,
                            })
                          }}
                        >
                          Add Offer
                        </PropertyCardCta>
                      ) : (
                        <>
                          {dealIdToSend && transactionTypeToSend === OFFER && (
                            <PropertyCardCta
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                // setIsViewOffer(true)
                                // setModalOpenView(true)
                                // setClientID({
                                //   deal_id: id,
                                // })

                                setCurrentTransactionDetails(data)
                                getClientTransactionsById({ transactionId: id })
                                setCurrentDrawerPage("search-criteria")
                                getBrokerageTransactionStatus({ transactionId: id })
                                setCurrentTab("OFFERS")
                              }}
                            >
                              View Offers
                            </PropertyCardCta>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
              </>
              <>
                {currentFilters && currentFilters.view_type === "view_mine" ? (
                  <>
                    {dealIdToSend && transactionTypeToSend === CONTRACT && (
                      <PropertyCardCta
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setIsViewContract(true)
                          setModalOpenView(true)
                          setClientID({
                            deal_id: id,
                          })
                        }}
                      >
                        View Contract
                      </PropertyCardCta>
                    )}
                  </>
                ) : null}
              </>
            </PropertyCardRightContainer>
          </Col>
        </Row>
      </PropertyCardDetailsContainer>
      <PropertyCardTabsContainer>
        <PropertyCardTab
          onClick={() => {
            setCurrentTab("NOTES")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("search-criteria")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          Notes
        </PropertyCardTab>
        <PropertyCardTab
          onClick={() => {
            setCurrentTab("TIMELINE")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("search-criteria")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          Timeline
        </PropertyCardTab>
        <PropertyCardTab
          onClick={() => {
            setCurrentTab("REMINDERS")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("search-criteria")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          Reminders
        </PropertyCardTab>
        <PropertyCardTab
          onClick={() => {
            setCurrentTab("FINANCING")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("search-criteria")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          Financing Options
        </PropertyCardTab>
        <PropertyCardTab
          onClick={() => {
            setCurrentTab("OVERVIEW")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("search-criteria")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          Add Info
        </PropertyCardTab>
      </PropertyCardTabsContainer>
    </PropertyCardContainer>
  )
}

export default withRouter(TechAssetsContainer(ReferralContainer(CRMV2Container(SearchCriteriaInfoList))))
