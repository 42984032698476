import React, { useEffect } from "react"
import styled from "styled-components"
import CookiesStorage from "services/CookieStorage"
import { Route, Switch, withRouter } from "react-router-dom"
import { authCheck } from "services/auth"
import {
  GetStartedPage,
  EnterEmailPage,
  EmailSentPage,
  LetsConnectPage,
  DownloadApp,
} from "./Pages"
import {
  Navbar,
} from "../../ICAOnboarding/Components"

const Wrap = styled.div`
`

const SignupV2 = ({
  history,
}) => {
  useEffect(() => {
    const isLogged = authCheck()

    if (isLogged) {
      const user = CookiesStorage.load("user")
      if (user.firstname === "") {
        history.push(`/signup/lets-connect?email=${user.email}`)
      } else {
        history.push("/community")
      }
    }
  }, [])

  return (
    <Wrap>
      <Navbar />
      <Switch>
        <Route path="/signup" exact component={GetStartedPage} />
        <Route path="/signup/enter-email" exact component={EnterEmailPage} />
        <Route path="/signup/email-sent" exact component={EmailSentPage} />
        <Route path="/signup/lets-connect" exact component={LetsConnectPage} />
        <Route path="/signup/download-app" exact component={DownloadApp} />
      </Switch>
    </Wrap>
  )
}

export default withRouter(SignupV2)
