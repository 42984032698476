import React from "react"
import styled from "styled-components"
import { withRouter } from "react-router-dom"
import Button from "@ui/Button"
import Heading from "dumbComponents/common/Typography/Heading"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import Box from "@ui/Box"
import ISADetailedPlanCard from "./ISADetailedPlanCard"
import colors from "@colors"

const StyledStart = styled.div`
  background-color: #ffffff;
  border: solid 1px #ebeaea;
  padding: 2rem;
  padding-top: 120px;
  @media (max-width: 500px) {
    padding-top: 60px;
    border: none;
  }
`

const PricingWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
`

const BoxWrapper = styled(Box)`
  @media (max-width: 500px) {
    margin: 0 auto;
  }
`

const Anchor = styled.a`
  display: block;
  margin-bottom: 15px;
  font-size: 10px;
  color: ${colors.primaryColor};
`
const ChangePlansTypeWrap = styled.div`
  margin-top:50px;
`

const SingleUserCTA = styled(Button)`
   width:230px;
   height:42px;
   border: 1px solid black;
   background:white;
   border-top-left-radius: 100px;
   border-bottom-left-radius: 100px;
   border-top-right-radius: 0px;
   border-bottom-right-radius: 0px;
   color: ${colors.black};

   &:hover{
    background: ${colors.contentText};
    color: white;
    border: 1px solid black;
   }

   ${props => props.isSingleOrTeam && `
       background: ${colors.contentText};
       color:white;
    `}

    @media (max-width: 500px) {
     border-radius: 100px !important;
    }
`

const TeamUserCTA = styled(Button)`
   width:230px;
   height:42px;
   border: 1px solid black;
   background: ${colors.white};
   border-top-right-radius: 100px;
   border-bottom-right-radius: 100px;
   border-top-left-radius: 0px;
   border-bottom-left-radius: 0px;
   color: ${colors.black};

   &:hover{
    background: ${colors.contentText};
    color: ${colors.white};
    border: 1px solid ${colors.black};
   }

   ${props => !props.isSingleOrTeam && `
       background: ${colors.contentText};
       color: ${colors.white};
    `}

    @media (max-width: 500px) {
     margin-top: 2%;
     border-radius: 100px !important;
    }
`

const ConditionsApplyTeamOnly = styled.p`
  text-align: left;
  margin-left: 20%;
  position: relative;
  bottom: 17px;
  @media(max-width: 1680px) {
   margin-left: 16%;
  }
  @media(max-width: 1440px) {
   margin-left: 9%;
  }
  @media(max-width: 1280px) {
   text-align: left;
   margin-left: 52px;
   bottom: 22px;
  }

`
const SubscribeButton = styled(Button)`
  border: none;
  font-size: 18px;
  font-weight: 500;
  background-image: linear-gradient(101deg, #0ac4ba, #2bda8e);
  color: ${colors.white};
`

//TODO Deprecate this map safely
export const DEPRECATE_pricingPlansMap = {
  rtl_monthly_299: {
    id: "rtl_monthly_299",
    amount: 299,
    amountLabel: "$299.00",
    duration: 1,
    description: "1 month plan",
    product: "rtl",
    type: "single",
  },
  rtl_yearly_3588_v2: {
    id: "rtl_yearly_3588_v2",
    amount: 3588,
    amountLabel: "$309.00 x 12",
    duration: 12,
    description: "12 months plan",
    product: "rtl",
    type: "single",
  },
  rtl_six_months_1854: {
    id: "rtl_six_months_1854",
    amount: 1854,
    amountLabel: "$309.00 x 6",
    duration: 6,
    description: "6 months plan",
    product: "rtl",
    type: "single",
  },
  team_rtl_quarterly_admin_837: {
    id: "team_rtl_quarterly_admin_837",
    amount: 279,
    amountLabel: "$279.00 x 3",
    duration: 3,
    description: "3 months plan",
    product: "rtl",
    type: "team",
  },
  team_test_rtl_quarterly_admin_3: {
    id: "team_test_rtl_quarterly_admin_3",
    amount: 1,
    amountLabel: "$1.00 x 3",
    duration: 3,
    description: "3 months plan",
    product: "rtl",
    type: "team",
  },
  team_rtl_six_months_admin_1554: {
    id: "team_rtl_six_months_admin_1554",
    amount: 259,
    amountLabel: "$259.00 x 6",
    duration: 6,
    description: "6 months plan",
    product: "rtl",
    type: "team",
  },
  team_test_rtl_six_months_admin_6: {
    id: "team_test_rtl_six_months_admin_6",
    amount: 1,
    amountLabel: "$1.00 x 6",
    duration: 6,
    description: "6 months plan",
    product: "rtl",
    type: "team",
  },
  rtl_quarterly_987: {
    id: "rtl_quarterly_987",
    amount: 987,
    amountLabel: "$329.00 x 3",
    duration: 3,
    description: "3 months plan",
    product: "rtl",
    type: "single",
  },
  rtl_yearly_3588: {
    id: "rtl_yearly_3588",
    amount: 3588,
    amountLabel: "$299.00 x 12",
    duration: 12,
    description: "1 year plan",
    product: "rtl",
    type: "single",
  },
  rtl_six_months_1494: {
    id: "rtl_six_months_1494",
    amount: 1494,
    amountLabel: "$299.00 x 6",
    duration: 6,
    description: "6 month plan",
    undiscountedPrice: 1794,
    discount: 300,
    discountLabel: "6 Month Discount",
    product: "rtl",
    type: "single",
  },
  blu_metered: {
    id: "blu_metered",
    amount: 200,
    amountLabel: "$200.00",
    duration: null,
    description: "Pay as you go",
    product: "blu",
  },
  blu_metered_300: {
    id: "blu_metered_300",
    amount: 300,
    amountLabel: "$300.00",
    duration: null,
    description: "Pay as you go",
    product: "blu",
  },
}

const PricingSection = ({
  isLoggedIn,
  cart,
  addPlanToCart,
  removePlanFromCart,
  pricingPlans,
  history: { push },
  goToLogin,
  currentPlanType,
  changePlanType,
  isCartHavingTeamPlan,
  clearPricingCart,
  categorizedSubscriptions,
  pricingPlansMap,
  isIOS,
  toggleHeaderBanner,
}) => {
  const isSingleOrTeam = currentPlanType === "Single"
  const goToCheckout = () => {
    push("/checkout")
  }
  const { data, isFetching } = pricingPlans
  let conditionsApplyText = ""
  if (!isFetching && data) {
    if (isSingleOrTeam) {
      conditionsApplyText = data.single_plans.additional_text
    } else {
      conditionsApplyText = data.team_plans.additional_text
    }
  }
  //const { additional_text } = pricingPlans.team_plans
  const handleChangePlanType = (type, clearCart, changeType) => {
    clearCart()
    changeType({ type })
  }

  return (
    <StyledStart>
      <PricingWrapper>
        <BoxWrapper width={2 / 3}>
          <Heading type="h2" center="center" lineHeight={1.28}>
            Radius Assist ISA Plans
          </Heading>
          <Paragraph align="center" mt="15" lineHeight={1.32} size="22">
            You can send us your leads in 2 ways. Select one or both types of plans that fit your needs.
          </Paragraph>
        </BoxWrapper>
        <ChangePlansTypeWrap>
          <SingleUserCTA
            isSingleOrTeam={isSingleOrTeam}
            onClick={() => handleChangePlanType("Single", clearPricingCart, changePlanType)}
          >
          Single User
          </SingleUserCTA>
          <TeamUserCTA
            isSingleOrTeam={isSingleOrTeam}
            onClick={() => handleChangePlanType("Team", clearPricingCart, changePlanType)}
          >
          Team
          </TeamUserCTA>
        </ChangePlansTypeWrap>
        <ISADetailedPlanCard
          pricingPlans={pricingPlans}
          currentPlanType={currentPlanType}
          addPlanToCart={addPlanToCart}
          removePlanFromCart={removePlanFromCart}
          isCartHavingTeamPlan={isCartHavingTeamPlan}
          isLoggedIn={isLoggedIn}
          cart={cart}
          pricingPlansMap={pricingPlansMap}
          categorizedSubscriptions={categorizedSubscriptions}
          toggleHeaderBanner={toggleHeaderBanner}
        />
      </PricingWrapper>
      <Box mobile mt={100} pb={100}>
        {isLoggedIn
          ? (
            <div>
              <ConditionsApplyTeamOnly>{conditionsApplyText}</ConditionsApplyTeamOnly>
              <Anchor
                href="https://www.radiusagent.com/terms-and-conditions"
                target="_blank"
              >
                Terms and Conditions
              </Anchor>
              <SubscribeButton onClick={goToCheckout}>
                  Checkout
              </SubscribeButton>
            </div>
          )
          : (!isIOS && (
            <React.Fragment>
              <ConditionsApplyTeamOnly>{conditionsApplyText}</ConditionsApplyTeamOnly>
              <SubscribeButton onClick={goToLogin}>
                Get Started
              </SubscribeButton>
            </React.Fragment>
          ))
        }
      </Box>
    </StyledStart>
  )
}

export default withRouter(PricingSection)
