import React from "react"
import styled from "styled-components"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import { Link } from "react-router-dom"
import appContainer from "container/App"
import Box from "@ui/Box"
import colors from "@colors"
import { HIGHLIGHT_FONT, ATC } from "@fonts"
import { mobile, xsMobile } from "@consts"
import { checkForUserObject } from "services/Utils"
import ReferralManagerContactV3 from "dumbComponents/Referral/common/ReferralManagerContactV3"
import RadiusLogo from "images/radius_logo_sq.png"
import CookiesStorage from "services/CookieStorage"
import RealtyContainer from "container/Realty"
import EWebinarComponent from "dumbComponents/common/EWebinarScript"
import RARPopUpContainer from "container/App/RARPopUpContainer"
import queryString from "query-string"
import root from "window-or-global"
import LinkOrA from "./Navbar/LinkOrA"

const Wrapper = styled.div`
  background-color: ${colors.white};
  padding-top: 120px;
  padding-bottom: ${props => props.customPadding || "60px"};
  display: flex;
  position: relative;
  overflow: hidden;
  z-index: 4;
  margin-bottom: 30px;
  ${mobile(`
    flex-direction: column;
    padding-top: 80px;
  `)}
`

const Brand = styled.div`
  flex: 1;
  padding-top: 16px;
  border-top: 2px solid ${colors.black};
  max-width: 408px;
  ${mobile(`
    border-top: none;
    max-width: none;
  `)}
`
const SocialWrapper = styled.div`
  text-align: left;
  flex: 3 auto;
  position: relative;
  margin-top: 16px;
`

const ServicesWrapper = styled.div`
  text-align: left;
  position: relative;
  margin-left: 40px;
  width: 212px;
  p {
    width: max-content;
  }
  ${mobile(`
    margin-top: 80px;
    &:nth-child(1) {
      margin-left: 0px;
    }
  `)}
  ${xsMobile(`
    margin-top: 30px;
    margin-left: 0px;
    max-width: none;
    width: 100%;

  `)}
  h4 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    color: ${colors.black};
    font-family: ${HIGHLIGHT_FONT};
  }
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    height: 2px;
    width: 100%;
    border-radius: 20px;
    background: ${colors.black};
  }
  flex: 1;
`

const AnchorLink = styled(LinkOrA)`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 300;
  text-align: right;
  color: #282b31;
  text-decoration: none;
`

const StyledParagraph = styled(Paragraph)`
  font-weight: 700;
  color: ${colors.black};
  font-family: ${HIGHLIGHT_FONT};
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
`

const CopyrightWrapper = styled.div`
  background: ${colors.white};
  text-align: center;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  ${xsMobile(`
    flex-direction: column;
    text-align: center;
  `)}
  ul {
    display: flex;
    ${xsMobile(`
      justify-content: space-evenly;
    `)}
    li {
      margin: 0px 10px;
      font-size: 12px;
    }
  }
`

const LinksWrap = styled.div`
  display: flex;
  ${xsMobile(`
    flex-direction: column;
  `)}
`

const CopyPara = styled.p`
  margin-bottom: 0;
  font-family: ${ATC};
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  display: flex;
  ${xsMobile(`
    text-align: center;
    margin: 20px auto 50px auto;
  `)}
`
const LogoWrap = styled.div`
  background: url(${RadiusLogo}) center no-repeat;
  width: 98px;
  height: 98px;
  position: absolute;
  top: -45px;
  filter: brightness(0.1);

  @media(max-width: 425px) {
    top: 0px;
    left: 50%;
    transform: translate( -50%, -50%);
  }
`

const Wrap = styled.div`
  padding: 40px 0px 40px 0px;
  display: block;
  ${props => props.hide && `
    display: none;
    `}

  @media(min-width: 2500px) {
    margin-top: 22px;
  }

  @media(max-width: 1099px) {
    padding: 40px;
  }

 ${props => props.isEven && `
  background: #1F2C56;
 `}
`

const ContactUsButton = styled.button`
 font-family: ${ATC};
 font-size: 14px;
 font-weight: 300;
 text-align: right;
 color: #282b31;
 text-decoration: none;
 cursor: pointer;
 background: transparent;
 border: none;
`

const FlexBlock = styled.div`
 width: 100%;

 ${props => props.borderBottom && `
    @media(min-width: 1100px) {
      height: 175px;
      display: flex;
      justify-content: space-around;
    }

    @media(max-width: 1099px) {
      border-bottom: 1px solid black;
    }
 `}

 ${props => props.logo && `

  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: baseline;
  height: unset;

   @media(max-width: 1099px) {
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
   }
 `}
`

const InnerWrap = styled.div`
  ${props => props.second && `
    margin-bottom: 30px;
  `}
`

const SectionTitle = styled.div`
  h1 {
    font-family: Arial;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 5px;
    text-align: left;
    color: #3460FF;
  }
`

const SectionLinks = styled.div`

  display: flex;
  flex-wrap: wrap;

  @media(min-width: 1099px) {
    max-width: 234px;
  }

  @media(max-width: 1098px) {
    ${props => props.mod && `
      gap: 0px 10px;
    `}
  }
`

const Links = styled.div`
  height: 42px;
  width: 103px;

  a {
    font-family: Arial;
    font-size: 15px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000;
    text-decoration: none;

    ${props => props.isEven && `
      color: #fff;
    `}
  }
`

const ImageWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 25px;
    gap: 15px;
`

const Store = styled.div`
  img {
    height: 38.989349365234375px;
    width: 126.18775177001953px;

    ${props => props.isEven && `
      filter: invert(1) brightness(1) contrast(1);
    `}
  }
`

const RadiusLogoWrap = styled.div`
  img {
    height: 31.7005558013916px;
    width: 149.9988250732422px;

    ${props => props.isEven && `
      filter: invert(1) brightness(1) contrast(1);
    `}
  }
`

const BorderSpan = styled.div`
  display: none;
  border-bottom: 1px solid #303030;
  width: 100%;
  margin-top: 39px;
  margin-bottom: 10px;

  @media(min-width: 1099px) {
    display: block;
  }

  ${props => props.isEven && `
      color: white;
      border-bottom: 1px solid #fff;

  `}
`

class Footer extends React.Component {
  state = {
    isIosBrowser: false,
    // isISAPage: false,
  }

  componentDidMount() {
    const {
      hideHubspot,
      toggleRARFormOpen,
    } = this.props

    const { search, pathname } = root.location
    const parsedQuery = queryString.parse(search)
    const isIosBrowser = parsedQuery && parsedQuery.platform === "ios"
    // const isISAPage = pathname && pathname.includes("/radius-assist")

    this.setState({
      isIosBrowser,
      // isISAPage,
    })

    // setTimeout(() => {
    //   console.log("aaaaa", toggleRARFormOpen)
    //   toggleRARFormOpen(true)
    // }, 2000)

    // if (hideHubspot) {
    //   if (root.HubSpotConversations) {
    //     root.HubSpotConversations.widget.remove()
    //   }
    // }
  }

  componentDidUpdate(prevProps) {
    const {
      hideHubspot,
    } = prevProps
    // if (root.HubSpotConversations) {
    //   const status = root.HubSpotConversations.widget.status()
    //   if (status.loaded) {
    //     root.HubSpotConversations.widget.remove()
    //   } else if (!hideHubspot) {
    //     root.HubSpotConversations.widget.load()
    //   } else {
    //     root.HubSpotConversations.widget.remove()
    //   }
    // }
  }

  render() {
    const {
      isIosBrowser,
      // isISAPage,
    } = this.state

    const {
      customStyle,
      customPadding,
      setCurrentLocationForRealty,
      submitScheduleACall,
      currentState,
      currentCity,
      RARTracker,
      scheduleACallResponse,
      isRARModalOpen,
      hideWebinar,
      hideRarForm,
      vwoVariation,
      vwoActiveVariant,
      isEven,
    } = this.props

    const user = checkForUserObject("user")
    const isInterestSubmitted = CookiesStorage.load("interestViaPopUp")

    const evenVariant = isEven || vwoActiveVariant === "Blue"

    return (
      <Wrap
        hide={isIosBrowser}
        isEven={evenVariant}
      >
        <FlexBlock
          borderBottom
        >
          <InnerWrap>
            <SectionTitle>
              <h1>ABOUT</h1>
            </SectionTitle>
            <SectionLinks
              mod
            >
              <Links
                isEven={evenVariant}
              >
                <Link to="/about">About Us</Link>
              </Links>
              <Links
                isEven={evenVariant}
              >
                <Link to="/privacy-policy">Privacy Policy</Link>
              </Links>
              <Links
                isEven={evenVariant}
              >
                <a href="https://radiusagent.applytojob.com/apply" target="_blank">Careers</a>
              </Links>
              <Links
                style={{
                  width: "max-content",
                }}
                isEven={evenVariant}
              >
                <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
              </Links>
              <Links
                isEven={evenVariant}
              >
                <a href="https://blog.radiusagent.com" target="_blank">Blog</a>
              </Links>
            </SectionLinks>
          </InnerWrap>
          <InnerWrap
            second
          >
            <SectionTitle>
              <h1>STAY CONNECTED</h1>
            </SectionTitle>
            <SectionLinks>
              <Links
                isEven={evenVariant}
              >
                <a href="https://www.linkedin.com/company/radiusagent" target="_blank">Linkedin</a>
              </Links>
              {/* <Links
                isEven={evenVariant}
              >
              https://www.instagram.com/radius_agent
                <a href="https://twitter.com/radiusagent" target="_blank">Twitter</a>
              </Links> */}
              <Links
                isEven={evenVariant}
              >
                <a href="https://www.instagram.com/radius_agent" target="_blank">Instagram</a>
              </Links>
              <Links
                isEven={evenVariant}
              >
                <a href="https://www.facebook.com/radiusagent/" target="_blank">Facebook</a>
              </Links>
            </SectionLinks>
          </InnerWrap>
        </FlexBlock>
        <BorderSpan
          isEven={evenVariant}
        />
        <FlexBlock
          logo
        >
          <ImageWrap>
            <Store
              isEven={evenVariant}
            >
              <a href="https://itunes.apple.com/us/app/agentdesks-real-estate-agents/id974478110?mt=8">
                <img src={`${CLOUDFRONT}/AppleDownload.png`} alt="apple_store" />
              </a>
            </Store>
            <Store
              isEven={evenVariant}
            >
              <a href="https://play.google.com/store/apps/details?id=com.agentdesks.android.crm&hl=en">
                <img src={`${CLOUDFRONT}/AndroidStore.png`} alt="android_play_store" />
              </a>
            </Store>
          </ImageWrap>
          <RadiusLogoWrap
            isEven={evenVariant}
          >
            <img src={`${CLOUDFRONT}/RadiusLogo3x.png`} alt="radius_logo" />
          </RadiusLogoWrap>
        </FlexBlock>
        <EWebinarComponent />
      </Wrap>
    )
  }
}

export default appContainer(RealtyContainer(RARPopUpContainer(Footer)))
