import React, { useEffect, useRef, useState } from "react"
import TechAssets from "container/TechAssets"
import styled from "styled-components"
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import CookiesStorage from "services/CookieStorage"
import { get } from "lodash"
import {
  GoogleAutoComplete, Form, Button,
  InputNumber, Select, Modal, Input,
} from "@ui/antd"
import colors from "@colors"
import { ATC } from "@fonts"
import ThreeDotLoader from "@ui/ThreeDotLoader"
import createToast from "@ui/Toast"

const { Item } = Form
const { Option } = Select

const Wrap = styled.div``

const FormFillWrap = styled.div`
  margin: 0;
`

const FormTitle = styled.h1`
font-family: ${ATC};
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 16px;
color: #303030;
`

const CustomItem = styled(Item)`
  margin-left: 0px !important;
  margin-right: 0px !important;
  .ant-input {
    border: none !important;
  }
  .ant-input-number-input-wrap {
    margin-top: 8px;
  }
`

const CustomModal = styled(Modal)`
  .ant-modal-title {
    margin-top: 5px;
    font-size: 22px;
  }
`

const MortgageForm = ({
  showMortgageForm,
  toggleMortgageForm,
  sendPropertyDetails,
  sendClientDetails,
  sendClientDetailsResponse,
  sendMortgageActionResponse,
}) => {
  const formRef = useRef(null)
  const [form] = Form.useForm()
  const [cityFormValue, setCityFormValue] = useState([])
  const secureId = CookiesStorage.load("secureId")
  const agentId = CookiesStorage.load("agentId")

  const submittingClientDetails = get(sendClientDetailsResponse, "isFetching")
  const submittingMortgageProperty = get(sendMortgageActionResponse, "isFetching")
  const isFetching = submittingClientDetails || submittingMortgageProperty

  const setCities = (location) => {
    const currentFieldValues = formRef.current.getFieldsValue()
    const locationDetails = {
      c_locality_name: get(location, "locality.long_name"),
      state: get(location, "administrative_area_level_1.long_name"),
      stateShortName: get(location, "administrative_area_level_1.short_name"),
      country: get(location, "country.long_name"),
      latitude: get(location, "lat"),
      longitude: get(location, "lng"),
      address: get(location, "address"),
      route: get(location, "route.long_name"),
      streetName: get(location, "street_number.short_name"),
      zipcode: get(location, "postal_code.short_name"),
    }
    currentFieldValues.location = locationDetails.address
    currentFieldValues.zipcode = parseInt(locationDetails.zipcode, 10)
    formRef.current.setFieldsValue({ ...currentFieldValues })
    setCityFormValue(locationDetails)
  }

  const setLocaCity = (value) => {
    console.log(value)
  }
  
  const onFinish = (values) => {
    let payload = {}
    const property = {
      city: cityFormValue.c_locality_name,
      state: cityFormValue.state,
      country: "United States",
      address: cityFormValue.c_locality_name,
      agent_id: parseInt(agentId, 10),
      price: values.price,
      zipcode: values.zipcode,
      purpose: values.purpose,
      timeline: values.timeline,
    }

    const { phone } = values
    payload = {
      agent_id: parseInt(agentId, 10),
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      phone: phone.split("-").join(""),
      // loan_amount: values.loan_amount,
      // loan_purpose: values.loan_purpose,
      credit_profile: values.credit_profile,
      is_there_a_co_borrower: values.is_there_a_co_borrower,
      household_income: values.household_income,
      secure_id: secureId,
      category: "finance",
      source: "mortgage",
      type: "mortgage",
      need_down_payment_assistance: values.need_down_payment_assistance,
    }

    payload.emails = [{
      email: values.email,
      is_primary: true,
    }]

    payload.phones = [{
      phone: phone.split("-").join(""),
      is_primary: true,
    }]
    sendPropertyDetails({
      payload: property,
      callback: property_id => {
      sendClientDetails({ payload: { ...payload, listing_prop_id: property_id } }); 
      form.resetFields();
      toggleMortgageForm(false);
      createToast("Form Submitted!")
      },
    })
  }

  return (
    <>
      <div>Submitted</div>
      {showMortgageForm && (
        <CustomModal
          title="Introduce a Client"
          open={showMortgageForm}
          footer={null}
          onCancel={() => toggleMortgageForm(false)}
        >
          <Wrap>
            <FormFillWrap>
              <Form
                name="transaction_form"
                preserve
                colon={false}
                layout="vertical"
                requiredMark={false}
                onFinish={onFinish}
                ref={formRef}
                form={form}
              >
                <FormTitle>
                  Client&apos;s Firstname *
                </FormTitle>
                <CustomItem
                  name="firstname"
                  rules={[{
                    required: true,
                  }]}
                >
                  <Input placeholder="Firstname..." />
                </CustomItem>
                <FormTitle>
                  Client&apos;s Lastname *
                </FormTitle>
                <CustomItem
                  name="lastname"
                  rules={[{
                    required: true,
                  }]}
                >
                  <Input placeholder="Lastname..." />
                </CustomItem>
                <FormTitle>
                  Client&apos;s Email *
                </FormTitle>
                <CustomItem
                  name="email"
                  rules={[{
                    required: true,
                    type: "email",
                    message: "The input is not valid E-mail!",
                  }]}
                >
                  <Input placeholder="Email..." />
                </CustomItem>
                <FormTitle>
                  Client&apos;s Phone Number *
                </FormTitle>
                <CustomItem
                  name="phone"
                  normalize={normalizePhone}
                  rules={[{
                    required: true,
                  }]}
                >
                  <Input
                    controls={false}
                    placeholder="Number..."
                  />
                </CustomItem>
                <FormTitle>
                  Client&apos;s Property Address *
                </FormTitle>
                <CustomItem name="location" rules={[{required: true}]}>
                  <GoogleAutoComplete
                    id="cities"
                    types={["address"]}
                    name="cities"
                    // formValue={cityFormValue}
                    onChange={(e) => { setLocaCity(e.target.value) }}
                    location={(loc) => { setCities(loc) }}
                    placeholder="Location..."
                    styles={{
                      height: "49px",
                      borderRadius: "0px",
                      background: "white",
                      fontSize: "16px",
                      marginTop: "10px",
                    }}
                    restrict={{
                      lat: 37.09024,
                      lng: -95.712891,
                      south_west: {
                        lat: 25.82,
                        lng: -124.39,
                      },
                      north_east: {
                        lat: 49.38,
                        lng: -66.94,
                      },
                      address: "United States",
                      country: {
                        long_name: "United States",
                        short_name: "US",
                        types: [
                          "country",
                          "political",
                        ],
                      },
                    }}
                  />
                </CustomItem>
                <FormTitle>
                  Zipcode *
                </FormTitle>
                <CustomItem
                  name="zipcode"
                  rules={[{
                    required: true,
                    pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
                    message: "Zipcode should be at least 5 digits",
                  }]}
                >
                  <InputNumber
                    controls={false}
                    placeholder="Zipcode..."
                  />
                </CustomItem>
                <FormTitle>
                  Price
                </FormTitle>
                <CustomItem
                  name="price"
                >
                  <InputNumber
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    controls={false}
                  />
                </CustomItem>
                {/* <FormTitle>
                  Loan Amount
                </FormTitle>
                <CustomItem
                  name="loan_amount"
                  rules={[{ required: true, message: "Loan Amount Required" }]}
                >
                  <InputNumber
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    controls={false}
                  />
                </CustomItem> */}
                <FormTitle>
                  Type of property
                </FormTitle>
                <CustomItem name="type">
                  <Select placeholder="Select...">
                    <Option value="Single Family">Single Family</Option>
                    <Option value="Multi-Family">Multi-Family</Option>
                    <Option value="Condominium">Condominium</Option>
                    <Option value="Townhome">Townhome</Option>
                  </Select>
                </CustomItem>
                {/* <FormTitle>
                  Main use of property
                </FormTitle>
                <CustomItem name="purpose">
                  <Select placeholder="Select...">
                    <Option value="Primary Home">Primary Home</Option>
                    <Option value="Secondary Home">Secondary Home</Option>
                    <Option value="Investment Property">Investment Property</Option>
                  </Select>
                </CustomItem> */}
                <FormTitle>
                  Timeline
                </FormTitle>
                <CustomItem name="timeline">
                  <Select placeholder="Select...">
                    <Option value="Signed Contract">Signed Contract</Option>
                    <Option value="Shopping Now">Shopping Now</Option>
                    <Option value="Buying less than 3 months">Buying less than 3 months</Option>
                    <Option value="Buying in 3-6 months">Buying in 3-6 months</Option>
                    <Option value="Buying in 6 months or more">Buying in 6 months or more</Option>
                  </Select>
                </CustomItem>
                <FormTitle>
                  Need Down Payment Assistance?
                </FormTitle>
                <CustomItem name="need_down_payment_assistance">
                  <Select placeholder="Select...">
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                  </Select>
                </CustomItem>
                <FormTitle>
                  Household Income *
                </FormTitle>
                <CustomItem
                  name="household_income"
                  rules={[{ required: true, message: "Household Income Required" }]}
                >
                  <Select placeholder="Select...">
                    <Option value="$50,000 - $150,000">$50,000 - $150,000</Option>
                    <Option value="$150,001 - $300,000">$150,001 - $300,000</Option>
                    <Option value="$300,001 - $500,000">$300,001 - $500,000</Option>
                    <Option value="$500,001 +">$500,001 +</Option>
                  </Select>
                </CustomItem>
                <FormTitle>
                  Is there a co-owner? *
                </FormTitle>
                <CustomItem
                  name="is_there_a_co_borrower"
                  rules={[{ required: true, message: "Co-owner Required" }]}
                >
                  <Select placeholder="Select...">
                    <Option value={false}>No</Option>
                    <Option value={true}>Yes</Option>
                  </Select>
                </CustomItem>
                <FormTitle>
                  What’s the client’s credit score?
                </FormTitle>
                <CustomItem name="credit_profile">
                  <Select placeholder="Select...">
                    <Option value="Excellent (720+)">Excellent (720+)</Option>
                    <Option value="Good (660-719)">Good (660-719)</Option>
                    <Option value="Average (620-659)">Average (620-659)</Option>
                    <Option value="Below Average (580-619)">Below Average (580-619)</Option>
                    <Option value="Low (< 579)">{"Low (< 579)"}</Option>
                  </Select>
                </CustomItem>
                <CustomItem>
                  <Button
                    style={{
                      float: "right",
                      background: `${colors.marineBLue}`,
                      borderColor: `${colors.marineBLue}`,
                    }}
                    type="primary"
                    htmlType="submit"
                    disabled={isFetching}
                  >
                    {isFetching ? <ThreeDotLoader /> : "Submit"}
                  </Button>
                </CustomItem>
              </Form>
            </FormFillWrap>
          </Wrap>
        </CustomModal>
      )}
    </>
  )
}

export default TechAssets(MortgageForm)
