import React, { useState } from "react"
import axios from "axios"
import DebouncedSelect from "../Components/debouncedSelect"
import {
  Wrap,
  TabsWrap,
  TabsInfo,
  TabsTitle,
} from "./styles"

const MLS = [
  {
    value: "mls",
    label: "Search using MLS",
  },
  {
    value: "address",
    label: "Search using Property Address",
  },
]

const MLSTabs = ({
  callback,
}) => {
  const [currentMLSTab, toggleMLSTab] = useState("mls")
  const [searchValue, setValue] = useState([])
  const [isFetching, setFetching] = useState(false)

  const fetchUserList = async (search) => {
    if (!search) return []
  
    const url = `${API_URL}get-mls-data?mlsId=${search}`

    setFetching(true)
    const mapped = await axios.get(url)
      .then((res) => {
        setFetching(false)
        const {
          response,
        } = res.data || {}
        return response && response.data || []
      })

    const options = mapped && mapped.map((item) => {
      return {
        ...item,
        value: item.Id,
        label: item.UnparsedAddress,
        address: item.UnparsedAddress,
        id: item.Id,
        image: item.Media[0]?.MediaURL || "",
        typedMLSId: search,
      }
    })
    return options
  }

  const fetchUserListOnPropertyAddress = async (search) => {
    if (!search) return []

    const url = `${API_URL}get-mls-data?propertyAddress=${search}`

    setFetching(true)
    const mapped = await axios.get(url)
      .then((res) => {
        setFetching(false)
        const {
          response,
        } = res.data || {}
        return response && response.data || []
      })

    const options = mapped && mapped.map((item) => {
      return {
        ...item,
        value: item.Id,
        label: item.UnparsedAddress,
        address: item.UnparsedAddress,
        id: item.Id,
        image: item.Media[0]?.MediaURL || "",
        typedPropertyAddress: search,
      }
    })

    return options
  }

  return (
    <Wrap>
      <TabsWrap>
        {MLS.map(option => (
          <TabsInfo isSelected={option.value === currentMLSTab} onClick={() => toggleMLSTab(option.value)}>
            <TabsTitle isSelected={option.value === currentMLSTab}>
              {option.label}
            </TabsTitle>
          </TabsInfo>
        ))}
      </TabsWrap>
      {currentMLSTab === "mls" && (
        <DebouncedSelect
          showSearch
          placeholder="Search by MLS"
          value={searchValue}
          fetchOptions={fetchUserList}
          hideSelected
          isFetching={false}
          onChange={(newValue) => {
            setValue(newValue)
            callback(newValue)
          }}
        />
      )}
      {currentMLSTab === "address" && (
        <DebouncedSelect
          showSearch
          placeholder="Search by Property Address"
          value={searchValue}
          isFetching={false}
          hideSelected
          fetchOptions={fetchUserListOnPropertyAddress}
          onChange={(newValue) => {
            setValue(newValue)
            callback(newValue)
          }}
        />

      )}
    </Wrap>
  )
}

export default MLSTabs
