import React, { useEffect, useState } from "react"
import styled from "styled-components"
import root from "window-or-global"
import TechAssetsContainer from "container/TechAssets"
import { xsMobile as mobile, tablet } from "@consts"
import ArrowIcon from "images/Graphics/HomeV6/WhiteRightArrow.svg"
import colors from "@colors"
import { ATC } from "@fonts"

const Wrap = styled.div`
  width: 100%;
  background: #306F4D;
  padding: 65px 40px 65px 40px;
  ${tablet(`
    padding: 60px 20px;
  `)}
  > h2,
  > p {
    color: #fff;
  }
`

const RateWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  gap: 20px;
  ${tablet(`
    flex-wrap: wrap;
    justify-content: center;
    
  `)}
  ${mobile(`
    flex-direction: column;
    margin-top: 40px;
  `)}
 
`

const Rate = styled.div`
  border: 1px solid #fff;
  border-radius: 18px;
  width: 200px;
  background: #fff;
  padding: 27px 14px 15px 18px;
  ${tablet(`
    width: 182px;
  `)}
  ${mobile(`
    width: 100%;
  `)}
  text-align: center;
  // > header {
  //   border-bottom: 1px solid #306F4D;
  //   padding: 20px 16px;
  //   font-size: 24px;
  //   ${mobile(`
  //     font-size: 23px;
  //   `)}
  // }

  // > main {
  //   padding: 10px 16px;
  //   > p {
  //     font-size: 24px;
  //     font-weight: 700;
  //     margin: 8px;
  //     ${mobile(`
  //       font-size: 24px;
  //     `)}
  //     &:nth-child(1) {
  //       color: #306F4D;
  //     }
  //   }
  //   > h6 {
  //     font-family: 'ATC Arquette';
  //     color: #fff;
  //     font-size: 14px;
  //     font-weight: 400;
  //     margin: 20px 10px;
  //     ${tablet(`
  //       font-size: 16px;
  //     `)}
  //   }
  // }
`

const Title = styled.h2`
  font-family: Butler;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;

  ${tablet(`
    font-size: 22px;
  `)}
  ${mobile(`
    font-size: 22px;
  `)}
  ${props => props.isThankyouPage && `
    color: #fff;
    font-size: 28px;
    letter-spacing: 3px;

    ${tablet(`
      font-size: 20px;
      font-weight: 500;
    `)}

    ${mobile(`
      font-size: 20px;
      font-weight: 500;
    `)}
  `}
`

const DisclaimerText = styled.div`
  text-align: center;
  margin-top: 40px;
  color: #fff;
  font-size: 14px;
  ${mobile(`
    font-size: 14px;
  `)}
`

const Header = styled.header`
  font-family: ${ATC};
  font-size: 22px;
  font-weight: 400;
  line-height: 41px;
  letter-spacing: 0px;
  text-align: center;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GreenBorder = styled.div`
  height: 5px;
  width: 53px;
  background-color: #306F4D;
  margin: 0 auto 20px auto;
`

const NoMatter = styled.p`
  font-family: ${ATC} ;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`

const RateText = styled.p`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0px;
`

const RateTextAPR = styled.p`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 0px;
`

const Fixed = styled.p`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`

const Main = styled.main` 
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const TodayMortgageRates = styled.div`
  margin-top: 71px;
  margin-bottom: 50px;

  p {
    font-family: Butler;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }
`

const CTAWrap = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;

  justify-content: center;
  margin: 40px 0px 40px 0px;

  ${tablet(`
     flex-direction: column;
  `)}

  ${mobile(`
     flex-direction: column;
  `)}
`

const Button = styled.button`
  font-family: ${ATC};
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: white;

  border-radius: 3px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9.22664px 16.1466px;
  border: none;
  cursor: pointer;

  width: max-content;
  height: 48px;

  background: #E5AC3D;
`

const paraOne = `*Rates and fees above are displayed as of time 
posted and are subject to change without notice. Interest rates 
and APRs are for informational purposes and do not include all 
applicable fees. Your actual rates, payments, and costs may differ. 
Some loan options may not be available in all states.
`

const paraTwo = `
We don’t yet have your complete financial picture. Your actual rate, payment
and costs could be different. Get an official Loan Estimate before choosing
a loan.  Loan approval is subject to underwriter review. Not everyone who applies will be approved.
`

const paraThree = `
We use certain assumptions to provide a rate.  Those assumptions include that closing costs are
paid out of pocket, your debt-to-income ratio is below 35%, you are purchasing or refinancing
a single-family home that is your primary residence, you are making a down payment of 
at least 20%, and your credit score is 760 or higher.  If you
are refinancing, you’re not taking
cash out. Refinancing may cause your finance charges to be higher over the life of the loan.
`

const FurtherDropdown = styled.div`
  display: flex;
  padding-top: 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  p {
    text-align: left;
    margin: 0;
    color: ${colors.white};
  }

  ${props => props.flexDC && `
    flex-direction: column;
  `}
`

const TextWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const ImageWrap = styled.div`
  cursor: pointer;
  rotate: 90deg;

  ${props => props.reverse && `
    rotate: 270deg;
  `}
`

const Disclaimer = styled.div`
  width: 900px;
`

const TodaysRate = ({
  isThankyouPage,
  fetchTodaysPrice,
  todaysPriceResponse,
}) => {
  const [showDropdown, setDropdown] = useState(false)
  useEffect(() => {
    // loadData()
    fetchTodaysPrice()
  }, [])

  const {
    data: rates,
    isFetching: fetchingRates,
  } = todaysPriceResponse || {}

  return (
    <Wrap>
      <Title
        isThankyouPage={isThankyouPage}
        style={{
          textAlign: isThankyouPage ? "center" : "inhrit",
          color: isThankyouPage ? "#ffffff" : "#fff",
        }}
      >
        {!isThankyouPage
          ? `
            Low Rates
            `
          : "Today’s rates".toUpperCase() }
      </Title>
      {!isThankyouPage && (
        <NoMatter>
          No matter what type of loan your client needs, we have the right loan products at the lowest rates possible.
        </NoMatter>
      )}
      <TodayMortgageRates>
        <p>Today&apos; Mortgage Rates</p>
      </TodayMortgageRates>
      <RateWrap>
        {fetchingRates ? (
          <p>Loading</p>
        ) : (
          <>
            {rates && rates.map(rate => (
              <Rate key={rate.id}>
                <Header>
                  {`${rate.title}*`}
                </Header>
                <GreenBorder>
                </GreenBorder>
                <Main>
                  <RateText>
                    {`${rate.rate}% rate`}
                  </RateText>
                  <RateTextAPR>
                    {`${rate.apr}% APR`}
                  </RateTextAPR>
                  <Fixed>
                    {rate.time_period}
                  </Fixed>
                </Main>
              </Rate>
            ))}
          </>
        )}
      </RateWrap>
      <CTAWrap>
        <Button
          onClick={() => {
            root.open("https://loandeskmortgage.com", "_blank")
          }}
        >
          Meet your new LO partner
        </Button>
      </CTAWrap>
      <DisclaimerText style={{ color: isThankyouPage ? "#ffffff" : "#fff" }}>
        All APRs are updated daily.
      </DisclaimerText>
      {showDropdown ? (
        <FurtherDropdown flexDC>
          <TextWrap>
            <p>
              *View Legal Disclosure
            </p>
            <ImageWrap reverse onClick={() => setDropdown(false)}>
              <img src={ArrowIcon} alt="options-arrow" />
            </ImageWrap>
          </TextWrap>
          <Disclaimer>
            <p>
              {paraOne}
            </p>
            <br />
            <p>
              {paraTwo}
            </p>
            <br />
            <p>
              {paraThree}
            </p>
          </Disclaimer>
        </FurtherDropdown>
      ) : (
        <FurtherDropdown>
          <p>
            *View Legal Disclosure
          </p>
          <ImageWrap onClick={() => setDropdown(true)}>
            <img src={ArrowIcon} alt="options-arrow" />
          </ImageWrap>
        </FurtherDropdown>
      )}
    </Wrap>
  )
}

export default TechAssetsContainer(TodaysRate)
