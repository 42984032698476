import React from "react"
import root, { console } from "window-or-global"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import queryString from "query-string"
import Loader from "@ui/Loader"
import { Route, withRouter, Redirect } from "react-router-dom"
import { authCheck } from "services/auth"
import Navbar from "dumbComponents/common/Navbar"
import container from "container/App/headerLayoutContainer"
import techAssets from "container/TechAssets"
import { mobile, SIDEBAR_WIDTH } from "@consts"
import RadiusBanners from "dumbComponents/common/RadiusBanners"
import Sidebar from "@ui/Sidebar"
import ScrollToTop from "@ui/ScrollToTop"
import cookie from "services/CookieStorage"
import { UTMExtract } from "services/Utils"
import PubNubForUpload from "dumbComponents/Listings/pages/Docusign/PubNubForUploads"
import CRMModal from "dumbComponents/CRMV2/CRMDashboard/Components/DragAndDropArea/Modals/CRMModal"
import UploadUpdateModal from "./UploadUpdateModal"
const TC_PARENT_ROUTES = [
  "/realty/listings",
  "/realty/offers",
  "/realty/transaction-coordination",
]

// const Navbar = Loadable({
//   loader: () => import("dumbComponents/common/Navbar/index.js"),
//   loading: <div></div>,
// })

// const RadiusBanners = Loadable({
//   loader: () => import("dumbComponents/common/RadiusBanners/index.js"),
//   loading: <></>,
// })

// const Sidebar = Loadable({
//   loader: () => import("@ui/Sidebar"),
//   loading: <></>,
// })

const LayoutWrap = styled.div`
  padding-top: 120px;
  transition-duration: 0.3s;
  width: calc(100% - ${SIDEBAR_WIDTH}px);
  left: ${SIDEBAR_WIDTH}px;
  position: relative;

  ${mobile`
    left: 0px;
    width: calc(100%);
  `}
`

const ChildRoot = styled.div`
  max-width: ${props => props.layoutMaxWidth};
  margin: 0px auto;
`

const handleLogout = () => {
  // this will failed on SSR
  try {
    root.location.href = `${WEB_URL}login`
  } catch (e) {
    // ignore error
  }
}

const Layout = ({ children, location, globalPaddingTop, layoutMaxWidth,isShowUpdate,toggleShowUpdate,setContentForUpdate }) => {
  const { search } = root.location
  const parsedQuery = queryString.parse(search)
  const isIosBrowser = parsedQuery && parsedQuery.platform === "ios"
  return (
    <LayoutWrap
      style={{ paddingTop: globalPaddingTop }}
    >
      {isShowUpdate && (
        <CRMModal
          toClose={() => { 
            toggleShowUpdate(false) 
            setContentForUpdate(null)
          }}
        >
          <UploadUpdateModal />
        </CRMModal>
      )}
      <PubNubForUpload />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {/* {!isIosBrowser && (
        <RadiusBanners />
      )} */}
      <Navbar location={location} />
      <Sidebar />
      <ScrollToTop />
      <ChildRoot layoutMaxWidth={layoutMaxWidth}>
        <div>
          {children}
        </div>
      </ChildRoot>
    </LayoutWrap>
  )
}

const HeaderRoute = ({
  component: Component,
  location,
  requireAuth,
  isOnlyPublic,
  globalPaddingTop,
  history,
  toggleShowUpdate,
  setContentForUpdate,
  isShowUpdate,
  layoutMaxWidth = "1440px",
  ...rest
}) => (
  <>
  <Route
    {...rest}
    render={(props) => {
      const isLogged = authCheck()
      const tcInformation = cookie.load("tcInformation")
      if (tcInformation?.is_tc && isLogged && !TC_PARENT_ROUTES.includes(props?.match?.path)) {
        return (<Redirect to="/realty/listings" />)
      }
      if (requireAuth) {
        if (isLogged) {
          return (
            <Layout
              style={{ paddingTop: globalPaddingTop }}
              location={location}
              globalPaddingTop={globalPaddingTop}
              layoutMaxWidth={layoutMaxWidth}
              isShowUpdate={isShowUpdate}
              toggleShowUpdate={toggleShowUpdate}
              setContentForUpdate={setContentForUpdate}
            >
              <Component {...props} />
            </Layout>
          )
        }
        handleLogout()
      }
      if (isOnlyPublic && isLogged) {
        const redirectParams = UTMExtract()
        if (redirectParams) {
          history.push(`/community/${redirectParams}`)
        } else {
          history.push("/community")
        }
      }

      return (
        <> 
          <Layout
          style={{ paddingTop: globalPaddingTop }}
          location={location}
          globalPaddingTop={globalPaddingTop}
          layoutMaxWidth={layoutMaxWidth}
          isShowUpdate={isShowUpdate}
          toggleShowUpdate={toggleShowUpdate}
          setContentForUpdate={setContentForUpdate}
        >
          {!isLogged ? (
            <Redirect to="/signup" />
          ) : (
            <Component {...props} />
          )}
        </Layout>
        </>
      )
    }}
  />
  </>
  
)

export default container(techAssets(withRouter(HeaderRoute)))
