import styled from "styled-components";
import colors from "@colors";
import { INTER, MONTSERRAT } from "@fonts";

const Container = styled.div`
  border-radius: 9px;
  margin-top: 24px;
  background: ${colors.white};
      @media (max-width: 1024px) {
        margin-top: 15px;

    }
`;
const Header = styled.div`
  color: ${colors.new_grey600};
  font-family: ${MONTSERRAT};
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  margin-top: 48px;
  @media (max-width: 1024px) {
    margin-top: 24px;
  }
`;

const Tabs = styled.div`
  display: flex;
  padding: 4px 12px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 4px;
  background: ${colors.new_tab_color};
  margin-right: 8px;
  text-transform: uppercase;
  margin-bottom: 12px;
  font-family: ${INTER};
  color: ${colors.new_grey600};
  @media (max-width: 1024px) {
    margin-bottom: 8px;
  }
`;

const ButtonShow = styled.div`
  color: ${colors.new_primary};
  font-family: ${INTER};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  letter-spacing: 0.5px;
  cursor: pointer;
      margin-bottom: 30px;
`;

const TabsWrapper = styled.div`
  display: flex;
  font-size: 14px;
  flex-wrap: wrap;
`;

const Description = styled.div`
  color: ${colors.new_grey600};
  align-self: stretch;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-family: ${INTER};
  margin-bottom: 12px;
  margin-top: 10px;
`;

const ListedBy = styled.div`
color: #545487;
font-family: ${INTER};
font-size: 12px;
font-style: normal;
font-weight: 400;
letter-spacing: 0.5px;
margin-bottom:8px;
line-height:18px;
margin-top: -8px`;

export {
  ListedBy,
  Description,
  TabsWrapper,
  Tabs,
  Header,
  Container,
  ButtonShow,
};
