import React from "react"
import styled from "styled-components"
import root from "window-or-global"
import { Link, withRouter } from "react-router-dom"
import colors from "@colors"
import { ATC } from "@fonts"
import TechAssetsContainer from "container/TechAssets"
import container from "container/Referral"
import { LeaderBoardFontStyle } from "dumbComponents/Leaderboard/components/LeaderBoardStyledComponent"

const Wrap = styled.ul`
  display: flex;
  border-bottom: 2px solid ${colors.disabledInput};
  margin: 42px 0px 36px 0px;
  width: 750px;
`

const LoaderWrap = styled.div`
   height: 600px;
   width: 100%;
`

const StyledLink = styled(Link)`
  ${LeaderBoardFontStyle}
  font-family: ${ATC};
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 4px 18px;
  display: inline-block;
  position: relative;
  color: ${colors.suvaGray} !important;
  ${props => props.width && `
    width: ${props.width}
  `}

  ${props => props.isActive ? `
    font-weight: 600;
    color: ${colors.marineBLue}!important;
    &:after {
      content: "";
      height: 3px;
      position: absolute;
      bottom: -6px;
      left: 0px;
      width: 100%;
      background: ${colors.marineBLue};
      border-radius: 2px;
      ${props.width && `
        width: 160px;
      `}
    }
  ` : `
    color: ${colors.black};
    border-bottom: 3px solid transparent;
  `}
`

const FinancingTab = ({
  location, agentState, user,
}) => {
  const { pathname } = location
  const {
    citiesServed,
  } = user || {}
  const States = agentState && agentState.data ? agentState.data.map(st => st.name) : []
  if (citiesServed) {
    citiesServed.forEach((cityInfo) => {
      States.push(cityInfo.state)
    })
  }
  const enabledState = "California"

  const handleOnClick = (clickedText) => {
    const isPartOfBrokerageTeam = user
      && parseInt(user.brokerageTeamId, 10) > 0 || user.isBrokerageTeamMember
    const userType = user.isSubscribedToBrokerPlan
    ? isPartOfBrokerageTeam ? "client" : "client" : "community_member"
    const payload = {
      login_status: "logged_in",
      agent_id: user.id,
      agent_type: userType,
      ag_nm: `${user.firstname} ${user.lastname}`,
      ag_eml: user.email,
      event: "cash_offers_subsection_clicked",
      clicked_text: clickedText,
    }
    root.dataLayer.push(payload)
  }

  return (
    <Wrap>
      {/* {States.includes(enabledState) && (
        <li>
          <StyledLink to="/realty/financing/mortgage" isActive={pathname === "/realty/financing/mortgage"}>
            MORTGAGE
          </StyledLink>
        </li>
      )} */}
      <li>
        <StyledLink to="/realty/financing/cashoffers" isActive={pathname === "/realty/financing/cashoffers"} onClick={() => handleOnClick("cash_offers")}>
          CASH OFFERS
        </StyledLink>
      </li>
      <li>
        <StyledLink to="/realty/financing/buybeforesell" isActive={pathname === "/realty/financing/buybeforesell"} onClick={() => handleOnClick("buy_before_you_sell")}>
          BUY BEFORE YOU SELL
        </StyledLink>
      </li>
      {/* <li>
        <StyledLink to="/realty/financing/presale" isActive={pathname === "/realty/financing/presale"}>
          PRE-SALE HOME PREP
        </StyledLink>
      </li> */}
    </Wrap>
  )
}

export default TechAssetsContainer(container(withRouter(FinancingTab)))
