import React from "react"
import styled from "styled-components"
import Paragraph from "dumbComponents/common/Typography/Paragraph"
import Box from "@ui/Box"
import colors from "@colors"
import { HIGHLIGHT_FONT } from "@fonts"
import { mobile, xsMobile } from "@consts"
import { checkForUserObject, getOS } from "services/Utils"
import { authCheck } from "services/auth"
import appContainer from "container/App"
import ApplyFlowContainer from "container/App/applyFlowContainer"
import { handleDataLayerPush } from "services/dataLayerUtil"
import ReferralManagerContactV3 from "dumbComponents/Referral/common/ReferralManagerContactV3"
import RadiusLogo from "images/radius_logo_sq.png"
import CookiesStorage from "services/CookieStorage"
import RealtyContainer from "container/Realty"
import EWebinarComponent from "dumbComponents/common/EWebinarScript"
import RARPopUpContainer from "container/App/RARPopUpContainer"
import queryString from "query-string"
import root from "window-or-global"
import LinkOrA from "./Navbar/LinkOrA"
import AppForm from "../HomePageV2/Components/AppForm/index"
import GradientBox from "../HomePageV2/Components/GradientBox/index"
import { BlueButton } from "../HomePageV2/Components/commonStyles"
import { ATC, INTER, MONTSERRAT } from "@fonts"

const GetConnectedWrap = styled.div`
  ${props => props.desktopOnly && `
    display: block;

    @media(max-width: 1099px) {
      display: none;
    }
  `}

  ${props => !props.desktopOnly && `
    display: none;

    @media(max-width: 1099px) {
      display: block;
    }
  `}
`

const Wrapper = styled.div`
  background-color: #1C1C1F;
  // padding-top: 120px;
  padding-bottom: ${props => props.customPadding || "60px"};
  display: flex;
  position: relative;
  // overflow: hidden;
  z-index: 4;
  margin-bottom: 30px;
  flex-direction: column;

  width: 100%;
  //height: 499px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  height: max-content;
  margin: 0 auto;
  padding-top: 80px;


  @media(max-width: 1099px) {
    padding: 0px 20px 50px;
    align-items: flex-start;
    position: relative;
    top: -57px;
    overflow: hidden;
  }

`

const MobileCTAWrap = styled.div`
  display: none;

  @media(min-width: 391px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;
    border-bottom: 1px solid #FFF;
    max-width: 400px;
  }

  @media(min-width: 1100px) {
    display: none;
  }

  @media(max-width: 390px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 10px;
    position: relative;
    top: 26px;
  }
`

const QRCode = styled.div`
  margin-top: 10px;
  img {
    width: 90px;
    height: 90px;
  }
`

const Wrap = styled.div`
  display: block;
  ${props => props.hide && `
    display: none;
    `}
`

const AnchorsWrap = styled.div`
  width: 453px;
  // height: 35px;
  display: flex;
  flex-direction: column;
  gap: 21px;

  @media(max-width: 1099px) {
    max-width: 400px;
  }
 `

const DesktopWrap = styled.div`
  display: block;
  margin-top: 40px;

  @media(max-width: 1099px) {
    display: none;
  }
`

const JoinOurCommunity = styled.div`
   p {
    font-family: ${MONTSERRAT};
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #B6B8FF;
   }
`

const AvailableOn = styled.div`
    margin-top: 10px;
   p {
    color: #FFF;
    text-align: left;
    font-family: ${MONTSERRAT};
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.523%;
    display: flex;

    span {
      margin-right: 8px;
      img {
        width: 13px;
        height: 13px;
      }
    }
   }
`

const ScanTheApp = styled.div`
 p {
    font-family: ${INTER};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    width: 238px;
 }
`

const RedirectEle = styled(LinkOrA)`
    color: #EDEDED;
    font-family: ${INTER};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.84px;

    img {
      height: 25px;
      width: 25px;
    }
`

const AnchorLink = styled.a`
    color: #EDEDED;
    font-family: ${INTER};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 33.84px;

    img {
      height: 25px;
      width: 25px;
    }

    @media(max-width: 1099px) {
      color: #E2E2E2;
      font-family: ${INTER};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 141%; /* 19.74px */
    }
`

const RedirectionEle = styled(LinkOrA)`
  color: #A5A8FF;
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 141%; /* 19.74px */
  text-decoration-line: underline;
`

const SocialAndDisclosureWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 77%;
  align-items: baseline;
  border-top: 0.5px solid #4a4848;
  margin-top: 90px;
  flex-direction: row-reverse;

  @media(max-width: 1099px) {
    padding-top: 25px;F
    width: 100%;
    margin-top: 325px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }
  
`

const AnchorAndFormWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-evenly;
  width: 100%;
`

const SocialWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 9px;

  @media(max-width: 1099px) {
    margin: 17px;
  }
`

const Copyright = styled.div`
  display: flex;
  align-items: initial;
  gap: 5px;

  p {
    color: ${props => props.blue ? "#A5A8FF" : "#fff"};
    font-family: ${INTER};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px; /* 136.364% */
    letter-spacing: 0.5px;

    ${props => props.uppercase && `
        text-transform: uppercase;
    `}
  }

  img {
    width: 13px;
    height: 13px;
    flex-shrink: 0;
    position: relative;
    top: 2px;
  }
`

const LegalText = styled.div`

  h1 {
    color: #FFF;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 141%;

    ${props => props.noMargin && `
      margin: 0;
    `}
  }
  p {
    color: #FFF;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: ${props => props.lH || "141%"};

    ${props => props.noMargin && `
      margin: 0;
    `}

    ${props => props.anchor && `
      color: inherit;
    `}

    ${props => props.mobile && `
      @media(max-width: 1099px) {
        max-width: 345px;
      }
    `}

    @media(max-width: 1099px) {
      width: max-content;
    }
  }
`

const SectionOne = styled.div``

const SectionTwo = styled.div`
  max-width: 488px;


  @media(max-width: 1099px) {
    max-width: 400px;
    padding-top: 34px;
  }
`

const ConnectedTitle = styled.div`
  height: 23px;
  p {
    color: #E2E2E2;
    font-family: ${INTER};
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 141%; /* 16.92px */
    text-transform: uppercase;
    margin: 0;
  }
`

const UpperRow = styled.div`
  display: flex;
  gap: 114px;
  border-bottom: 1px solid #ffffff1f;
  padding-bottom: 60px;


  @media(max-width: 1099px) {
    gap: unset;
    display: flex;
    flex-direction: column;
  }
`

const GetConnected = ({
  desktopOnly,
  userData,
}) => (
  <GetConnectedWrap
    desktopOnly={desktopOnly}
  >
    {desktopOnly && (
      <ConnectedTitle>
        <p>GET CONNECTED</p>
      </ConnectedTitle>
    )}
    <SocialWrapper>
      <AnchorLink
        onClick={() => {
          handleDataLayerPush(userData, "footer_link_clicked", "facebook")
        }}
        target="_blank"
        href="https://www.facebook.com/radiusagent/"
      >
        <img src={`${CLOUDFRONT}/FacebookInverted.svg`} alt="radius_socials" />
      </AnchorLink>
      <AnchorLink
        onClick={() => {
          handleDataLayerPush(userData, "footer_link_clicked", "instagram")
        }}
        target="_blank"
        href="https://www.instagram.com/radius_agent/"
      >
        <img src={`${CLOUDFRONT}/InstagramInverted.svg`} alt="radius_socials" />
      </AnchorLink>
      <AnchorLink
        onClick={() => {
          handleDataLayerPush(userData, "footer_link_clicked", "linkedin")
        }}
        target="_blank"
        href="https://www.linkedin.com/company/radiusagent"
      >
        <img src={`${CLOUDFRONT}/LinkedinInverted.png`} alt="radius_socials" />
      </AnchorLink>
    </SocialWrapper>
  </GetConnectedWrap>
)

const BottomRow = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;

  ${props => props.desktopMarginTop && `
    margin-top: 20px;
  `}

  ${props => props.fD && `
    flex-direction: ${props.fD};
  `}

  // a {
  //   color: #A5A8FF;
  //   cursor: pointer;
  // }

  @media(max-width: 1099px) {
    display: flex;
    flex-direction: column;
  }
`

const EqualHousing = styled.div`
  height: 226px;
  position: relative;

  img {
    position: absolute;
    width: 46.001px;
    height: 49px;
    bottom: 0;

    @media(max-width: 1099px) {
      bottom: -43px;
    }
  }

  @media(max-width: 1099px) {
    height: 50px;

  }
`

class Footer extends React.Component {
  state = {
    isIosBrowser: false,
    // isISAPage: false,
  }

  componentDidMount() {
    const {
      hideHubspot,
      toggleRARFormOpen,
    } = this.props

    const { search, pathname } = root.location
    const parsedQuery = queryString.parse(search)
    const isIosBrowser = parsedQuery && parsedQuery.platform === "ios"
    // const isISAPage = pathname && pathname.includes("/radius-assist")

    this.setState({
      isIosBrowser,
    })
  }

  componentDidUpdate(prevProps) {
    const {
      hideHubspot,
    } = prevProps
    // if (root.HubSpotConversations) {
    //   const status = root.HubSpotConversations.widget.status()
    //   if (status.loaded) {
    //     root.HubSpotConversations.widget.remove()
    //   } else if (!hideHubspot) {
    //     root.HubSpotConversations.widget.load()
    //   } else {
    //     root.HubSpotConversations.widget.remove()
    //   }
    // }
  }

  render() {
    const {
      isIosBrowser,
      // isISAPage,
    } = this.state

    const {
      customStyle,
      customPadding,
      paddingTop,
      appDownloadActionResponse,
      user: userData,
    } = this.props

    const user = checkForUserObject("user")
    const isLogged = authCheck()
    const isInterestSubmitted = CookiesStorage.load("interestViaPopUp")

    const {
      data: appDownloadData,
      isFetching: submittingInterestForm,
    } = appDownloadActionResponse || {}

    const isCurrentUserOniOS = getOS() === "ios" || getOS() === "android"

    return (
      <Wrap hide={isIosBrowser}>
        {!isCurrentUserOniOS && (
          <EWebinarComponent />
        )}
        <Wrapper
          style={{ ...customStyle || {} }}
          customPadding={customPadding}
        >
          <UpperRow>
            <SectionOne>
              <Row>
                <AnchorsWrap>
                  <AnchorLink
                    onClick={() => {
                      handleDataLayerPush(userData, "footer_link_clicked", "terms_and_conditions")
                    }}
                    className="terms_conditions_web"
                    target="_blank"
                    href="https://www.radiusagent.com/terms-and-conditions"
                  >
                    Terms and Conditions
                  </AnchorLink>
                  <AnchorLink
                    onClick={() => {
                      handleDataLayerPush(userData, "footer_link_clicked", "privacy_policy")
                    }}
                    className="privacy_policy_web"
                    target="_blank"
                    href="https://www.radiusagent.com/privacy-policy"
                  >
                    Privacy Policy
                  </AnchorLink>
                  <AnchorLink
                    onClick={() => {
                      handleDataLayerPush(userData, "footer_link_clicked", "blog")
                    }}
                    className="footer_blog_web"
                    target="_blank"
                    href="https://blog.radiusagent.com/?_gl=1*1vh3rie*_ga*MTk2NDA5MDk4OC4xNjk1OTkxNzQ0*_ga_J8ECV3XFM1*MTY5NTk5MTc0My4xLjEuMTY5NTk5MzM0Ni4wLjAuMA">
                    Blog
                  </AnchorLink>
                  <AnchorLink
                    onClick={() => {
                      handleDataLayerPush(userData, "footer_link_clicked", "about_us")
                    }}
                    className="footer_aboutus_web"
                    target="_blank"
                    href="https://radiusagent.com/about"
                  >
                    About Us
                  </AnchorLink>
                  <AnchorLink
                    onClick={() => {
                      handleDataLayerPush(userData, "footer_link_clicked", "careers")
                    }}
                    className="footer_careers_web"
                    target="_blank"
                    href="https://radiusagent.applytojob.com/apply"
                  >
                    Careers
                  </AnchorLink>
                </AnchorsWrap>
                <GetConnected desktopOnly userData={userData} />
              </Row>
              <Row>
                <MobileCTAWrap>
                  <GetConnected />
                  <BlueButton
                    height="45px"
                    mobileMinWidth="212px"
                    onClick={() => {
                      handleDataLayerPush(userData, "footer_link_clicked", "join_the_community")
                      const url = isCurrentUserOniOS
                        ? "https://itunes.apple.com/us/app/agentdesks-real-estate-agents/id974478110?mt=8"
                        : "https://play.google.com/store/apps/details?id=com.agentdesks.android.crm&hl=en"
                      root.open(url, "_blank")
                    }}
                  >
                    Join The Community
                  </BlueButton>
                </MobileCTAWrap>
                <DesktopWrap>
                  <JoinOurCommunity>
                    <p>Join Our Community</p>
                  </JoinOurCommunity>
                  <ScanTheApp>
                    <p>Scan to download the Radius Community App on your phone.</p>
                  </ScanTheApp>
                  <QRCode>
                    <img src={`${CLOUDFRONT}/QR_CODE_RADS.png`} alt="qr_radius_png" />
                  </QRCode>
                  <AvailableOn>
                    <p>
                      Available on
                      {" "}
                      <span
                        style={{
                          position: "relative",
                          left: "6px",
                        }}
                      >
                        <img src={`${CLOUDFRONT}/apple_icon_new.svg`} alt="apple" />
                      </span>
                      <span>
                        <img src={`${CLOUDFRONT}/android_icon_new.svg`} alt="apple" />
                      </span>
                    </p>
                  </AvailableOn>
                  {/* <GradientBox
                    customClass="gradient-wrap-black"
                    customPadding="18px 32px 28px 32px"
                  >
                    <AppForm
                      className="form_submit_community_benefits_web"
                      noBorder
                      submittingForm={submittingInterestForm}
                      appDownloadSMSData={appDownloadData}
                      footer
                    // width="600px"
                    />
                  </GradientBox> */}
                </DesktopWrap>
              </Row>
            </SectionOne>
            <SectionTwo>
              <Row>
                <LegalText
                  mobile
                >
                  <p>Radius Agent is a licensed real estate broker headquartered in the below address and licensed to do business in the below states. Radius is an Equal Opportunity Employer and supports the Fair Housing Act.</p>
                </LegalText>
              </Row>
              <Row
                desktopMarginTop="20px"
              >
                <LegalText noMargin>
                  <h1>Radius Agent - Headquarters</h1>
                  <p>1160 Battery Street East</p>
                  <p>San Francisco CA 94111</p>
                </LegalText>
              </Row>
              <Row
                desktopMarginTop="20px"
              >
                <LegalText
                  lH="216.5%"
                  mobile
                >
                  <p>
                    {`CA DRE #02051216, CO DRE #100106082, FL DBPR #CQ1068468, GA REC #79559,
                    OR REA #201252778, TX TREC #9011839, WA DOL #22017298`}
                  </p>
                </LegalText>
              </Row>
              <Row
                fD="column"
              >
                <a
                  onClick={() => {
                    handleDataLayerPush(userData, "footer_link_clicked", "consumer_notice")
                  }}
                  href="https://www.radiusagent.com/consumer-notice"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    color: "#A5A8FF",
                    cursor: "pointer",
                  }}
                >
                  <LegalText
                    anchor
                  >
                    <p>Consumer Notice</p>
                  </LegalText>
                </a>
                <a
                  onClick={() => {
                    handleDataLayerPush(userData, "footer_link_clicked", "information_about_brokerage_services")
                  }}
                  href="https://www.radiusagent.com/information-about-brokerage-services"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    color: "#A5A8FF",
                    cursor: "pointer",
                  }}
                >
                  <LegalText
                    anchor
                  >
                    <p>Information About Brokerage Services</p>
                  </LegalText>
                </a>
                <a
                  onClick={() => {
                    handleDataLayerPush(userData, "footer_link_clicked", "affliated_business_arrangement_disclosure")
                  }}
                  href="https://www.radiusagent.com/affliated-business-arrangement-disclosure"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{
                    color: "#A5A8FF",
                    cursor: "pointer",
                  }}
                >
                  <LegalText
                    anchor
                  >
                    <p>Affiliated Business Arrangement Disclosure</p>
                  </LegalText>
                </a>
              </Row>
              <Row>
                <EqualHousing>
                  <img src={`${CLOUDFRONT}/EqualHousingNew.png`} alt="rads_inv" />
                </EqualHousing>
              </Row>
            </SectionTwo>
          </UpperRow>
          <BottomRow>
            <Copyright
              blue
              uppercase
            >
              <p>©2016-2023 Agentdesks Incorporated. All rights reserved.</p>
            </Copyright>
            <Copyright>
              <img src={`${CLOUDFRONT}/RadsCroppedInv.png`} alt="rads_inv" />
              <p>
                Radius® is a registered service mark of Agentdesks Incorporated.
              </p>
            </Copyright>
          </BottomRow>
        </Wrapper>
      </Wrap>
    )
  }
}

export default appContainer(ApplyFlowContainer(RealtyContainer(RARPopUpContainer(Footer))))
