import React from "react"
import styled from "styled-components"
import TechAssets from "container/TechAssets"
import Hero from "./Hero"
import ActionContainer from "./ActionContainer"
import FirstBlockTestimonial from "./FirstBlockTestimonial"
import TodaysRates from "./TodaysRates"
import Speed from "./Speed"
import Elevated from "./Elevated"
import Partner from "./Partner"
import ApplyForm from "./ApplyForm"
import Footer from "./Footer"
import MortgageForm from "./MortgageForm"

const Wrap = styled.div``

const MortgageLandingPage = ({
  isContactFormOpen,
  toggleInterestForm,
  showMortgageForm,
  toggleMortgageForm,
}) => {
  return (
    <>
      <Wrap>
        <Hero />
        <ActionContainer
          toggleInterestForm={toggleInterestForm}
        />
        <FirstBlockTestimonial />
        {/* <TodaysRates /> */}
        <Speed />
        <Elevated />
        <Partner
          toggleInterestForm={toggleInterestForm}
        />
        <Footer />
      </Wrap>
      {isContactFormOpen && (
        <ApplyForm
          toClose={() => {
            toggleInterestForm(false)
          }}
        />
      )}
      <MortgageForm />
    </>
  )
}

export default TechAssets(MortgageLandingPage)
