import { combineReducers } from "redux"
import { routerReducer as routing } from "react-router-redux"
import { reducer as formReducer } from "redux-form"
import AppReducer from "container/App/reducer"
import ReferralReducer from "container/Referral/reducer"
import ClientReducer from "container/Client/reducer"
import ProfileReducer from "container/Profile/reducer"
import NavbarReducer from "container/Navbar/reducer"
import SettingsReducer from "container/Settings/reducer"
import ContactReducer from "container/StandAlone/ContactUs/reducer"
import SubmitReferral from "container/StandAlone/SubmitReferral/reducer"
import NetworkReducer from "container/Network/reducer"
import FormInputReducer from "container/FormInput/reducer"
import SearchReducer from "container/Search/reducer"
import NonAssistReducer from "container/NonAssist/reducer"
import ClientProfile from "container/ClientProfile/reducer"
import MarketplaceReducer from "container/Marketplace/reducer"
import AuthReducer from "container/Auth/reducer"
import AcademyReducer from "container/Academy/reducer"
import PropertyStatusReducer from "container/PropertyStatus/reducer"
import ProductInterestReducer from "container/ProductInterestModal/reducer"
import PubNubActivitiesReducer from "container/PubNubActivities/reducer"
import RealtyReducer from "container/Realty/reducer"
import PropertyDetailsReducer from "container/PropertyDetails/reducer"
import mentoringRarDashboardReducer from "container/Mentoring/reducer"
import techAssetsRarDashboardReducer from "container/TechAssets/reducer"
import inviteReducer from "container/App/inviteFeatureReducer"
import OnboardingReducer from "container/App/OnboardingReducer"
import leaderboardRarDashboardReducer from "container/Leaderboard/reducer"
import eventRoomReducer from "container/EventRoom/reducer"
import listingPageReducer from "container/ListingPage/reducer";
import transactionReducer from "container/Realty/TransactionFormReducer"
import teamProfileReducer from "container/Realty/TeamProfileReducer"
import BrokerJobsReducer from "container/BrokerJobs/reducer"
import recruitAndEarnV2Reducer from "container/App/recruitAndEarnV2Reducer"
import RoomsReducer from "container/Rooms/reducer"
import OnboardingSetupReducer from "container/OnboardingSetup/reducer"
import MarketingReducer from "container/Marketing/reducer"
import CRMV2Reducer from "container/CRM/reducer"
import ListingsV2Reducer from "container/ListingsV2/reducer"
import ListingsCoversheetReducer from "container/Listings/reducer"
import OffersV2Reducer from "container/OffersV2/reducer"

const rootReducer = combineReducers({
  app: AppReducer,
  auth: AuthReducer,
  referral: ReferralReducer,
  client: ClientReducer,
  routing,
  form: formReducer,
  profile: ProfileReducer,
  navbar: NavbarReducer,
  settings: SettingsReducer,
  contactUs: ContactReducer,
  network: NetworkReducer,
  formInput: FormInputReducer,
  search: SearchReducer,
  submitReferral: SubmitReferral,
  clientProfile: ClientProfile,
  marketplace: MarketplaceReducer,
  nonAssist: NonAssistReducer,
  academy: AcademyReducer,
  propertyStatus: PropertyStatusReducer,
  productInterest: ProductInterestReducer,
  pubNub: PubNubActivitiesReducer,
  realty: RealtyReducer,
  propertyDetails: PropertyDetailsReducer,
  mentoringRarDashboard: mentoringRarDashboardReducer,
  techAssetsRarDashboard: techAssetsRarDashboardReducer,
  inviteFeatureReducer: inviteReducer,
  leaderBoardRarDashboard: leaderboardRarDashboardReducer,
  onboarding: OnboardingReducer,
  eventRoom: eventRoomReducer,
  listingPage: listingPageReducer,
  transactionReducer,
  teamProfileReducer,
  brokerJobsDashboard: BrokerJobsReducer,
  rooms: RoomsReducer,
  recruitAndEarnV2Reducer,
  onboardingSetup: OnboardingSetupReducer,
  marketing: MarketingReducer,
  CRMV2: CRMV2Reducer,
  listingsV2: ListingsV2Reducer,
  listings: ListingsCoversheetReducer,
  offersV2: OffersV2Reducer,
})

export default rootReducer
