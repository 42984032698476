import React from "react"
import { Link, withRouter } from "react-router-dom"
import root from "window-or-global"
import cookie from "services/CookieStorage"
import queryString from "query-string"
import styled from "styled-components"
import ProfileDropdown from "dumbComponents/common/Navbar/ProfileDropdown"
import Container from "container/Navbar"
import appContainer from "container/App"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import OnboardingContainer from "container/OnboardingSetup"
import applyFlowContainer from "container/App/applyFlowContainer"
import RadiusBrandImg from "images/RadiusAgentNewLogoNoBar.svg"
import NewRadiusLogo from "images/home-v7/NewRadiusLogo.svg"
//import DropdownMenu from "@ui/DropdownMenu"
import AgentSearchInput from "dumbComponents/common/Navbar/AgentSearchInput"
import ReferAnAgentNav from "dumbComponents/common/Navbar/ReferAnAgentNav"
import {
  isCRS,
  //isBrowser,
  searchToObject,
  checkForUserObject,
  showFeatureTab,
  //getQueryFromURL,
} from "services/Utils"
import colors from "@colors"
import { ATC } from "@fonts"
import { handleDataLayerPush } from "services/dataLayerUtil"
//import RadiusAssist from "images/icons/assist-icon.svg"
//import { getNavbarIcon, InviteGiftIcon } from "./common"
import { SIDEBAR_WIDTH, NAVBAR_HEIGHT, NAVBAR_LEFT_RIGHT_SPACING } from "@consts"
import { ShimmerBox } from "shared/styles/animation"
import { isEmpty, isEqual } from "lodash"
import ThirdPartyNavbar from "./ThirdPartyNavbar"
import LoginButton from "./LoginButton"
import LinkOrA from "./LinkOrA"
import InviteBanner from "./InviteBanner"
import MobileMenu from "./MobileMenu"
import PressReleaseBanner from "./PressReleaseBanner"
import NewMainNav from "./NewMainNav"
import IncompleteProfileBanner from "./IncompleteProfileBanner"

const Wrapper = styled.div`
  display: none; 
  ${props => props.isAppMounted && `
    display: block;
  `}
  transition-duration: 0.5s;
  position: fixed;
  background: #fff;
  top: 0px;
  left: 0px;
  width: 100%;
  height: ${NAVBAR_HEIGHT}px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-right: ${NAVBAR_LEFT_RIGHT_SPACING}px;
  padding-left: 0;
  width: 100%;

  display: flex;
  justify-content: space-evenly;

  ${props => !props.hideBorder && `
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2.5px 0px;
  `}
  ${props => props.background && `
    background: ${props.background}
  `}
  z-index: 1000;
  font-family: ${ATC};


  ${props => props.isShowInviteeBanner && `
    height: 118px;
  `}
  ${props => props.isCardInactive && `
     top: 45px;
  `}

  ${props => props.isVersionV2 && !props.isLogged && `
    box-shadow: none;
  `}

  @media(min-width: 1100px) {
    justify-content: space-between
    ${props => props.isTCView && `
      justify-content: space-around;
    `}
    // gap: 50px;
  }


  @media(min-width: 1366px) {
    //justify-content: space-between;
    //padding: 0px 10% 0px 10%;
    justify-content: space-evenly;
    gap: ${props => props.modifyNavbar ? "480px" : "480px"}
  }

  @media(max-width: 1099px) {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: max-content;

    padding: 10px 10px 0px 10px;

    ${props => props.modifyNavbar && `
        justify-content: center;
        bottom: 0;
        top: unset;
        padding: 0px 10px 10px 10px;
    `}
  }
`

const UpgradeButton = styled.button`
  width: 90px;
  height: 30px;
  object-fit: contain;
  position: relative;
  top: 21px;
  left: 59px;
  background-color: ${colors.textGreen};
  color: ${colors.white};
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  @media(max-width: 1280px) {
    left: 19px;
  }
`

const Holder = styled.div`
  background: transparent;
  margin: 0 auto;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;

  z-index: 1000;
  font-family: ${ATC};

  @media(min-width: 1100px) {
    justify-content: space-between;
    ${props => props.isTCView && `
      justify-content: space-around;
    `}
    gap: ${props => props.modifyNavbar ? "460px" : "330px"}

    ${props => props.isLogged && `
       gap: 54px;
       align-items: center;
    `}
  }

   @media(min-width: 1366px) {
    gap: ${props => props.modifyNavbar ? "480px" : "180px"}

    ${props => props.isLogged && `
      gap: 132px;
      align-items: center;
      justify-content: flex-end;
    `}
  }

  @media(max-width: 1099px) {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: max-content;

    ${props => props.modifyNavbar && `
      justify-content: center;
    `}
    padding: 10px 10px 0px 10px;
  }
`

export const NavLink = styled.a`
  border-bottom: 4px solid transparent;
  padding: 5px 10px 0 10px;
  position: relative;
  height: 69px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(51, 51, 51);
  font-size: 12px;
  text-decoration: none;
  margin-top: 2px;
  color: ${props => (props.isActive ? `${colors.primaryColor}` : "")};
  border-bottom: ${props => (props.isActive ? `4px solid ${colors.primaryColor}` : "")};

  > p {
    margin: 0px;
    padding: 0px;
  }

  &:hover {
    cursor: pointer;
    color: ${colors.primaryColor};
    border-bottom: 4px solid ${colors.primaryColor};
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    svg path {
      fill: #11adf3;
    }
  }
`

const AccountNavWrap = styled.div.attrs({
  id: "AccountNavWrap",
})`
  display: flex;
  border-left: 1px solid ${colors.reddishGrey};
  height: 70px;
  align-items: center;
  gap: 54px;
  
  ${props => props.isVersionV2 && `
    border-left: none;
  `}
  
  &:hover {
    ul {
      display: block;
    }
  }

  @media(max-width: 1099px) {
    order: 2;
    gap: unset;
  }
`

/**NEW STYLES*/

const LogoDiv = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  align-items: center;
  gap: 48px;
  display: flex;
  padding-left: 0;
  margin-right: auto;

  @media(max-width: 1099px) {
   
    flex-shrink: 0;
     ${props => props.modifyNavbar && `
      display: none;
    `}
  }
`

const LogoWrapperRadius = styled.div`
  width: ${SIDEBAR_WIDTH}px;

  img {
    height: 31.7005558013916px;
    width: 149.9988250732422px;
  }


  @media(max-width: 1099px) {
    img {
      width: 118.333px;
      height: 25px;
    }
  }
`

const MobileLogoDiv = styled.button`
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;

  img {
    width: 27px;
    height: 27px;
  }

  @media(max-width: 1099px) {
    display: block;
    order: 1;
  }

  ${props => props.modifyNavbar && `
    display: none;
  `}
`

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isClient: false,
      isLogged: true,
      isLanding: true,
      modifyNavbar: false,
    }
  }

  componentDidMount() {
    const {
      fetchSubscriptions,
      fetchPricingPlans,
      getDisplayScreen,
      history,
    } = this.props
    const query = searchToObject()
    const screenWidth = root.innerWidth
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    this.searchQuery = query
    this.isBrowserVal = true
    const initState = {
      isLogged: checkForUserObject("secureId"),
      inviteCode: cookie.load("inviteCode"),
      isClient: true,
      isMobile: screenWidth < 940,
      isCrs: isCRS(),
    }
    this.setState({
      ...initState,
      isIosBrowser: parsedQuery && parsedQuery.platform === "ios",
    })
    fetchSubscriptions()
    fetchPricingPlans()
    //togglePressReleaseBanner({ isOpen: true })

    // root.addEventListener("scroll", this.handleNavbarDynamic)

    //Call Display Screen API to fetch payment status

    const secureId = cookie.load("secureId")
    const user = cookie.load("user")

    if (user.email && user.email !== undefined) {
      getDisplayScreen({
        history,
        email: user.email,
        secureId,
        loggedIn: secureId,
      })
    }
  }

  handleNavbarDynamic = (e) => {
    const {
      toggleLandingPageMQLFormToggle,
      heroMQLFormData,
    } = this.props

    if (root.scrollY > 300) {
      this.setState({
        modifyNavbar: true,
      })
      // if (heroMQLFormData.bool) {
      //   toggleLandingPageMQLFormToggle({
      //     heroMQLFormData: {
      //       bool: false,
      //       className: "",
      //     },
      //   })
      // }
    } else {
      this.setState({
        modifyNavbar: false,
      })
    }
  }

  redirectToISALanding = () => {
    const {
      history: {
        push,
      },
    } = this.props
    // push("/radius-assist?isNoRedirection=true")
  }

  getIsPremium = (categorizedSubscriptions) => {
    const {
      pricingPlansMap,
    } = this.props
    const currentPlan = (categorizedSubscriptions.rtl || categorizedSubscriptions.blu)
    const mappedPlan = pricingPlansMap[currentPlan]
    if (mappedPlan) {
      if (mappedPlan.tier === "PREMIUM") {
        return true
      }
    }
    return false
  }

  getIsLegacy = (categorizedSubscriptions) => {
    const {
      pricingPlansMap,
    } = this.props
    const currentPlan = (categorizedSubscriptions.rtl || categorizedSubscriptions.blu)
    const mappedPlan = pricingPlansMap[currentPlan]
    if (mappedPlan) {
      return !!mappedPlan.isLegacy
    }
    return false
  }

  handleLogoClick = (userData) => {
    handleDataLayerPush(userData, "radius_logo_clicked", "")
    const { isLogged } = this.state
    const { history } = this.props
    if (isLogged) {
      history.push("/community")
    } else {
      root.location.href = "https://www.radiusagent.com"
    }
  }

  handleHomeClick = () => {
    const { getFeedByHomeClick, history } = this.props
    getFeedByHomeClick({
      push: history.push,
    })
  }

  render() {
    const {
      isLogged,
      isClient,
      isCrs,
      isMobile,
      isLanding,
      inviteCode,
      isIosBrowser,
      randomNumber,
      modifyNavbar,
    } = this.state
    const {
      fetchNotification,
      history: {
        push,
      },
      notifications,
      fetchNotificationUnreadCount,
      unreadCount,
      location,
      profileImage,
      isNewFeeds,
      isFetchingNotifications,
      isFetchingFeeds,
      isCardInactive,
      isStartNavbarSpotlightFlow,
      categorizedSubscriptions,
      isShowInviteeBanner,
      toggleInviteBanner,
      shareTheLoveInviteeResponse,
      isHeaderBannerVisible,
      isUserATeamMember,
      isUserATeamAdmin,
      isSubscribedToAnyISAPlan,
      isAppMounted,
      finishedFetchingSubscriptions,
      user,
      isOpenPressReleaseBanner,
      togglePressReleaseBanner,
      isIncompleteProfileBannerVisible,
      toggleIncompleteProfileBanner,
      updateIncompleteProfile,
      isBroker,
      headerVersion,
      history,
      hideLogin,
      hideBorder,
      background,
      useLoggedLocal,
      hideResources,
      hideOurAgents,
      vwoVariation,
      vwoActiveVariant,
      getTeamDetailsForLogoResponse,
      showLoaderUntilTeamDetailsAreFetched,
    } = this.props

    const {
      data: teamDetails,
      isFetching: isFetchingTeamDetails,
      isFetchingMore: isFetchingMoreTeamDetails,
    } = getTeamDetailsForLogoResponse || {}

    // Location will be only available on server not on client.
    const isVersionV2 = headerVersion === "v2" || true
    let query = {}
    if (location) {
      query = queryString.parse(location.search)
    } else {
      query = this.searchQuery || {}
    }
    const { pathname } = root.location || []
    const isShowBanner = root.location.pathname !== "/radius-assist" && root.location.pathname !== "/realty" && root.location.pathname !== "/academy"
    let isPremium = false
    let currentPlanLegacy = false
    if (categorizedSubscriptions !== undefined && Object.keys(categorizedSubscriptions).length > 0) {
      isPremium = this.getIsPremium(categorizedSubscriptions)
      currentPlanLegacy = this.getIsLegacy(categorizedSubscriptions)
    }
    const { isCrs: isCrsURL, isWcr, isAreaa } = query

    const noSubscriptions =
      (Object.keys(categorizedSubscriptions).length <= 0
        && isHeaderBannerVisible
        && finishedFetchingSubscriptions
        && !cookie.load("bannerClosed"))
    const showUpgradeButton =
      (!isLanding
        && isLogged
        && !isPremium
        && Object.keys(categorizedSubscriptions).length > 0
        && !isUserATeamMember
        && !isUserATeamAdmin
        && finishedFetchingSubscriptions
        && !currentPlanLegacy)
    const isSearchPage = root.location && root.location.pathname === "/search"

    const isScheduleRoomPage = pathname === "/scheduledrooms"

    const evenVariant = vwoVariation === "Blue" || vwoActiveVariant === "Blue"

    if (isCrsURL === "true" || isWcr === "true" || isAreaa === "true") {
      return (
        <ThirdPartyNavbar
          thirdParty={query}
          profileImage={profileImage}
          user={user}
          isAdvanceUser={isSubscribedToAnyISAPlan || isUserATeamMember}
        />
      )
    }

    const variantClass = evenVariant ? "blue_radius" : "white_radius"
    const loginVariant = evenVariant ? "blue_variant" : "white_variant"

    return (
      <Wrapper
        isAppMounted={isAppMounted}
        hideBorder={hideBorder}
        background={background}
        className={isMobile && isSearchPage ? "search-page main-navbar" : "main-navbar"}
        isShowInviteeBanner={isShowInviteeBanner}
        isCardInactive={(isCardInactive || noSubscriptions) && !isLanding && isAppMounted}
        isVersionV2={isVersionV2}
        isLogged={isLogged}
        isEven={evenVariant}
        isTCView={!showFeatureTab()}
        modifyNavbar={modifyNavbar}
      >
        <Holder
          modifyNavbar={modifyNavbar}
          isLogged={isLogged}
        >
          {
            showLoaderUntilTeamDetailsAreFetched ? (
              <div style={{ marginRight: "auto" }}>
                <ShimmerBox br="5px" w={`${SIDEBAR_WIDTH - 40}px`} h="40px" ml="20px" />
                <ShimmerBox br="5px" w={`${SIDEBAR_WIDTH / 2}px`} h="40px" ml="40px" />
                <ShimmerBox br="5px" w={`${SIDEBAR_WIDTH / 2}px`} h="40px" ml="20px" mr="auto" />
              </div>
            ) : (!teamDetails?.hide_radius_logo || teamDetails?.hide_radius_logo === 0 || teamDetails?.hide_radius_logo === false) ? (
              <>
                <LogoDiv
                  modifyNavbar={modifyNavbar}
                  onClick={() => this.handleLogoClick(user)}
                  isEven={evenVariant}
                >
                  <LogoWrapperRadius>
                    <img
                      className="radius_nav_logo"
                      src={`${CLOUDFRONT}/RadiusLogo3x.png`}
                      alt="radius_circle"
                    />
                  </LogoWrapperRadius>

                  <NewMainNav
                    isBroker={isBroker}
                    isLogged={useLoggedLocal ? false : isLogged}
                    getFeedByHomeClick={this.handleHomeClick}
                    isClient={isClient}
                    isAppMounted={isAppMounted}
                    isVersionV2={isVersionV2}
                    hideResources={hideResources}
                    hideOurAgents={hideOurAgents}
                    isEven={evenVariant}
                    modifyNavbar={modifyNavbar}
                  />
                </LogoDiv>
              </>
            ) : (
              <></>
            )
          }

          {false && isBroker && !isScheduleRoomPage && showFeatureTab() && (<ReferAnAgentNav />)}

          {false && isLogged && (
            <NewMainNav
              isBroker={isBroker}
              isLogged={useLoggedLocal ? false : isLogged}
              getFeedByHomeClick={this.handleHomeClick}
              isClient={isClient}
              isAppMounted={isAppMounted}
              isVersionV2={isVersionV2}
              hideResources={hideResources}
              hideOurAgents={hideOurAgents}
              isEven={evenVariant}
            />
          )}

          <AccountNavWrap isVersionV2={isVersionV2}>
            {isLogged ? (
              <ProfileDropdown
                profileImage={profileImage}
                user={user}
                isAdvanceUser={isSubscribedToAnyISAPlan || isUserATeamMember}
                isEven={evenVariant}
              />
            ) : (
              <>
                {/* {!modifyNavbar && (
                  <NewMainNav
                    isBroker={isBroker}
                    isLogged={useLoggedLocal ? false : isLogged}
                    getFeedByHomeClick={this.handleHomeClick}
                    isClient={isClient}
                    isAppMounted={isAppMounted}
                    isVersionV2={isVersionV2}
                    hideResources={hideResources}
                    hideOurAgents={hideOurAgents}
                    isEven={evenVariant}
                  />
                )} */}
                <LoginButton
                  isVersionV2={isVersionV2}
                  isEven={evenVariant}
                  loginVariant={loginVariant}
                  modifyNavbar={modifyNavbar}
                />
              </>
            )}
          </AccountNavWrap>
        </Holder>
      </Wrapper>
    )
  }
}

export default TeamProfileContainer(OnboardingContainer(applyFlowContainer(appContainer(withRouter(Container(Navbar))))))
