import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Wrap,
  RightPane,
  Container,
  ColLeft,
  ColRight,
  ImagesContainer,
  TabWrapper,
  TabBtns,
  MobileCarousel,
  DatesCardWrapper,
  OpenHouseDesc,
  OpenHouseHeader,
  ShimmerContainer,
  WebOnlyOpenHouse,
  StickyWrap
} from "./styles.js";
// import {GlobalModalStyle} from "./customModalStyles"
import { ShimmerBox } from "shared/styles/animation";
import CarouselWrapper from "./Carousel/index";
import ListingMaps from "./ListingMaps/index";
import OpenHouse from "./OpenHouse/index";
import PricingHistory from "./PricingHistory/index";
import GettingAround from "./GettingAround/index";
import NearBySchools from "./NearBySchools/index";
import NearByRestaurants from "./NearByRestaurants/index";
import WhatsSpecial from "./WhatsSpecial/index";
import { Helmet } from "react-helmet";
import ListingPageContainer from "container/ListingPage/ListingPageContainer";
import NoDataFound from "../NoDataFound/index";
import urlParser from "url-parse";
import { isSuccess, getQueryFromURL } from "services/Utils";
import root from "window-or-global";
import queryString from "query-string";
import RightStickyGrid from "./RightStickyCard/index";
import DatesCardCarousel from "./DatesCardCarousel/index";
// import TopStickNav from "./TopStickyNav/index"
import { Button, Checkbox, Divider, Tabs, Modal } from "antd";
import ShareIcon from "./Icons/share.js";
import HeartIcon from "./Icons/heart.js";
import RequestTourModal from "./RequestTourModal/index";
import CarouselMobile from "./Carousel/mobile";
import ContactAgentModal from "./ContactAgentModal/index";
import createToast from "dumbComponents/common/Toast/customToast";

const ListingPageLayout = ({
  fetchListingPageDetails,
  listingPageResponse,
  match,
}) => {

  // comment this for ssr 
  // const url = getQueryFromURL(root.location.pathname + root.location.search);
  // const { MLS_ID, Property_id } = match.params;
  // useEffect(() => {
  //   if (!listingPageResponse.data) {
  //     const payload = {
  //       MLS_ID: MLS_ID,
  //       Property_id: Property_id,
  //     };
  //     fetchListingPageDetails(payload);
  //   }
  //   const url =
  //     "http://localhost:8087/property/OC24057601/Address-123?page=mobile&unique_mls_id=2034OC24057601";

  //   const queryParams = getQueryParams(url);
  //   const location = urlParser(url);
  //   const query = queryString.parse(location.query);
  //   console.log(query);
  // }, [MLS_ID, Property_id]);
  // const getQueryParams = (url) => {
  //   const queryString = url.split("?")[1]; // Split the URL at '?' and take the second part
  //   const params = new URLSearchParams(queryString); // Create a URLSearchParams object

  //   const queryParamsArray = [];
  //   params.forEach((value, key) => {
  //     queryParamsArray.push({ key, value });
  //   });

  //   return queryParamsArray;
  // };


  const [openContactModal, setopenContactModal] = useState(false);

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    createToast("Link Copied !");
  }

  const topNavBtns = (
    <div style={{ display: "flex" }}>
      <TabBtns onClick={copy}>
        <ShareIcon style={{paddingRight: "8px", height: "30px", width: "30px"}}/> Share this property
      </TabBtns>
      <TabBtns onClick={() => setopenContactModal(true)}>
        <HeartIcon style={{paddingRight: "8px", height: "30px", width: "30px"}}/> Interested
      </TabBtns>
    </div>
  );

  const tabItems = [
    {
      key: "overview",
      label: "Overview",
      children: "",
    },
    {
      key: "openHouses",
      label: "Open Houses",
      children: "",
    },
    {
      key: "gettingAround",
      label: "Getting Around",
      children: "",
    },
    {
      key: "pricingHistory",
      label: "Pricing & Tax History",
      children: "",
    },
  ];

  const handleTabClick = (key) => {
    const element = document.getElementById(key);
    console.log(key);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [openModal, setopenModal] = useState(false);
  const [visitingDateTime, setVisitingDateTime] = useState();

  const handleSelectedDateTime = (dateTime) => {
    setopenModal(true);
  };
  // -----post date and time data-----

  const meta = {
    title: `${listingPageResponse?.data?.UnparsedAddress}, ${listingPageResponse?.data?.PostalCode} | MLS #${listingPageResponse?.data?.Id} | Radius`,
    description: `This ${listingPageResponse?.data?.LivingArea} ${listingPageResponse?.data?.LivingAreaUnits} Single Family home has ${listingPageResponse?.data?.BedroomsTotal} bed(s) and ${listingPageResponse?.data?.BathroomsFull} bath(s). It is located at ${listingPageResponse?.data?.UnparsedAddress}, ${listingPageResponse?.data?.PostalCode}.`,
    url: `${WEB_URL}`,
    imageUrl: `${listingPageResponse?.data?.Media[0]}`,
    keywords: `Buy house in ${listingPageResponse?.data?.PostalCode}, Buy House with ${listingPageResponse?.data?.BedroomsTotal} Bed(s), Buy House with ${listingPageResponse?.data?.BathroomsFull} bath(s),`,
  };

  return (
    <>
      {listingPageResponse?.data?.Id ? (
        <>
          <Helmet>
            <title>{meta.title}</title>
            <meta property="og:title" content={meta.title} />
            <meta property="title" content={meta.title} />
            <meta property="og:url" content={meta.url} />
            <meta property="url" content={meta.url} />
            <meta property="og:image" content={meta.imageUrl} />
            <meta property="og:description" content={meta.description} />
            <meta property="description" content={meta.description} />
            <meta property="keywords" content={meta.keywords} />
          </Helmet>

          {openContactModal && (
            <ContactAgentModal
              openModal={openContactModal}
              onClose={() => setopenContactModal(false)}
              address={listingPageResponse?.data?.UnparsedAddress}
              pinCode={listingPageResponse?.data?.PostalCode}
            ></ContactAgentModal>
          )}

          {/* <RequestTourModal openModal={openModal} onClose={() => setopenModal(false)} isVisitingDateTime={openModal} address={listingPageResponse?.data?.UnparsedAddress}
          pinCode={listingPageResponse?.data?.PostalCode}/> */}

          <TabWrapper>
            <Tabs
              tabBarExtraContent={topNavBtns}
              items={tabItems}
              onTabClick={handleTabClick}
              defaultActiveKey="1"
              style={{ width: "1355px", zIndex: "10", margin: "0px" }}
            />
          </TabWrapper>

          <ImagesContainer id="overview">
            {listingPageResponse?.data?.Media?.length > 0 && (
              <CarouselWrapper />
            )}
          </ImagesContainer>

          <Container>
            <ColLeft>
              <MobileCarousel>
                <CarouselMobile listingPageResponse={listingPageResponse} />
              </MobileCarousel>
              <Wrap style={{paddingTop: "0px"}}>
                <WhatsSpecial />
                {listingPageResponse?.data?.Latitude &&
                  listingPageResponse?.data?.Longitude && <ListingMaps />}
                <WebOnlyOpenHouse>
                  <OpenHouse />
                </WebOnlyOpenHouse>
                <hr id="gettingAround"></hr>
                {listingPageResponse?.data?.gettingAround?.bikeScore?.score ||
                listingPageResponse?.data?.gettingAround?.walkScore?.score ? (
                  <GettingAround />
                ) : null}
                <NearBySchools />
                <hr></hr>
                <NearByRestaurants />
                <hr id="pricingHistory"></hr>
                <PricingHistory />
              </Wrap>
            </ColLeft>

            <ColRight>
              <StickyWrap
                style={{
                  borderRadius: "16px",
                  border: "1px solid #E0E0EB",
                }}
              >
                <RightStickyGrid listingPageResponse={listingPageResponse} />
              </StickyWrap>
            </ColRight>
          </Container>
        </>
      ) : (
        <div style={{padding: "0 20px", display: "flex", alignItems: "center", flexDirection: "column"}}>
          <ShimmerContainer>
              <ShimmerBox w="96%" h="60px" style={{ margin: "10px auto", marginTop: "20px" }} />
              <ShimmerBox w="96%" h="400px" style={{ margin: "20px auto" }} />
          </ShimmerContainer>
          <Container style={{paddingTop: "0px"}}>
            <ColLeft style={{padding: "0 20px"}}>
              <ShimmerBox w="100%" h="300px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="200px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="200px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="200px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="200px" style={{ margin: "20px auto" }} />
              <ShimmerBox w="100%" h="300px" style={{ margin: "20px auto" }} />
            </ColLeft>
            <ColRight>
              <ShimmerBox w="100%" h="300px" style={{ margin: "20px auto" }} />
            </ColRight>
          </Container>
        </div>
      )}
    </>
  );
};

export default ListingPageContainer(ListingPageLayout);
