import {
  takeLatest,
  call,
  put,
  select,
} from "redux-saga/effects"
import axios from "axios"
import Cookies from "react-cookies"
import {
  BuyerAgentInfoRoute,
  DocListsRoute,
  SubmitAndSendAllDocuments,
} from "dumbComponents/Offers/consts"
import {
  BUYER_INFORMATION_STEP,
  PROPERTY_INFORMATION_STEP,
  PROPERTY_INFORMATION_FORMS,
  DEAL_TERMS_STEP,
  DEAL_TERMS_FORMS,
} from "dumbComponents/OffersV2/consts"
import * as ListingsActions from "container/Listings/actions"
import { get } from "lodash"
import customToast from "dumbComponents/OffersV3/components/Toast/customToast"
import root from "window-or-global"
// import { toast as createToastNew } from "react-toastify"
import { format } from "date-fns"
import createToast from "@ui/Toast"
import createToastNew from "dumbComponents/common/Toast/customToast"
import { isSuccess } from "services/Utils"
import CookiesStorage from "services/CookieStorage"
import { updateCreateDealFormStageAPI } from "container/Realty/api"
import * as OfferV2Actions from "container/OffersV2/actions"
import {
  fetchProductAndServices,
  saveRarTechRequestApi,
  sendSlackNotificationForProductApi,
  getRarAssetsApi,
  rarSaveCreativeRequestApi,
  saveDesignReferenceImageApi,
  getFinancingClientListApi,
  getFinancingSettingApi,
  rarSaveFinancingClientListApi,
  fetchStateApi,
  fetchTodaysPriceApi,
  getPopupDetailsApi,
  fetchBrandingAssetsAPI,
  fetchBrandingInfoAPI,
  updateBrandingInfoAPI,
  createBrandingInfoAPI,
  getAllListingsAPI,
  getListingStatusMapAPI,
  getListingCoverSheetAPI,
  createListingCoverSheetAPI,
  updateListingCoverSheetAPI,
  updateListingCoverSheetVendorAPI,
  getContractByIdAPI,
  createContractAPI,
  updateContractAPI,
  getGoogleAuthTokenAPI,
  getListingHistoryAPI,
  createOfferAPI,
  getAllOffersAPI,
  getDealBasedOnIDAPI,
  updateOfferDealStatusAPI,
  updateOfferAPI,
  updateVendorAPI,
  updateLenderAPI,
  getListingDocumentsAPI,
  sendListingDocumentsAPI,
  createSendeViewAPI,
  downloadEnvelopeDocAPI,
  getListingStepsAPI,
  createReceiverViewAPI,
  createReceiverViewOfferAPI,
  updateDocusignEnvelopeStatusAPI,
  fetchDealTimelineAPI,
  fetchDealTCDetailsAPI,
  updateDealTimelineAPI,
  createAllListingImagesAPI,
  getOfferDocumentsAPI,
  sendForVoidAndRestartAPI,
  sendTroubleShootMessageAPI,
  createEditViewAPI,
  createEditViewOfferAPI,
  uploadListingDocToDocusignAPI,
  sendForVoidAndDeleteAPI,
  regenerateListingDocumentsAPI,
  sendOfferDocumentsAPI,
  createSendeViewOfferAPI,
  getOfferStepsAPI,
  updateOfferDocusignEnvelopeStatusAPI,
  sendForVoidAndRestartOfferAPI,
  sendTroubleShootMessageOfferAPI,
  uploadOfferDocToDocusignAPI,
  uploadCoverLetterDocToDocusignAPI,
  sendForVoidAndDeleteOfferAPI,
  regenerateOfferDocumentsAPI,
  getOffersStepsAPI,
  putCoverLetterDocsAPI,
  fetchCoverLetterDocsAPI,
  submitAndSendAllOffersFileAPI,
  sendAllOffersDocsAPI,
  sendDocusignDocsAPI,
  requestToCreateZipAPI,
  generateListingEnvelopeFromTemplateAPI,
  generateOfferEnvelopeFromTemplateAPI,
  fetchListingDocusignTemplatesAPI,
  fetchOfferocusignTemplatesAPI,
  getTemplatePreviewListingAPI,
  getTemplatePreviewOfferAPI,
  getAllListingsPostAPI,
  getAllOffersPostAPI,
  voidAndDuplicateCompletedOfferAPI,
  deleteCompletedListingAPI,
  deleteCompletedOfferAPI,
  voidAndDuplicateCompletedListingAPI,
  deleteCoverLetterAPI,
  sendMailToListingAgentsAPI,
  saveListingStepsAPI,
  sendMortgagePropertiesAPI,
  sendClientDetailsAPI,
  createContractV2API,
  updateContractV2API,
  getAgentInformationAPI,
  getOfferContractDetailsAPI,
  getContractFieldValidationAPI,
  convertOfferToContractAPI,
  convertTransactionToContractAPI,
  fetchContractDetailsBasedOnIdApi,
  convertListingToContractAPI,
  createSendeViewOfferChecklistAPI,
  createEditViewOfferChecklistAPI,
  downloadEnvelopeDocOfferChecklistAPI,
  sendForVoidAndRestartOfferChecklistAPI,
  sendForVoidAndDeleteOfferChecklistAPI,
  createReceiverViewOfferChecklistAPI,
} from "./api"
import {
  getRarProductAndServices,
  saveRarTechRequest,
  sendSlackNotificationForProduct,
  getRarAssets,
  rarSaveCreativeRequest,
  saveDesignReferenceImage,
  removeDesignReferenceImage,
  getFinancingClientList,
  getFinancingSetting,
  rarSaveFinancingClientList,
  toggleFinanceModal,
  showSuccessBar,
  fetchState,
  fetchTodaysPriceAction,
  getPopupDetailsAction,
  showPopupAction,
  toggleScreenPopup,
  setIsShow,
  fetchBrandingAssetsAction,
  fetchBrandingInfoAction,
  updateBrandingInfoAction,
  uploadDocsToBucketAction,
  createBrandingInfoAction,
  getAllListings,
  getListingStatusMap,
  getListingCoverSheet,
  createListingCoverSheet,
  updateListingCoverSheet,
  setLoaderOnAgentInfo,
  setDealToView,
  updateDealStatus,
  getContractById,
  createContract,
  updateContract,
  getGoogleAuthToken,
  resetContractsForm,
  resetListingsForm,
  getListingHistory,
  createOfferAction,
  getAllOffersAction,
  getCurrentDealDetailsAction,
  updateOfferDealStatusAction,
  updateOfferAction,
  updateVendorAction,
  updateLenderAction,
  toggleAllCashOfferAction,
  getListingDocuments,
  sendListingDocuments,
  createSendeView,
  downloadEnvelopeDoc,
  getListingSteps,
  downloadingDocs,
  toggleMortgagePopupAction,
  createReceiverView,
  createReceiverViewOffer,
  refreshListingDocuments,
  updateDocusignEnvelopeStatus,
  fetchDealTimelineAction,
  fetchDealTCDetailsAction,
  updateDealTimelineAction,
  imageUploadForListing,
  createAllListingImages,
  getOfferDocumentsAction,
  sendForVoidAndRestart,
  sendForVoidAndRestartOffer,
  sendTroubleShootMessage,
  createEditView,
  createEditViewOffer,
  uploadListingDocToDocusign,
  sendForVoidAndDelete,
  regenerateListingDocuments,
  setLoaderAction,
  setUnselect,
  getOfferDocuments,
  sendOfferDocuments,
  createSendeViewOffer,
  downloadEnvelopeDocOffer,
  getOfferSteps,
  updateOfferDocusignEnvelopeStatus,
  sendTroubleShootMessageOffer,
  uploadOfferDocToDocusign,
  uploadCoverLetterDocToDocusign,
  sendForVoidAndDeleteOffer,
  regenerateOfferDocuments,
  uploadCoverLetterAction,
  putCoverLetterDocsAction,
  fetchCoverLetterDocsAction,
  submitAndSendAllOffersFile,
  refreshOfferDocuments,
  requestToCreateZip,
  generateListingEnvelopeFromTemplate,
  generateOfferEnvelopeFromTemplate,
  fetchListingDocusignTemplates,
  fetchOfferocusignTemplates,
  getTemplatePreview,
  showCoverLetterResponseAction,
  voidAndDuplicateCompletedOfferAction,
  voidAndDuplicateCompletedListingAction,
  deleteCompletedListingAction,
  deleteCompletedOfferAction,
  deleteCoverLetter,
  sendMailToListingAgents,
  sendAllOffersDocsAction,
  sendDocusignDocsAction,
  saveListingStepsAction,
  toggleDocumentSelectionConfirm,
  sendMortgagePropertiesAction,
  sendClientDetailsAction,
  toggleMortgageForm,
  createContractV2Action,
  updateContractV2Action,
  showSuccessModalAction,
  getAgentDetailsAction,
  showUpdatingBuyerInformationLoader,
  getOfferContractDetailsAction,
  getContractFieldValidationAction,
  createOfferConvertedContractAction,
  createTransactionConvertedContractAction,
  dealCountUpdateAction,
  processBeforeViewingContractAction,
  createListingConvertedToContractAction,
  createSendeViewOfferChecklistAction,
  createEditViewOfferChecklistAction,
  downloadEnvelopeDocOfferChecklistAction,
  sendForVoidAndRestartOfferChecklistAction,
  sendForVoidAndDeleteOfferChecklistAction,
  createReceiverViewOfferChecklistAction,
  defineAgentTypeAction,
} from "./actions"

const toastConfig = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
}

const toastSuccessConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "blue-background ",
}

const toastErrorConfig = {
  position: "top-left",
  autoClose: 3000,
  hideProgressBar: true,
  className: "red-background ",
}

// https://api1.agentdesks.com/v1.0/
function* handleFetchRARProductServices() {
  try {
    // const agentId = cookie.load("productAgentId") || ""
    // const apiCall = agentId ? fetchProductAndServicesTest : fetchProductAndServices
    const res = yield call(fetchProductAndServices)
    if (isSuccess(res)) {
      yield put(getRarProductAndServices.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(getRarProductAndServices.failure(error))
  }
}

function* handleSaveRarTechRequest(action) {
  try {
    const res = yield call(saveRarTechRequestApi, action.data)
    if (isSuccess(res)) {
      yield put(saveRarTechRequest.success(res.data && res.data.response))
      const rarProductAndServices = yield select(state => state.techAssetsRarDashboard.rarProductAndServices)
      rarProductAndServices.data.products.forEach((rar) => {
        if (rar.id === action.data.rarTechAssetsId) {
          // eslint-disable-next-line no-param-reassign
          rar.requested = true
        }
      })
      rarProductAndServices.data.financing.forEach((rar) => {
        if (rar.id === action.data.rarTechAssetsId) {
          // eslint-disable-next-line no-param-reassign
          rar.requested = true
        }
      })
      rarProductAndServices.data.services.forEach((rar) => {
        if (rar.id === action.data.rarTechAssetsId) {
          // eslint-disable-next-line no-param-reassign
          rar.requested = true
        }
      })
      yield put(getRarProductAndServices.success(rarProductAndServices.data))
      createToast("Request successful !")
    }
  } catch (error) {
    yield put(saveRarTechRequest.failure(error))
    createToast("Request Error, Please try after sometime !")
  }
}

function* hadleSendSlackNotificationForProduct(action) {
  try {
    yield call(sendSlackNotificationForProductApi, action.data)
  } catch (error) {
    yield put(getRarProductAndServices.failure(error))
  }
}

function* handleGetRarAssets(action) {
  try {
    const res = yield call(getRarAssetsApi, action.data)
    if (isSuccess(res)) {
      yield put(getRarAssets.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(getRarAssets.failure(error))
  }
}

function* handleRarSaveCreativeRequest(action) {
  try {
    const { details, history } = action.data
    const res = yield call(rarSaveCreativeRequestApi, details)
    if (isSuccess(res)) {
      yield put(rarSaveCreativeRequest.success(res.data && res.data.response))
      // createToast("Creative request successful !")
      history.push("/realty/marketing-assets/creative-request-submitted")
    }
  } catch (error) {
    yield put(rarSaveCreativeRequest.failure(error))
    createToast("Request Error, Please try after sometime !")
  }
}

function* handleSaveDesignReferenceImage(action) {
  try {
    const res = yield call(saveDesignReferenceImageApi, action.data.fd)
    if (isSuccess(res)) {
      yield put(saveDesignReferenceImage.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(saveDesignReferenceImage.failure(error))
  }
}

function* handleRemoveDesignReferenceImage() {
  yield put(saveDesignReferenceImage.success({}))
}

function* handleGetFinancingClientList(action) {
  try {
    const res = yield call(getFinancingClientListApi, action.data)
    if (isSuccess(res)) {
      yield put(getFinancingClientList.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(getFinancingClientList.failure(error))
  }
}

function* handleGetFinancingSetting() {
  try {
    const res = yield call(getFinancingSettingApi)
    if (isSuccess(res)) {
      yield put(getFinancingSetting.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(getFinancingSetting.failure(error))
  }
}

// function* handleGetOnboardingBranding(action) {
//   try {
//     const res = yield call(getOnboardingBrandingAPI)
//     if (isSuccess(res)) {
//       const { response } = res.data
//       const { response: responseData } = response
//       yield put(getOnboardingBranding.success(response))
//       if (action?.data?.isDraft) {
//         yield put(toggleScreenPopup.call(0))
//         return
//       }
//       if (action?.data?.isContinue) {
//         yield put(toggleScreenPopup.call(action.data.isContinue))
//         return
//       }
//       if (!responseData) {
//         yield put(toggleScreenPopup.call(0))
//       }
//       if (responseData && responseData.completed_steps && responseData.completed_steps.length) {
//         if (responseData.completed_steps.includes("personal_bank_info")) {
//           yield put(toggleScreenPopup.call(2))
//         }
//         if (responseData.completed_steps.includes("s_corp_bank_info")) {
//           yield put(toggleScreenPopup.call(3))
//         }
//         if (responseData.completed_steps.includes("tc_info")) {
//           yield put(toggleScreenPopup.call(5))
//         }
//       }
//     }
//   } catch (e) {
//     yield put(getOnboardingBranding.failure(e))
//   }
// }

function* handleRarSaveFinancingClientList(action) {
  try {
    const res = yield call(rarSaveFinancingClientListApi, action.data.formData)
    const result = yield call(getFinancingClientListApi, action.data.payload)
    if (isSuccess(result) && isSuccess(res)) {
      yield put(getFinancingClientList.success(result.data && result.data.response))
      yield put(rarSaveFinancingClientList.success(res.data && res.data.response))
      yield put(toggleFinanceModal.success(false))
      yield put(showSuccessBar.success({
        show: true,
        type: action.data.payload.type,
      }))
    }
  } catch (error) {
    yield put(rarSaveFinancingClientList.failure(error))
  }
}

function* handleToggleFinanceModal(action) {
  try {
    yield put(toggleFinanceModal.success(action.data))
  } catch (error) {
    yield put(toggleFinanceModal.failure(error))
  }
}

function* handleShowSuccessBar(action) {
  try {
    yield put(showSuccessBar.success({
      show: action.data,
      type: null,
    }))
  } catch (error) {
    yield put(showSuccessBar.failure(error))
  }
}
function* handleFetchState(action) {
  try {
    const res = yield call(fetchStateApi, action.data)
    if (isSuccess(res)) {
      yield put(fetchState.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(fetchState.failure(error))
  }
}

function* handleFetchTodaysPrice() {
  try {
    const res = yield call(fetchTodaysPriceApi)
    if (isSuccess(res)) {
      yield put(fetchTodaysPriceAction.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(fetchTodaysPriceAction.failure(error))
  }
}

function* handleFetchDealTimeline(action) {
  try {
    const { dealId } = action.data
    const res = yield call(fetchDealTimelineAPI, dealId)
    if (isSuccess(res)) {
      yield put(fetchDealTimelineAction.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(fetchDealTimelineAction.failure(error))
  }
}

function* handleFetchDealTCDetails(action) {
  try {
    const { dealId } = action.data
    const res = yield call(fetchDealTCDetailsAPI, dealId)
    if (isSuccess(res)) {
      yield put(fetchDealTCDetailsAction.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(fetchDealTCDetailsAction.failure(error))
  }
}

function* handleUpdateDealTimeline(action) {
  try {
    const { dealId, payload, callback } = action.data
    const res = yield call(updateDealTimelineAPI, dealId, payload)
    if (isSuccess(res)) {
      yield put(updateDealTimelineAction.success(res.data && res.data.response))
      callback()
    }
  } catch (error) {
    yield put(updateDealTimelineAction.failure(error))
  }
}

function* handleFetchBrandingAssets() {
  try {
    const res = yield call(fetchBrandingAssetsAPI)
    if (isSuccess(res)) {
      yield put(fetchBrandingAssetsAction.success(res.data && res.data.response))
    }
  } catch (error) {
    yield put(fetchBrandingAssetsAction.failure(error))
  }
}

function* handleFetchBrandingInfo() {
  try {
    const res = yield call(fetchBrandingInfoAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      const { isShow } = res.data.response
      if (isShow === 0 || isShow === false) {
        yield put(setIsShow.call(0))
      }
      yield put(fetchBrandingInfoAction.success(response.response))
      if (response.response && response.response.completed_steps && response.response.completed_steps.length) {
        if (response.response.completed_steps.includes("basic_info")) {
          yield put(toggleScreenPopup.call(3))
        }
        if (response.response.completed_steps.includes("business_card")) {
          yield put(toggleScreenPopup.call(3))
        }
        if (response.response.completed_steps.includes("yard_sign")) {
          yield put(toggleScreenPopup.call(3))
        }
        if (response.response.form_completion_percentage === 100) {
          yield put(toggleScreenPopup.call(null))
        }
      } else {
        yield put(toggleScreenPopup.call(1))
      }
    }
  } catch (error) {
    yield put(fetchBrandingInfoAction.failure(error))
  }
}

function* updateBrandingModalForm(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(updateBrandingInfoAPI, payload)
    if (isSuccess(res)) {
      yield put(updateBrandingInfoAction.success(res.data && res.data.response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(updateBrandingInfoAction.failure(e))
  }
}

function* createBrandingModalForm(action) {
  try {
    const {
      payload,
    } = action.data
    const res = yield call(createBrandingInfoAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(createBrandingInfoAction.success(res.data && res.data.response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(createBrandingInfoAction.failure(e))
  }
}

function* handleFileUploadToBucketBranding(action) {
  try {
    const {
      file,
      objName,
      fileName,
    } = action.data

    const signedRes = yield fetch(`${FILE_UPLOAD_API_V2}bucket=branding_assets_user_logo&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      const existingObj = yield select(state => state.techAssetsRarDashboard.uploadDocsToBucketResponse.data)
      if (existingObj) {
        yield put(uploadDocsToBucketAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
          ...existingObj,
        }))
      } else {
        yield put(uploadDocsToBucketAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
        }))
      }
      // callback()
      // createToast.info(`Uploaded ${file.name}`)
    }
  } catch (e) {
    console.log("error====", e)
    // createToast("Error Uploading file")
    // yield put(AppActions.uploadDocsToBucketAction.failure(e))
  }
}

function* handleGetPopupDetails() {
  try {
    const res = yield call(getPopupDetailsApi, "?eventType=login&popupType=mortgage")
    if (isSuccess(res)) {
      yield put(getPopupDetailsAction.success(res.data && res.data.response))
      yield put(showPopupAction.call({ showMortgagePopup: true }))
    }
  } catch (error) {
    yield put(getPopupDetailsAction.failure(error))
  }
}

function* handleGetListings(action) {
  try {
    const tcInformation = CookiesStorage.load("tcInformation")
    const teamDetails = yield select(state => state.teamProfileReducer.getTeamDetailsResponse?.data)
    let query = ""
    if (action.data?.agentId && tcInformation?.is_tc) {
      const teamLeadId = teamDetails?.team_members?.find(tm => tm.member_type === "Team Lead")?.agent_id
      action.data.agentId = teamLeadId
    }
    const queries = { ...action.data }
    Object.keys(queries).forEach((dt) => {
      query += `${dt}=${queries[dt]}&`
    })
    let res = {}
    if (action.data?.apiMethod === "post") {
      res = yield call(getAllListingsPostAPI, action.data)
    } else {
      res = yield call(getAllListingsAPI, query)
    }
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(getAllListings.success(response))
    }
  } catch (error) {
    yield put(getAllListings.success([]))
    // yield put(getAllListings.failure(error))
  }
}

function* handleGetListingStatusMap() {
  try {
    const res = yield call(getListingStatusMapAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(getListingStatusMap.success(response))
    }
  } catch (error) {
    yield put(getListingStatusMap.failure(error))
  }
}

function* handleUpdateDealStatus(action) {
  try {
    const {
      dealId,
      step,
      callback,
      notes,
    } = action.data
    const payload = {
      deal_id: dealId,
      step,
    }
    if (notes) {
      payload.notes = notes
    }
    const res = yield call(updateCreateDealFormStageAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(updateDealStatus.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    yield put(updateDealStatus.failure(error))
  }
}

function* handleSaveStepsListings(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const user = CookiesStorage.load("user")
    payload.admin_name = user.twilio_client_name || "anonymous"
    const res = yield call(saveListingStepsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(saveListingStepsAction.success(response))
      if (callback) {
        callback(response)
      }
    }
  } catch (error) {
    yield put(saveListingStepsAction.failure(error))
  }
}

function* handleGetListingCoverSheet(action) {
  try {
    const listing_id = action?.data?.listing_id
    const callAPI = action?.data?.callAPI
    const editingDealTeamMemberId = yield select(state => state.techAssetsRarDashboard.editingDealTeamMemberId)
    let res1 = { data: { response: {} } }
    let res2 = { data: { response: {} } }
    let res3 = { data: { response: {} } }
    let res4 = { data: { response: {} } }
    let res5 = { data: { response: {} } }
    let res6 = { data: { response: {} } }
    let res7 = { data: { response: {} } }
    let res8 = { data: { response: {} } }

    if (callAPI.includes("agent_info")) res1 = yield call(getListingCoverSheetAPI, `${listing_id}/agent_info?agent_id=${editingDealTeamMemberId}`)
    if (callAPI.includes("seller_info")) res2 = yield call(getListingCoverSheetAPI, `${listing_id}/seller_info`)
    if (callAPI.includes("source_time")) res3 = yield call(getListingCoverSheetAPI, `${listing_id}/source_time`)
    if (callAPI.includes("property_info")) res4 = yield call(getListingCoverSheetAPI, `${listing_id}/property_info`)
    if (callAPI.includes("tc_info")) res5 = yield call(getListingCoverSheetAPI, `${listing_id}/tc_info`)
    if (callAPI.includes("insert_vendor")) res6 = yield call(getListingCoverSheetAPI, `${listing_id}/insert_vendor`)
    if (callAPI.includes("select_vendor")) res7 = yield call(getListingCoverSheetAPI, `${listing_id}/select_vendor?agent_id=${editingDealTeamMemberId}`)
    if (callAPI.includes("sign_post")) res8 = yield call(getListingCoverSheetAPI, `${listing_id}/sign_post`)

    // if (isSuccess(res1)) {
    const response = {
      agentInfo: res1.data.response?.agents,
      sellerInfo: res2.data.response?.sellers,
      sourceTime: res3.data.response,
      propertyInfo: res4.data.response,
      tcInfo: res5.data.response,
      insertVendor: res6.data.response,
      selectVendor: res7.data.response?.vendors,
      signPost: res8.data.response,
    }
    if (response.agentInfo && response.agentInfo.length) {
      const primaryAgent = response.agentInfo.filter(ag => ag.is_primary === "Y" || ag.is_primary === 1)
      const secondaryAgent = response.agentInfo.filter(ag => ag.is_primary === "N" || ag.is_primary === 0)
      if (primaryAgent.length) {
        response.agentInfo = [
          ...primaryAgent,
          ...secondaryAgent,
        ]
      }
    }
    yield put(getListingCoverSheet.success(response))
    if (response.agentInfo && response.agentInfo.length) {
      yield put(setLoaderOnAgentInfo.call(false))
    }
    // }
  } catch (error) {
    yield put(getListingCoverSheet.failure(error))
  }
}

function* handleCreateListingCoverSheet(action) {
  try {
    const {
      payload,
      callAPI,
      nextRoute,
      history,
      callback,
    } = action.data
    const editingDealTeamMemberId = yield select(state => state.techAssetsRarDashboard.editingDealTeamMemberId)
    console.log("===editingDealTeamMemberId===", editingDealTeamMemberId)
    if (payload.agents && editingDealTeamMemberId) {
      // eslint-disable-next-line no-unused-expressions
      payload.agents?.forEach((dt) => {
        if (dt.is_primary === "Y" || dt.is_primary === 1) {
          // eslint-disable-next-line no-param-reassign
          dt.agent_id = editingDealTeamMemberId
        }
      })
      payload.agent_id = editingDealTeamMemberId
    }
    const res = yield call(createListingCoverSheetAPI, payload)

    if (isSuccess(res)) {
      const dealIdToSend = res.data.response.listing_id
      yield put(createListingCoverSheet.success(res.data && res.data.response))
      if (callback) {
        callback(dealIdToSend)
      }
      yield put(getListingCoverSheet.request({ listing_id: dealIdToSend, callAPI }))
      // yield put(createListingCoverSheet.success({}))
      yield put(setDealToView.call(dealIdToSend))
      history.push(nextRoute)
    }
  } catch (e) {
    yield put(createListingCoverSheet.failure(e))
  }
}

function* handleUpdateListingCoverSheet(action) {
  try {
    const {
      payload,
      listing_id,
      callAPI,
      nextRoute,
      history,
      callback,
      type,
      vendorId,
    } = action.data
    let res = null
    const editingDealTeamMemberId = yield select(state => state.techAssetsRarDashboard.editingDealTeamMemberId)
    console.log("===editingDealTeamMemberId===", editingDealTeamMemberId)
    if (payload.agents && editingDealTeamMemberId) {
      payload.agents?.forEach((dt) => {
        if (dt.is_primary === "Y" || dt.is_primary === 1) {
          dt.agent_id = editingDealTeamMemberId
        }
      })
    }

    if (type === "vendor_update") {
      res = yield call(updateListingCoverSheetVendorAPI, { payload, id: vendorId })
    } else {
      res = yield call(updateListingCoverSheetAPI, { payload, listing_id })
    }
    if (isSuccess(res)) {
      yield put(updateListingCoverSheet.success(res.data && res.data.response))
      yield put(getListingCoverSheet.request({ listing_id, callAPI }))
      // yield put(updateListingCoverSheet.success({}))
      if (callback) {
        callback()
      }
      history.push(nextRoute)
    }
  } catch (e) {
    yield put(updateListingCoverSheet.failure(e))
  }
}

function* handleGetContractById(action) {
  try {
    const {
      listingId,
      offerId,
      additionaPayload,
      listingView,
      history,
      callback,
    } = action.data || {}
    let query = ""
    console.log(action.data)
    Object.keys(action.data).forEach((dt) => {
      if (dt !== "additionaPayload" && dt !== "callback") {
        query += `${dt}=${action.data[dt]}&`
      }
    })
    // yield put(ListingsActions.setIsLoadingViewListingPageAction.call(true))
    const res = yield call(getContractByIdAPI, query)

    if (isSuccess(res)) {
      const { response } = res.data
      if (offerId) {
        let successResponse = { ...response }
        if (additionaPayload && Object.keys(additionaPayload).length > 0) {
          successResponse = {
            ...successResponse,
            ...additionaPayload,
          }
        }
        yield put(getContractById.success([successResponse]))
        // if (response.deal_id) {
        //   yield put(setDealToView.call(response.deal_id))
        //   yield put(getContractById.request({
        //     dealId: response.deal_id,
        //   }))
        // }
      } else if (listingId) {
        let successResponse = { ...response }
        if (additionaPayload && Object.keys(additionaPayload).length > 0) {
          successResponse = {
            ...additionaPayload,
            step: "incomplete",
          }
        }
        console.log("successResponse", successResponse)
        yield put(ListingsActions.viewListingContractAction.call(false))
        // yield put(ListingsActions.setCurrentListingTabAction.call("contract_details"))
        yield put(getContractById.success([successResponse]))

        if (listingView && history) {
          history.push(`/listings/form/${listingId}`)
        }
      } else {
        let newResponse = {}
        if (response[0]?.agent_type) {
          yield put(defineAgentTypeAction.call(response[0]?.agent_type))
        }

        if (additionaPayload) {
          newResponse = { ...response[0], ...additionaPayload }
        } else {
          newResponse = { ...response[0] }
        }

        yield put(getContractById.success([newResponse]))
      }
      if(callback) {
        callback(response)
      }
      yield put(setLoaderOnAgentInfo.call(false))
    }
  } catch (error) {
    console.log("==error===", error)
    yield put(getContractById.failure(error))
  }
}

function* handleCreateContract(action) {
  try {
    const {
      payload,
      history,
      nextRoute,
      offerId,
      doNotFetchContract,
    } = action.data
    if (payload.agent_id) {
      const editingDealTeamMemberId = yield select(state => state.techAssetsRarDashboard.editingDealTeamMemberId)
      if (editingDealTeamMemberId) {
        payload.agent_id = editingDealTeamMemberId
      }
    }
    console.log("offerId", offerId)
    const offersParam = offerId ? `offerId=${offerId}` : ""
    const res = yield call(createContractAPI, payload, offersParam)
    if (isSuccess(res)) {
      yield put(createContract.success(res.data && res.data.response))
      // // yield put(createListingCoverSheet.success({}))
      const dealIdToSend = res.data.response.deal_id
      yield put(setDealToView.call(dealIdToSend))
      if (!doNotFetchContract) {
        yield put(getContractById.request({ dealId: dealIdToSend, contractOnly: 1 }))
      }
      // yield put(getContractById.request({ dealId: dealIdToSend }))
      // if (callback) {
      //   callback(dealIdToSend)
      // }
      history.push(nextRoute)
    }
  } catch (e) {
    yield put(createListingCoverSheet.failure(e))
  }
}

function* handleUpdateContract(action) {
  try {
    const {
      payload,
      dealId,
      history,
      nextRoute,
      callAPI,
      callForGetAPI,
      callback,
      doNotFetchContract,
    } = action.data
    if (payload.agent_id) {
      const editingDealTeamMemberId = yield select(state => state.techAssetsRarDashboard.editingDealTeamMemberId)
      if (editingDealTeamMemberId) {
        payload.agent_id = editingDealTeamMemberId
      }
    }
    const res = yield call(updateContractAPI, { payload, dealId })
    if (isSuccess(res)) {
      yield put(updateContract.success(res.data && res.data.response))
      // yield put(getContractById.request({ dealId, contractOnly: 1 }))
      if (!doNotFetchContract) {
        yield put(getContractById.request({ dealId }))
      }
      if (callForGetAPI) {
        yield put(getListingCoverSheet.request({ dealId, callAPI }))
      }
      // yield put(updateListingCoverSheet.success({}))
      // if (callback) {
      //   callback()
      // }
      if (history) {
        history.push(nextRoute)
      }
      
    }
  } catch (e) {
    yield put(updateListingCoverSheet.failure(e))
  }
}

function* handleGetGoogleAuthToken() {
  try {
    const res = yield call(getGoogleAuthTokenAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(getGoogleAuthToken.success(response))
    }
  } catch (error) {
    console.log("==error===", error)
    yield put(getGoogleAuthToken.failure(error))
  }
}

function* handleResetContractsForm() {
  try {
    yield put(resetContractsForm.success(null))
  } catch (error) {
    console.log("==error===", error)
    yield put(resetContractsForm.failure(error))
  }
}

function* handleResetListingsForm() {
  try {
    yield put(resetListingsForm.success(null))
  } catch (error) {
    console.log("==error===", error)
    yield put(resetListingsForm.failure(error))
  }
}

function* handleGetListingHistory(action) {
  const {
    listingId,
  } = action.data
  try {
    const res = yield call(getListingHistoryAPI, { listingId })
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(getListingHistory.success(response))
    }
  } catch (error) {
    console.log("==error===", error)
    yield put(getListingHistory.failure(error))
  }
}

function* handleOfferCreation(action) {
  try {
    const {
      payload,
      nextRoute,
      history,
    } = action.data
    if (payload.agents && payload.current_offer_step !== "listing_agent_info") {
      const editingDealTeamMemberId = yield select(state => state.techAssetsRarDashboard.editingDealTeamMemberId)
      if (editingDealTeamMemberId) {
        // eslint-disable-next-line no-unused-expressions
        payload.agents?.forEach((dt) => {
          if (dt.is_primary === "Y" || dt.is_primary === 1) {
            // eslint-disable-next-line no-param-reassign
            dt.agent_id = editingDealTeamMemberId
          }
        })
        payload.agent_id = editingDealTeamMemberId
      }
    }
    const res = yield call(createOfferAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      const dealId = response && response.offer_id
      yield put(createOfferAction.success(response && response.response))
      history.push(`${nextRoute}?dealId=${dealId}`)
    }
  } catch (e) {
    yield put(createOfferAction.failure(e))
  }
}

function* handleFetchOffers(action) {
  try {
    const {
      skip,
      limit,
      query,
    } = action.data
    let newQuery = query
    let res = {}
    const tcInformation = CookiesStorage.load("tcInformation")
    const teamDetails = yield select(state => state.teamProfileReducer.getTeamDetailsResponse?.data)
    // TC adding for Team Lead
    if (tcInformation?.is_tc) {
      const teamLeadId = teamDetails?.team_members?.find(tm => tm.member_type === "Team Lead")?.agent_id
      const queryArray = query.split("&")
      let hasAgentIdInQuery = false
      queryArray.forEach((q, index) => {
        if (q.includes("agentId")) {
          queryArray[index] = `agentId=${teamLeadId}`
          hasAgentIdInQuery = true
        }
      })
      if (!hasAgentIdInQuery) {
        queryArray.push(`agentId=${teamLeadId}`)
      }
      // eslint-disable-next-line no-param-reassign
      action.data.agentId = teamLeadId
      newQuery = queryArray.join("&")
    }
    // Team Lead / TC adding for Team Member
    const editingDealTeamMemberId = yield select(state => state.techAssetsRarDashboard.editingDealTeamMemberId)
    if (editingDealTeamMemberId) {
      // eslint-disable-next-line no-param-reassign
      const queryArray = query.split("&")
      let hasAgentIdInQuery = false
      queryArray.forEach((q, index) => {
        if (q.includes("agentId")) {
          // commenting out to fix redirection to tm offers 
          // queryArray[index] = `agentId=${editingDealTeamMemberId}`
          hasAgentIdInQuery = true
        }
      })
      if (!hasAgentIdInQuery) {
        queryArray.push(`agentId=${editingDealTeamMemberId}`)
      }
      // eslint-disable-next-line no-param-reassign
      action.data.agentId = editingDealTeamMemberId
      newQuery = queryArray.join("&")
    }
    if (action.data?.apiMethod === "post") {
      // eslint-disable-next-line no-param-reassign
      action.data.include = "in-contract-offers"
      res = yield call(getAllOffersPostAPI, action.data)
    } else {
      newQuery += "&include=in-contract-offers"
      res = yield call(getAllOffersAPI, skip, limit, newQuery)
    }
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(getAllOffersAction.success(response))
    }
  } catch (e) {
    yield put(getAllOffersAction.failure(e))
  }
}

function* handleFetchDeal(action) {
  try {
    const {
      id,
      step,
      updateLenderOnForm,
      updateVendorOnForm,
      currentFinancingType,
    } = action.data
    const editingDealTeamMemberId = yield select(state => state.techAssetsRarDashboard.editingDealTeamMemberId)
    const res = yield call(getDealBasedOnIDAPI, step, id, editingDealTeamMemberId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      if (response && response.offer_status) {
        yield put(OfferV2Actions.updateStatusForCurrentCoverSheet.call(response.offer_status))
      }

      if (step === BUYER_INFORMATION_STEP) {
        const agents = [...response.agents]
        agents.forEach((item) => {
          const name = item.name?.split(" ") || ""
          const firstName = name && name[0]
          const lastName = name && name.length > 0 && name[1] || ""
          item.isMainAgent = false
          item.isEditModeVisible = false
          item.agent_first_name = firstName
          item.agent_last_name = lastName
          item.agent_brokerage_address = `${item.brokerage_address || ""}, ${item.city || ""}, ${item.state || ""}, ${item.formatted_zipcode || ""}`
        })
        if (agents.length > 0) {
          agents[0].isMainAgent = true
        }

        const buyers = [...response.buyers]
        buyers.forEach((item) => {
          // item.name = item.name
          item.isMainAgent = false
          item.isBuyerEditFormVisible = false
          item.f_id = item.client_id
          item.source = response.source
          item.other_source = response.other_source
          // item.agent_brokerage_address = `${item.brokerage_address || ""}, ${item.city || ""}, ${item.state || ""}, ${item.formatted_zipcode || ""}`
        })

        if (buyers.length > 0) {
          buyers[0].isMainAgent = true
        }

        const lenders = [...response.lenders]
        lenders.forEach((item) => {
          item.ue = item.id
          item.label = item.lender_name
          item.lender_officer_name = `${item.lender_officer_first_name} ${item.lender_officer_last_name}`
          item.lender_id = item.id
          item.lender_co = item.lender_name
          item.isLenderEditVisible = false
          item.lender_office_address = `${item.lender_address ? `${item.lender_address}` : ""}${item.lender_city ? `, ${item.lender_city}` : ""}${item.lender_state ? `, ${item.lender_state}` : ""} ${item.lender_zipcode ? `- ${item.lender_zipcode}` : ""}`
        })

        let currentSelectedLender = ""
        let currentSelectedVendor = ""

        if (updateLenderOnForm) {
          currentSelectedLender = {
            ...lenders[0],
          }
        }

        const vendors = [...response.vendors]
        vendors.forEach((item) => {
          item.value = item.id
          item.label = item.vendor_name
          item.vendor_officer_name = `${item.vendor_officer_first_name} ${item.vendor_officer_last_name}`
          item.vendor_id = item.id
          item.vendor_office_name = item.vendor_name
          item.isVendorEditVisible = false
          item.vendor_office_address = `${item.vendor_address ? `${item.vendor_address}` : ""}${item.vendor_city ? `, ${item.vendor_city}` : ""}${item.vendor_state ? `, ${item.vendor_state}` : ""} ${item.vendor_zipcode ? `- ${item.vendor_zipcode}` : ""}`
        })

        if (updateVendorOnForm) {
          currentSelectedVendor = {
            ...vendors[vendors.length - 1],
          }
        }

        const isAllCash = response.is_all_cash || response.financing_information === "cash" ? "cash" : null

        const payload = {
          agents,
          buyers,
          lenders,
          selected_lender_id: response.selected_lender_id,
          selectedLender: response.selected_lender_id && lenders.filter(item => item.id === response.selected_lender_id)[0],
          vendors,
          selected_vendor_id: response.selected_vendor_id,
          selectedVendor: response.selected_vendor_id && vendors.filter(item => item.id === response.selected_vendor_id)[0],
          financingType: isAllCash || response.financing_information,
          source: response.source || "zillow_agent_personal_account",
          other_source: response.other_source,
        }

        console.log("IDHAR CHECK", currentSelectedLender)
        
        if (currentSelectedLender && updateLenderOnForm) {

          const finalPayload = {
            agents: [...response.agents],
            clients: [...response.buyers],
            draft: 1,
            new_route: true,
            lender_id: null,
            vendor_id: response.selected_vendor_id,
            financing_information: currentFinancingType,
            source: response.source,
            other_source: response.other_source,
            is_all_cash: response.is_all_cash,
            current_offer_step: "buyers_information_mobile",
          }

          finalPayload.lender_id = currentSelectedLender.id
          payload.selected_lender_id = currentSelectedLender.id
          payload.selectedLender = currentSelectedLender.id && lenders.filter(item => item.id === currentSelectedLender.id)[0]

          console.log(payload)

          yield put(OfferV2Actions.updateBuyerInformationTabAction.request({
            id,
            payload: finalPayload,
          }))
        }

        if (currentSelectedVendor && updateVendorOnForm) {
          const finalPayload = {
            agents: [...response.agents],
            clients: [...response.buyers],
            draft: 1,
            new_route: true,
            lender_id: response.lender_id,
            vendor_id: null,
            financing_information: response.financing_information,
            source: response.source,
            other_source: response.other_source,
            is_all_cash: response.is_all_cash,
            current_offer_step: "buyers_information_mobile",
          }

          finalPayload.vendor_id = currentSelectedVendor.id
          payload.selected_vendor_id = currentSelectedVendor.id
          payload.selectedVendor = currentSelectedVendor.id && vendors.filter(item => item.id === currentSelectedVendor.id)[0]

          yield put(OfferV2Actions.updateBuyerInformationTabAction.request({
            id,
            payload: finalPayload,
          }))
        }

        yield put(showUpdatingBuyerInformationLoader.call(false))
        yield put(OfferV2Actions.updateBuyerInformationObjectAction.call(payload))
      }

      if (step === "property_information_mobile") {
        const state = response.state || "california"
        const currentState =  state.toLowerCase() || "california"
        yield put(OfferV2Actions.updateStateForOffersAction.call(currentState))
        const listing_agents = [...response.listing_agents]

        listing_agents.forEach((item) => {
          const name = item.name?.split(" ") || ""
          const firstName = name && name[0]
          const lastName = name && name.length > 0 && name[1] || ""

          item.listing_agent_first_name = firstName
          item.listing_agent_last_name = lastName
          item.listing_agent_id = item.id
          item.isListingAgentEditVisible = false
        })

        const sellers = [...response.sellers]

        sellers.forEach((item) => {
          // item.firstname = item.firstname
          // item.lastname = item.lastname
          item.seller_id = item.id
          item.isSellerEditVisible = false
        })

        const currentForm = PROPERTY_INFORMATION_FORMS[currentState]

        console.log("currentForm grand",currentForm)

        for(let key in response) {
          if (currentForm.hasOwnProperty(key)) {
            currentForm[key] = response[key]
          }
        }
        for(let key in currentForm){
          currentForm[key] = response[key]
        }

        const payload = {
          addressRepresentation: false,
          currentState,
          type_of_transaction: response.type_of_transaction,
          form: {
            [currentState]: {
              ...currentForm,
              // ...response,
            }
          },
          listing_agents,
          sellers,
          mls_number: response.mls_number,
        }
        yield put(OfferV2Actions.updatePropertyInformationObjectAction.call(payload))
      }

      if (step === DEAL_TERMS_STEP) {
        //DEAL_TERMS_FORMS
        const currentState = "california"

        const currentForm = DEAL_TERMS_FORMS[currentState]

        console.log(currentForm)

        for (let key in response) {
          if (currentForm.hasOwnProperty(key)) {
            currentForm[key] = response[key]
          }
        }
        
        const payload = {
          currentState,
          form: {
            [currentState]: {
              ...currentForm,
            },
          },
          type_of_transaction: response.type_of_transaction,
        }

        yield put(OfferV2Actions.updateDealTermsObjectAction.call(payload))
      }

      yield put(setDealToView.call(id))
      yield put(getCurrentDealDetailsAction.success(response))
      yield put(setLoaderAction.call(false))
    }
  } catch (e) {
    yield put(getCurrentDealDetailsAction.failure(e))
  }
}

function* handleUpdateOfferDealStatus(action) {
  try {
    const {
      payload,
      callback,
    } = action.data

    const res = yield call(updateOfferDealStatusAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      const agentId = CookiesStorage.load("agentId")
      yield put(updateOfferDealStatusAction.success(response))
      if (callback) {
        callback()
        return
      }
      yield put(getAllOffersAction.request({
        skip: 0,
        limit: 100,
        query: `&agentId=${agentId}`,
      }))
    }
  } catch (error) {
    yield put(updateOfferDealStatusAction.failure(error))
  }
}

function* handleOfferUpdate(action) {
  try {
    const {
      payload,
      nextRoute,
      history,
      dealId,
      lastStep,
    } = action.data
    if (payload.agents && payload.current_offer_step !== "listing_agent_info") {
      const editingDealTeamMemberId = yield select(state => state.techAssetsRarDashboard.editingDealTeamMemberId)
      if (editingDealTeamMemberId) {
        payload.agents?.forEach((dt) => {
          if (dt.is_primary === "Y" || dt.is_primary === 1) {
            dt.agent_id = editingDealTeamMemberId
          }
        })
      }
    }
    const res = yield call(updateOfferAPI, payload, dealId)
    yield put(getOfferSteps.request({
      payload: {
        offerId: dealId,
      },
      dontNavigate: true,
    }))
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(updateOfferAction.success(response && response.response))
      history.push(`${nextRoute}${!lastStep ? `?dealId=${dealId}` : ""}`)
    }
  } catch (e) {
    yield put(updateOfferAction.failure(e))
  }
}

function* handleVendorUpdate(action) {
  try {
    const {
      vendorId,
      payload,
      nextRoute,
      history,
      dealId,
    } = action.data
    const res = yield call(updateVendorAPI, vendorId, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(updateVendorAction.success(response && response.response))
      history.push(`${nextRoute}?dealId=${dealId}`)
    }
  } catch (e) {
    yield put(updateVendorAction.failure(e))
  }
}

function* handleLenderUpdate(action) {
  try {
    const {
      vendorId,
      payload,
      nextRoute,
      history,
      dealId,
    } = action.data
    const res = yield call(updateLenderAPI, vendorId, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(updateLenderAction.success(response && response.response))
      history.push(`${nextRoute}?dealId=${dealId}`)
    }
  } catch (e) {
    yield put(updateLenderAction.failure(e))
  }
}

function* handleGetListingDocuments(action) {
  try {
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const res = yield call(getListingDocumentsAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      if (!response.length && action.data.callback) {
        action.data.callback()
      }
      console.log("======response==>>>>", response)
      yield put(getListingDocuments.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(getListingDocuments.failure(e))
  }
}

function* handleSendListingDocuments(action) {
  try {
    const {
      payload,
      callback,
    } = action.data

    createToastNew("Loading all the required documents")
    const res = yield call(sendListingDocumentsAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      console.log("===res.data===", res.data)
      yield put(sendListingDocuments.success({}))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    console.log("===error===", error)
    const errorMessage = get(error, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(sendListingDocuments.failure(error))
  }
}

function* handleCreateSendeView(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(createSendeViewAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      root.open(response.url, "_blank")
      yield put(createSendeView.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(createSendeView.failure(e))
  }
}

function* handleCreateReceiverView(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(createReceiverViewAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      root.open(response.url, "_blank")
      yield put(createReceiverView.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(createReceiverView.failure(e))
  }
}

function* handleCreateEditView(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(createEditViewAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      root.open(response.url, "_blank")
      yield put(createEditView.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(createEditView.failure(e))
  }
}

function* handleDownloadEnvelopeDoc(action) {
  try {
    yield put(downloadingDocs.call(true))
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const secureId = Cookies.load("secureId")
    const config = {
      method: "get",
      url: `${API_URL}brokerage-docs/envelopes/download?${query}`,
      headers: { "secure-id": secureId },
      responseType: "blob",
    }
    axios(config)
      .then((response) => {
        action.data.callback()
        const link = document.createElement("a")
        link.setAttribute("href", URL.createObjectURL(response.data))
        link.setAttribute("download", `${action.data.documentDetail.name || action.data.documentDetail.document_name || action.data.documentDetail.title}.pdf`)
        document.body.appendChild(link)
        setTimeout(() => {
          link.click()
        }, 1000)
      })
      .catch((error) => {
        console.log(error)
      })
    yield put(downloadEnvelopeDoc.success({}))
  } catch (error) {
    const errorMessage = get(error, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(downloadEnvelopeDoc.failure(error))
  }
}

function* handleGetListingSteps(action) {
  try {
    const { listingStatus, history, payload } = action.data
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const res = yield call(getListingStepsAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(getListingSteps.success(response))
      if (listingStatus === "Pending") {
        history.push(`/realty/listings/in-contracts/basic-info?dealId=${payload.listingId}&status=listing_pending`)
        return
      }
      if (response.docusign) {
        // callback()
        history.push("/realty/listings/documents/lists")
      } else {
        const { callback } = action.data
        callback()
      }
    }
  } catch (e) {
    yield put(getListingSteps.failure(e))
  }
}

function handletoggleWelcomeModal() {
  try {
    CookiesStorage.save("isAcknowledgementShown", true)
    console.log("saga called")
  } catch (error) {
    console.log("Error while saving cookies")
  }
}

function* handleRefreshListingDocuments(action) {
  try {
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const res = yield call(getListingDocumentsAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      if (!response.length && action.data.callback) {
        action.data.callback()
      }
      console.log("======response==>>>>", response)
      yield put(refreshListingDocuments.success(response))
      yield put(getListingDocuments.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(refreshListingDocuments.failure(e))
    yield put(getListingDocuments.failure(e))
  }
}
// c41903c7-de34-418c-93db-79702b82b239
// http://localhost:8087/realty/listings/documents-callback?event=signing_complete

function* handleUpdateDocusignEnvelopeStatus(action) {
  try {
    const res = yield call(updateDocusignEnvelopeStatusAPI, action.data.payload)
    if (isSuccess(res)) {
      yield put(updateDocusignEnvelopeStatus.success(res))
      action.data.callback()
    }
  } catch (e) {
    action.data.callback()
    // const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    // customToast(errorMessage)
    yield put(updateDocusignEnvelopeStatus.failure(e))
  }
}

function* handleImageUploadForListing(action) {
  try {
    const {
      file,
      objName,
      fileName,
      callback,
    } = action.data

    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=broker_listing_images&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      callback(signedResJson.fileUrlAfterUpload)
    }
    yield put(imageUploadForListing.success(signedRes))
  } catch (e) {
    console.log("error====", e)
  }
}

function* handleCreateAllListingImages(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(createAllListingImagesAPI, payload)
    if (isSuccess(res)) {
      yield put(createAllListingImages.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(createAllListingImages.failure(e))
  }
}

function* handleGetOfferDocuments(action) {
  try {
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const res = yield call(getOfferDocumentsAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      console.log(response)
      if(response && response.length > 0) {
        response.forEach((item, iter) => {
          item.name = item.name || item.title || `Your Document ${iter + 1}`
        })
        yield put(OfferV2Actions.updateOffersDocumentsObjectAction.call(response))
      }


      //OLD FLOW
      // if (!response.length && action.data.callback) {
      //   action.data.callback()
      // }
      // console.log("======responseOffers==>>>>", response)
      yield put(getOfferDocuments.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(getOfferDocuments.failure(e))
  }
}

function* handleSendForVoidAndRestartOffer(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(sendForVoidAndRestartOfferAPI, payload[0])
    // if (res.filter(item => item.status !== 200).length === 0) {
    //   // const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    //   customToast("Something Went Wrong")
    // }
    yield put(sendForVoidAndRestartOffer.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(sendForVoidAndRestartOffer.failure(e))
  }
}

function* handleCreateReceiverViewOffer(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(createReceiverViewOfferAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      root.open(response.url, "_blank")
      yield put(createReceiverViewOffer.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast("Something went wrong", "error")
    yield put(createReceiverViewOffer.failure(e))
  }
}

function* handleCreateEditViewOffer(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(createEditViewOfferAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      root.open(response.url, "_blank")
      yield put(createEditViewOffer.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(createEditViewOffer.failure(e))
  }
}

function* handleSendForVoidAndRestart(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(sendForVoidAndRestartAPI, payload[0])
    // if (res.filter(item => item.status !== 200).length === 0) {
    //   // const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    //   customToast("Something Went Wrong")
    // }
    yield put(sendForVoidAndRestart.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(sendForVoidAndRestart.failure(e))
  }
}

function* handleSendForVoidAndDelete(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(sendForVoidAndDeleteAPI, payload[0])
    // if (res.filter(item => item.status !== 200).length === 0) {
    //   // const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    //   customToast("Something Went Wrong")
    // }
    yield put(sendForVoidAndDelete.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(sendForVoidAndDelete.failure(e))
  }
}

function* handleSendTroubleShootMessage(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(sendTroubleShootMessageAPI, payload)
    if (isSuccess(res)) {
      yield put(sendForVoidAndRestart.success(res.data && res.data.response))
      customToast("We have received your request. We will soon get in touch with you.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        className: "blue-background ",
      })
      if (callback) callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(sendForVoidAndRestart.failure(e))
  }
}

function* handleUploadListingDocToDocusign(action) {
  try {
    const {
      isFileLarge,
      payload,
      callback,
    } = action.data
    if(isFileLarge) createToastNew("Processing document. You will be notified when envelope is created")
    const res = yield call(uploadListingDocToDocusignAPI, payload)
    if (isSuccess(res)) {
      yield put(uploadListingDocToDocusign.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(uploadListingDocToDocusign.failure(e))
  }
}

function* handleRegenerateListingDocuments(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(regenerateListingDocumentsAPI, payload)
    if (isSuccess(res)) {
      yield put(regenerateListingDocuments.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(regenerateListingDocuments.failure(e))
  }
}

function* handleSendOfferDocuments(action) {
  try {
    const {
      payload,
      callback,
    } = action.data

    const res = yield call(sendOfferDocumentsAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      console.log("===res.data===", res.data)
      yield put(sendOfferDocuments.success({}))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    console.log("===error===", error)
    const errorMessage = get(error, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(sendOfferDocuments.failure(error))
  }
}

function* handleCreateSendeViewOffer(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(createSendeViewOfferAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      root.open(response.url, "_blank")
      yield put(createSendeViewOffer.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(createSendeViewOffer.failure(e))
  }
}

function* handleDownloadEnvelopeDocOffer(action) {
  try {
    yield put(downloadingDocs.call(true))
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const secureId = Cookies.load("secureId")
    const config = {
      method: "get",
      url: `${API_URL}brokerage-offers-docs/envelopes/download?${query}`,
      headers: { "secure-id": secureId },
      responseType: "blob",
    }
    axios(config)
      .then((response) => {
        // action.data.callback()
        const link = document.createElement("a")
        link.setAttribute("href", URL.createObjectURL(response.data))
        link.setAttribute("download", `${action.data.documentDetail.name || action.data.documentDetail.document_name || action.data.documentDetail.title}.pdf`)
        document.body.appendChild(link)
        setTimeout(() => {
          link.click()
        }, 1000)
        customToast("Download Completed.")
      })
      .catch((error) => {
        console.log(error)
      })
    yield put(downloadEnvelopeDocOffer.success({}))
  } catch (error) {
    const errorMessage = get(error, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(downloadEnvelopeDocOffer.failure(error))
  }
}

function* handleGetOfferSteps(action) {
  try {
    const {
      history, callback, dontNavigate, offerStatus,
    } = action.data
    const { payload } = action.data
    const { offerId: dealId } = payload
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const res = yield call(getOfferStepsAPI, query)

    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(getOfferSteps.success(response))
      if (dontNavigate) {
        return
      }

      if (offerStatus === "buyers_information_mobile") {
        history.push(`/realty/offers-v3/${dealId}`)
        return     
      }


      if (offerStatus === "offer_accepted") {
        history.push(`/realty/offers/in-contracts/basic-info?offerId=${dealId}&status=${offerStatus}`)
        return
      }
      // history.push(`${DocListsRoute}?dealId=${dealId}`)
      // return
      if (!response.cover_sheet) {
        history.push(`${BuyerAgentInfoRoute}?dealId=${dealId}`)
        return
      }
      if (!response.docusign || !response.cover_letter || !response.pre_qualification) {
        history.push(`${DocListsRoute}?dealId=${dealId}`)
        return
      }
      // if (!response.docusign) {
      //   history.push(`${DocListsRoute}?dealId=${dealId}`)
      //   return
      // }
      // if (!response.cover_letter) {
      //   history.push(`${CoverLetterRoute}?dealId=${dealId}`)
      //   return
      // }
      // if (!response.pre_qualification) {
      //   history.push(`${PreQualificationRoute}?dealId=${dealId}`)
      //   return
      // }
      if (callback) {
        callback()
      } else {
        history.push(`${SubmitAndSendAllDocuments}?dealId=${dealId}`)
      }
    }
  } catch (e) {
    yield put(getOfferSteps.failure(e))
  }
}

function* handleFetchCoverLetterDocs(action) {
  try {
    const {
      dealId,
      location,
    } = action.data.payload
    const { callback } = action.data
    const res = yield call(fetchCoverLetterDocsAPI, dealId, location)
    yield put(getOfferSteps.request({
      payload: {
        offerId: dealId,
      },
      dontNavigate: true,
    }))
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(fetchCoverLetterDocsAction.success(response))
      callback(response)
    }
  } catch (e) {
    yield put(fetchCoverLetterDocsAction.failure(e))
  }
}

function* handleUpdateOfferDocusignEnvelopeStatus(action) {
  try {
    const res = yield call(updateOfferDocusignEnvelopeStatusAPI, action.data.payload)
    if (isSuccess(res)) {
      yield put(updateOfferDocusignEnvelopeStatus.success(res))
      action.data.callback()
    }
  } catch (e) {
    action.data.callback()
    // const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    // customToast(errorMessage)
    yield put(updateOfferDocusignEnvelopeStatus.failure(e))
  }
}

function* handleSendTroubleShootMessageOffer(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(sendTroubleShootMessageOfferAPI, payload)
    if (isSuccess(res)) {
      yield put(sendTroubleShootMessageOffer.success(res.data && res.data.response))
      customToast("We have received your request. We will soon get in touch with you.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        className: "blue-background ",
      })
      if (callback) callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(sendTroubleShootMessageOffer.failure(e))
  }
}

function* handleUploadOfferDocToDocusign(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(uploadOfferDocToDocusignAPI, payload)
    if (isSuccess(res)) {
      yield put(uploadOfferDocToDocusign.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(uploadOfferDocToDocusign.failure(e))
  }
}

function* handleUploadCoverLetterDocToDocusign(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    createToast("Uploading Cover Letter.")
    const res = yield call(uploadCoverLetterDocToDocusignAPI, payload)
    if (isSuccess(res)) {
      yield put(uploadCoverLetterDocToDocusign.success(res.data && res.data.response))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    console.log("error====", e)
    yield put(uploadCoverLetterDocToDocusign.failure(e))
  }
}

function* handleSendForVoidAndDeleteOffer(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(sendForVoidAndDeleteOfferAPI, payload[0])
    // if (res.filter(item => item.status !== 200).length === 0) {
    //   // const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    //   customToast("Something Went Wrong")
    // }
    yield put(sendForVoidAndDeleteOffer.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(sendForVoidAndDeleteOffer.failure(e))
  }
}

function* handleRegenerateOfferDocuments(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(regenerateOfferDocumentsAPI, payload)
    if (isSuccess(res)) {
      yield put(regenerateOfferDocuments.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(regenerateOfferDocuments.failure(e))
  }
}

function* handleUploadForCoverLetter(action) {
  try {
    const {
      file,
      objName,
      fileName,
      bucketName,
    } = action.data

    const signedRes = yield fetch(`https://us-central1-sturdy-chimera-767.cloudfunctions.net/file-uploader?bucket=${bucketName}&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      console.log("file upload saga", signedResJson)
      const existingObj = yield select(state => state.techAssetsRarDashboard.uploadCoverLetterResponse.data)
      if (existingObj) {
        console.log("existing saga", existingObj)
        yield put(uploadCoverLetterAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
          ...existingObj,
        }))
      } else {
        yield put(uploadCoverLetterAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
        }))
      }
    }
  } catch (e) {
    console.log("error====", e)
  }
}

function* handlePutCoverLetterDocs(action) {
  try {
    const {
      payload,
      dealId,
      callback,
    } = action.data
    const res = yield call(putCoverLetterDocsAPI, dealId, payload)
    if (isSuccess(res)) {
      yield put(putCoverLetterDocsAction.success(res.data && res.data.response))
      yield put(getOfferSteps.request({
        payload: {
          offerId: dealId,
        },
        dontNavigate: true,
      }))
      callback()
    }
  } catch (e) {
    yield put(putCoverLetterDocsAction.failure(e))
  }
}

function* handleSubmitAndSendAllOffersFile(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    let query = ""
    Object.keys(payload).forEach((dt) => {
      query += `${dt}=${payload[dt]}&`
    })
    const res = yield call(submitAndSendAllOffersFileAPI, query)
    if (isSuccess(res)) {
      yield put(submitAndSendAllOffersFile.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(submitAndSendAllOffersFile.failure(e))
  }
}

function* handleSendAllOffersDocs(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    let query = ""
    Object.keys(payload).forEach((dt) => {
      query += `${dt}=${payload[dt]}&`
    })
    const res = yield call(sendAllOffersDocsAPI, query)
    if (isSuccess(res)) {
      yield put(sendAllOffersDocsAction.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(sendAllOffersDocsAction.failure(e))
  }
}

function* handleSendDocusignDocs(action) {
  try {
    yield put(downloadingDocs.call(true))
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const secureId = Cookies.load("secureId")
    const config = {
      method: "get",
      url: `${API_URL}brokerage-docs/envelopes/download?${query}`,
      headers: { "secure-id": secureId },
      responseType: "blob",
    }
    axios(config)
      .then((response) => {
        action.data.callback()
        const link = document.createElement("a")
        link.setAttribute("href", URL.createObjectURL(response.data))
        link.setAttribute("download", `${action.data.documentDetail}`)
        document.body.appendChild(link)
        setTimeout(() => {
          link.click()
        }, 1000)
      })
      .catch((error) => {
        console.log(error)
      })
    yield put(sendDocusignDocsAction.success({}))
  } catch (error) {
    const errorMessage = get(error, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(sendDocusignDocsAction.failure(error))
  }
}

function* handleRefreshOfferDocuments(action) {
  try {
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const res = yield call(getOfferDocumentsAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      const filtered = response.filter(item => item.status !== "active" && item.status !== "created")
      yield put(OfferV2Actions.updateEnvelopesObjectAction.call(filtered))
      if (!response.length && action.data.callback) {
        action.data.callback()
      }
      console.log("======response==>>>>", response)
      yield put(refreshOfferDocuments.success(response))
      yield put(getOfferDocuments.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.message") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(refreshOfferDocuments.failure(e))
    yield put(getOfferDocuments.failure(e))
  }
}

function* handleRequestToCreateZip(action) {
  try {
    const {
      offerId,
      callback,
    } = action.data
    const res = yield call(requestToCreateZipAPI, { offerId })
    if (isSuccess(res)) {
      yield put(requestToCreateZip.success(res.data && res.data.response))
      callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(requestToCreateZip.failure(e))
  }
}

function* handleGenerateListingEnvelopeFromTemplate(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const query = ""
    // Object.keys(payload).forEach((dt) => {
    //   query += `${dt}=${payload[dt]}&`
    // })
    const res = yield call(generateListingEnvelopeFromTemplateAPI, payload)
    if (isSuccess(res)) {
      yield put(generateListingEnvelopeFromTemplate.success(res.data && res.data.response))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    console.log("error====", e)
    yield put(generateListingEnvelopeFromTemplate.failure(e))
  }
}

function* handleGenerateOfferEnvelopeFromTemplate(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const query = ""
    // Object.keys(payload).forEach((dt) => {
    //   query += `${dt}=${payload[dt]}&`
    // })
    const res = yield call(generateOfferEnvelopeFromTemplateAPI, payload)
    if (isSuccess(res)) {
      yield put(generateOfferEnvelopeFromTemplate.success(res.data && res.data.response))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    console.log("error====", e)
    yield put(generateOfferEnvelopeFromTemplate.failure(e))
  }
}

function* handleFetchListingDocusignTemplates(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    let query = ""
    Object.keys(payload).forEach((dt) => {
      query += `${dt}=${payload[dt]}&`
    })
    const res = yield call(fetchListingDocusignTemplatesAPI, query)
    if (isSuccess(res)) {
      yield put(fetchListingDocusignTemplates.success(res.data && res.data.response))
      // callback()
    }
  } catch (e) {
    console.log("error====", e)
    yield put(fetchListingDocusignTemplates.failure(e))
  }
}

function* handleFetchOfferocusignTemplates(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    let query = ""
    Object.keys(payload).forEach((dt) => {
      query += `${dt}=${payload[dt]}&`
    })
    const res = yield call(fetchOfferocusignTemplatesAPI, query)
    if (isSuccess(res)) {
      yield put(fetchOfferocusignTemplates.success(res.data && res.data.response))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    console.log("error====", e)
    yield put(fetchOfferocusignTemplates.failure(e))
  }
}

function* handleGetTemplatePreview(action) {
  try {
    const {
      payload,
      dealType,
    } = action.data
    let res = {}
    if (dealType === "listing") {
      res = yield call(getTemplatePreviewListingAPI, payload)
    } else if (dealType === "offer") {
      res = yield call(getTemplatePreviewOfferAPI, payload)
    }
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      root.open(response.url, "_blank")
      yield put(getTemplatePreview.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(getTemplatePreview.failure(e))
  }
}

function* handleVoidAndDuplicateCompletedOffer(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(voidAndDuplicateCompletedOfferAPI, payload)
    yield put(voidAndDuplicateCompletedOfferAction.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(voidAndDuplicateCompletedOfferAction.failure(e))
  }
}

function* handleVoidAndDuplicateCompletedListing(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(voidAndDuplicateCompletedListingAPI, payload)
    yield put(voidAndDuplicateCompletedOfferAction.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(voidAndDuplicateCompletedOfferAction.failure(e))
  }
}

function* handleDeleteCompletedListing(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(deleteCompletedListingAPI, payload)
    yield put(deleteCompletedListingAction.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(deleteCompletedListingAction.failure(e))
  }
}

function* handleDeleteCompletedOffer(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(deleteCompletedOfferAPI, payload)
    yield put(deleteCompletedOfferAction.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(deleteCompletedOfferAction.failure(e))
  }
}

function* handleDeleteCoverLetter(action) {
  try {
    const { templateId, type, callback } = action.data
    const params = `?templateId=${templateId}&type=${type}`
    const res = yield call(deleteCoverLetterAPI, params)
    if (isSuccess(res)) {
      yield put(deleteCoverLetter.success(res))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleGetCoverLetter(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    let query = ""
    Object.keys(payload).forEach((dt) => {
      query += `${dt}=${payload[dt]}&`
    })
    const res = yield call(fetchOfferocusignTemplatesAPI, query)
    if (isSuccess(res)) {
      yield put(showCoverLetterResponseAction.success(res.data && res.data.response))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    console.log(e)
  }
}

function* handleSendMailToListingAgents(action) {
  const {
    payload,
    callback,
  } = action.data
  try {
    console.log("==--action.data====", action.data)
    const res = yield call(sendMailToListingAgentsAPI, payload)
    yield put(sendMailToListingAgents.success(res.data && res.data.response))
    customToast("Email has been triggered. Please check your Inbox", toastSuccessConfig)
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    customToast("Something went wrong!")
    if (callback) callback()
    yield put(sendMailToListingAgents.failure(e))
  }
}

function* handleSendPropertyDetails(action) {
  const {
    payload,
    callback,
  } = action.data
  try {
    const res = yield call(sendMortgagePropertiesAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(sendMortgagePropertiesAction.success(response))
      if (callback) callback(response.property_id)
    }
  } catch (e) {
    console.log("error====", e)
    yield put(sendMortgagePropertiesAction.failure(e))
  }
}

function* handleSendClientDetails(action) {
  const {
    payload,
  } = action.data
  try {
    const res = yield call(sendClientDetailsAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(sendClientDetailsAction.success(response))
    }
    customToast.info("Details Submitted Successfully")
    yield put(toggleMortgageForm.call(false))
  } catch (e) {
    console.log("error====", e)
    yield put(sendClientDetailsAction.failure(e))
  }
}

function* handleV2ContractCreation(action) {
  try {
    const {
      payload,
      state,
      currentAgentId,
      callback,
    } = action.data
    const res = yield call(createContractV2API, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(setDealToView.call(response.deal_id))
      if(callback) callback(response.deal_id)
      if (currentAgentId && state) {
        yield put(getAgentDetailsAction.request({
          state,
          agentId: currentAgentId,
          dealId: response.deal_id,
          currentPayload: payload,
          doNoRefetch: true,
        }))
      }
      yield put(createContractV2Action.success(response))
    }
  } catch (e) {
    customToast("Something went wrong.", toastErrorConfig)
    yield put(createContractV2Action.failure(e))
  }
}

function* handleCreateContractFromOffer(action) {
  try {
    const {
      payload,
      offerId,
    } = action.data
    const res = yield call(convertOfferToContractAPI, payload, offerId)
    if (isSuccess(res)) {
      const { response } = res.data
      const {
        metrics,
      } = response || {}
      const agentId = CookiesStorage.load("agentId")
      const isForSelf = parseInt(agentId, 10) === payload.agent_id
      yield put(setDealToView.call(null))
      yield put(dealCountUpdateAction.call({
        forAgent: isForSelf ? "" : `${metrics.firstname} ${metrics.lastname}`,
        dealCount: metrics.total_deals_count,
      }))
      yield put(createOfferConvertedContractAction.success(response))
      yield put(showSuccessModalAction.call(true))
    }
  } catch (e) {
    const errorMessage = e.data && e.data.error && e.data.error.message 
    ? e.data.error.message 
    : "Something went wrong.";
    customToast(errorMessage, "error");
    yield put(createOfferConvertedContractAction.failure(e))
  }
}

function* handleV2ContractUpdate(action) {
  try {
    const {
      payload,
      dealId,
      isFinal,
      currentAgentId,
      state,
      updateOfferStatus,
    } = action.data
    const res = yield call(updateContractV2API, payload, dealId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(updateContractV2Action.success(response))

      if (isFinal) {
        const {
          metrics,
        } = response || {}
        const agentId = parseInt(CookiesStorage.load("agentId"), 10)
        const isForSelf = agentId === payload.agent_id
        yield put(dealCountUpdateAction.call({
          forAgent: isForSelf ? "" : `${metrics.firstname} ${metrics.lastname}`,
          dealCount: metrics.total_deals_count,
        }))
        console.log("response", response)
        createToastNew("Contract details updated")
        yield put(showSuccessModalAction.call(true))
      } else if (currentAgentId && state) {
        yield put(getAgentDetailsAction.request({
          state,
          agentId: currentAgentId,
          dealId,
          currentPayload: payload,
        }))
      }
    }
  } catch (e) {
    const {
      payload,
      isFinal,
    } = action.data
    if (isFinal) {
      root.scrollTo(0, 0)
      customToast("Contract could not be submitted, please check the mandatory fields", toastErrorConfig)
    }    
    // else if (payload.save_draft > 0){
    //     customToast("Contract could not be updated, something went wrong.", toastErrorConfig)
    // }
    yield put(updateContractV2Action.failure(e))
  }
}

function* handleGetAgentDetails(action) {
  try {
    const {
      agentId,
      state,
      dealId,
      currentPayload,
      doNoRefetch,
    } = action.data
    const res = yield call(getAgentInformationAPI, agentId, state)
    if (isSuccess(res)) {
      const { response } = res.data
      console.log(response)
      const primaryAgent = {
        ...response,
        is_primary: 1,
        agent_id: agentId,
        agent_type: currentPayload.property_info.client_type,
      }

      const payload = {
        ...currentPayload,
        agent_id: agentId,
        save_draft: 1,
        agents_info: [primaryAgent],
        client_info: [
          ...currentPayload.client_info,
        ],
        transaction_details: {
          ...currentPayload.transaction_details,
        },
      }

      if (!doNoRefetch) {
        yield put(updateContractV2Action.request({
          dealId,
          payload,
        }))

        yield put(getContractById.request({
          dealId,
          transactionType: "Contract",
        }))
      } else {
        yield put(getContractById.request({
          dealId,
          transactionType: "Contract",
          additionaPayload: payload,
        }))
      }
      
      yield put(getAgentDetailsAction.success(response))
    }
  } catch (error) {
    customToast("Something went wrong.", toastErrorConfig)
    yield put(getAgentDetailsAction.failure(error))
  }
}

function* handleGetOfferContractDetails(action) {
  try {
    const {
      offerId,
      contractDealId,
    } = action.data || {}
    if (!offerId) {
      return
    }

    const res = yield call(getOfferContractDetailsAPI, offerId)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(getOfferContractDetailsAction.success(response))
      const additionaPayload = {
        ...response,
        ...response.contract_information,
      }

      additionaPayload.agents_info = [...additionaPayload.agents_information]
      additionaPayload.clients = [...additionaPayload.clients_information]

      yield put(getContractById.request({
        offerId: contractDealId || offerId,
        additionaPayload,
      }))
    }
  } catch (e) {
    customToast("Something went wrong.")
    yield put(getOfferContractDetailsAction.failure(e))
  }
}

//getContractFieldValidationAction
// function* handleContractFormValidation(action) {
//   const {
//     id,
//   } = action.data
//   try {
//     const res = yield call(sendClientDetailsAPI, payload)
//     if (isSuccess(res)) {
//       const { response } = res.data
//       yield put(sendClientDetailsAction.success(response))
//     }
//     createToastNew.info("Details Submitted Successfully", toastConfig)
//     yield put(toggleMortgageForm.call(false))
//   } catch (e) {
//     console.log("error====", e)
//     yield put(sendClientDetailsAction.failure(e))
//   }
// }

function* handleContractFormValidation(action) {
  try {
    const {
      payload,
      dealId,
      isFinal,
      updateOfferStatus,
    } = action.data
    const res = yield call(getContractFieldValidationAPI, dealId)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data
      yield put(getContractFieldValidationAction.success(response))
      const {
        property_information,
        agent_information,
        client_information,
        transaction_information,
      } = res.data.response || {}
      if (property_information === 0 && agent_information === 0 && client_information === 0 && transaction_information === 0) {
        yield put(updateContractV2Action.request({
          payload,
          dealId,
          isFinal,
          updateOfferStatus,
        }))
      } else {
        root.scrollTo(0, 0)
        customToast.error("Please enter the missing details.", "error")
      }
    }
  } catch (e) {
    console.log(e)
    yield put(getContractFieldValidationAction.failure(e))
  }
}

function* handleTransactionToContractConversation(action) {
  try {
    const {
      payload,
      transactionId,
    } = action.data
    const res = yield call(convertTransactionToContractAPI, payload, transactionId)
    if (isSuccess(res)) {
      const { response } = res.data
      const {
        metrics,
      } = response || {}
      const agentId = parseInt(CookiesStorage.load("agentId"), 10)
      const isForSelf = agentId === payload.agent_id
      yield put(setDealToView.call(null))
      yield put(createTransactionConvertedContractAction.success(response))
      yield put(dealCountUpdateAction.call({
        forAgent: isForSelf ? "" : `${metrics.firstname} ${metrics.lastname}`,
        dealCount: metrics.total_deals_count,
      }))
      yield put(showSuccessModalAction.call(true))
    }
  } catch (e) {
    customToast("Something went wrong.", "error")
    yield put(createTransactionConvertedContractAction.failure(e))
  }
}

function* handleProcessBeforeContracts(action) {
  try {
    const {
      dealId: listingId,
      listingView, //if user is coming from the listing dashboard/list view
      history, //necessary if user is coming from listing view
      callback,
    } = action.data || {}
    const res = yield call(fetchContractDetailsBasedOnIdApi, listingId)
    if (isSuccess(res)) {
      const { response } = res.data
      const additionaPayload = {
        ...response,
        ...response.property_information,
        ...response.contract_information,
        listing_address: response.property_information.street,
        house_number: response.property_information.apt,
      }

      additionaPayload.agents_info = [...additionaPayload.agents_information]

      additionaPayload.agents_info.forEach((item) => {
        item.brokerage = item.listing_brokerage
      })

      //need fixing here
      additionaPayload.clients = additionaPayload.client_information
        ? [...additionaPayload.client_information] : additionaPayload.clients_information
          ? [...additionaPayload.clients_information] : []

      if (response.contract_information) {
        const {
          clients,
        } = response.contract_information || {}

        additionaPayload.clients = [...clients]
        // const existingListingState = yield select(state => state.listings.listingData)
        // yield put(setDealToView.call(id))
      }
      yield put(processBeforeViewingContractAction.success(response))

      yield put(getContractById.request({
        listingId,
        additionaPayload,
        listingView,
        history,
        callback,
      }))
    }
  } catch (e) {
    customToast("Something went wrong.")
    yield put(processBeforeViewingContractAction.failure(e))
  }
}

function* handleListingToContractConversion(action) {
  try {
    const {
      payload,
      listingId,
    } = action.data
    const res = yield call(convertListingToContractAPI, payload, listingId)
    if (isSuccess(res)) {
      const { response } = res.data
      const {
        metrics,
      } = response || {}
      const agentId = CookiesStorage.load("agentId")
      const isForSelf = parseInt(agentId, 10) === payload.agent_id
      yield put(setDealToView.call(null))
      yield put(dealCountUpdateAction.call({
        forAgent: isForSelf ? "" : `${metrics.firstname} ${metrics.lastname}`,
        dealCount: metrics.total_deals_count,
      }))
      yield put(createListingConvertedToContractAction.success(response))
      const currentDetails = yield select(state => state.listings.listingData)
      yield put(ListingsActions.currentDealDetailsObjectAction.call({
        ...currentDetails,
        listingStatus: "pending",
      }))
      yield put(showSuccessModalAction.call(true))
    }
  } catch (e) {
    const errorMessage = e.data && e.data.error && e.data.error.message 
    ? e.data.error.message 
    : "Something went wrong.";
    customToast(errorMessage, "error");
    yield put(createListingConvertedToContractAction.failure(e))
  }
}

function* handleCreateSendeViewOfferChecklist(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(createSendeViewOfferChecklistAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      root.open(response.url, "_blank")
      yield put(createSendeViewOfferChecklistAction.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(createSendeViewOfferChecklistAction.failure(e))
  }
}

function* handleCreateEditViewOfferChecklist(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(createEditViewOfferChecklistAPI, payload)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      root.open(response.url, "_blank")
      yield put(createEditViewOfferChecklistAction.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(createEditViewOfferChecklistAction.failure(e))
  }
}

function* handleDownloadEnvelopeDocChecklist(action) {
  try {
    yield put(downloadingDocs.call(true))
    let query = ""
    Object.keys(action.data.payload).forEach((dt) => {
      query += `${dt}=${action.data.payload[dt]}&`
    })
    const secureId = Cookies.load("secureId")
    const config = {
      method: "get",
      url: `${API_URL}brokerage-offers-docs/envelopes/download?${query}`,
      headers: { "secure-id": secureId },
      responseType: "blob",
    }
    axios(config)
      .then((response) => {
        action.data.callback()
        const link = document.createElement("a")
        link.setAttribute("href", URL.createObjectURL(response.data))
        link.setAttribute("download", `${action.data.documentDetail.name || action.data.documentDetail.document_name || action.data.documentDetail.title}.pdf`)
        document.body.appendChild(link)
        setTimeout(() => {
          link.click()
        }, 1000)
      })
      .catch((error) => {
        console.log(error)
      })
    yield put(downloadEnvelopeDocOfferChecklistAction.success({}))
  } catch (error) {
    const errorMessage = get(error, "data.response") || "Something went wrong, Please try again later!"
    customToast(errorMessage)
    yield put(downloadEnvelopeDocOfferChecklistAction.failure(error))
  }
}

function* handleSendForVoidAndDeleteOfferChecklist(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(sendForVoidAndDeleteOfferChecklistAPI, payload[0])
    yield put(sendForVoidAndDeleteOfferChecklistAction.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(sendForVoidAndDeleteOfferChecklistAction.failure(e))
  }
}

function* handleSendForVoidAndRestartOfferChecklist(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    console.log("==--action.data====", action.data)
    const res = yield call(sendForVoidAndRestartOfferChecklistAPI, payload[0])
    yield put(sendForVoidAndRestartOfferChecklistAction.success(res.data && res.data.response))
    if (callback) callback()
  } catch (e) {
    console.log("error====", e)
    yield put(sendForVoidAndRestartOfferChecklistAction.failure(e))
  }
}

function* handleCreateReceiverViewOfferChecklist(action) {
  try {
    let query = ""
    Object.keys(action.data).forEach((dt) => {
      query += `${dt}=${action.data[dt]}&`
    })
    const res = yield call(createReceiverViewOfferChecklistAPI, query)
    if (isSuccess(res)) {
      const {
        response,
      } = res.data

      root.open(response.url, "_blank")
      yield put(createReceiverViewOfferChecklistAction.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.response") || "Something went wrong, Please try again later!"
    customToast("Something went wrong", "error")
    yield put(createReceiverViewOfferChecklistAction.failure(e))
  }
}


export default function* main() {
  yield takeLatest(getRarProductAndServices.REQUEST, handleFetchRARProductServices)
  yield takeLatest(saveRarTechRequest.REQUEST, handleSaveRarTechRequest)
  yield takeLatest(sendSlackNotificationForProduct.REQUEST, hadleSendSlackNotificationForProduct)
  yield takeLatest(getRarAssets.REQUEST, handleGetRarAssets)
  yield takeLatest(rarSaveCreativeRequest.REQUEST, handleRarSaveCreativeRequest)
  yield takeLatest(saveDesignReferenceImage.REQUEST, handleSaveDesignReferenceImage)
  yield takeLatest(removeDesignReferenceImage.REQUEST, handleRemoveDesignReferenceImage)
  yield takeLatest(getFinancingClientList.REQUEST, handleGetFinancingClientList)
  yield takeLatest(getFinancingSetting.REQUEST, handleGetFinancingSetting)
  yield takeLatest(rarSaveFinancingClientList.REQUEST, handleRarSaveFinancingClientList)
  yield takeLatest(toggleFinanceModal.REQUEST, handleToggleFinanceModal)
  yield takeLatest(showSuccessBar.REQUEST, handleShowSuccessBar)
  yield takeLatest(fetchState.REQUEST, handleFetchState)
  yield takeLatest(fetchTodaysPriceAction.REQUEST, handleFetchTodaysPrice)
  yield takeLatest(getPopupDetailsAction.REQUEST, handleGetPopupDetails)
  yield takeLatest(fetchBrandingAssetsAction.REQUEST, handleFetchBrandingAssets)
  yield takeLatest(fetchBrandingInfoAction.REQUEST, handleFetchBrandingInfo)
  yield takeLatest(updateBrandingInfoAction.REQUEST, updateBrandingModalForm)
  yield takeLatest(createBrandingInfoAction.REQUEST, createBrandingModalForm)
  yield takeLatest(uploadDocsToBucketAction.REQUEST, handleFileUploadToBucketBranding)
  //listings
  yield takeLatest(getAllListings.REQUEST, handleGetListings)
  yield takeLatest(getListingStatusMap.REQUEST, handleGetListingStatusMap)
  yield takeLatest(updateDealStatus.REQUEST, handleUpdateDealStatus)

  yield takeLatest(getListingCoverSheet.REQUEST, handleGetListingCoverSheet)
  yield takeLatest(createListingCoverSheet.REQUEST, handleCreateListingCoverSheet)
  yield takeLatest(updateListingCoverSheet.REQUEST, handleUpdateListingCoverSheet)

  yield takeLatest(getContractById.REQUEST, handleGetContractById)
  yield takeLatest(createContract.REQUEST, handleCreateContract)
  yield takeLatest(updateContract.REQUEST, handleUpdateContract)
  yield takeLatest(getGoogleAuthToken.REQUEST, handleGetGoogleAuthToken)
  yield takeLatest(resetContractsForm.REQUEST, handleResetContractsForm)
  yield takeLatest(resetListingsForm.REQUEST, handleResetListingsForm)
  yield takeLatest(getListingHistory.REQUEST, handleGetListingHistory)

  yield takeLatest(saveListingStepsAction.REQUEST, handleSaveStepsListings)

  //offer
  yield takeLatest(createOfferAction.REQUEST, handleOfferCreation)
  yield takeLatest(getAllOffersAction.REQUEST, handleFetchOffers)
  yield takeLatest(getCurrentDealDetailsAction.REQUEST, handleFetchDeal)
  yield takeLatest(updateOfferDealStatusAction.REQUEST, handleUpdateOfferDealStatus)
  yield takeLatest(updateOfferAction.REQUEST, handleOfferUpdate)
  yield takeLatest(updateVendorAction.REQUEST, handleVendorUpdate)
  yield takeLatest(updateLenderAction.REQUEST, handleLenderUpdate)
  yield takeLatest(getListingDocuments.REQUEST, handleGetListingDocuments)
  yield takeLatest(sendListingDocuments.REQUEST, handleSendListingDocuments)
  yield takeLatest(createSendeView.REQUEST, handleCreateSendeView)
  yield takeLatest(createReceiverView.REQUEST, handleCreateReceiverView)
  yield takeLatest(createEditView.REQUEST, handleCreateEditView)
  yield takeLatest(createEditViewOffer.REQUEST, handleCreateEditViewOffer)
  yield takeLatest(downloadEnvelopeDoc.REQUEST, handleDownloadEnvelopeDoc)
  yield takeLatest(getListingSteps.REQUEST, handleGetListingSteps)
  yield takeLatest(toggleMortgagePopupAction.type, handletoggleWelcomeModal)
  yield takeLatest(refreshListingDocuments.REQUEST, handleRefreshListingDocuments)
  yield takeLatest(updateDocusignEnvelopeStatus.REQUEST, handleUpdateDocusignEnvelopeStatus)
  yield takeLatest(fetchDealTimelineAction.REQUEST, handleFetchDealTimeline)
  yield takeLatest(fetchDealTCDetailsAction.REQUEST, handleFetchDealTCDetails)
  yield takeLatest(updateDealTimelineAction.REQUEST, handleUpdateDealTimeline)
  yield takeLatest(imageUploadForListing.REQUEST, handleImageUploadForListing)
  yield takeLatest(createAllListingImages.REQUEST, handleCreateAllListingImages)
  yield takeLatest(uploadCoverLetterAction.REQUEST, handleUploadForCoverLetter)
  yield takeLatest(putCoverLetterDocsAction.REQUEST, handlePutCoverLetterDocs)
  yield takeLatest(fetchCoverLetterDocsAction.REQUEST, handleFetchCoverLetterDocs)

  //Docusign Offer
  // yield takeLatest(getOfferDocumentsAction.REQUEST, handleGetOfferDocuments)
  yield takeLatest(sendForVoidAndRestart.REQUEST, handleSendForVoidAndRestart)
  yield takeLatest(sendForVoidAndDelete.REQUEST, handleSendForVoidAndDelete)
  yield takeLatest(sendTroubleShootMessage.REQUEST, handleSendTroubleShootMessage)
  yield takeLatest(uploadListingDocToDocusign.REQUEST, handleUploadListingDocToDocusign)

  yield takeLatest(regenerateListingDocuments.REQUEST, handleRegenerateListingDocuments)

  yield takeLatest(getOfferDocuments.REQUEST, handleGetOfferDocuments)
  yield takeLatest(sendOfferDocuments.REQUEST, handleSendOfferDocuments)
  yield takeLatest(createSendeViewOffer.REQUEST, handleCreateSendeViewOffer)
  yield takeLatest(createReceiverViewOffer.REQUEST, handleCreateReceiverViewOffer)
  yield takeLatest(downloadEnvelopeDocOffer.REQUEST, handleDownloadEnvelopeDocOffer)
  yield takeLatest(getOfferSteps.REQUEST, handleGetOfferSteps)
  yield takeLatest(updateOfferDocusignEnvelopeStatus.REQUEST, handleUpdateOfferDocusignEnvelopeStatus)
  yield takeLatest(sendForVoidAndRestartOffer.REQUEST, handleSendForVoidAndRestartOffer)
  yield takeLatest(sendTroubleShootMessageOffer.REQUEST, handleSendTroubleShootMessageOffer)
  yield takeLatest(uploadOfferDocToDocusign.REQUEST, handleUploadOfferDocToDocusign)
  yield takeLatest(uploadCoverLetterDocToDocusign.REQUEST, handleUploadCoverLetterDocToDocusign)
  yield takeLatest(sendForVoidAndDeleteOffer.REQUEST, handleSendForVoidAndDeleteOffer)
  yield takeLatest(regenerateOfferDocuments.REQUEST, handleRegenerateOfferDocuments)
  yield takeLatest(regenerateOfferDocuments.REQUEST, handleRegenerateOfferDocuments)
  yield takeLatest(refreshOfferDocuments.REQUEST, handleRefreshOfferDocuments)
  yield takeLatest(submitAndSendAllOffersFile.REQUEST, handleSubmitAndSendAllOffersFile)
  yield takeLatest(sendAllOffersDocsAction.REQUEST, handleSendAllOffersDocs)
  yield takeLatest(sendDocusignDocsAction.REQUEST, handleSendDocusignDocs)
  yield takeLatest(requestToCreateZip.REQUEST, handleRequestToCreateZip)
  yield takeLatest(generateListingEnvelopeFromTemplate.REQUEST, handleGenerateListingEnvelopeFromTemplate)
  yield takeLatest(generateOfferEnvelopeFromTemplate.REQUEST, handleGenerateOfferEnvelopeFromTemplate)
  yield takeLatest(fetchListingDocusignTemplates.REQUEST, handleFetchListingDocusignTemplates)
  yield takeLatest(fetchOfferocusignTemplates.REQUEST, handleFetchOfferocusignTemplates)
  yield takeLatest(getTemplatePreview.REQUEST, handleGetTemplatePreview)
  yield takeLatest(voidAndDuplicateCompletedOfferAction.REQUEST, handleVoidAndDuplicateCompletedOffer)
  yield takeLatest(voidAndDuplicateCompletedListingAction.REQUEST, handleVoidAndDuplicateCompletedListing)
  yield takeLatest(deleteCompletedListingAction.REQUEST, handleDeleteCompletedListing)
  yield takeLatest(deleteCompletedOfferAction.REQUEST, handleDeleteCompletedOffer)
  yield takeLatest(deleteCoverLetter.REQUEST, handleDeleteCoverLetter)
  yield takeLatest(showCoverLetterResponseAction.REQUEST, handleGetCoverLetter)
  yield takeLatest(sendMailToListingAgents.REQUEST, handleSendMailToListingAgents)
  yield takeLatest(sendMortgagePropertiesAction.REQUEST, handleSendPropertyDetails)
  yield takeLatest(sendClientDetailsAction.REQUEST, handleSendClientDetails)
  yield takeLatest(createContractV2Action.REQUEST, handleV2ContractCreation)
  yield takeLatest(updateContractV2Action.REQUEST, handleV2ContractUpdate)
  yield takeLatest(getAgentDetailsAction.REQUEST, handleGetAgentDetails)
  yield takeLatest(getOfferContractDetailsAction.REQUEST, handleGetOfferContractDetails)
  yield takeLatest(getContractFieldValidationAction.REQUEST, handleContractFormValidation)
  yield takeLatest(createOfferConvertedContractAction.REQUEST, handleCreateContractFromOffer)
  yield takeLatest(createTransactionConvertedContractAction.REQUEST, handleTransactionToContractConversation)
  yield takeLatest(processBeforeViewingContractAction.REQUEST, handleProcessBeforeContracts)
  yield takeLatest(createListingConvertedToContractAction.REQUEST, handleListingToContractConversion)
  yield takeLatest(createSendeViewOfferChecklistAction.REQUEST, handleCreateSendeViewOfferChecklist)
  yield takeLatest(createEditViewOfferChecklistAction.REQUEST, handleCreateEditViewOfferChecklist)
  yield takeLatest(downloadEnvelopeDocOfferChecklistAction.REQUEST,handleDownloadEnvelopeDocChecklist)
  yield takeLatest(sendForVoidAndRestartOfferChecklistAction.REQUEST, handleSendForVoidAndRestartOfferChecklist)
  yield takeLatest(sendForVoidAndDeleteOfferChecklistAction.REQUEST, handleSendForVoidAndDeleteOfferChecklist)
  yield takeLatest(createReceiverViewOfferChecklistAction.REQUEST, handleCreateReceiverViewOfferChecklist)
}
