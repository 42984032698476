import styled from "styled-components";
import colors from "@colors";
import { INTER, MONTSERRAT } from "@fonts";

const Header = styled.h3`
  color: ${colors.new_grey600};
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: ${MONTSERRAT};
  margin-bottom: 24px;
  margin-top: 48px;
  @media (max-width: 1024px) {
    padding-bottom: 6px;
    margin-top: 32px;
  }
`;
const SchoolName = styled.p`
  color: #232338;
  font-family: ${INTER};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
`;

const ListBullet = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: ${colors.new_colorNeutral200};
  margin-right: 8px;
  margin-top: 2px;
`;

const SubHeading = styled.p`
  color: ${colors.new_grey600};
  font-family: ${MONTSERRAT};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
`;
const ScoreWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const Info = styled.p`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
`;

const InfoData = styled.p`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
`;

const Flex = styled.div`
  display: flex;
`;
const Block = styled.div`
  display: block;
  padding-top: 5px;
  margin-left:10px;
`;

const Grades = styled.p`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
`;

const ScoreCard = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  padding: 10.5px 10px 9.5px 10px;
  justify-content: center;
  align-items: center;
  background: ${colors.new_colorNeutral100};
  border-radius: 50%;
`;

const ScoreNumber = styled.span`
  color: ${colors.new_primary};

  font-family: ${INTER};
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%;
  letter-spacing: 0.5px;
`;

const ScoreOutOf = styled.span`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.5px;
`;

const ButtonShow = styled.div`
  color: ${colors.new_primary};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  display: flex;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-top: 8px;
  cursor: pointer;
`;

export {
  Header,
  SubHeading,
  ScoreCard,
  ScoreOutOf,
  ScoreNumber,
  Flex,
  Info,
  Block,
  InfoData,
  SchoolName,
  Grades,
  ListBullet,
  ScoreWrap,
  ButtonShow
};