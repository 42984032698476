import React from "react"
import styled from "styled-components"
import Modal from "@ui/Modal"
import moment from "moment"
import root from "window-or-global"
import {
  Input,
  Select,
  Form,
  AutoComplete,
  Divider,
  Button,
  GoogleAutoComplete,
  Checkbox,
  Spin,
  InputNumber,
} from "@ui/antd"
import colors from "@colors"
import US_STATES from "shared/US_STATES.json"
import { normalizePhone } from "dumbComponents/TransactionCoordinationV2/Forms/Common/Utils"
import { EmailValidations } from "dumbComponents/Offers/consts"
import { ATC } from "@fonts"

const STATE_OPTIONS = Object.keys(US_STATES).map(key => ({ label: US_STATES[key], value: US_STATES[key] }))

const LabelComponent = ({ text, required }) => (
  <>
    <LabelText>{text}</LabelText>
    {required && (<Required>*</Required>)}
  </>
)

const Required = styled.span`
  color: #E34C4C
`

const LabelText = styled.h2`
  font-family: ${ATC};
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
  color: ${colors.black};
  text-align: left;
`

const { Item } = Form

const Wrap = styled.div`
  padding: 50px;

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .ant-form-item-explain {
    text-align: left;
    position: relative;
    top: 6px;
  }

  .ant-select-selection-item{
    text-align: left;
  }

`

const Title = styled.div`
  p {
    font-family: Butler;
    font-size: 24px;
    font-weight: 400;
    text-align: left;
  }
  margin-bottom: 25px;
`

const sendMail = ({ mailId }) => {
  if (root.heap) {
    root.heap.track("click_send_mail_contact_dashboard")
  }
 
  return null
}

const EmailForm = ({
  toClose,
}) => {
  const onFinish = (values) => {
    const {
      firstname,
      lastname,
      email,
      phone,
      state,
      nmls_id,
    } = values

    const subject = "Partner with Loandesk Mortgage"

    const body = ` 
      The MLO Recruitment form was completed on ${moment().format("dddd, MMMM Do YYYY, h:mm:ss a")} 
      with the following information:

      Name: ${firstname} ${lastname}
      Phone: ${phone}
      Email: ${email}
      State: ${state}
      NMLS ID: ${nmls_id}
    `

    const michaelEmail = "michael.bardales@radius-mortgage.com"

    const mail = document.createElement("a")
    mail.href = `mailto:${michaelEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    mail.click()

    setTimeout(() => {
      toClose()
    }, 1000)
  }

  const onFinishFailed = (e) => {
    console.error(e)
  }

  const commonRules = [
    {
      required: true,
      message: "Required",
    },
  ]

  return (
    <Form
      name="mortgage_interest_form"
      preserve
      colon={false}
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      // initialValues={this.getInitialValues()}
    >
      <Item
        name="firstname"
        label={<LabelComponent text="First Name" required />}
        rules={commonRules}
      >
        <Input />
      </Item>
      <Item
        name="lastname"
        label={<LabelComponent text="Last Name" required />}
        rules={commonRules}
      >
        <Input />
      </Item>
      <Item
        name="email"
        label={<LabelComponent text="Email" required />}
        rules={EmailValidations}
      >
        <Input />
      </Item>
      <Item
        name="phone"
        label={<LabelComponent text="Phone" required />}
        rules={commonRules}
        normalize={normalizePhone}
      >
        <Input />
      </Item>
      {/* <Item
        name="state"
        label={<LabelComponent text="State" required />}
        rules={commonRules}
      >
        <Input />
      </Item> */}
      <Item
        name="state"
        label={<LabelComponent text="State" required />}
        rules={commonRules}
      >
        <Select
          onChange={(value) => { console.log(value) }}
        >
          {STATE_OPTIONS && STATE_OPTIONS.map(item => (
            <Select.Option value={item.value}>{item.label}</Select.Option>
          ))}
        </Select>
      </Item>
      <Item
        name="nmls_id"
        label={<LabelComponent text="NMLS ID" required />}
        rules={commonRules}
      >
        <Input />
      </Item>

      <Item>
        <Button
          style={{
            width: 119,
            height: 48,
            background: "#3B60E4",
            float: "right",
            borderColor: "white",
          }}
          type="primary"
          shape="round"
          size="large"
          // loading={creatingOffer || fetchingDeal || updatingDeal}
          htmlType="submit"
        >
          Apply
        </Button>
      </Item>
    </Form>
  )
}

const ApplyForm = ({
  customOverlayStyles,
  toClose,
}) => (
  <Modal
    show
    toClose={() => { toClose() }}
    modalVersion={3}
    width="640px"
    modalStyles={{
      marginTop: 0,
      padding: 0,
      boxShadow: "none",
    }}
    overlayStyles={{
      ...customOverlayStyles || {},
    }}
    customDialogColor={colors.black}
    zIndex={1}
    dialogStyles={{
      background: "#F9F9F7",
      borderRadius: "0px",
      minHeight: "600px",
      fontFamily: {ATC},
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "28px",
      lineHeight: "32px",
      textAlign: "center",
      color: colors.black,
    }}
    iconStyles={{
      padding: "8px",
      borderRadius: "18px",
      fontSize: "10px",
      right: "20px",
      margin: "6px",
      width: "40px",
    }}
  >
    <Wrap>
      <Title>
        <p>Contact Details</p>
      </Title>
      <EmailForm
        toClose={toClose}
      />
    </Wrap>
  </Modal>
)

export default ApplyForm

