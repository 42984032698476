import {
  takeLatest, call, put, select,
} from "redux-saga/effects"
// import root from "window-or-global"
// import cookie from "services/CookieStorage"
// import { get } from "lodash"
import root from "window-or-global"
import CookiesStorage from "services/CookieStorage"
import { toast as createToast } from "react-toastify"
import { get, isEmpty } from "lodash"
import { isSuccess } from "services/Utils"
import * as Actions from "container/App/actions"
import {
  fetchUserProfileAPI, fetchUserOrientationInfoAPI, createUserOrientationInfoAPI, updateUserOrientationInfoAPI,
  getOnboardingSetupAPI, createOnboardingSetupAPI, updateOnboardingSetupAPI, getStripeSecretKeyAPI, getFeeScheduleAPI,
  sendVerifyEmailLinkAPI, verifyEmailLinkTokenAPI, getReceiverViewICAAPI, getAllICADocListAPI, updateEnvelopeStatusAPI, updateAgentEmailAPI, getDisplayScreenAPI
} from "./api"
import * as AppActions from "./actions"

const xUserHeader = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_3) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.89 Safari/537.36 ADUA(Chrome|WebKit|WebCRM|1|web|5|false|94808156743894)"

const toastConfig = {
  position: "top-center",
  autoClose: 2500,
  hideProgressBar: true,
}

function* handleSetupModalInfo(action) {
  try {
    const res = yield call(fetchUserProfileAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.fetchUserInfoAction.success(response))
      console.log("response", response)
    }
  } catch (error) {
    yield put(AppActions.fetchUserInfoAction.failure(error))
    console.log("error")
  }
}

function* handleOrientationModalInfo(action) {
  try {
    const isFirstCall = !!(action && action.data && action.data.isFirstCall)
    const res = yield call(fetchUserOrientationInfoAPI)
    if (isSuccess(res)) {
      const { response: responseData } = res.data
      yield put(AppActions.fetchUserOrientationInfoAction.success(responseData))
      const { response } = responseData
      if (isFirstCall && response && response.completed_steps) {
        
        const count = response?.completed_steps ? response?.completed_steps.length : 0
        if (response.completed_steps.length === 0) {
          yield put(AppActions.setFormStage.call("1"))
          return
        }
        if (response.completed_steps[count - 1] === "basic_license_information") {
          yield put(AppActions.setFormStage.call("2"))
          return
        }
        if (response.completed_steps[count - 1] === "upload_files") {
          yield put(AppActions.setFormStage.call("3"))
          return
        }
        if (response.completed_steps[count - 1] === "assistant_info") {
          yield put(AppActions.setFormStage.call("4"))
          return
        }
        if (response.completed_steps[count - 1] === "referral_info") {
          yield put(AppActions.setFormStage.call("5"))
          return
        }
        if (response.completed_steps[count - 1] === "checklist_screen_info") {
          yield put(AppActions.setFormStage.call("0"))
          return
        }
        if (response.completed_steps[count - 1] === "get_started") {
          yield put(AppActions.setFormStage.call("1"))
          return
        }
        if (count === 0) {
          yield put(AppActions.setFormStage.call("0"))
          return
        }
        // yield put(AppActions.setFormStage.call("0"))
      }
      if (response && !response.completed_steps) {
        yield put(AppActions.setFormStage.call("1"))
      }
    }
  } catch (error) {
    yield put(AppActions.fetchUserOrientationInfoAction.failure(error))
    console.log("error", error)
  }
}

function* createOrientationModalForm(action) {
  try {
    const { payload, setStage, isGettingStarted } = action.data
    const res = yield call(createUserOrientationInfoAPI, payload)
    if (isSuccess(res)) {
      const { reponse } = res.data
      yield put(AppActions.createUserOrientationInfoAction.success(reponse))
      // if (!isGettingStarted) {
        yield put(AppActions.fetchUserOrientationInfoAction.request())
        yield put(AppActions.setFormStage.call(setStage))
      // }
      
    }
  } catch (e) {
    console.log("show error..", e)
    yield put(AppActions.createUserOrientationInfoAction.failure(e))
  }
}

function* updateOrientationModalForm(action) {
  try {
    const { payload, setStage } = action.data
    const res = yield call(updateUserOrientationInfoAPI, payload)
    if (isSuccess(res)) {
      const { reponse } = res.data
      yield put(AppActions.updateUserOrientationInfoAction.success(reponse))
      yield put(AppActions.fetchUserOrientationInfoAction.request())
      yield put(AppActions.setFormStage.call(setStage))
    }
  } catch (e) {
    console.log("show error..")
    yield put(AppActions.updateUserOrientationInfoAction.failure(e))
  }
}

function* handleFileUploadToBucketOrientation(action) {
  try {
    const {
      file,
      objName,
      fileName,
      callback,
    } = action.data

    const signedRes = yield fetch(`${FILE_UPLOAD_API_V2}bucket=real_estate_license&filename=${file.name}`)
    const signedResJson = yield signedRes.json()
    const myHeaders = new Headers({ "Content-Type": file.type })

    const res = yield fetch(signedResJson.url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
    })

    if (res.status === 200 && signedResJson.fileUrlAfterUpload) {
      const existingObj = yield select(state => state.onboardingSetup.uploadDocsToBucketResponse.data)
      if (existingObj) {
        yield put(AppActions.uploadDocsToBucketAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
          ...existingObj,
        }))
      } else {
        yield put(AppActions.uploadDocsToBucketAction.success({
          [objName]: {
            [objName]: signedResJson.fileUrlAfterUpload,
            [`${objName}FileName`]: fileName,
          },
        }))
      }
      // callback()
      // createToast.info(`Uploaded ${file.name}`, toastConfig)
    }
  } catch (e) {
    console.log("error====", e)
    // createToast.error("Error Uploading file", toastConfig)
    // yield put(AppActions.uploadDocsToBucketAction.failure(e))
  }
}

function* handleGetOnboardingSetup(action) {
  try {
    const res = yield call(getOnboardingSetupAPI)
    if (isSuccess(res)) {
      const { response } = res.data
      // const { response: responseData, isShow } = response
      // if (!isShow) {
      //   return
      // }
      yield put(AppActions.getOnboardingSetup.success(response))
      // yield put(AppActions.toggleSetupPopup.call(1))
      // if (action?.data?.isDraft) {
      //   yield put(AppActions.toggleCommissionsPopup.call(0))
      //   return
      // }
      // yield put(AppActions.toggleSetupPopup.call(5))
      // return

      if (action?.data?.isContinue) {
        yield put(AppActions.toggleSetupPopup.call(action.data.isContinue))
        return
      }
      if (!response.isOrientationCompleted) {
        return
      }
      if (!response) {
        yield put(AppActions.toggleSetupPopup.call(1))
      }
      if (response && response.completed_steps && response.completed_steps.length) {
        if (response.completed_steps.includes("basic_info")) {
          yield put(AppActions.toggleSetupPopup.call(2))
        }
        if (response.completed_steps.includes("office_details")) {
          yield put(AppActions.toggleSetupPopup.call(3))
        }
        if (response.completed_steps.includes("social_media_details")) {
          yield put(AppActions.toggleSetupPopup.call(4))
        }
        if (response.completed_steps.includes("agent_bio_details")) {
          yield put(AppActions.toggleSetupPopup.call(6))
        }
      } else {
        yield put(AppActions.toggleSetupPopup.call(1))
      }
    }
  } catch (e) {
    console.log("ERROR===", e)
    yield put(AppActions.getOnboardingSetup.failure(e))
  }
}

function* handleCreateOnboardingSetup(action) {
  try {
    const {
      payload,
      isDraft,
      isContinue,
    } = action.data

    const res = yield call(createOnboardingSetupAPI, payload)
    yield put(AppActions.getOnboardingCommission.request({ isDraft, isContinue }))
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.createOnboardingCommission.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(AppActions.createOnboardingCommission.failure(e))
  }
}

function* handkleUpdateOnboardingSetup(action) {
  try {
    const {
      payload,
      isDraft,
      isContinue,
    } = action.data

    const res = yield call(updateOnboardingSetupAPI, payload)
    yield put(AppActions.getOnboardingSetup.request({ isDraft, isContinue }))
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.updateOnboardingSetup.success(response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(AppActions.updateOnboardingSetup.failure(e))
  }
}

function* handleGetFeeDetails() {
  try {
    const secureId = CookiesStorage.load("secureId")
    const headers = new Headers()
    headers.append("secure-id", secureId)
    const url = `${API_URL}user/onboarding/fee-schedule`
    const authFetch = yield fetch(url, {
      headers,
      mode: "cors",
      method: "GET",
    })
    const res = yield authFetch
    const response = yield authFetch.json()
    if (isSuccess(res)) {
      yield put(AppActions.getFeeScheduleAction.success(response.response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(AppActions.getFeeScheduleAction.failure(e))
  }
}

function* handleSendVerifyLinkEmail(action) {
  try {
    const headers = new Headers()
    const { payload, callback, secureId } = action.data
    if (secureId) {
      headers.append("secure-id", secureId)
    } else {
      // internal id is unique
      headers.append("internal-id", "d3abb3c23d1692f2cc9abb85d6f4ce66")
    }
    headers.append("content-Type", "application/json")
    const url = `${API_URL}user/login/send-verification-email`
    const authFetch = () => fetch(url, {
      headers,
      mode: "cors",
      body: JSON.stringify(payload),
      method: "POST",
    })
    const res = yield call(authFetch)
    if (isSuccess(res)) {
      yield put(AppActions.sendVerifyEmailLinkAction.success(res.status))
      if (callback) {
        callback()
      }
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(AppActions.sendVerifyEmailLinkAction.failure(e))
  }
}

function* handleVerifyEmailLinkToken(action) {
  try {
    const { payload, callback } = action.data
    const headers = new Headers()
    // internal id is unique
    headers.append("x-user-agent", xUserHeader)
    headers.append("internal-id", "d3abb3c23d1692f2cc9abb85d6f4ce66")
    headers.append("content-Type", "application/json")
    const url = `${API_URL}user/login/verify-email-link-token`
    const authFetch = yield fetch(url, {
      headers,
      mode: "cors",
      body: JSON.stringify(payload),
      method: "POST",
    })
    const res = yield authFetch
    const response = yield authFetch.json()
    if (isSuccess(res)) {
      yield put(AppActions.verifyEmailLinkTokenAction.success(response.response))
      CookiesStorage.saveUserToCookies(response.response.user)
      CookiesStorage.save("secureId", response.response.secure_id)
      CookiesStorage.save("email", response.response.user.email)
      if (callback) {
        callback(response.response.secure_id, response.response.user.email, response.response.newUser)
      }
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(AppActions.verifyEmailLinkTokenAction.failure(e))
  }
}

function* handleGetDislayScreen(action) {
  try {
    const {
      secureId, email, history, loggedIn, newUser,
    } = action.data
    const user = CookiesStorage.load("user")
    const redirectUrl = CookiesStorage.load("redirectUrl")
    const headers = new Headers()
    headers.append("secure-id", secureId)
    CookiesStorage.save("secureId", secureId)
    const query = `?email=${email}`
    const url = `${API_URL}user/login/office-app/get-display-screen${query}`
    const authFetch = yield fetch(url, {
      headers,
      mode: "cors",
      method: "GET",
    })
    const res = yield authFetch
    const response = yield authFetch.json()
    if (isSuccess(res)) {
      yield put(AppActions.getDisplayScreenAction.success(response))
      if (redirectUrl) {
        yield put(Actions.fetchUserInfoAction.request({
          callback: () => {            
            root.location.href = `${RADIUS_URL}${redirectUrl}`
            CookiesStorage.remove("redirectUrl")
          },
        }))
        return
      }
      if (loggedIn) {
        const {
          response: backendRes,
        } = response || {}
        if (backendRes && backendRes.action === "showPendingPaymentScreen") {
          yield put(AppActions.triggerPaymentBannerAction.call(true))
        }
      } else {
        switch (response.response.action) {
          // ICA signed and Subscribed to brokerage plan
          case "showHomeScreen":
            yield put(Actions.fetchUserInfoAction.request({ triggerDataLayerPush: true, callback: () => { root.location.href = "/realty/dashboard" } }))
            break
          // subscribed to brokerage plan
          case "showICAForm":
            history.push("/office/ica-onboarding")
            break
          // ICA signed but not paid for subscription
          case "showPendingPaymentScreen":
            history.push("/stripe/addCardDetails/authenticate")
            break
            // default case, network agent login
          case "showPendingPaymentScreenPostTrial":
            yield put(AppActions.triggerPaymentBannerAction.call(true))
            yield put(Actions.fetchUserInfoAction.request({ callback: () => { history.push("/community") } }))
            break
          case "showBrokerageInfoScreenMQLFilled":
          case "showBrokerageInfoScreen":
            if (newUser || !user.firstname) {
              history.push(`/signup/lets-connect?email=${email}`)
            } else {
              yield put(Actions.fetchUserInfoAction.request({ callback: () => { root.location.href = "/community" } }))
            }
            break
          default:
            break
        }
      }
      
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(AppActions.sendVerifyEmailLinkAction.failure(e))
  }
}

function* handleGetReceiverViewICA(action) {
  try {
    const { payload } = action.data
    const secureId = CookiesStorage.load("secureId")
    const headers = new Headers()
    headers.append("secure-id", secureId)
    headers.append("content-Type", "application/json")
    const url = `${API_URL}brokerage-ica/envelopes/receiver-view`
    const authFetch = yield fetch(url, {
      headers,
      mode: "cors",
      method: "POST",
      body: JSON.stringify(payload),
    })
    const res = yield authFetch
    const response = yield authFetch.json()
    if (isSuccess(res)) {
      yield put(AppActions.getReceiverViewICAAction.success(response.response))
      // root.open(response.response.url, "_blank")
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(AppActions.getReceiverViewICAAction.failure(e))
  }
}

function* handleFetchAllICADocs() {
  try {
    const secureId = CookiesStorage.load("secureId")
    const headers = new Headers()
    headers.append("secure-id", secureId)
    const url = `${API_URL}brokerage-ica/envelopes`
    const authFetch = yield fetch(url, {
      headers,
      mode: "cors",
      method: "GET",
    })
    const res = yield authFetch
    const response = yield authFetch.json()
    if (isSuccess(res)) {
      yield put(AppActions.getAllICADocListAction.success(response.response))
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(AppActions.getAllICADocListAction.failure(e))
  }
}

function* handleGetStripeSecretKey(action) {
  try {
    const { secureId, callback } = action.data
    const headers = new Headers()
    headers.append("secure-id", secureId)
    const url = `${API_URL}stripe/customers/without-token`
    const authFetch = yield fetch(url, {
      headers,
      mode: "cors",
      method: "POST",
    })
    const res = yield authFetch
    const response = yield authFetch.json()
    if (isSuccess(res)) {
      yield put(AppActions.getStripeSecretKeyAction.success(response.response))
      if (callback) {
        callback(response.response.stripe_client_secret)
      }
    }
  } catch (e) {
    const errorMessage = get(e, "data.error.message") || "Something went wrong, Please try again later!"
    createToast.error(errorMessage, toastConfig)
    yield put(AppActions.getStripeSecretKeyAction.failure(e))
  }
}

function* handleUpdateEnvelopeStatus(action) {
  try {
    const { payload } = action.data
    const res = yield call(updateEnvelopeStatusAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.updateEnvelopeStatusAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.updateEnvelopeStatusAction.failure(error))
  }
}

function* handleUpdateAgentEmail(action) {
  try {
    const { payload, callback } = action.data
    const res = yield call(updateAgentEmailAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AppActions.updateAgentEmailAction.success(response))
      if (callback) {
        callback()
      }
    }
  } catch (error) {
    yield put(AppActions.updateAgentEmailAction.failure(error))
  }
}

function* handlePaymentBanner(action) {
  try {
    const {
      email,
    } = action.data
    const query = `?email=${email}`
    const res = yield call(getDisplayScreenAPI, query)
    if (isSuccess(res)) {
      const { response } = res.data
      if (response.action === "showPendingPaymentScreenPostTrial") {
        yield put(AppActions.triggerPaymentBannerAction.call(true))
      }
      yield put(AppActions.checkPaymentBannerAction.success(response))
    }
  } catch (error) {
    yield put(AppActions.checkPaymentBannerAction.failure(error))
  }
}

export default function* main() {
  yield takeLatest(AppActions.fetchUserInfoAction.REQUEST, handleSetupModalInfo)
  yield takeLatest(AppActions.fetchUserOrientationInfoAction.REQUEST, handleOrientationModalInfo)
  yield takeLatest(AppActions.createUserOrientationInfoAction.REQUEST, createOrientationModalForm)
  yield takeLatest(AppActions.updateUserOrientationInfoAction.REQUEST, updateOrientationModalForm)
  yield takeLatest(AppActions.uploadDocsToBucketAction.REQUEST, handleFileUploadToBucketOrientation)

  yield takeLatest(AppActions.getOnboardingSetup.REQUEST, handleGetOnboardingSetup)
  yield takeLatest(AppActions.createOnboardingSetup.REQUEST, handleCreateOnboardingSetup)
  yield takeLatest(AppActions.updateOnboardingSetup.REQUEST, handkleUpdateOnboardingSetup)
  yield takeLatest(AppActions.getStripeSecretKeyAction.REQUEST, handleGetStripeSecretKey)
  yield takeLatest(AppActions.getFeeScheduleAction.REQUEST, handleGetFeeDetails)
  yield takeLatest(AppActions.sendVerifyEmailLinkAction.REQUEST, handleSendVerifyLinkEmail)
  yield takeLatest(AppActions.verifyEmailLinkTokenAction.REQUEST, handleVerifyEmailLinkToken)
  yield takeLatest(AppActions.getDisplayScreenAction.REQUEST, handleGetDislayScreen)
  yield takeLatest(AppActions.getReceiverViewICAAction.REQUEST, handleGetReceiverViewICA)
  yield takeLatest(AppActions.getAllICADocListAction.REQUEST, handleFetchAllICADocs)
  yield takeLatest(AppActions.updateEnvelopeStatusAction.REQUEST, handleUpdateEnvelopeStatus)
  yield takeLatest(AppActions.updateAgentEmailAction.REQUEST, handleUpdateAgentEmail)
  yield takeLatest(AppActions.checkPaymentBannerAction.REQUEST, handlePaymentBanner)
}
