import React, { useEffect, useState, useCallback } from "react"
import { debounce, get } from "lodash"
import { withRouter } from "react-router-dom"
import cookie from "services/CookieStorage"
import { Form } from "antd"
import createToast from "dumbComponents/common/Toast/customToast"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"
import {
  FloatingCTA,
} from "dumbComponents/common/InputFields"
import Listings from "container/Listings"
import SearchContainer from "container/Search/SearchContainer"
import TechAssetsContainer from "container/TechAssets"
import TCContainer from "container/Realty/TransactionFormContainer"
import AppContainer from "container/App"
import queryString from "query-string"
import FormShimmer from "../FormShimmer"
import FileList from "../FileListing"
import {
  PropertyInformationForm,
  ClientInformationForm,
  TransactionDetailsForm,
  ContractFileUploadForm,
  BuyerAgentForm,
} from "../ContractForm/Forms"
import {
  Wrap,
  StyledCollapse,
  StyledPanel,
  StatusWrap,
  StatusTag,
} from "./style"
import {
  PAYLOAD,
  VALID_STATES,
} from "./const"
import {
  getStatusLabel,
  getIsEdit,
} from "./utils"

const FileTypeFieldMap = {
  listing_with_accepted_offer: ["price", "list_price", "commission_type", "team_split_percentage", "created_at", "closed_on"],
  pending_contract: ["price", "commission_type", "team_split_percentage", "created_at", "closed_on"],
  dual: ["price", "list_price", "commission_type", "team_split_percentage", "created_at", "closed_on"],
  lease_listing: ["list_price", "rent_amount_term", "rent_amount", "commission_type", "team_split_percentage", "created_at", "closed_on", "lease_commencement_on"],
  pending_lease: ["rent_amount_term", "rent_amount", "commission_type", "team_split_percentage", "created_at", "closed_on", "lease_commencement_on"],
  external_referral_agreement: ["price", "commission_type", "team_split_percentage", "created_at", "closed_on"],
}
const CollapsibleForm = ({
  options,
  listingData,
  history,
  dealToView,
  getContractById,
  getContractByIdResponse,
  createContract,
  setDealToView,
  createContractV2,
  updateContractV2,
  createContractV2Response,
  updateContractV2Response,
  comingFromOffers,
  editingDealTeamMemberId,
  getOfferContractDetails,
  getContractFieldValidation,
  getContractFieldValidationResponse,
  createOfferConvertedContractResponse,
  createOfferConvertedContract,
  createTransactionConvertedContract,
  createListingConvertedToContract,
  uploadTCFilesResponse,
  fetchTCDocumentsFromS3Response,
  createTransactionConvertedContractResponse,
  documents,
  showAgentType,
  getOfferContractDetailsResponse,
  history: {
    location,
  },
  createListingConvertedToContractResponse,
  processBeforeViewingContractResponse,
  performNecessaryActionsBeforeLoadingListingResponse,
  user,
  fetchUserInfo,
  getTeamDetailsResponse,
}) => {
  const {
    isFetching: fetchingContractDetails,
    data: contractResponse,
  } = getContractByIdResponse || {}
  const {
    data: teamData,
  } = getTeamDetailsResponse || {}
  const {
    isFetching: fetchingOfferContractDetails,
    data: contractOfferResponse
  } = getOfferContractDetailsResponse || {}


  const {
    isFetching: isFetchingForProcessingBeforeViewingContract,
  } = processBeforeViewingContractResponse || {}

  const {
    isFetching: isFetchingForPerformingNecessaryActionsBeforeLoadingListing,
  } = performNecessaryActionsBeforeLoadingListingResponse || {}

  const [currentActivePanels, setActivePanels] = useState(["property_information"])
  const [currentPayload, setCurrentPayload] = useState(PAYLOAD)
  const [currentOfferId, setOfferId] = useState("")
  const [currentStatus, setCurrentStatus] = useState("INCOMPLETE")
  const [isFormEditable, setFormEditableState] = useState(true)
  const [isContractPhase, setContractPhase] = useState(false)
  const [isTransactionToContractPhase, setTransactionToContractPhase] = useState(false)
  const userAgent = cookie.load("user")
  const tcInformation = cookie.load("tcInformation")
  const agent_id = userAgent.id
  const queryParams = queryString.parse(location.search)
  const showUpload = false
  const [ClientInformationFormRef] = Form.useForm()
  const [TransactionDetailsFormRef] = Form.useForm()
  const [isSplitWithTeam, setSplitWithTeam] = useState(false)
  const [currentSelectedState, setCurrentSelectedState] = useState("")
  const [currentSelectedClientType, setCurrentSelectedClientType] = useState()
  const {
    dealId: listingId,
    contractId,
  } = listingData || {}
  const {
    contract_phase: contractPhase,
    transaction_phase: transactionToContractPhase,
    listingId: listingDummyId,
    offerId: offerDummyId,
  } = queryParams || {}

  const {
    isFetching: covertingTransactionToContract,
  } = createTransactionConvertedContractResponse || {}

  const {
    data: creatingContract,
    isFetching: savingFormDraft,
  } = createContractV2Response || {}

  const {
    isFetching: updatingFormDraft,
  } = updateContractV2Response || {}

  const {
    isFetching: validating,
    data: validationReport,
  } = getContractFieldValidationResponse || {}

  const {
    isFetching: creatingContractFromOffers,
  } = createOfferConvertedContractResponse || {}

  const {
    isFetching: convertingListingToContract,
  } = createListingConvertedToContractResponse || {}

  const {
    data: files,
    isFetching: uploadingFiles,
  } = uploadTCFilesResponse || {}

  const {
    data: fetchedFiles,
    isFetching: fetchingTCFiles,
  } = fetchTCDocumentsFromS3Response || {}

  useEffect(() => {
    fetchUserInfo()
    const locationSplit = location.pathname.split("/")
    const contractId = locationSplit.indexOf("listings")<= -1 && locationSplit.indexOf("offers-v3") <= -1 && locationSplit[locationSplit.length - 1] || ""
    const offerId = locationSplit.indexOf("transaction-coordination") <= -1
    && locationSplit.indexOf("listings") <= -1
    && locationSplit[locationSplit.length - 1] || ""
    if (offerId && !transactionToContractPhase) {
      setOfferId(offerId)
    }

    if (offerId && !transactionToContractPhase) { //need to handle this unnecessary case
      const id = parseInt(offerId, 10)
      setDealToView(id)
      getOfferContractDetails({
        offerId: id,
      })
    } else if (dealToView && !transactionToContractPhase) {
      getContractById({
        dealId: dealToView,
        transactionType: "Contract",
      })
    } else if (contractId && !transactionToContractPhase && (listingDummyId || offerDummyId )) {
      let brokeragetype = "" 
      let dummyId
      if(listingDummyId){
        dummyId = parseInt(listingDummyId,10)
        brokeragetype = "listings"
      } else if(offerDummyId) {
        dummyId = parseInt(offerDummyId,10)
        brokeragetype = "offers"
      }
      const id = parseInt(contractId, 10)
      setDealToView(id)
      getContractById({
        dealId: id,
        transactionType: "Contract",
        brokeragetype,
        dummyId
      })
    }
  }, [])

  useEffect(() => {
    if (!fetchingContractDetails && contractResponse && contractResponse.length > 0) {
      const currentStateOfForm = { ...currentPayload }
      const dbResponse = contractResponse[0]
      // setCurrentPayload(prevState => ({
      //   ...prevState,
      //   ...formState,
      // }))
      const {
        clients,
        listing_address,
        house_number,
        city,
        county,
        zipcode,
        file_type,
        client_type,
        state,
        price,
        list_price,
        rent_amount,
        rent_amount_term,
        other_rent_amount_term,
        commission_type,
        commision_percentage,
        fixed_fee,
        team_split_percentage,
        created_at, //might need change
        closed_on, //might need change
        lease_commencement_on, //mnc
        transaction_type,
        other_transaction_type,
        is_all_cash_offer,
        lender_email_address,
        country,
        agents_info,
        agent_info,
        completed_steps = [],
        step = false,
        agent_firstname,
        agent_lastname,
        agent_first_name,
        agent_last_name,
        agent_license_number,
        agent_email,
        agent_phone,
        brokerage_address,
        brokerage_license_number,
        apt,
        agent_type,
        email,
        phone,
        listing_status = "",
        contract_status,
      } = dbResponse || {}

      let agentId
      if(agents_info[0]){
        agentId =agents_info[0].agent_id
      } else {
        agentId = dbResponse.agent_id
      }
      if (tcInformation?.is_tc) {
        const teamLead = teamData?.team_members?.find(member => member.member_type === "Team Lead")
        const teamLeadId = teamLead ? teamLead.agent_id : null
        if (agentId == teamLeadId)setSplitWithTeam(true)
        else setSplitWithTeam(false)
      } else {
        if(user && user.isBrokerageTeamMember == false){
          setSplitWithTeam(false)
        } else {
          if(user.isBrokerageTeamLead == false){
            setSplitWithTeam(false)
          } else {
            user.id == agentId ? setSplitWithTeam(true) : setSplitWithTeam(false)
          }
        }
      }
      

      const primaryAgentObject = {
        agent_license_number: agent_license_number || "",
        agent_type: agent_type || "",
        apt: apt || "",
        brokerage_address: brokerage_address || "",
        brokerage_license_number: brokerage_license_number || "",
        brokerage_name: "",
        city: city || "",
        email: agent_email || email || "",
        is_primary: 1,
        name: `${agent_first_name || agent_firstname} ${agent_last_name || agent_lastname}`,
        phone: agent_phone || phone || "",
        state: state || "",
        zipcode: zipcode || "",
        landline: "",
      }
 
      const propertyInfoObject = {
        listing_address,
        house_number,
        city,
        county,
        zipcode,
        file_type,
        client_type,
        country,
        state,
      }

      const transactionFormObject = {
        price,
        list_price,
        rent_amount,
        rent_amount_term,
        other_rent_amount_term,
        commission_type,
        commision_percentage,
        fixed_fee,
        team_split_percentage,
        created_at, //might need change
        closed_on, //might need change
        lease_commencement_on, //mnc
        transaction_type,
        other_transaction_type,
        is_all_cash_offer,
        lender_email_address,
        agent_id: dbResponse.agent_id || userAgent.id,
      }

      const statusOfForm = contract_status?.display_name?.length ? contract_status.display_name.toUpperCase() : getStatusLabel(completed_steps || [], step)
      const formEditable = getIsEdit(step)

      currentStateOfForm.agent_id = dbResponse.agent_id || userAgent.id
      currentStateOfForm.save_draft = 1
      currentStateOfForm.property_info = propertyInfoObject
      currentStateOfForm.client_info = clients
      currentStateOfForm.agents_info = agents_info && agents_info.length > 0
        ? agents_info : agent_info
        && agent_info.length > 0 ? agent_info : [primaryAgentObject]
      currentStateOfForm.transaction_details = transactionFormObject


      if (contractPhase === "true") {
        setContractPhase(true)
      }

      if (transactionToContractPhase === "true") {
        setTransactionToContractPhase(true)
      }
      setCurrentStatus(statusOfForm)
      setCurrentPayload(currentStateOfForm)
      setFormEditableState(formEditable)
      setActivePanels(["property_information", "client_information", "transaction_information", "buyer_agent_information"])
    }
  }, [contractResponse, fetchingContractDetails])

  const saveFormData = useCallback(
    debounce(async (payload, dealIdPresent = "", isPropertyForm, dbData, reFetch) => {
      try {
        const {
          location,
        } = history

        const {
          agent_id: currentAgentId,
          property_info: {
            house_number,
            listing_address,
            city,
            state,
            zipcode,
            client_type,
            file_type,
          },
        } = payload || {}

        const locationSplit = location.pathname.split("/")
        const offerId = locationSplit.indexOf("transaction-coordination") <= -1
        && locationSplit[locationSplit.length - 1] || ""

        const dbDataExists = dbData && dbData.length > 0

        const isValidState = (item) => {
          if (VALID_STATES.includes(item)) {
            return item
          }

          return false
        }

        if (isPropertyForm) {
          const propertyPayload = {
            agent_id: currentAgentId,
            current_contract_step: "basic_info",
            house_number,
            listing_address,
            city,
            state,
            zipcode,
            client_type,
            file_type,
            saveDraft: 1,
          }

          if (dbDataExists && (dbData[0].deal_id || dbData[0].id)) { // if valid deal just
            const id = dbData[0].deal_id || dbData[0].id
            setDealToView(id)
            const updatePayload = {
              ...payload,
              agent_id: editingDealTeamMemberId || currentAgentId,
              transaction_details: {
                ...payload.transaction_details,
                agent_id: parseInt(editingDealTeamMemberId || currentAgentId , 10),
              },
            }

            if (payload && payload.agents_info && payload.agents_info.length > 0) {
              updateContractV2({
                agent_id: currentAgentId,
                dealId: dealIdPresent,
                payload: updatePayload,
              })
            } else {
              updateContractV2({
                agent_id: currentAgentId,
                dealId: dealIdPresent,
                payload: updatePayload,
                state: isValidState(state),
                currentAgentId: updatePayload.property_info.client_type ? editingDealTeamMemberId || currentAgentId : "",
              })
            }
          } else if (offerId && dbDataExists && !dbData[0].deal_id) { //if offer id and no deal id
            createContract({
              offerId,
              payload: propertyPayload,
              doNotFetchContract: true,
              state: isValidState(state),
              currentAgentId: editingDealTeamMemberId,
            })
          } else if (!dbDataExists && dealIdPresent) {
            const updatePayload = {
              ...payload,
              agent_id: editingDealTeamMemberId || currentAgentId,
              transaction_details: {
                ...payload.transaction_details,
                agent_id: parseInt(editingDealTeamMemberId || currentAgentId, 10),
              },
            }
            if (payload && payload.agents_info && payload.agents_info.length > 0) {
              updateContractV2({
                agent_id: currentAgentId,
                dealId: dealIdPresent,
                payload: updatePayload,
              })
            } else {
              updateContractV2({
                agent_id: currentAgentId,
                dealId: dealIdPresent,
                payload: updatePayload,
                state: isValidState(state),
                currentAgentId: updatePayload.property_info.client_type ? editingDealTeamMemberId || currentAgentId : "",
              })
            }
          } else { // new contract
            const createPayload = {
              ...payload,
              agent_id: parseInt(editingDealTeamMemberId || currentAgentId, 10),
              transaction_details: {
                agent_id: parseInt(editingDealTeamMemberId || currentAgentId, 10),
              },
            }

            if (createPayload.property_info.file_type) {
              createContractV2({
                payload: createPayload,
                state: isValidState(state),
                currentAgentId: createPayload.property_info.client_type ? editingDealTeamMemberId || currentAgentId : "",
              })
            }
          }
        } else if (offerId && offerId === dealIdPresent) {
          if (dbDataExists && !dbData[0].deal_id) {
            createContractV2({
              payload,
            })
          }
        } else if (offerId && offerId !== dealIdPresent) {
          const updatePayload = {
            ...payload,
          }
          if (!updatePayload.transaction_details.agent_id) {
            updatePayload.transaction_details.agent_id = parseInt(editingDealTeamMemberId || currentAgentId, 10)
          }

          if (dbDataExists && (dbData[0].deal_id || dealIdPresent)) {
            const id = dbData[0].deal_id || dealIdPresent
            updatePayload.transaction_details.agent_id = parseInt(updatePayload.transaction_details.agent_id, 10)
            setDealToView(id)
            updateContractV2({
              dealId: id,
              payload: updatePayload,
            })
          } else if (dbDataExists && (dbData[0].id || dealIdPresent)) {
            const id = dbData[0].id || dealIdPresent
            setDealToView(id)
            updateContractV2({
              dealId: id,
              payload: updatePayload,
            })
          }
        } else if (dealIdPresent) {
          const updatePayload = {
            ...payload,
          }
          if (!updatePayload.transaction_details.agent_id) {
            updatePayload.transaction_details.agent_id = parseInt(editingDealTeamMemberId || currentAgentId, 10)
          }
          const id = dbDataExists ? dbData[0].deal_id ? dbData[0].deal_id : dbData[0].id : dealIdPresent
          setDealToView(id)
          updateContractV2({
            dealId: dbData[0].deal_id || dbData[0].id || dealIdPresent,
            payload: updatePayload,
          })
        } else {
          const createPayload = {
            ...payload,
            agent_id: parseInt(editingDealTeamMemberId || currentAgentId, 10),
            transaction_details: {
              agent_id: parseInt(editingDealTeamMemberId || currentAgentId, 10),
            },
          }

          if (createPayload.property_info.file_type) {
            createContractV2({
              payload: createPayload,
            })
          }
        }

        /*This would be mostly client information*/
        if (reFetch && payload.client_info.length <= 0) {
          getContractById({
            dealId: dealToView,
            transactionType: "Contract",
          })
        }
      } catch (error) {
        console.error("Error saving form data:", error)
      }
    }, 1800),
    []
  )

  const showERAFields = (fieldType) => {
    const propertyInfo = currentPayload && currentPayload.property_info
    if (contractResponse === null && !propertyInfo.client_type && !propertyInfo.file_type) return false
    const fileType = propertyInfo.file_type || (contractResponse && (contractResponse[0].file_type || contractResponse?.file_type))
    const clientType = propertyInfo.client_type || (contractResponse && (contractResponse[0]?.client_type || contractResponse?.client_type))
    if (!(clientType === "tenant" || clientType === "landlord") && fieldType === "price") {
      return true
    }
    if (fileType === "external_referral_agreement" && (clientType === "tenant" || clientType === "landlord")) {
      if (clientType === "tenant" || clientType === "landlord") {
        if (fieldType.includes("rent")) {
          return true
        }
        if (fieldType === "list_price" && clientType === "tenant") {
          return false
        }
        if (fieldType === "list_price" && clientType === "landlord") {
          return true
        }
        if (fieldType === "price" && (clientType === "landlord" || clientType === "tenant")) {
          return false
        }
      }
    } else if (fileType === "external_referral_agreement" && (clientType !== "landlord" || clientType !== "tenant")) {
      if (fieldType === "price") {
        return true
      }
    }
    return false
  }

  const showField = (fieldType) => {
    const propertyInfo = currentPayload && currentPayload.property_info
    if (fieldType == "team_split_percentage") {
      if (fieldType === "team_split_percentage") {
        if ((user && user.isBrokerageTeamMember == false) && !(tcInformation?.is_tc)) {
          return false
        }
        return true
      }
    }
    if (contractResponse === null && !propertyInfo.file_type) return false
    const fileType = propertyInfo.file_type || (contractResponse && (contractResponse[0]?.file_type || contractResponse?.file_type))
    if (fileType && FileTypeFieldMap[fileType]) {
      return FileTypeFieldMap[fileType].includes(fieldType)
    }
  }

  const handleFormChangePropertyForm = (form, content) => {
    const formState = { ...currentPayload }
    formState.property_info = { ...content }
    setCurrentPayload(prevState => ({
      ...prevState,
      ...formState,
    }))

    if (!isTransactionToContractPhase && !listingId) {
      saveFormData(formState, dealToView, true, contractResponse)
    }
  }

  const handleInitPropertyChange = (locationObject) => {
    const formState = { ...currentPayload }
    formState.property_info = { ...currentPayload.property_info, ...locationObject }
    setCurrentPayload(prevState => ({
      ...prevState,
      ...formState,
    }))

    if (!isTransactionToContractPhase && !listingId) {
      saveFormData(formState, dealToView, true, contractResponse)
    }
  }

  const handleFormChangeClientForm = (form, content) => {
    try {
      if (content.co_clients && content.co_clients.length > 0) {
        content.co_clients.forEach((item) => {
          const currentClient = item
          currentClient.is_primary = 0
          // currentClient.client_id = null
          currentClient.referral_id = null
          // currentClient.source = null
        })
      }

      const payload = {
        clients: [],
      }

      const primaryClient = {
        ...content,
        client_id: null,
        referral_id: null,
      }

      delete primaryClient.co_clients

      payload.clients.push(primaryClient)
      if (content.co_clients) {
        const coClient = content.co_clients.filter(item => item.name && item)
        const modified = coClient.map(item => ({
          client_id: item.agent_id || "",
          email: item.email || "",
          is_primary: 0,
          name: item.name ? item.name : `${item.firstname}${item.lastname ? ` ${item.lastname}` : ""}`,
          phone: item.phone || "",
          referral_id: null,
          source: primaryClient.source,
        }))
        payload.clients = [...payload.clients, ...modified]
      }

      const formState = { ...currentPayload }

      formState.client_info = payload.clients

      setCurrentPayload(prevState => ({
        ...prevState,
        ...formState,
      }))

      if (!isContractPhase && !isTransactionToContractPhase && !listingId) {
        saveFormData(formState, dealToView, false, contractResponse, true)
      }
    } catch (e) {
      console.log("Error", e)
    }
  }

  const handleFormChangeTransactionForm = (form, content) => {
    if(content.is_all_cash_offer && content.is_all_cash_offer == true ){
      content.is_all_cash_offer = 1
    } else if(content.is_all_cash_offer && content.is_all_cash_offer == false ){
      content.is_all_cash_offer = 0
    }
    const formState = { ...currentPayload }
    formState.transaction_details = { ...formState.transaction_details, ...content }

    if (formState.transaction_details.closed_on) {
      formState.transaction_details.closed_on = formState.transaction_details.closed_on.unix()
    }
    if (formState.transaction_details.created_at) {
      formState.transaction_details.created_at = formState.transaction_details.created_at.unix()
    }
    if (formState.transaction_details.lease_commencement_on) {
      formState.transaction_details.lease_commencement_on = formState.transaction_details.lease_commencement_on.unix()
    }

    const propertyInfo = currentPayload && currentPayload.property_info

    const fileType = propertyInfo.file_type || get(contractResponse, "[0].file_type", null) || ""
    const clientType = propertyInfo.client_type || get(contractResponse, "[0].client_type", null) || ""

    if (fileType && clientType) {
      if (fileType === "pending_contract"
      || fileType === "pending_lease"
      || (fileType === "external_referral_agreement" && (clientType !== "landlord"))) {
        formState.transaction_details.list_price = ""
      }

      if (fileType === "listing_with_accepted_offer"
      || fileType === "pending_contract"
      || fileType === "dual") {
        formState.transaction_details.rent_amount = ""
        formState.transaction_details.rent_amount_term = ""
      }

      if (fileType === "lease_listing"
      || fileType === "pending_lease"
      || (fileType === "external_referral_agreement" && (clientType === "landlord" || clientType === "tenant"))) {
        formState.transaction_details.price = ""
      }
    }

    formState.transaction_details.agent_id = editingDealTeamMemberId

    formState.transaction_details.is_all_cash_offer = formState.transaction_details.is_all_cash_offer
      && formState.transaction_details.is_all_cash_offer === 1 ? 1 : 0
    setCurrentPayload(prevState => ({
      ...prevState,
      ...formState,
    }))
    if (!isContractPhase && !isTransactionToContractPhase && !listingId) {
      saveFormData(formState, dealToView, false, contractResponse)
    }
  }

  const handleFormChangeBuyerAgentForm = (form, content) => {
    let formFor = ""
    let typeOfAgent = ""
    if ((currentPayload?.property_info?.client_type === "buyer")|| (currentPayload?.property_info?.client_type === "tenant")||(currentPayload?.property_info?.client_type === "landlord" || currentPayload?.property_info?.client_type ==="referral")) {
      formFor = "buyer_agents"
      typeOfAgent = "buyer"
    } else if (currentPayload?.property_info?.client_type === "seller" || currentPayload?.property_info?.client_type === "dual") {
      formFor = "seller_agents"
      typeOfAgent = "seller"
    }

    if (content.agent_type == "buyer" ||content.agent_type == "tenant" ||content.agent_type == "landlord"|| content.agent_type == "referral"){
      typeOfAgent = "buyer"
    } else if(content.agent_type == "seller" || content.agent_type == "dual") typeOfAgent = "seller"

    if (content.co_buyer_agents && content.co_buyer_agents.length > 0) {
      formFor = "buyer_agents"
    } else if (content.co_seller_agents && content.co_seller_agents.length > 0) {
      formFor = "seller_agents"
    }
    if (formFor === "buyer_agents" && content.co_buyer_agents) {
      console.log("content.co_buyer_agents",content.co_buyer_agents)
      const coBuyerTemp = [...content.co_buyer_agents]

      coBuyerTemp.forEach((item) => {
        const currentAgent = item
        currentAgent.is_primary = 0
        currentAgent.agent_id = item.agent_id || 0
        currentAgent.agent_first_name = currentAgent.agent_first_name || ""
        currentAgent.agent_last_name = currentAgent.agent_last_name || ""
        currentAgent.name = `${currentAgent.agent_first_name || ""}${currentAgent.agent_last_name ? ` ${currentAgent.agent_last_name}` : ""}`
        currentAgent.agent_license_number = item.agent_license_number || ""
        currentAgent.agent_type = content.agent_type || ""
        currentAgent.apt = item.apt || ""
        currentAgent.brokerage_address = item.brokerage_address || ""
        currentAgent.brokerage_license_number = item.brokerage_license_number || ""
        currentAgent.city = item.city || ""
        currentAgent.email = item.email || ""
        currentAgent.phone = item.phone || ""
        currentAgent.state = item.state || ""
        currentAgent.zipcode = item.zipcode || ""
        currentAgent.brokerage = item.brokerage || ""
        currentAgent.landline = item.landline || ""
        // delete currentAgent.agent_first_name
        // delete currentAgent.agent_last_name
      })
    } else if (formFor === "seller_agents" && content.co_seller_agents) {
      console.log("selle co agent",content.co_seller_agents)
      const coSellerTemp = [...content.co_seller_agents]

      coSellerTemp.forEach((item) => {
        const currentAgent = item
        currentAgent.is_primary = 0
        currentAgent.agent_id = item.agent_id || 0
        currentAgent.agent_first_name = currentAgent.agent_first_name || ""
        currentAgent.agent_last_name = currentAgent.agent_last_name || ""
        currentAgent.name = `${currentAgent.agent_first_name || ""}${currentAgent.agent_last_name ? ` ${currentAgent.agent_last_name}` : ""}`
        currentAgent.agent_license_number = item.agent_license_number || ""
        currentAgent.agent_type = content.agent_type || ""
        currentAgent.apt = item.apt || ""
        currentAgent.brokerage_address = item.brokerage_address || ""
        currentAgent.brokerage_license_number = item.brokerage_license_number || ""
        currentAgent.city = item.city || ""
        currentAgent.email = item.email || ""
        currentAgent.phone = item.phone || ""
        currentAgent.state = item.state || ""
        currentAgent.zipcode = item.zipcode || ""
        currentAgent.brokerage = item.brokerage || ""
        currentAgent.landline = item.landline || ""
        // delete currentAgent.agent_first_name
        // delete currentAgent.agent_last_name
      })
    }

    const payload = {}

    const primaryAgent = {
      ...content,
      name: `${content.agent_first_name} ${content.agent_last_name}`,
      apt: content.apt || "",
      agent_license_number: content.agent_license_number || "",
      brokerage_address : content.brokerage_address || "",
      brokerage_license_number : content.brokerage_license_number || "",
    }

    if (primaryAgent.co_buyer_agents) {
      delete primaryAgent.co_buyer_agents
    } else if (primaryAgent.co_seller_agents) {
      delete primaryAgent.co_seller_agents
    }

    delete primaryAgent.agent_first_name
    delete primaryAgent.agent_last_name

    if (typeOfAgent === "buyer" || formFor === "buyer_agents") {
      payload.buyer_agents = []
      payload.buyer_agents.push(primaryAgent)
    } else if (typeOfAgent === "seller" || formFor === "seller_agents") {
      payload.seller_agents = []
      payload.seller_agents.push(primaryAgent)
    }

    if (content.co_buyer_agents) {
      const coBuyers = content.co_buyer_agents.filter(item => item.agent_first_name && item)
      if (coBuyers && coBuyers.length > 0) {
        coBuyers.forEach((item) => {
          // delete item.agent_first_name
          // delete item.agent_last_name
          // delete item.landline
          // delete item.agent_brokerage_address
          // delete item.brokerage
          delete item.county
          delete item.country
        })
      }
      payload.buyer_agents = [...payload.buyer_agents, ...coBuyers]
    } else if (content.co_seller_agents) {
      const coSellers = content.co_seller_agents.filter(item => item.agent_first_name && item)

      if (coSellers && coSellers.length > 0) {
        coSellers.forEach((item) => {
          // delete item.agent_first_name
          // delete item.agent_last_name
          // delete item.landline
          // delete item.agent_brokerage_address
          // delete item.brokerage
          delete item.county
          delete item.country
        })
      }

      payload.seller_agents = [...payload.seller_agents, ...coSellers]
    }
    const formState = { ...currentPayload }

    if (formFor === "buyer_agents"|| typeOfAgent === "buyer") {
      formState.agents_info = payload.buyer_agents
    } else if (formFor === "seller_agents"|| typeOfAgent === "seller") {
      formState.agents_info = payload.seller_agents
    }
    setCurrentPayload(prevState => ({
      ...prevState,
      ...formState,
    }))

    if (!isContractPhase && !isTransactionToContractPhase && !listingId) {
      saveFormData(formState, dealToView, false, contractResponse)
    }
  }

  const handleFinalSubmit = () => {
    const validateEmail = (email) => {
      if (!email?.length) {
        return false
      }

      const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g

      return emailRegex.test(email)
    }

    const clientType = currentPayload.property_info.client_type
    const isClientPhoneMissing = currentPayload && !currentPayload.client_info[0].phone
    const isClientEmailInvalid = currentPayload && !validateEmail(currentPayload?.client_info?.[0]?.email)
    const isCommissionTypeMissing = currentPayload && !currentPayload.transaction_details.commission_type
    const isSalesPriceMissing = currentPayload && !currentPayload.transaction_details.price
    const isListingPriceMissing = currentPayload && !currentPayload.transaction_details.list_price
    const isTransactionTypeMissing = currentPayload && !currentPayload.transaction_details.transaction_type
    const isAcceptanceDateMissing = currentPayload && !currentPayload.transaction_details.created_at
    const isClosedOnMissing = currentPayload && !currentPayload.transaction_details.closed_on
    const isAgentLicenceMissing = currentPayload && !currentPayload.agents_info[0].agent_license_number
    const isRentAmountMissing = currentPayload && !currentPayload.transaction_details.rent_amount
    const isAllCashOfferMissing = currentPayload && currentPayload.transaction_details.is_all_cash_offer
    const lenderEmailMissing = currentPayload && !validateEmail(currentPayload?.transaction_details?.lender_email_address)
    let isCoClientcomplete = true

    if (currentPayload && currentPayload.client_info.length > 1) {
      for (let index = 0; index < currentPayload.client_info.length; index++) {
        const item = currentPayload.client_info[index];

        if (index !== 0) {
          isCoClientcomplete = !(item.phone === "" || item.name === "" || item.email === "" || !validateEmail(item.email))

          if (!isCoClientcomplete) {
            break // Exits the loop if the condition is met
          }
        }
      }
    }

    // const isLeaseCommencementMissing = currentPayload && !currentPayload.transaction_details.lease_commencement_on

    if (isClientEmailInvalid) {
      if (ClientInformationFormRef) {
        ClientInformationFormRef.submit()
      }
      createToast("Client information is incomplete or invalid. Please check and fix.", "error")
      return
    }

    if (isClientPhoneMissing) {
      if (ClientInformationFormRef) {
        ClientInformationFormRef.submit()
      }
      createToast("Client information is incomplete or invalid. Please check and fix.", "error")
      return
    }

    if (!isCoClientcomplete) {
      if (ClientInformationFormRef) {
        ClientInformationFormRef.submit()
      }
      createToast("Co-client information is incomplete or invalid. Please check and fix.", "error")
      return
    }

    if (isAgentLicenceMissing) {
      createToast("Agent information is incomplete or invalid. Please check and fix.", "error")
      return
    }

    if((clientType == "tenant"|| clientType == "landlord") && (isClosedOnMissing || isCommissionTypeMissing || isTransactionTypeMissing|| isAcceptanceDateMissing || isRentAmountMissing)){
      if (TransactionDetailsFormRef) {
        TransactionDetailsFormRef.submit()
      }
      createToast("Transaction details are missing", "error")
      return
    } else if((clientType == "referral" || clientType =="buyer") && (isClosedOnMissing || isCommissionTypeMissing || isTransactionTypeMissing|| isAcceptanceDateMissing || isSalesPriceMissing)){
      if (TransactionDetailsFormRef) {
        TransactionDetailsFormRef.submit()
      }
      createToast("Transaction details are missing", "error")
      return
    } else if((clientType == "seller" && clientType == "dual") && (isClosedOnMissing || isCommissionTypeMissing|| isSalesPriceMissing || isListingPriceMissing || isTransactionTypeMissing || isAcceptanceDateMissing)){
      if (TransactionDetailsFormRef) {
        TransactionDetailsFormRef.submit()
      }
      createToast("Transaction details are missing", "error")
      return
    }
    if (!isAllCashOfferMissing && lenderEmailMissing) {
      if (TransactionDetailsFormRef) {
        TransactionDetailsFormRef.submit()
      }
      createToast("Transaction details are missing", "error")
      return
    }

    let currentDealDocFiles = fetchedFiles && fetchedFiles.map(item => ({
      title: item.title,
      url: item.url,
    })) || []

    if (documents && documents.length > 0 && currentDealDocFiles.length <= 0) {
      currentDealDocFiles = documents.map(item => ({
        title: item.title,
        url: item.url,
      })) || []
    }

    const finalPayload = { ...currentPayload }
    if (currentDealDocFiles && currentDealDocFiles.length > 0) {
      finalPayload.deal_documents = [...currentDealDocFiles]
    }

    if (finalPayload && finalPayload.agents_info) {
      finalPayload.agents_info.forEach((item) => {
        if (!item.agent_type) {
          // eslint-disable-next-line no-param-reassign
          item.agent_type = null
        }
      })
    }

    if (dealToView && !isContractPhase && !transactionToContractPhase && !listingId) {
      finalPayload.save_draft = 0
      setTimeout(() => getContractFieldValidation({
        dealId: dealToView,
        payload: finalPayload,
        isFinal: true,
        updateOfferStatus: currentOfferId,
      }), 2000) 
    } else if (isContractPhase && currentOfferId) {
      finalPayload.save_draft = 0
      let agentIdValue
      if (!Number.isNaN(parseInt(editingDealTeamMemberId, 10))) {
        agentIdValue = parseInt(editingDealTeamMemberId, 10)
      } else {
        agentIdValue = parseInt(agent_id, 10)
      }
      finalPayload.agent_id = agentIdValue
      finalPayload.transaction_details.agent_id = agentIdValue
      createOfferConvertedContract({
        offerId: currentOfferId,
        payload: finalPayload,
      })
    } else if (isTransactionToContractPhase && dealToView) {
      finalPayload.save_draft = 0
      finalPayload.transaction_details.agent_id = parseInt(editingDealTeamMemberId || agent_id, 10)
      finalPayload.transaction_details.transaction_id = dealToView
      finalPayload.agent_id = parseInt(editingDealTeamMemberId || agent_id, 10)
      createTransactionConvertedContract({
        transactionId: dealToView,
        payload: finalPayload,
      })
    } else if (listingId && !contractId) { // a new contract has to be created
      const {
        editingAgentId,
      } = listingData || {}
      finalPayload.save_draft = 0
      finalPayload.agent_id = parseInt(editingAgentId || agent_id, 10)
      finalPayload.transaction_details.agent_id = parseInt(editingAgentId, 10)
      createListingConvertedToContract({
        listingId,
        payload: finalPayload,
      })
    } else if (listingId && contractId) { // updating existing contract
      const {
        editingAgentId,
      } = listingData || {}
      finalPayload.save_draft = 0
      finalPayload.transaction_details.agent_id = parseInt(editingAgentId, 10)
      finalPayload.agent_id = parseInt(editingAgentId || agent_id, 10)
      updateContractV2({
        agent_id: editingAgentId,
        dealId: contractId,
        payload: finalPayload,
        isFinal: true
      })
    }
  }

  const getPrefilledData = (isAgentData) => {
    const data = contractResponse && contractResponse.length > 0 && contractResponse[0]
    if (isAgentData) {
      //console.log(currentPayload)
    }
    if (data) return data
    return {}
  }

  const getFormName = () => {
    const currentClientType = currentPayload.property_info?.client_type
    
    if (!currentClientType) return "Agent Information"

    if (currentClientType === "seller") return "Seller Agent Information"

    if (currentClientType === "buyer") return "Buyer Agent Information"

    return "Agent Information"
  }

  const getAgentFormCTAName = () => {
    const currentClientType = currentPayload.property_info?.client_type
    
    if (!currentClientType) return "ADD ADDITIONAL AGENT INFORMATION"

    if (currentClientType === "seller") return "ADD CO-SELLER AGENT INFORMATION"

    if (currentClientType === "buyer") return "ADD CO-BUYER AGENT INFORMATION"

    return "ADD ADDITIONAL AGENT INFORMATION"
  }

  const isCurrentlySaving = creatingContractFromOffers || creatingContract || savingFormDraft || updatingFormDraft || validating || convertingListingToContract || covertingTransactionToContract

  const ctaTextForListingContracts = listingId && !contractId ? "Add Contract" : (((listingId && contractId) || (currentStatus && !currentStatus.includes("INCOMPLETE"))) ? "Update" : "")

  return (
    <>
      <StatusWrap>
        <StatusTag>{currentStatus}</StatusTag>
      </StatusWrap>
      <Wrap>
        <StyledCollapse
          expandIconPosition="start"
          defaultActiveKey={currentActivePanels}
        >
          <StyledPanel
            header="Property Information"
            key="property_information"
          >
            {fetchingContractDetails || isFetchingForProcessingBeforeViewingContract || isFetchingForPerformingNecessaryActionsBeforeLoadingListing ? (
              <>
                {/* <PropertyInformationForm
                  key="prop_123"
                  prefilledData={getPrefilledData()}
                  // handleSubmit={handlePropertyFormSubmit}
                  state
                  onValueChange={handleFormChangePropertyForm}
                  saving={isCurrentlySaving}
                  disableForm
                /> */}
                <FormShimmer />
              </>
            ) : (
              <PropertyInformationForm
                key="prop_236"
                prefilledData={getPrefilledData()}
                // handleSubmit={handlePropertyFormSubmit}
                state
                onValueChange={handleFormChangePropertyForm}
                saving={isCurrentlySaving}
                disableForm={isContractPhase || !isFormEditable || listingId}
                validationReport={validationReport && validationReport.property_information}
                handleInitPropertyChange={(locationObject) => {
                  //setInitialPropertyInformation(locationObject)
                  if (!isTransactionToContractPhase && !listingId) {
                    setCurrentSelectedState(locationObject?.state)
                    handleInitPropertyChange(locationObject)
                  }
                }}
                handleStateChange={(value) => {
                  setCurrentSelectedState(value)
                }}
                handleClientTypeChange={(value) => {
                  setCurrentSelectedClientType(value)
                }}
              />
            )}
          </StyledPanel>
          {/**PRE-FILL*/}
          <StyledPanel
            header={getFormName()}
            key="buyer_agent_information"

          >
            {fetchingContractDetails || isFetchingForProcessingBeforeViewingContract || isFetchingForPerformingNecessaryActionsBeforeLoadingListing ? (
              <>
                {/* <BuyerAgentForm
                  prefilledData={null}
                  saving={isCurrentlySaving}
                  disableForm
                  key="jajasdl"
                  formName="loader"
                  title={getFormName()}
                  coCTAName={getAgentFormCTAName()}
                /> */}
                <FormShimmer />
              </>
            ) : (
              <BuyerAgentForm
                formName={getFormName()?.toLowerCase()?.includes("seller") ? "contract_seller_agent_information" : "contract_buyer_agent_information"}
                prefilledData={getPrefilledData(true)}
                // handleSubmit={handlePropertyFormSubmit}
                state
                onValueChange={handleFormChangeBuyerAgentForm}
                saving={isCurrentlySaving}
                disableForm={isContractPhase || !isFormEditable}
                key="radius"
                title={getFormName()}
                coCTAName={getAgentFormCTAName()}
                validationReport={validationReport && validationReport.agent_information}
              />
            )}
          </StyledPanel>
          <StyledPanel
            header="Client Information"
            key="client_information"
          >
            {fetchingContractDetails || isFetchingForProcessingBeforeViewingContract || isFetchingForPerformingNecessaryActionsBeforeLoadingListing ? (
              <FormShimmer />
            ) : (
              <ClientInformationForm
                prefilledData={getPrefilledData()}
                onFinish={() => {}}
                state
                onValueChange={handleFormChangeClientForm}
                saving={isCurrentlySaving}
                disableForm={isContractPhase || !isFormEditable}
                validationReport={validationReport && validationReport.client_information}
                form={ClientInformationFormRef}
                editingAgentId={agent_id}
              />
            )}
          </StyledPanel>
          <StyledPanel
            header="Transaction Details"
            key="transaction_information"
          >
            {fetchingContractDetails || isFetchingForProcessingBeforeViewingContract || isFetchingForPerformingNecessaryActionsBeforeLoadingListing ? (
              <FormShimmer />
            ) : (
              <TransactionDetailsForm
                prefilledData={getPrefilledData()}
                // onFinish={handleSubmitForClientInformation}
                showField={showField}
                showERAFields={showERAFields}
                state
                saving={isCurrentlySaving}
                onValueChange={handleFormChangeTransactionForm}
                dealId={dealToView || listingId}
                disableForm={!isFormEditable}
                validationReport={validationReport && validationReport.transaction_information}
                form={TransactionDetailsFormRef}
                showAgentType={showAgentType}
                isSplitWithTeam={isSplitWithTeam}
                currentSelectedStateFromPropertyInformation={currentSelectedState}
                currentSelectedClientTypeFromPropertyInformation={currentSelectedClientType}
              />
            )}
          </StyledPanel>
        </StyledCollapse>
        {/* <ContractFileUploadForm isContractPhase={isContractPhase} disabled={!isFormEditable} /> */}
        {/* <FileList documents={documents} disabled={!isFormEditable} dealId={!isContractPhase ? dealToView : ""} /> */}
      </Wrap>
      <FloatingCTA customText={ctaTextForListingContracts} disabled={(!listingId && !dealToView) || !isFormEditable || isCurrentlySaving || updateContractV2Response?.isFetching} onClick={handleFinalSubmit} loading={isCurrentlySaving} />
    </>
  )
}
export default AppContainer(Listings(TCContainer(TeamProfileContainer(TechAssetsContainer(SearchContainer(withRouter(CollapsibleForm)))))))
