import {
  createSignalAction,
  createSimpleCreator,
} from "shared/reduxUtils"

const BASE = "LISTINGS_V2"

export const getListingsAction = createSignalAction(BASE, "GET_LISTINGS")
export const constructListingsQueryAction = createSimpleCreator(BASE, "CONSTRUCT_QUERY")
export const updateListingsQueryAction = createSimpleCreator(BASE, "UPDATE_QUERY_STATUS")
export const toggleListingsFilterDrawerAction = createSimpleCreator(BASE, "TOGGLE_LISTINGS_DRAWER")
export const getListingMetricsAction = createSignalAction(BASE, "GET_LISTING_METRICS")
export const getPaginationCountAction = createSignalAction(BASE, "GET_PAGINATION_LISTING_ACTION")
export const deleteListingAction = createSignalAction(BASE, "DELETE_LISTING")
export const toggleDeleteModalAction = createSimpleCreator(BASE, "TOGGLE_DELETE")
export const toggleMLSModalAction = createSimpleCreator(BASE, "TOGGLE_MLS_MODAL")
export const toggleStatusChangeModalAction = createSimpleCreator(BASE, "TOGGLE_STATUS_CHANGE")
export const currentDealDetailsObjectAction = createSimpleCreator(BASE, "SET_CURRENT_LISTING_DEAL_INFO")
export const createListingForModalAction = createSimpleCreator(BASE, "TOGGLE_MODAL_FOR_CREATE_LISTING")
export const initiateListingAction = createSignalAction(BASE, "INITIATE_LISTING_ACTION")
export const getListingMappingsAction = createSignalAction(BASE, "GET_LISTING_MAPPINGS")
export const updatePropertyInformationAction = createSignalAction(BASE, "UPDATE_PROPERTY_INFORMATION")
export const setCurrentListingTabAction = createSimpleCreator(BASE, "SET_CURRENT_LISTING_TAB")
export const setCurrentContractsTabAction = createSimpleCreator(BASE, "SET_CURRENT_CONTRACTS_TAB")
export const togglePropertyFormOrCardViewAction = createSimpleCreator(BASE, "TOGGLE_PROPERTY_FORM")
export const validateListingFormAction = createSignalAction(BASE, "VALIDATE_LISTING_COVERSHEET")
export const getBrokerageListingsStatusesAction = createSignalAction(BASE, "GET_BROKERAGE_LISTINGS")
export const updateBrokerageListingStatusAction = createSignalAction(BASE, "UPDATE_BROKERAGE_LISTING_STATUS")
export const updateListingAgentInformationPostMlsSelectionAction = createSignalAction(BASE, "UPDATE_POST_MLS_LISTING_AGENT")
export const updateListingAgentInformationAction = createSignalAction(BASE, "UPDATE_LISTING_AGENT_INFORMATION")
export const getListingAgentsDetailsAction = createSignalAction(BASE, "GET_LISTING_AGENT_DETAILS")
export const getListingDataAgentsDataPostMLSAction = createSignalAction(BASE, "GET_LISTING_AGENTS_DATA_POST_MLS")
export const performNecessaryActionsBeforeLoadingListingAction = createSignalAction(BASE, "PREPARE_LISTING")
export const updatePropertyInformationPostMLSUpdateAction = createSignalAction(BASE, "UPDATE_POST_MLS_PROPERTY_INFORMATION")
export const getPropertyInformationDataPostMLSUpdateAction = createSignalAction(BASE, "GET_PROPERTY_DATA_POST_MLS")
export const getSellerInformationForListingAction = createSignalAction(BASE, "GET_SELLER_INFORMATION")
export const updateSellerInformationForListingAction = createSignalAction(BASE, "UPDATE_SELLER_INFORMATION")
export const updateListingTermsForListingAction = createSignalAction(BASE, "UPDATE_LISTING_TERMS")
export const validateAndSubmitAction = createSignalAction(BASE, "VALIDATE_AND_SUBMIT")
export const fetchAgentInformationForCoverSheetAction = createSignalAction(BASE, "FETCH_AGENT_INFORMATION_FOR_LISTINGS")
export const handleCollapseValuesAction = createSimpleCreator(BASE, "HANDLE_COLLAPSE_FOR_LISTINGS")
export const clearExistingDataAction = createSimpleCreator(BASE, "CLEAR_MLS_DATA")
export const moveToOnMarketAction = createSignalAction(BASE, "MOVE_TO_ON_MARKET")
export const toggleMoveToMarketAction = createSimpleCreator(BASE, "TOGGLE_ON_MARKET")
export const setMLSIdForOnMarketAction = createSimpleCreator(BASE, "SET_OPTIONAL_MLS_ID_FOR_ON_MARKET")
export const listingToTransactionConversionConfirmationModalToggleAction = createSimpleCreator(BASE, "TOGGLE_CONRIRM_FOR_LISTING_CONTRACT")
export const viewListingContractAction = createSimpleCreator(BASE, "VIEW_LISTING_CONTRACT")
export const setCurrentListingsStateAction = createSimpleCreator(BASE, "SET_CURRENT_STATE")
export const setChecklistTemplateAction = createSimpleCreator(BASE, "SET_CHECKLIST_TEMPLATE")
export const fetchChecklistMetadataAction = createSignalAction(BASE, "FETCH_CHECKLIST_METADATA")
export const updateChecklistTemplateMetadataAction = createSignalAction(BASE, "UPDATE_CHECKLIST_TEMPLATE_METADATA")
export const fetchChecklistTemplateAction = createSignalAction(BASE,"FETCH_CHECKLIST_TEMPLATE")
export const fetchEnvelopeListingsAction = createSignalAction(BASE, "FETCH_ENVELOPE_LISTINGS")
export const updateEnvelopeObjectAction = createSimpleCreator(BASE, "UPDATE_ENVELOPE_OBJECT")
export const fetchMergedEnvelopesAction = createSignalAction(BASE, "FETCH_MERGED_ENVELOPES")
export const updateMergeEnvelopeObjectAction = createSimpleCreator(BASE, "UPDATE_MERGED_ENVELOPES")
export const toggleCreateEnvelopeAction = createSimpleCreator(BASE, "TOGGLE_CREATE_ENVELOPE")
export const createReceiverListingViewV2Action = createSignalAction(BASE, "TRIGGER_RECEIVER_VIEW")
export const updateOfferDocsListViewAction = createSimpleCreator(BASE, "UPDATE_OFFER_DOC_LIST")
export const fetchOfferDocumentsListAction = createSignalAction(BASE, "FETCH_OFFER_DOCUMENTS")
export const fetchListingDocumentsListAction = createSignalAction(BASE, "FETCH_LISTING_DOCUMENTS")
export const updateListingDocsAction = createSimpleCreator(BASE, "UPDATE_LISTING_DOCS")
export const fetchLibraryUploadedFilesListAction = createSignalAction(BASE, "FETCH_LIBRARY_UPLOADED_FILES")
export const updateUploadedDocsListObjectAction = createSimpleCreator(BASE, "UPDATE_UPLOADED_DOCS")
export const updateLibraryUploadsListAction = createSignalAction(BASE, "UPDATE_LIBRARY_UPLOAD")
export const deleteLibraryUploadedFileListAction = createSignalAction(BASE, "DELETE_LIBRARY_UPLOADED_FILE")
export const updateEnvelopesAction = createSignalAction(BASE, "UPDATE_ENVELOPE_LIST")
export const setListingIdAction = createSimpleCreator(BASE, "SET_LISTING_ID")
export const updateChecklistObjectAction = createSimpleCreator(BASE, "CREATE_CHECKLIST_OBJECT")
export const createEnvelopeChecklistAction = createSignalAction(BASE, "CREATE_ENVELOPE_CHECKLIST")
export const fetchListingPackageDocumentsAction = createSignalAction(BASE, "CREATE_LISTING_PACKAGE")
export const updateListingPackageDetailsAction = createSimpleCreator(BASE, "UPDATE_LISTING_PACKAGE")
export const updateListingTabDisabledStatusAction = createSimpleCreator(BASE, "UPDATE_LISTING_TABS_DISABLED_STATUS")
export const toggleDeleteFieldsConfirmationInCoverSheetAction = createSimpleCreator(BASE, "TOGGLE_DELETE_MODAL")
export const processDeletionOfFieldsInCoversheetAction = createSignalAction(BASE, "PROCESS_DELETION")
export const sendListingPackageMailAction = createSignalAction(BASE, "SEND_LISTING_PACKAGE_MAIL")
export const documentsListingsProcessingAction = createSimpleCreator(BASE, "DOC_LISTING_PROCESS_ACTION")
export const refreshListingsV2DocumentsAction = createSignalAction(BASE, "REFRESH_LISTINGSV2_DOCUMENTS")
export const viewListingModalAction = createSimpleCreator(BASE, "VIEW_LISTING_MODAL")
export const toggleMultipleEnvelopeShareAction = createSimpleCreator(BASE, "TOGGLE_MULTIPLE_ENVELOPE")
export const setViewListingSendUIAction = createSimpleCreator(BASE,"SET_VIEW_SEND")
export const setEnvelopeIdAction = createSimpleCreator(BASE,"SET_ENVELOPE_ID")
export const sendOffersChecklistDocumentsAction = createSignalAction(BASE, "SEND_OFFERSCHECKLIST_DOCUMENTS")
export const sendReminderListingAction = createSignalAction(BASE, "SEND_REMINDER_LISTING")
export const sendReminderOfferChecklistAction = createSignalAction(BASE,"SEND_REMINDER_OFFER_CHECK")
export const toggleAddSignedDocsAction = createSimpleCreator(BASE, "TOGGLE_SIGNED_DOCS")
export const showAddDocumentAction = createSimpleCreator(BASE, "ADD_DOCUMENT_MODAL")
export const setEnvelopeIdForAddDocumentAction = createSimpleCreator(BASE, "SET_ENVELOPE_ID_ADD_DOCs")
export const viewListingPersistenceAction = createSignalAction(BASE, "VIEW_LISTING_PERSISTENCE_ACTION")
export const setIsLoadingViewListingPageAction = createSimpleCreator(BASE, "SET_IS_LOADING_VIEW_LISTING_PAGE")
