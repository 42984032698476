import {
  takeLatest,
  call,
  put,
  takeEvery,
  select,
  throttle,
} from "redux-saga/effects"
import root from "window-or-global"
import { get } from "lodash"
import createToast from "@ui/Toast"
import cookie from "services/CookieStorage"
import { getPopupDetailsAction } from "container/TechAssets/actions"
import {
  isSuccess,
  initiatePendo,
} from "services/Utils"
import { saveProfileInfoAPI } from "container/Profile/api"
import { getOnboardingNavigateRoute } from "dumbComponents/Auth/authUtils"
import * as AuthActions from "./actions"
import * as API from "./api"

function* handleLogin(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(API.userLoginAPI, payload)
    if (isSuccess(res)) {
      yield put(getPopupDetailsAction.request())
      callback(res)
    }
  } catch (error) {
    const errorMessage = get(error, "data.error.message") || ""
    createToast(errorMessage || "Something went wrong.", "error")
    yield put(AuthActions.loginUserAction.failure(error))
  }
}

function* handleAppleLogin(action) {
  try {
    const {
      payload,
      callback,
    } = action.data
    const res = yield call(API.appleSigninAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      callback(response)
      yield put(AuthActions.appleSignInAction.success(response))
    }
  } catch (error) {
    const errorMessage = get(error, "data.error.message")
    createToast(errorMessage, "error")
    yield put(AuthActions.appleSignInAction.failure(error))
  }
}

function* saveSocialLoginData(action) {
  try {
    const {
      payload,
      push,
      login_type,
    } = action.data
    const res = yield call(saveProfileInfoAPI, payload)
    if (isSuccess(res)) {
      const { response } = res.data
      yield put(AuthActions.saveSocialLoginDataAction.success(response))
      if (login_type === "google_login") {
        const user = JSON.parse(root.localStorage.getItem("google_user"))
        cookie.saveUserToCookies(user)
        root.localStorage.removeItem("google_user")
        const {
          onboardingQuizInfo,
          citiesServed,
          account_type,
          isUserOnboarded
        } = user
        const isRealEstateAgent = account_type === "real_estate_agent"

        if (!isUserOnboarded && onboardingQuizInfo && onboardingQuizInfo.showOnboardingQuiz && isRealEstateAgent) {
          const onboardingRoute = getOnboardingNavigateRoute(onboardingQuizInfo.showStepKey)
          if (onboardingRoute) {
            push(onboardingRoute)
            return
          }
        }
        if (!(citiesServed && citiesServed.length) && isRealEstateAgent) {
          push("/onboarding/tell-about-yourself")
          return
        }
      }
      push("/community")
    }
  } catch (error) {
    yield put(AuthActions.saveSocialLoginDataAction.failure(error))
  }
}

function* saveProductInterest(action) {
  const {
    payload,
    callback,
  } = action.data
  try {
    const res = yield call(API.saveProductInterestAPI, payload)
    callback()
  } catch (error) {
    // asdas
    callback()
  }
}

function* saveProductInterestSteps(action) {
  const {
    payload,
    callback,
  } = action.data
  try {
    const res = yield call(API.saveProductInterestStepsAPI, payload)
    if (callback) callback()
  } catch (error) {
    if (callback) callback()
  }
}

export default function* main() {
  yield takeLatest(AuthActions.loginUserAction.REQUEST, handleLogin)
  yield takeLatest(AuthActions.appleSignInAction.REQUEST, handleAppleLogin)
  yield takeLatest(AuthActions.saveSocialLoginDataAction.REQUEST, saveSocialLoginData)
  yield takeLatest(AuthActions.saveProductInterestAction.REQUEST, saveProductInterest)
  yield takeLatest(AuthActions.saveProductInterestStepsAction.REQUEST, saveProductInterestSteps)
}
