import styled from "styled-components";
import colors from "@colors";
import { INTER, MONTSERRAT } from "@fonts";

const Header = styled.div`
  color: ${colors.new_grey600};
  font-size: 24px;
  margin-top: 48px;
  margin-bottom: 24px;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-family: ${MONTSERRAT};
  @media (max-width: 1024px) {
    margin-top: 32px;
  }
`;

const Table = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 16px;
`;
const Border = styled.div`
  margin-top: 8px;
  border-bottom: 1px solid ${colors.new_borderPurple};
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ColumnData = styled.div`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  margin-top: 12px;
  line-height:1.5;
`;
const ColumnTitle = styled.div`
  color: ${colors.new_grey600};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: 16px;
`;

const ButtonShow = styled.div`
  color: ${colors.new_primary};
  font-family: ${INTER};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-top: 8px;
  cursor: pointer;
`;

const Wrap = styled.div`
  @media (max-width: 1024px) {
    margin-bottom: 50px;
  }
  margin-bottom: 50px;
`
export { Header, ColumnTitle, Row, Border, Table, ColumnData, ButtonShow, Wrap };
