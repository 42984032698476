import React from "react"
import { Select } from "antd"
import { uniqBy } from "lodash"
import cookie from "services/CookieStorage"
import TechAssetsContainer from "container/TechAssets"
import TeamProfileContainer from "container/Realty/TeamProfileContainer"

const TeamMemberSelect = ({
  getTeamDetailsResponse,
  onChange
}) => {
  let defaultValue
  const getMoreText = (agentId, userId, memberInfo) => {
    const tcInformation = cookie.load("tcInformation")
    if (tcInformation?.is_tc && memberInfo.member_type === "Team Lead") {
      return "(Team Lead)"
    }
    if (agentId === userId) {
      defaultValue = `${memberInfo.firstname} ${memberInfo.lastname} (For Me)`
      return "(For Me)"
    }
    return ""
  }

  const getOptions = () => {
    const {
      data: teamDetails,
    } = getTeamDetailsResponse || {}
    const user = cookie.load("user") || {}
    const teamNames = (teamDetails && teamDetails.team_members) ? teamDetails.team_members.map(tm => ({
      value: tm.agent_id,
      label: `${tm.firstname} ${tm.lastname || ""} ${getMoreText(tm.agent_id, user.id, tm)}`,
      moreValue: tm.member_type,
    })) : []

    const merged = [
      ...teamNames.filter(tm => tm.moreValue === "Team Lead"),
      ...teamNames.filter(tm => tm.moreValue !== "Team Lead"),
    ]

    const teams = uniqBy([...merged], "value")

    return [
      ...teams,
    ]
  }

  return (
    <Select
      showSearch
      style={{
        width: "100%",
      }}
      placeholder="Search"
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase())}
      // filterSort={(optionA, optionB) => (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())}
      onChange={onChange}
      options={getOptions()}
      defaultValue={defaultValue}
    />
  )
}
export default TeamProfileContainer(TechAssetsContainer(TeamMemberSelect))
