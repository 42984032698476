/* eslint-disable no-unused-expressions */
import React, { useState } from "react"
import TechAssetsContainer from "container/TechAssets"
import ReferralContainer from "container/Referral"
import { withRouter } from "react-router-dom"
import CRMV2Container from "container/CRM"
import { formatAmount, commaSeperatedNumberUS } from "services/formUtils"
import {
  NewSelect, Row, Col, Space,
} from "@ui/antd"
import moment from "moment"
import {
  ARCHIVE, IN_CONTRACT, CLOSED, SELLER, LISTING, SHOWING_TOURS, SENDING_RECEIVING_OFFERS, OFFER, CONTRACT, BUYER, CLOSED_STATUS,
} from "../../../common"
import {
  PropertyCardContainer,
  PropertyCardDetailsContainer,
  PropertyCardAddress,
  PropertyCardBudget,
  PropertyCardStatus,
  PropertyCardSpecifications,
  StatusIndicator,
  StatusSelectContainer,
  StatusColors,
  PropertyCardLastUpdated,
  PropertyCardCta,
  PropertyCardLeftContainer,
  PropertyCardRightContainer,
  PropertyCardTabsContainer,
  PropertyCardTab,
  PropertyCardImage,
} from "../../styles"

const PropertyInfoList = ({
  data,
  setCurrentDrawerPage,
  setCurrentTransactionDetails,
  updateClientTransaction,
  getBrokerageTransactionStatus,
  setCurrentTab,
  currentFilters,
  setModalOpenConfirm,
  setModalOpenView,
  getClientTransactionsById,
  toggleGoToContract,
  toggleArchiveLeadModal,
  setIsViewListing,
  setIsViewOffer,
  setIsAddListing,
  setIsAddOffer,
  setIsCreateWarning,
  setIsClosed,
  setIsEndContract,
  setIsViewOfferToContract,
  setIsViewListingToContract,
  setStatusValue,
  setIsViewContract,
  setClientID,
  toggleShowInContract,
}) => {
  const {
    property, id, client_id, type, status, brokerage_transaction_status,
    radius_transaction_type,
  } = data || {}
  const {
    city, state, country, property_status, property_type,
    updated_at, no_of_bath_room, no_of_bed_room, price, sale_price,
    address, zipcode, covered_area, sale_type, area_coverage,
  } = property

  const {
    id: dealIdToSend, initiateTransaction, transactionType: transactionTypeToSend, status: statusToSend
  } = brokerage_transaction_status

  const [currentStatusForIndicator, setCurrentStatusForIndicator] = useState(status)

  const getColor = currentStatus => StatusColors.find(cl => cl.label === currentStatus)?.value

  const handleChange = (value) => {
    if (value === ARCHIVE) {
      toggleArchiveLeadModal(true)
    } else {
      const payload = {
        status: value,
        client_id,
        type,
      }
      updateClientTransaction({
        transactionId: id,
        payload,
        currentFilters,
      })
    }
  }

  return (
    <PropertyCardContainer
      onClick={() => {
        setCurrentTransactionDetails(data)
        getClientTransactionsById({ transactionId: id })
        setCurrentDrawerPage("property-listing")
        getBrokerageTransactionStatus({ transactionId: id })
      }}
    >
      <PropertyCardDetailsContainer>
        <Row>
          <Col span={16}>
            <Space align="start" style={{ width: "100%" }}>
              <PropertyCardImage />

              <PropertyCardLeftContainer>
                <PropertyCardAddress>
                  {address}
                </PropertyCardAddress>
                <Space style={{ width: "100%" }}>
                  <PropertyCardBudget>
                    {formatAmount(sale_price) || formatAmount(price)}
                  </PropertyCardBudget>
                  <PropertyCardStatus>
                    {property_status}
                  </PropertyCardStatus>
                </Space>
                <PropertyCardSpecifications>
                  {property_type}
                  {no_of_bed_room > 0 && (
                    `, ${no_of_bed_room} Beds`
                  )}
                  {no_of_bath_room > 0 && (
                    `, ${no_of_bath_room} Baths`
                  )}
                  {covered_area > 0 && area_coverage && (
                    `, ${commaSeperatedNumberUS(covered_area)} ${area_coverage}`
                  )}
                </PropertyCardSpecifications>
              </PropertyCardLeftContainer>
            </Space>
          </Col>
          <Col span={8}>
            <PropertyCardRightContainer>
              <StatusSelectContainer>
                <StatusIndicator bg={getColor(currentStatusForIndicator || status)} />
                <NewSelect
                  defaultValue={status}
                  onChange={(value) => {
                    setStatusValue(value)
                    setCurrentStatusForIndicator(value)

                    if (value === ARCHIVE) {
                      handleChange(value)
                    } else if (initiateTransaction) {
                      if (SELLER.includes(type)) {
                        if (value === CLOSED) {
                          setStatusValue(IN_CONTRACT)
                          setIsClosed(true)
                          return
                        }
                        if (value === IN_CONTRACT) {
                          setIsEndContract(true)
                          toggleGoToContract({ isShowGoToContract: true })
                          return
                        }
                        setIsAddListing(true)
                        setIsCreateWarning(true)
                        handleChange(value)
                      } else {
                        if (value === IN_CONTRACT || value === CLOSED) {
                          setIsEndContract(true)
                          toggleGoToContract({ isShowGoToContract: true })
                          return
                        }
                        setIsAddOffer(true)
                        handleChange(value)
                      }
                    } else if (!initiateTransaction && ((value !== IN_CONTRACT && value !== CLOSED) || statusToSend === CLOSED_STATUS) && transactionTypeToSend === CONTRACT) {
                      if (SELLER.includes(type)) {
                        setIsViewListingToContract(true)
                      } else {
                        setIsViewOfferToContract(true)
                      }
                    } else if (transactionTypeToSend === CONTRACT && value === CLOSED) {
                      toggleShowInContract(true)
                      handleChange(value)
                    } else if (!initiateTransaction && value === IN_CONTRACT) {
                      setIsEndContract(true)
                      toggleGoToContract({ isShowGoToContract: true })
                    } else if (!initiateTransaction && value === CLOSED) {
                      setStatusValue(IN_CONTRACT)
                      setIsClosed(true)
                    } else {
                      handleChange(value)
                    }
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  bordered={false}
                  options={[
                    {
                      value: "New Client/Accepted",
                      label: "New Client/Accepted",
                    },
                    {
                      value: "Met with Client",
                      label: "Met with Client",
                    },
                    {
                      value: "Pre-approved/Listing Prepped",
                      label: "Pre-approved/Listing Prepped",
                    },
                    {
                      value: "Showings/Tours",
                      label: "Showings/Tours",
                    },
                    {
                      value: "Sending/Receiving Offers",
                      label: "Sending/Receiving Offers",
                    },
                    {
                      value: "In Contract",
                      label: "In Contract",
                    },
                    {
                      value: "Closed",
                      label: "Closed",
                    },
                    {
                      value: "Archive",
                      label: "Archive",
                    },
                  ]}
                  placement="bottomRight"
                  dropdownMatchSelectWidth={false}
                  style={{ maxWidth: "170px" }}
                />
              </StatusSelectContainer>
              <PropertyCardLastUpdated>
                Last Updated At:
                {" "}
                {moment(updated_at * 1000).format("MM/DD/YYYY")}
              </PropertyCardLastUpdated>
              <>
                {false && (SELLER.includes(type)) && currentFilters && currentFilters.view_type === "view_mine" && (status !== IN_CONTRACT && status !== CLOSED) ? (
                  <PropertyCardCta>
                    {initiateTransaction === true ? (
                      <PropertyCardCta
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setIsAddListing(true)
                          setIsCreateWarning(true)
                          setModalOpenConfirm(true)
                          setClientID({
                            deal_id: id,
                          })
                        }}
                      >
                        Add Listing
                      </PropertyCardCta>
                    ) : (
                      <>
                        {dealIdToSend !== null && transactionTypeToSend === LISTING && (
                          <PropertyCardCta
                            onClick={(e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              setIsViewListing(true)
                              setModalOpenView(true)
                              setClientID({
                                deal_id: id,
                              })
                            }}
                          >
                            View Listing
                          </PropertyCardCta>
                        )}
                      </>
                    )}
                  </PropertyCardCta>
                ) : null}
              </>
              <>
                {(BUYER.includes(type)) && currentFilters && currentFilters.view_type === "view_mine"
                  && ((status === SHOWING_TOURS || status === SENDING_RECEIVING_OFFERS)) ? (
                    <>
                      {initiateTransaction === true ? (
                        <PropertyCardCta
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setIsAddOffer(true)
                            setIsCreateWarning(true)
                            setModalOpenConfirm(true)
                            setClientID({
                              deal_id: id,
                            })
                          }}
                        >
                          Add Offer
                        </PropertyCardCta>
                      ) : (
                        <>
                          {dealIdToSend !== null && transactionTypeToSend === OFFER && (
                            <PropertyCardCta
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setIsViewOffer(true)
                                setModalOpenView(true)
                                setClientID({
                                  deal_id: id,
                                })
                              }}
                            >
                              View Offer
                            </PropertyCardCta>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
              </>
              <>
                {currentFilters && currentFilters.view_type === "view_mine" ? (
                  <>
                    {dealIdToSend !== null && transactionTypeToSend === CONTRACT && (
                      <PropertyCardCta
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setIsViewContract(true)
                          setModalOpenView(true)
                          setClientID({
                            deal_id: id,
                          })
                        }}
                      >
                        View Contract
                      </PropertyCardCta>
                    )}
                  </>
                ) : null}
              </>
            </PropertyCardRightContainer>
          </Col>
        </Row>
      </PropertyCardDetailsContainer>
      <PropertyCardTabsContainer>
        <PropertyCardTab
          onClick={() => {
            setCurrentTab("NOTES")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("property-listing")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          Notes
        </PropertyCardTab>
        <PropertyCardTab
          onClick={() => {
            setCurrentTab("TIMELINE")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("property-listing")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          Timeline
        </PropertyCardTab>
        <PropertyCardTab
          onClick={() => {
            setCurrentTab("REMINDERS")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("property-listing")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          Reminders
        </PropertyCardTab>
        {/* <TabTitleContainer
          onClick={() => {
            setCurrentTab("FINANCING")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("property-listing")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          <TabTitle
            cursor="pointer"
          >
            Financing Options
          </TabTitle>
        </TabTitleContainer>
        <TabTitle>
          |
        </TabTitle> */}
        <PropertyCardTab
          onClick={() => {
            setCurrentTab("OVERVIEW")
            setCurrentTransactionDetails(data)
            setCurrentDrawerPage("property-listing")
            getBrokerageTransactionStatus({ transactionId: id })
          }}
        >
          More Info
        </PropertyCardTab>
      </PropertyCardTabsContainer>
    </PropertyCardContainer>
  )
}

export default withRouter(TechAssetsContainer(ReferralContainer(CRMV2Container(PropertyInfoList))))
