import React, { useEffect, useRef, useCallback, useState } from "react"
import OldOffers from "container/TechAssets"
import OffersV2 from "container/OffersV2"
import { withRouter } from "react-router-dom"
import { StyledCheckbox } from "dumbComponents/OffersV2/DocusignDocs/Components/CheckboxList/styles"
import { debounce } from "lodash"
import { ShimmerBox } from "shared/styles/animation"
import { Form, Popover } from "antd"
import cookie from "services/CookieStorage"
import {
  Wrap,
  SectionWrap,
  ListRowWrap,
  StateWrap,
  GreyState,
  State,
} from "./styles"
import {
  SelectField,
} from "../InputFields"
import {
  PropertyInformationForm,
  ListingAgentForm,
  SellerForm,
} from "./Forms"
import {
  getState,
  RADIUS_STATES,
  getStateLowerCase,
  PROPERTY_INFORMATION_STEP,
} from "../../../consts"

const PropertyInformation = ({
  propertyInformationObject,
  updatePropertyInformationObject,
  addNewListingAgentToggle,
  stateForOffer,
  updatePropertyInformationTab,
  buyersAgentInformationObject,
  dealToView,
  updatePropertyInformationTabResponse,
  updateStateForOffers,
  fetchPropertyInformation,
  fetchPropertyInformationResponse,
  getOfferDocumentsResponse,
  history: {
    location,
  },
  form: formRef,
  listingAgentForm,
  sellerFormRef,
}) => {
  const splitUrl = location?.pathname?.split("/") || ""
  const extractedId = splitUrl && splitUrl[splitUrl.length - 1]
  const [initialPropertyInformation, setInitialPropertyInformation] = useState(null)
  const user = cookie.load("user") || {}
  console.log("user",user)

  const {
    isFetching: fetchingDocuments,
  } = getOfferDocumentsResponse || {}

  useEffect(() => {
    if (dealToView) {
      fetchPropertyInformation({
        id: dealToView,
        current_offer_step: PROPERTY_INFORMATION_STEP,
      })
    }
  }, [])

  const debouncedSubmit = useCallback(
    debounce(async (flag, provided, customCallback, currentDealId, doNotReFetch) => {
      try {
        if (!customCallback) {
          handleFinalSubmit(flag, provided, currentDealId, doNotReFetch)
          updatePropertyInformationObject(provided)
        } else {
          customCallback()
        }
      } catch (error) {
        console.error("Error saving form data:", error)
      }
    }, 3000),
    []
  )

  const {
    isFetching: fetchingCurrentTabResponse,
    data: dataAvailable,
  } = fetchPropertyInformationResponse || {}
  const payloadRef = useRef({ ...propertyInformationObject })
  useEffect(() => {
    if (dataAvailable) {
      payloadRef.current = { ...propertyInformationObject }
    }
  }, [fetchingCurrentTabResponse, dataAvailable])
  const payload = { ...propertyInformationObject }

  const {
    isFetching: updatingPropertyInformation,
  } = updatePropertyInformationTabResponse || {}
  const {
    currentState,
    form,
    listing_agents = [],
    sellers,
    addressRepresentation,
  } = propertyInformationObject || {}

  const handleFinalSubmit = (draft = false, draftPayload, currentDealId, doNotReload) => {
    console.log("draft payload", draftPayload)
    payloadRef.current = { ...draftPayload }

    const {
      type_of_transaction,
      listing_agents: currentListingAgents,
      sellers: currentSellers,
      // form: currentForm,
    } = draftPayload || {}

    const listingAgentsPayload = currentListingAgents.map((item, iter) => ({
      is_primary: iter === 0 ? 1 : 0,
      agent_id: item.agent_id || 0,
      is_radius_user: item.agent_id ? true: false,
      name: `${item.listing_agent_first_name} ${item.listing_agent_last_name}`,
      email: item.email,
      agent_license_number: item.agent_license_number,
      landline: item.landline,
      phone: item.phone,
      brokerage: item.brokerage,
      brokerage_license_number: item.brokerage_license_number,
      brokerage_address: item.brokerage_address,
      apt: item.apt,
      city: item.city,
      state: item.state,
      zipcode: item.zipcode,
    }))
  
    const sellerPayload = currentSellers.map((item, iter) => ({
      is_primary: iter === 0 ? 1 : 0,
      firstname: item.firstname,
      email: item.email,
      phone: item.phone,
      lastname: item.lastname,
      type_of_ownership: item.type_of_ownership,
      ownership_name: item.ownership_name,
    }))

    const finalPayload = {
      clients: [
        ...sellerPayload,
      ],
      agents: [
        ...listingAgentsPayload,
      ],
      mls_select: "",
      mls_number: "",
      apn_number: "",
      street: "",
      apt: "",
      city: "",
      state: getState(draftPayload.currentState),
      zipcode: "",
      county: "",
      ...draftPayload,
      type_of_transaction,
      draft: draft ? 1 : 0,
      new_route: true,
      current_offer_step: "property_information_mobile",
    }

    //clean
    delete finalPayload.sellers
    delete finalPayload.listing_agents
    delete finalPayload.form
    delete finalPayload.currentState

    updatePropertyInformationTab({
      id: currentDealId || dealToView || parseInt(extractedId, 10),
      payload: finalPayload,
      doNotReload,
    })
  }

  const handlePropertyFormUpdate = (values) => {
    // const { mls_number } = propertyInformationObject || {}
    // const formPayload = {
    //   ...payload[form[stateForOffer]],
    //   ...values,
    // }

    // payload.form = {
    //   ...form,
    //   [stateForOffer]: {
    //     ...formPayload,
    //   },
    //   mls_number,
    // }

    // updatePropertyInformationObject(payload)
  }

  const handleListingAgentGoBack = () => {
    addNewListingAgentToggle(false)
  }

  const onFinishAddListingAgent = (values) => {
    const {
      co_listing_agent_details,
    } = values || {}
    // const data = {
    //   ...values,
    //   isListingAgentEditVisible: false,
    //   listing_agent_id: uuid.v4(),
    // }

    payload.listing_agents = co_listing_agent_details

    updatePropertyInformationObject(payload)
    // handleListingAgentGoBack()
  }

  const handleEditModeForSeller = (id) => {
    const currentSellers = [...propertyInformationObject.sellers]

    currentSellers.forEach((item) => {
      const curr = item
      if (item.seller_id === id) {
        curr.isSellerEditVisible = !item.isSellerEditVisible
      }
    })

    payload.sellers = [...currentSellers]

    updatePropertyInformationObject(payload)
  }

  const handleDeleteSeller = (id) => {
    const filteredListingAgents = payload.listing_agents.filter(item => item.seller_id !== id)

    payload.listing_agents = [...filteredListingAgents]

    updatePropertyInformationObject(payload)
  }

  const handleEditSeller = (values) => {
    const currentEditSeller = payload.sellers.filter(item => item.isSellerEditVisible)[0]
    const currentSellerId = currentEditSeller.seller_id

    const updated = {
      ...currentEditSeller,
      ...values,
      isSellerEditVisible: false,
    }

    payload.sellers.forEach((item, iter) => {
      if (item.seller_id === currentSellerId) {
        payload.sellers[iter] = { ...updated }
      }
    })

    updatePropertyInformationObject(payload)
  }

  const handleRepresentation = (e) => {
    const data = {
      ...propertyInformationObject,
      addressRepresentation: e.target.checked,
    }

    const mainAgent = buyersAgentInformationObject.agents.filter(item => item.isMainAgent)[0]
    // const currentAgent = {
    //   ...mainAgent,
    //   is_primary: 1,
    //   listing_agent_first_name: mainAgent.agent_first_name,
    //   listing_agent_last_name: mainAgent.agent_last_name,
    //   listing_agent_id: mainAgent.agent_id,
    // }
    console.log("mainAgent",mainAgent)
    const currentAgent = {
      ...mainAgent,
      is_primary: 1,
      listing_agent_first_name: user.firstname,
      listing_agent_last_name: user.lastname,
      listing_agent_id: user.agent_id,
      email: user.email,
    }

    if (e.target.checked) {
      data.listing_agents.forEach((item) => {
        item.is_primary = 0
      })

      data.listing_agents = [currentAgent, ...data.listing_agents]
    } else {
      data.listing_agents.shift()
      if (data.listing_agents.length > 0) {
        data.listing_agents[0].is_primary = 1
      }
    }

    updatePropertyInformationObject(data)
  }

  const handleStateChange = async(val) => {
    const state = getStateLowerCase(val)
    await updateStateForOffers(state)
    const submitPayload = {
      ...payload,
      ...payload.form[stateForOffer],
    }
    updatePropertyInformationObject(submitPayload)
  }

  const handleOnTypeChangeForListingAgents = (changedFields, allFields) => {
    console.log("allFields",allFields)
    console.log("changedFields",changedFields)
    const {
      co_listing_agent_details,
    } = allFields || {}
    // eslint-disable-next-line no-prototype-builtins
    if (changedFields.hasOwnProperty("co_listing_agent_details")) {
      const filtered = [...co_listing_agent_details].filter(item => item.listing_agent_first_name !== undefined)
      if (payloadRef.current.listing_agents.length > 1) {
        payloadRef.current.listing_agents = [payloadRef.current.listing_agents[0], ...filtered]
      } else {
        const curr = { ...allFields }
        delete curr.co_listing_agent_details
        payloadRef.current.listing_agents = [{ ...curr }, ...filtered]
      }
    } else {
      const primaryListingAgent = { ...allFields }
      // delete primaryListingAgent.co_listing_agent_details
      primaryListingAgent.is_primary = 1
      payloadRef.current.listing_agents[0] = {
        ...primaryListingAgent,

      }
    }

    const submitPayload = {
      ...payloadRef.current,
      ...payloadRef.current.form[stateForOffer],
    }

    //handleFinalSubmit(true, submitPayload)
    console.log("submitPayload", submitPayload)

    //do not refetch here.
    debouncedSubmit(true, submitPayload, null, dealToView, true)
  }

  const handleOnTypeChangeForPropertyInformation = (changedFields, allFields) => {
    //console.log(initialPropertyInformation)
    const { mls_number } = propertyInformationObject || {}
    const propertyPayload = {
      ...payloadRef.current,
      ...allFields,
      ...changedFields,
      currentState: stateForOffer,
      mls_number,
    }
    const curr = { ...allFields }
    propertyPayload.form[stateForOffer] = { ...curr }

    const isReloadDisabled = changedFields && typeof changedFields === "object"
    && (changedFields.hasOwnProperty("apn_number")
    || changedFields.hasOwnProperty("apt")
    || changedFields.hasOwnProperty("listing_type")
    || changedFields.hasOwnProperty("page")
    || changedFields.hasOwnProperty("plat_book")
    || changedFields.hasOwnProperty("subdivision")
    || changedFields.hasOwnProperty("phase")
    || changedFields.hasOwnProperty("unit")
    || changedFields.hasOwnProperty("block")
    || changedFields.hasOwnProperty("lot")
    || changedFields.hasOwnProperty("section_gmd")
    || changedFields.hasOwnProperty("district")
    || changedFields.hasOwnProperty("tax_parcel_id_number")
    || changedFields.hasOwnProperty("county")
    || changedFields.hasOwnProperty("zipcode")
    || changedFields.hasOwnProperty("city")
    || changedFields.hasOwnProperty("legal_description")
    || changedFields.hasOwnProperty("tax_id_number")
    || changedFields.hasOwnProperty("street")
    )

    console.log("isReloadDisabled", isReloadDisabled)

    debouncedSubmit(null, null, () => {
      updatePropertyInformationObject(propertyPayload)
      handleFinalSubmit(true, propertyPayload, dealToView, isReloadDisabled)
    }, dealToView, isReloadDisabled)
  }

  const handleInitPropertyChange = (locationObject) => {
    const { mls_number } = propertyInformationObject || {}
    const propertyPayload = {
      ...payloadRef.current,
      currentState: stateForOffer,
      ...locationObject,
      mls_number,
    }
    propertyPayload.form[stateForOffer] = { ...locationObject }

    console.log("propertyPayload", propertyPayload)

    debouncedSubmit(null, null, () => {
      updatePropertyInformationObject(propertyPayload)
      handleFinalSubmit(true, propertyPayload, dealToView, true)
    }, dealToView, true)
  }

  const handleSellersChange = (changedFields, allFields) => {
    const {
      co_sellers,
    } = allFields || {}
    // eslint-disable-next-line no-prototype-builtins
    if (changedFields.hasOwnProperty("co_sellers")) {
      if (payloadRef.current.sellers.length > 1) {
        co_sellers.forEach((item, index) => {
          if ((!co_sellers[index].lastname
          || co_sellers[index].lastname === undefined
          || co_sellers[index].lastname === null)) {
            co_sellers[index].lastname = null
          }

          if ((!co_sellers[index].type_of_ownership
          || co_sellers[index].type_of_ownership === undefined
          || co_sellers[index].type_of_ownership === null)) {
            co_sellers[index].type_of_ownership = null
          }

          if ((!co_sellers[index].ownership_name
            || co_sellers[index].ownership_name === undefined
            || co_sellers[index].ownership_name === null)) {
            co_sellers[index].ownership_name = null
          }

          if ((!co_sellers[index].email
            || co_sellers[index].email === undefined
            || co_sellers[index].email === null)) {
            co_sellers[index].email = null
          }

          if ((!co_sellers[index].phone
            || co_sellers[index].phone === undefined
            || co_sellers[index].phone === null)) {
            co_sellers[index].phone = null
          }
        })

        const filtered = co_sellers.filter((item => item.firstname))
        payloadRef.current.sellers = [payloadRef.current.sellers[0], ...filtered]
      } else {
        const curr = { ...allFields }
        if (!curr.lastname
          || curr.lastname === undefined
          || curr.lastname === null) {
          curr.lastname = null
        }
        if (!curr.type_of_ownership
          || curr.type_of_ownership === undefined
          || curr.type_of_ownership === null) {
          curr.type_of_ownership = null
        }

        if (!curr.phone
          || curr.phone === undefined
          || curr.phone === null) {
          curr.phone = null
        }

        if (!curr.ownership_name
          || curr.ownership_name === undefined
          || curr.ownership_name === null) {
          curr.ownership_name = null
        }

        if (!curr.email
          || curr.email === undefined
          || curr.email === null) {
          curr.email = null
        }

        delete curr.co_sellers
        payloadRef.current.sellers = [{ ...curr }, ...co_sellers]
      }
    } else {
      const primarySeller = { ...allFields }
      // if (allFields && !allFields.lastname) {
      //   return
      // }
      if (!primarySeller.lastname
        || primarySeller.lastname === undefined
        || primarySeller.lastname === null) {
        primarySeller.lastname = null
      }
      if (!primarySeller.type_of_ownership
        || primarySeller.type_of_ownership === undefined
        || primarySeller.type_of_ownership === null) {
        primarySeller.type_of_ownership = null
      }

      primarySeller.is_primary = 1
      payloadRef.current.sellers[0] = {
        ...primarySeller,
      }
    }

    const submitPayload = {
      ...payloadRef.current,
      ...payloadRef.current.form[stateForOffer],
    }

    // handleFinalSubmit(true, submitPayload)
    if (submitPayload && submitPayload.sellers && submitPayload.sellers.length > 0) {
      debouncedSubmit(true, submitPayload, null, dealToView, true)
    }
  }

  return (
    <>
      {/* {fetchingCurrentTabResponse ? (
        <p>Loading</p>
      ) : (

      )} */}
      <Wrap>
        {/*Property Agent Information*/}
        <SectionWrap>
          {/* <HeaderWrap>
              <SectionTitle>
                <p>Property Information</p>
              </SectionTitle>
              <SectionTitle>
                {updatingPropertyInformation === true ? isSaving : saved}
              </SectionTitle>
            </HeaderWrap> */}
          <ListRowWrap>
            <StyledCheckbox
              value="here"
              onChange={e => handleRepresentation(e)}
            >
              I represent this listing address.
            </StyledCheckbox>
            <StateWrap>
              <GreyState>
                <p>State</p>
              </GreyState>
              <State>
                <h2>{getState(stateForOffer)}</h2>
              </State>
            </StateWrap>
          </ListRowWrap>
          {fetchingCurrentTabResponse ? (
            <ShimmerBox w="100%" h="42px" />
          ) : (
            <ListRowWrap>
              <SelectField
                placeholder="Select State"
                options={RADIUS_STATES}
                //style={customSelectStyles}
                onChange={handleStateChange}
                defaultValue={getState(stateForOffer)}
                // arrowIcon={<DropdownIcon />}
                text="Select State*"
              />
            </ListRowWrap>
          )}
          {fetchingCurrentTabResponse ? (
            <ListRowWrap>
              <PropertyInformationForm
                key="property_form_loader"
                prefilledData={form[stateForOffer]}
                state={stateForOffer}
                //onFinish={handlePropertyFormUpdate}
                //handleOnTypeChangeForPropertyInformation={handleOnTypeChangeForPropertyInformation}
                disabled
              />
            </ListRowWrap>
          ) : (
            <ListRowWrap>
              <PropertyInformationForm
                key="property_form_final"
                prefilledData={form[stateForOffer]}
                state={stateForOffer}
                onFinish={handlePropertyFormUpdate}
                handleOnTypeChangeForPropertyInformation={handleOnTypeChangeForPropertyInformation}
                disabled={fetchingDocuments}
                form={formRef}
                getInitalPropertyValues={(locationObject) => {
                  //setInitialPropertyInformation(locationObject)
                  console.log(locationObject)
                  handleInitPropertyChange(locationObject)
                }}
              />
            </ListRowWrap>
          )}
        </SectionWrap>

        {/*Listing Agents Information*/}
        <SectionWrap>
          {fetchingCurrentTabResponse ? (
            <ListingAgentForm
              key="listing_agent_loader"
              title="Listing Agent Information"
              //handleEditModeChange={handleListingAgentGoBack}
              //onFinish={onFinishAddListingAgent}
              isFirst={!listing_agents || listing_agents.length <= 0}
              listingAgents={listing_agents}
              updatePropertyInformationObject={updatePropertyInformationObject}
              payload={payload}
              propertyInformationObject={propertyInformationObject}
              addressRepresentation={addressRepresentation}
              //handleOnTypeChangeForListingAgents={handleOnTypeChangeForListingAgents}
              disabled
            />
          ) : (
            <ListingAgentForm
              key="listing_agent_final"
              title="Listing Agent Information"
              handleEditModeChange={handleListingAgentGoBack}
              onFinish={() => {}}
              isFirst={!listing_agents || listing_agents.length <= 0}
              listingAgents={listing_agents}
              updatePropertyInformationObject={updatePropertyInformationObject}
              payload={payload}
              propertyInformationObject={propertyInformationObject}
              addressRepresentation={addressRepresentation}
              handleOnTypeChangeForListingAgents={handleOnTypeChangeForListingAgents}
              disabled={fetchingDocuments}
              form={listingAgentForm}
              radiusAgentCallbackSave={(selected, index) => {
                const currentPayload = { ...payloadRef.current }
                if (currentPayload.listing_agents.length > 1) { //updating indexed agent
                  const updatedAgent = {
                    ...currentPayload.listing_agents[index + 1],
                    ...selected,
                  }
                  currentPayload.listing_agents[index + 1] = updatedAgent
                } else if (currentPayload.listing_agents.length === 1 && !index) { //updating existing primary agent
                  const primaryAgent = {
                    ...currentPayload.listing_agents[0],
                    ...selected,
                  }
                  currentPayload.listing_agents[0] = { ...primaryAgent }
                } else if (currentPayload.listing_agents.length === 1 && index) { //first co agent
                  const newCoAgent = {
                    ...selected,
                    is_primary: 0,
                  }
                  currentPayload.listing_agents.push(newCoAgent)
                } else { //new primary agent
                  const newAgent = {
                    ...selected,
                    is_primary: 1,
                  }
                  currentPayload.listing_agents = [newAgent]
                }

                handleFinalSubmit(true, currentPayload, dealToView, true)
              }}
            />
          )}
          
        </SectionWrap>

        {/*Sellers Information*/}
        <SectionWrap>
          {fetchingCurrentTabResponse ? (
            <SellerForm
              key="seller_form_loader"
              title="Saving..."
              prefilledData={sellers}
              //handleEditModeChange={handleEditModeForSeller}
              //onFinish={handleEditSeller}
              //deleteSeller={handleDeleteSeller}
              //handleSellersChange={handleSellersChange}
              disabled
            />
          ) : (
            <SellerForm
              key="seller_form_final"
              title="Seller Information"
              prefilledData={sellers}
              handleEditModeChange={handleEditModeForSeller}
              onFinish={() => {}}
              deleteSeller={handleDeleteSeller}
              handleSellersChange={handleSellersChange}
              disabled={fetchingDocuments}
              form={sellerFormRef}
            />
          )}
        </SectionWrap>

        {/*Submit Form*/}
        {/* <SectionWrap>
            <BlueButton maxWidth onClick={handleFinalSubmit}>
              {updatingPropertyInformation ? (
                <ThreeDotLoader color="#FFF" />
              ) : (
                <p>Save &amp; continue</p>
              )}
            </BlueButton>
          </SectionWrap> */}
      </Wrap>

    </>
  )
}

export default withRouter(OldOffers(OffersV2(PropertyInformation)))
