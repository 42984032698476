import Link from "next/link"
import React from "react"
import styled from "styled-components"
import { xsMobile as mobile, tablet } from "@consts"
import WhiteArc from "images/Graphics/MortgageLandingPageAssets/WhiteArc.png"
import RadiusLogo from "images/Graphics/MortgageLandingPageAssets/RadiusWhiteLogo.png"
import { ATC } from "@fonts"

const Wrap = styled.div`
  padding: 100px 0px;
  background: #306F4D;
  position: relative;
  color: #fff;
  overflow: hidden;
  ${tablet(`
    padding: 100px 20px;
  `)}
  ${mobile(`
    padding: 20px;
    padding-bottom: 100px;
  `)}
`

const ArcImg = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
  max-width: 400px;
  ${mobile(`
    top: auto;
    width: 90%;
    height: 75px;
  `)}

  img {
    width: 100%;
  }
`

const InnerWrap = styled.div`
  display: flex;
  gap: 205px;


  @media(max-width: 767px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`

const BrandWrap = styled.div`
  flex: 1;
  ${tablet(`
    text-align: center;
  `)}
  ${mobile(`
    margin-top: 60px;
  `)}
  > img {
    position: relative;
    left: 70px;
    max-width: 300px;
    ${tablet(`
      max-width: 200px;
    `)}
  }
`

const InfoWrap = styled.div`
  ${tablet(`
    margin-left: 30px;
    position: relative;
    top: -25px;
  `)}
  ${mobile(`
    text-align: center;
    margin-top: 50px;
    margin-left: 0px;
  `)}
  
  > h3 {
    font-size: 24px;
    font-family: ${ATC} !important;
    font-weight: 500;
  }
  > h5 {
    font-family: ${ATC} !important;
    font-size: 16px;
    font-weight: 500;
  }
  ul {
    margin: 0px;
    padding: 0px;
    display: flex;
    list-style: none;
    gap: 20px;
    a {
      font-size: 16px;
    }
    ${mobile(`
      justify-content: center;
    `)}
  }

`

const LegalWrap = styled.div`
  display: flex;
  align-items: baseline;
  gap: 20px;
  ${tablet(`
    flex-direction: column;
  `)}
  ${mobile(`
    gap: 5px;
  `)}
  > p {
    margin: 0px;
    font-family: ${ ATC };
    font-size: 16px;
    /* ${tablet(`
      font-size: 12px;
    `)} */
  }

  a {
    color: white;
    text-decoration: none;
  }
`

const Footer = () => (
  <Wrap>
    <InnerWrap className="container">
      <ArcImg>
        <img src={WhiteArc} alt="" />
      </ArcImg>
      <BrandWrap>
        <img src={RadiusLogo} alt="Radius Mortgage Logo" />
      </BrandWrap>
      <InfoWrap>
        <h3>
          Low rates. Fast closes. Elevated service.
        </h3>
        <h5>Questions? Call (415) 855-4880</h5>
        <br />
        <br />
        <LegalWrap>
          <p>
            {`© Radius Agent, ${(new Date()).getFullYear()}. All rights reserved.`}
          </p>
          <ul>
            <li>
              <Link href="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
            <li>
              <a href="https://loandeskmortgage.com/terms-of-use" target="_blank">
                Terms of Use
              </a>
            </li>
            <li>
              <a href="https://loandeskmortgage.com/about-us" target="_blank">
                About Us
              </a>
            </li>
          </ul>
        </LegalWrap>
        <LegalWrap
          style={{
            marginTop: "20px",
          }}
        >
          <p>
            {`
              Loandesk Mortgage, LLC; NMLS #2262435 Loandesk Mortgage is an Equal 
              Opportunity Employer and supports the Fair Housing Act. Licensed 
              in 1 state. CA: Licensed by the California Department of Real Estate.
            `}
          </p>
        </LegalWrap>
      </InfoWrap>
    </InnerWrap>
  </Wrap>
)

export default Footer
