import React from "react"
import styled from "styled-components"
import colors from "@colors"
import { ATC } from "@fonts"
import { xsMobile } from "@consts"
import RealtyContainer from "container/Realty"
import EWebinarComponent from "dumbComponents/common/EWebinarScript"
import RARPopUpContainer from "container/App/RARPopUpContainer"
import queryString from "query-string"
import root from "window-or-global"
import LinkOrA from "./Navbar/LinkOrA"

const AnchorLink = styled(LinkOrA)`
  font-family: ${ATC};
  font-size: 14px;
  font-weight: 300;
  text-align: right;
  color: #282b31;
  text-decoration: none;
`

const CopyrightWrapper = styled.div`
  background: ${colors.white};
  text-align: center;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  ${xsMobile(`
    flex-direction: column;
    text-align: center;
  `)}
  ul {
    display: flex;
    ${xsMobile(`
      justify-content: space-evenly;
    `)}
    li {
      margin: 0px 10px;
      font-size: 12px;
    }
  }
`

const CopyPara = styled.p`
  margin-bottom: 0;
  font-family: ${ATC};
  font-size: 12px;
  font-weight: 300;
  text-align: left;
  display: flex;
  ${xsMobile(`
    text-align: center;
    margin: 20px auto 50px auto;
  `)}
`

const Wrap = styled.div`
  display: block;
  ${props => props.hide && `
    display: none;
    `}
`

class Footer extends React.Component {
  state = {
    isIosBrowser: false,
    // isISAPage: false,
  }

  componentDidMount() {
    const { search } = root.location
    const parsedQuery = queryString.parse(search)
    const isIosBrowser = parsedQuery && parsedQuery.platform === "ios"
    // const isISAPage = pathname && pathname.includes("/radius-assist")

    this.setState({
      isIosBrowser,
    })
  }

  componentDidUpdate(prevProps) {
    const {
      hideHubspot,
    } = prevProps
    // if (root.HubSpotConversations) {
    //   const status = root.HubSpotConversations.widget.status()
    //   if (status.loaded) {
    //     root.HubSpotConversations.widget.remove()
    //   } else if (!hideHubspot) {
    //     root.HubSpotConversations.widget.load()
    //   } else {
    //     root.HubSpotConversations.widget.remove()
    //   }
    // }
  }

  render() {
    const {
      isIosBrowser,
      // isISAPage,
    } = this.state

    const {
      hideWebinar,
    } = this.props

    return (
      <Wrap hide={isIosBrowser}>
        {!hideWebinar && (
          <EWebinarComponent />
        )}
        <CopyrightWrapper>
          <CopyPara>&copy; Radius Agent, {(new Date()).getFullYear()}. All rights reserved.</CopyPara>
          <ul style={{ marginBottom: 10 }}>
            <li>
              <AnchorLink to="/privacy-policy">
                Privacy Policy
              </AnchorLink>
            </li>
            <li>
              <AnchorLink to="/terms-and-conditions">
                Terms of Use
              </AnchorLink>
            </li>
          </ul>
        </CopyrightWrapper>
      </Wrap>
    )
  }
}

export default RealtyContainer(RARPopUpContainer(Footer))
